import React from 'react'
import { useTranslation } from 'react-i18next';
import { useUserInfoCRUD } from 'app/modules/profile/redux/useUserInfoCRUD';

import {
	AsideMenuList,
	AsideMenuItem,
	AsideMenuSubitem,
	AsideMenuSublist,
} from 'app/components/asideMenuComponents';


const AppAsideMenu: React.FC = () => {
	const { t } = useTranslation(undefined, { keyPrefix: 'layout.aside-menu' });
	const { userInfo, isAggregator } = useUserInfoCRUD();

	return (
		<AsideMenuList>
			<AsideMenuItem
				to="/analytics"
				text={t('analytics')}
				iconType="organisation"
			/>

			<AsideMenuSublist
				to="/invoices"
				text={t('invoices')}
				iconType="home"
			>
				<AsideMenuSubitem
					to="invoices/list"
					text={t('invoices/list')}
				/>

				<AsideMenuSubitem
					to="invoices/history"
					text={t('invoices/history')}
				/>
			</AsideMenuSublist>

			{(isAggregator) && (
				<AsideMenuItem
					to="/providers"
					text={t('providers')}
					iconType="card"
				/>
			)}

			{(userInfo?.isOwner) && (
				<AsideMenuItem
					to="/users"
					text={t('users')}
					iconType="users"
				/>
			)}

			{(isAggregator) && (
				<AsideMenuItem
					to="/currencies"
					text={t('rate')}
					iconType="settings"
				/>
			)}
		</AsideMenuList>
	);
}

export { AppAsideMenu };
