import React from 'react';
import { AppTextField, IAppTextFieldProps } from 'app/components';
import { useField } from 'formik';


const UNPField: React.FC<IAppTextFieldProps> = (props) => {
	const [formikField, formikMeta, { setValue, setError, setTouched }] = useField(props.name);

	return (
		<AppTextField
			onChange={(event: any) => {
				const value = event.target.value;
				if (value !== "" && !/^[a-zA-Z0-9-]+$/.test(value)) {
					return;
				}
				const upperCaseValue = value.toUpperCase?.();
				setValue(upperCaseValue);
			}}
			inputProps={{
				maxLength: 9
			}}
			{...props}
		/>
	);
}


UNPField.displayName = 'UNPField';
export { UNPField };
