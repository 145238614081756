import React, { createContext, useContext, useState, useCallback, useMemo, useEffect } from 'react';
import { isEqual } from 'lodash';
import { applyTableFilter, initQueryParams, tableCounts } from 'utils';
import { useServiceProvidersCRUD, useUsersCRUD } from 'hooks';
import { userInfoSchema } from 'app/yup';
// Types
import { EUserRoles, IGetUsersAllParams, IUserInfo } from 'apis/cabinetApi/extraTypes'
import { IUseUsersUIContextReturn, IUsersNavigate } from 'app/modules/users/types';


// Трюк с undefined в качестве init value контекста
// https://stackoverflow.com/questions/58193424/passing-state-with-usecontext-in-typescript
const UsersContext = createContext<IUseUsersUIContextReturn | undefined>(undefined);
const useUsersUIContext = () => {
	const contextValue = useContext(UsersContext);
	if (contextValue === undefined) throw new Error('Expected context value to be set');
	return contextValue;
};


const UsersUIProvider: React.FC<{ navigateActions: IUsersNavigate }> = ({ navigateActions, children }) => {
	const { getUsersForTable, saveUser, records } = useUsersCRUD();
	const { getServiceProviderFullData, setInitServiceProvider } = useServiceProvidersCRUD();

	//* ========== Логика таблицы ==========
	const [queryParams, setQueryParamsBase] = useState<IGetUsersAllParams>(initQueryParams);
	const setQueryParams = useCallback((nextQueryParams?: IGetUsersAllParams | null) => {
		if (nextQueryParams) {
			setQueryParamsBase((prevQueryParams) => {
				if (isEqual(prevQueryParams, nextQueryParams)) {
					return prevQueryParams;
				} else {
					return nextQueryParams;
				}
			});
		}
		if (nextQueryParams === null) {
			setQueryParamsBase({ ...initQueryParams });
			return;
		}
		if (nextQueryParams === undefined) {
			setQueryParamsBase((prevQueryParams) => ({ ...prevQueryParams }));
		}
	}, []);

	// Запрос на обновление records при изменении queryParams через пагинацию или фильтры
	useEffect(() => {
		getUsersForTable(queryParams);
	}, [queryParams]);

	const onTableFilterChange = useCallback((values: typeof queryParams.filter) => {
		applyTableFilter(queryParams, setQueryParams, values);
	}, [queryParams, setQueryParams]);

	const onTableRowExpand = useCallback((rowId: string) => {
		const chosenUser = records.find((user) => user.id === rowId);
		if (chosenUser?.role === EUserRoles.sp && chosenUser?.serviceProviderId) {
			setInitServiceProvider();
			return getServiceProviderFullData(chosenUser.serviceProviderId)
		};
	}, [records]);


	//* ========== Логика формы ==========
	const onUserFormSubmit = useCallback(async (values: IUserInfo) => {
		const castedValues = userInfoSchema.cast(values);
		const result = await saveUser(castedValues);
		if (result) {
			setQueryParams();
			setTimeout(() => {
				navigateActions.navigateToListPage();
			}, 0);
		}
	}, []);


	return (
		<UsersContext.Provider
			value={{
				queryParams,
				setQueryParams,
				onTableFilterChange,
				onTableRowExpand,
				onUserFormSubmit,

				...navigateActions
			}}
		>
			{children}
		</UsersContext.Provider>
	);
}


export { UsersUIProvider, useUsersUIContext };
