import React from 'react';
import { useTranslation } from 'react-i18next';

import { 
	Stack,
} from '@mui/material';

import {
	AppNavLink,
} from 'app/components';
import {
	FooterBar,
	LangToggler,
} from 'app/components/footerComponents';


const AppFooter: React.FC = () => {
	const keyPrefix = 'layout.footer.links';
	const { t } = useTranslation(undefined, { keyPrefix });

	const footerLinks = [
		{ text: 'reference',       to: '/reference' },
		{ text: 'confidentiality', to: '/confidentiality' },
		{ text: 'terms',           to: '/terms' },
	];

	return (
		<FooterBar>
			<LangToggler/>

			{/* <Stack direction="row" spacing={5}>
				{footerLinks.map(({ text, to }) => (
					<AppNavLink key={text} to={to}>{t(text)}</AppNavLink>
				))}
			</Stack> */}
		</FooterBar>
	);
}

export { AppFooter };
