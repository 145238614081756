// Types
import { PayloadAction } from '@reduxjs/toolkit';
//* Типы используются пока только для ПУ и пользователей,
//* при необходимости расширить


export interface IModuleState<T> {
	/** Статус загрузки `массива` records */
	listLoading: boolean;
	/** Статус ожидания `действия` на сервере CRUD  */
	actionLoading: boolean;
	/** Статус ожидания `полной инфы` edittingEntity */
	entityLoading: boolean;
	/** Массив сущностей для таблицы, удовлетворяющих условиям запроса getAll */
	records: Array<T>;
	/** Общее количество сущностей, удовлетворяющих условиям запроса getAll */
	total: number;
	/** Редактируемая сущность (либо полученная с сервера, либо init) */
	edittingEntity: T;
	/** Текст ошибки rejected запроса */
	lastError: string;
};

export enum EApiCallTypes {
	list   = 'list',
	action = 'action',
	entity = 'entity',
};

export const startAsyncCall = (state: any, action: PayloadAction<IStartCallActionPayload>): void => {
	const { callType } = action.payload;
	if (callType === EApiCallTypes.action) state.actionLoading = true;
	if (callType === EApiCallTypes.entity) state.entityLoading = true;
	if (callType === EApiCallTypes.list)   state.listLoading = true;
	state.lastError = '';
};
export interface IStartCallActionPayload {
	callType: EApiCallTypes;
};

export const catchAsyncError = (state: any, action: PayloadAction<ICatchErrorActionPayload>): void => {
	const { callType, errorMessage } = action.payload;
	if (callType === EApiCallTypes.action) state.actionLoading = false;
	if (callType === EApiCallTypes.entity) state.entityLoading = false;
	if (callType === EApiCallTypes.list)   state.listLoading = false;
	state.lastError = errorMessage;
};
export interface ICatchErrorActionPayload extends IStartCallActionPayload {
	errorMessage: string;
};
