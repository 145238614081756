import React, { useRef, useLayoutEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import {
	Box,
	Stack,
	CircularProgress,
	Typography,
	alpha,
} from '@mui/material';
import { AppIcon } from './';
// Types
import { To } from 'react-router-dom';
import { isFunction } from 'lodash';


const AppPageContainer: React.FC = ({ children }) => {
	return (
		<motion.div
			animate={{ opacity: 1, y: 0 }}
			initial={{ opacity: 0, y: 10 }}
			exit={{ opacity: 0, y: -10 }}
			transition={{ duration: 0.5 }}
		>
			{children}
		</motion.div>
	);
}


interface IAppPageTitleProps {
	title:     string;
	subtitle?: string;
	link?:     To | (() => void);
	action?:   React.ReactNode;
}
const AppPageTitle: React.FC<IAppPageTitleProps> = ({ title, subtitle, link, action }) => {
	const navigate = useNavigate();

	const backLinkHandler = useCallback(() => {
		if (link === undefined) return;
		if (isFunction(link)) {
			link();
		} else {
			navigate(link);
		}
	}, [link]);


	return (
		<Stack
			direction="row"
			justifyContent="space-between"
			alignItems="center"
			spacing={2}
			sx={(theme) => ({
				marginBottom: theme.spacing(8),
			})}
		>
			<Stack spacing={2}>
				{/* title */}
				<Typography
					component="h2"
					sx={(theme) => ({
						font: theme.font.header.lg,
						color: theme.color.primary,
					})}
				>
					{(link) && (
						<AppIcon
							iconType="next"
							size="sm"
							sx={(theme) => ({
								marginRight: theme.spacing(5),
								color: theme.color.secondary,
								verticalAlign: 'middle',
								cursor: 'pointer',
								transform: 'rotate(180deg)',
								'&:hover': {
									color: theme.color.epos.hover,
								}
							})}
							onClick={backLinkHandler}
						/>
					)}

					{title}
				</Typography>

				{/* subtitle */}
				<Typography
					sx={(theme) => ({
						paddingLeft: (link) ? theme.spacing(10) : 0,
						font: theme.font.small.md,
						color: theme.color.secondary,
					})}
				>
					{subtitle}
				</Typography>
			</Stack>

			{action}
		</Stack>
	);
}


const AppPageLoadscreen: React.FC<{ isLoading: boolean }> = ({ isLoading, children }) => {
	const ref = useRef<HTMLDivElement>();
	useLayoutEffect(() => {
		if (!ref.current) return;
		ref.current.classList.add('transition');
	}, [isLoading, ref]);

	return (
		<Box sx={{ position: 'relative' }}>
			{(isLoading) && (
				<Box
					ref={ref}
					// className={'transition'}
					sx={(theme) => ({
						position: 'absolute',
						top: 0, left: 0, right: 0, bottom: 0,
						zIndex: 1000,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						background: alpha(theme.color.bg_01 || '', 0.5),
						transition: 'opacity 1s 0.2s',
						opacity: 0,
						// backdropFilter: 'blur(3px)'
						'&.transition': {
							opacity: 1
						}
					})}
				>
					<CircularProgress color="primary" size={50} />
				</Box>
			)}

			{children}
		</Box>
	);
}


export { AppPageContainer, AppPageTitle, AppPageLoadscreen };
