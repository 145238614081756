import NumberFormat from 'react-number-format';
import { useDirectoryCRUD } from 'hooks';


interface IAmountNumberFormatProps {
	amount:        number | null | undefined;
	currencyCode?: string | null | undefined;
	prefix?:       string;
};
const AmountNumberFormat: React.FC<IAmountNumberFormatProps> = ({ amount, currencyCode, prefix }) => {
	const { currencies } = useDirectoryCRUD();
	const currency = currencies.find((cur) => cur.code == currencyCode)?.abbr;
	const suffix = currency ? ` ${currency}` : undefined;

	return (
		<NumberFormat
			value={amount}
			displayType="text"
			prefix={prefix}
			suffix={suffix}
			thousandSeparator={' '}
			decimalScale={2}
			fixedDecimalScale={true}
			decimalSeparator={','}
		/>
	)
}
AmountNumberFormat.displayName = 'AmountNumberFormat';


interface IPercentNumberFormatProps {
	percent: number | null | undefined;
};
const PercentNumberFormat: React.FC<IPercentNumberFormatProps> = ({ percent }) => {
	return (
		<NumberFormat
			value={percent}
			displayType="text"
			suffix={' %'}
			thousandSeparator={' '}
			decimalScale={2}
			fixedDecimalScale={true}
			decimalSeparator={','}
		/>
	)
}
PercentNumberFormat.displayName = 'PercentNumberFormat';


interface IPhoneNumberFormatProps {
	phone: string | null | undefined;
}
const PhoneNumberFormat: React.FC<IPhoneNumberFormatProps> = ({ phone }) => {
	return (
		<NumberFormat
			displayType="text"
			value={phone}
			format="+### (##) ### ## ##"
			isNumericString
		/>
	);
}


export { AmountNumberFormat, PercentNumberFormat, PhoneNumberFormat };
