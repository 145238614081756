import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserInfoCRUD } from 'hooks';
import {
	Stack,
	Typography,
} from '@mui/material';
import {
	AppAvatar,
	AppPopover,
	AppIcon,
} from 'app/components';
import { HeaderProfileMenu } from './HeaderProfileMenu';


const ProfileToggler: React.FC = () => {
	const keyPrefix = `layout.header.profile`;
	const { t } = useTranslation(undefined, { keyPrefix });
	const { userInfo } = useUserInfoCRUD();

	const [anchorEl, setAnchorEl] = useState<Element | null>(null);
	const handleClick = (event: React.SyntheticEvent) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	}


	return (<>
		<Stack
			component="button"
			onClick={handleClick}
			direction="row"
			alignItems="center"
			spacing={3}
			sx={(theme) => ({
				flex: '0 0 auto',
				justifyContent: 'space-between',
				width: theme.spacing(60),
				minWidth: theme.spacing(60),
				maxWidth: theme.spacing(60),
				padding: 0,
				color: theme.color.primary,
				background: 'transparent',
				border: 'none',
				cursor: 'pointer',
				'&:hover': {
					color: theme.color.epos.hover,
				}
			})}
		>
			<AppAvatar
				alt={'Profile image'}
				src={''}
			/>

			<Typography
				style={{ marginRight: 'auto' }}
				sx={(theme) => ({
					font: theme.font.small.md,
					overflow: 'hidden',
					whiteSpace: 'nowrap',
					textOverflow: 'ellipsis',
				})}
			>
				{userInfo?.person?.fullName}
			</Typography>

			<AppIcon
				iconType="down"
				sx={(theme) => ({
					color: theme.color.secondary,
				})}
			/>
		</Stack>

		<AppPopover
			open={Boolean(anchorEl)}
			onClose={handleClose}
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
		>
			<HeaderProfileMenu/>
		</AppPopover>
	</>);
}


export { ProfileToggler };
