import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { useField } from 'formik';
import { AppAsyncAutocomplete, IAppAsyncAutocompleteProps, Typography } from 'app/components';
// Types
import { IUserInfo } from 'apis/cabinetApi/extraTypes';
import { eposCabinetApiCalls } from 'apis/cabinetApi/cabinetApiCalls';


interface IUserAutocompleteProps {
	name:                  string;
	onAutocompleteChange?: IAppAsyncAutocompleteProps<IUserInfo>['onAutocompleteChange'];
	disabled?:             boolean;
}
const UserAutocomplete: React.FC<IUserAutocompleteProps> = (props) => {
	const { name, onAutocompleteChange, disabled } = props;

	const [{ value }, formikMeta, { setValue }] = useField(name);

	const initialValue = useRef(value);

	const [autocompleteValue, setAutocompleteValue] = useState<IUserInfo | null>(null);
	
	const getInitUserInfo = useCallback(async () => {
		if (!initialValue.current) return;
		const response = await eposCabinetApiCalls.getUserById(initialValue.current);
		if (response.ok && response.data) {
			setAutocompleteValue(response.data);
		}
	}, [initialValue.current]);

	useLayoutEffect(() => {
		getInitUserInfo();
	}, [getInitUserInfo]);

	const autocompleteChangeHandler = useCallback((value: IUserInfo | null) => {
		setValue(value?.id ?? null);
		setAutocompleteValue(value);
	}, []);

	const getOptionLabel = (option: IUserInfo): string => {
		const fullName = option.person?.fullName;
		const email = option.loginInfo?.email?.toLowerCase();
		if (fullName && email) {
			return `${fullName} (${email})`;
		}
		if (fullName && !email) {
			return fullName;
		}
		if (!fullName && email) {
			return email;
		}
		return '';
	}


	return (
		<AppAsyncAutocomplete
			name={name}
			autocompleteValue={autocompleteValue}
			onAutocompleteChange={autocompleteChangeHandler}
			getOptionsAsync={(searchString, count) => {
				return eposCabinetApiCalls.getUsersAll({
					skip: 0,
					count,
					filter: { searchString }
				});
			}}
			getOptionLabel={(option: IUserInfo) => {
				const fullName = option.person?.fullName;
				const email = option.loginInfo?.email?.toLowerCase();
				if (fullName && email) {
					return `${fullName} ${email}`;
				}
				if (fullName && !email) {
					return fullName;
				}
				if (!fullName && email) {
					return email;
				}
				return '';
			}}
			renderOption={(props, option: IUserInfo) => (
				<li {...props} key={option.id}>
					{getOptionLabel(option)}
				</li>
			)}
			disabled={disabled}
		/>
	);
}


UserAutocomplete.displayName = 'UserAutocomplete';
export { UserAutocomplete };
