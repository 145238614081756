// Types
import { EApiCallTypes, IModuleState } from 'reduxStore/reduxTypes';
import {
	IGetInvoicesAllParams,
	IGetServiceProvidersAllParams,
	IInvoice,
	IInvoiceListItem,
	IInvoiceQRCode,
	InvoiceStateEnum,
} from 'apis/cabinetApi/extraTypes';
import { PayloadAction } from '@reduxjs/toolkit';


//! ========== Common types ==========
export interface IInvoiceAsProp {
	invoice: IInvoice;
};
export interface IInvoiceListItemAsProp {
	invoiceListItem: IInvoiceListItem;
};
export interface IInvoiceTablesAsProp {
	table: 'list' | 'history';
};


//! ========== Form types ==========
export enum EDiscountTypes {
	percent = 'DISCOUNT_PERCENT',
	amount  = 'DISCOUNT_AMOUNT'
};


//! ========== Provider types ==========
export interface IInvoicesNavigate {
	navigateToListPage:    () => void;
	navigateToHistoryPage: () => void;
	navigateToNewPage:     () => void;
	navigateToEditPage:    (id: string) => void;
	navigateToCopyPage:    (id: string) => void;
	navigateBack:          () => void;
};

export interface IUseInvoicesUIContext extends IInvoicesNavigate {
	// List table (все states)
	queryParamsList:						IGetInvoicesAllParams;
	setQueryParamsList:					(nextQueryParams?: IGetInvoicesAllParams | null) => void;
	onListTableFilterChange:		(values: IGetInvoicesAllParams['filter']) => void;
	// List history (states 20 | 50 | 70)
	queryParamsHistory:					IGetInvoicesAllParams;
	setQueryParamsHistory:			(nextQueryParams?: IGetInvoicesAllParams | null) => void;
	onHistoryTableFilterChange:	(values: IGetInvoicesAllParams['filter']) => void;

	onTableRowExpand:						(id: string) => void;
	onInvoiceFormSubmit:				(values: IInvoice) => void;
};

export const historyStates: Array<InvoiceStateEnum> = [
	InvoiceStateEnum.NUMBER_20,
	InvoiceStateEnum.NUMBER_50,
	InvoiceStateEnum.NUMBER_70
];


//! ========== useCRUD types ==========
export interface IInvoicesState extends Omit<IModuleState<IInvoice>, 'records'|'total'> {
	listRecords:    Array<IInvoiceListItem>,
	listTotal:      number;
	historyRecords: Array<IInvoiceListItem>,
	historyLoading: boolean;
	historyTotal:   number;
	invoiceQrData:  IInvoiceQRCode | null;
};
export enum EHistoryApiCallType { history = 'history' };
export type EInvoicesApiCallTypes = EApiCallTypes | EHistoryApiCallType;
export interface IInvoicesStartCallActionPayload {
	callType: EInvoicesApiCallTypes;
};
export const startInvoiceAsyncCall = (state: any, action: PayloadAction<IInvoicesStartCallActionPayload>): void => {
	const { callType } = action.payload;
	if (callType === EApiCallTypes.action) state.actionLoading = true;
	if (callType === EApiCallTypes.entity) state.entityLoading = true;
	if (callType === EApiCallTypes.list)   state.listLoading = true;
	if (callType === EHistoryApiCallType.history) state.historyLoading = true;
}

interface ICatchInvoiceErrorActionPayload extends IInvoicesStartCallActionPayload {
	errorMessage: string;
};
export const catchInvoiceAsyncError = (state: any, action: PayloadAction<ICatchInvoiceErrorActionPayload>): void => {
	const { callType, errorMessage } = action.payload;
	if (callType === EApiCallTypes.action) state.actionLoading = false;
	if (callType === EApiCallTypes.entity) state.entityLoading = false;
	if (callType === EApiCallTypes.list)   state.listLoading = false;
	state.lastError = errorMessage;
}

export interface IUseInvoicesCRUDReturn extends IInvoicesState {
	getInvoicesForListTable:	  (params: IGetServiceProvidersAllParams) => Promise<void>;
	getInvoicesForHistoryTable: (params: IGetServiceProvidersAllParams) => Promise<void>;
	getInvoiceFullData:		      (id: string) => Promise<boolean>;
	setInitInvoice:				      () => void;
	saveInvoice:                (values: IInvoice) => Promise<boolean>;
	changeInvoiceState:         (
		id: string,
		state: InvoiceStateEnum.NUMBER_0 | InvoiceStateEnum.NUMBER_10 | InvoiceStateEnum.NUMBER_30
	) => void;
	getInvoiceQrCode:           (id?: string) => Promise<void> | void;
	clearQrCode:                () => void;
	stornoInvoice:              (id: string, payDateUTC: Date | null | undefined) => void;
};
