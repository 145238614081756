import React, { useState } from 'react';
import {
	Box,
} from '@mui/material';
import {
	AppButton,
	AppIconButton,
	AppMenu,
	AppMenuItem
} from '../';
// Types
import { SxProps, Theme } from '@mui/material/styles';
import { TIconType } from '../';


interface IAppTableRowMenuButtonProps {
	text:      string;
	iconType?: TIconType;
	onClick:   React.MouseEventHandler<HTMLButtonElement>;
};
interface IAppTableRowMenuProps {
	rowId:     string;
	buttons:   Array<IAppTableRowMenuButtonProps>;
	sx?:       SxProps<Theme>;
	disabled?: boolean;
};
const AppTableRowMenu: React.FC<IAppTableRowMenuProps> = ({ rowId, buttons, sx=[], disabled }) => {
	const [anchorEl, setAnchorEl] = useState<Element | null>(null);

	const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		setAnchorEl(anchorEl ? null : event.currentTarget);
	}

	const handleButtonKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
		if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
			event.preventDefault();
			setAnchorEl(event.currentTarget);
		}
	}

	const handleClose = () => {
		setAnchorEl(null);
	}

	return (
		<Box
			sx={[
				(theme) => ({
					marginTop: theme.spacing(-1),
				}),
				...(Array.isArray(sx) ? sx : [sx])
			]}
		>
			<AppIconButton
				iconType="more"
				onClick={handleButtonClick}
				onKeyDown={handleButtonKeyDown}
				sx={(theme) => ({
					color: theme.color.secondary,
				})}
				disabled={disabled}
			/>

			<AppMenu
				open={Boolean(anchorEl)}
				onClose={handleClose}
				anchorEl={anchorEl}
				componentsProps={{
					root: {
						placement: 'bottom-end',
						keepMounted: false
					}
				}}
			>
				{buttons.map(({ text, iconType, onClick }) => (
					<AppMenuItem key={text}>
						<AppButton
							variant="list"
							iconType={iconType}
							fullWidth
							onClick={(event) => {
								event.stopPropagation();
								onClick(event);
								handleClose();
							}}
						>
							{text}
						</AppButton>
					</AppMenuItem>
				))}
			</AppMenu>
		</Box>
	);


	// return (
	// 	<Stack
	// 		sx={[
	// 			(theme) => ({
	// 				marginTop: theme.spacing(-1),
	// 			}),
	// 			...(Array.isArray(sx) ? sx : [sx])
	// 		]}
	// 	>
	// 		<AppIconButton
	// 			iconType="more"
	// 			onClick={handleClick}
	// 			sx={(theme) => ({
	// 				color: theme.color.secondary,
	// 			})}
	// 		/>

	// 		<AppPopover
	// 			open={Boolean(anchorEl)}
	// 			onClose={handleClose}
	// 			anchorEl={anchorEl}
	// 			anchorOrigin={{
	// 				vertical: 'top',
	// 				horizontal: 'right',
	// 			}}
	// 			transformOrigin={{
	// 				vertical: 'bottom',
	// 				horizontal: 'right',
	// 			}}
	// 		>
	// 			<Stack
	// 				sx={(theme) => ({
	// 					padding: `${theme.spacing(2)} 0`,
	// 					background: theme.color.bg_01,
	// 					borderRadius: theme.radius.x4,
	// 					boxShadow: theme.depth.tertiary,
	// 				})}
	// 			>
	// 				{buttons.map(({ text, iconType, onClick }) => (
	// 					<AppButton
	// 						key={text}
	// 						variant="list"
	// 						iconType={iconType}
	// 						onClick={(event) => {
	// 							onClick(event);
	// 							handleClose();
	// 						}}
	// 					>
	// 						{text}
	// 					</AppButton>
	// 				))}
	// 			</Stack>
	// 		</AppPopover>
	// 	</Stack>
	// );
}


export { AppTableRowMenu };
export  type { IAppTableRowMenuProps, IAppTableRowMenuButtonProps };
