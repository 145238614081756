import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import {
	AppModal,
	AppCardContainer,
	AppCardHeader,
	AppCardContent,
	AppCardFooter,
	AppCardActions,
	AppButton,
	AppIconButton,
} from 'app/components';
import { useLayoutUIContext } from 'app/providers';


const AppConfirmModal: React.FC = () => {
	const { t } = useTranslation(undefined, { keyPrefix: 'components.buttons' });
	
	const { comfirmModalParams, hideConfirmModal } = useLayoutUIContext();


	return (comfirmModalParams) ? (
		<AppModal
			open={Boolean(comfirmModalParams)}
			onClose={hideConfirmModal}
			scroll="body"
		>
			<AppCardContainer sx={(theme) => ({ width: theme.spacing(100) })}>
				<AppCardHeader title={comfirmModalParams.title}>
					<AppIconButton iconType="close" onClick={hideConfirmModal}/>
				</AppCardHeader>

				<AppCardContent>
					<Typography
						sx={(theme) => ({
							font: theme.font.small.md,
							color: theme.color.primary,
						})}
					>
						{comfirmModalParams.text}
					</Typography>
				</AppCardContent>

				<AppCardFooter>
					<AppCardActions>
						<AppButton
							variant="text"
							onClick={hideConfirmModal}
							sx={(theme) => ({
								flexBasis: '50%',
								maxWidth: '50%'
							})}
						>
							{t('cancel')}
						</AppButton>

						{(comfirmModalParams.action) && (
							<AppButton
								variant="main"
								onClick={comfirmModalParams.callback}
								sx={(theme) => ({
									flexBasis: '50%',
									maxWidth: '50%'
								})}
							>
								{comfirmModalParams.action}
							</AppButton>
						)}
					</AppCardActions>
				</AppCardFooter>
			</AppCardContainer>
		</AppModal>
	) : (
		null
	);
}


AppConfirmModal.displayName = 'AppConfirmModal';
export { AppConfirmModal };
