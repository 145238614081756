import * as Yup from 'yup';
import { errMsg } from './_errorMessages';
import { nullableString } from './_shortcuts';


const serviceProviderIIISchema = () => Yup.object().shape({
	'clientId': nullableString(),
		// .required(errMsg.required),
	'clientSecret': nullableString(),
		// .when('clientId', ([clientId], schema) => {
		// 	return clientId ? schema.required(errMsg.required) : schema;
		// }),
	'prefixId': nullableString(),
	'enable': Yup.boolean()
		.default(true)
}).nullable().default(null);


export { serviceProviderIIISchema };
