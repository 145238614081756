import React, { useCallback } from 'react';
import { extend } from 'lodash';
import { useSnackbar, SnackbarContent } from 'notistack';
import {
	Stack,
	Typography,
} from '@mui/material';
import { alpha } from '@mui/material';
// Types
import { SnackbarKey, SnackbarMessage } from 'notistack';
import { ENotistackVariants } from 'app/providers';


interface IAppNotificationProps {
	id:      SnackbarKey;
	message: {
		message: string;
		variant: ENotistackVariants;
	};
}
const AppNotification = React.forwardRef<HTMLDivElement, IAppNotificationProps>((props, ref) => {
	const { id } = props;
	const { message, variant } = props.message;
	
	const { closeSnackbar } = useSnackbar();

	const handleDismiss = useCallback(() => {
		closeSnackbar(id);
	}, [id, closeSnackbar]);

	return (
		<SnackbarContent ref={ref}>
			<Stack
				alignItems="center"
				sx={(theme) => extend({
					width: '100vw',
					minWidth: '100vw',
					maxWidth: '100vw',
					padding: theme.spacing(2),
					font: theme.font.body.md,
					color: theme.color.bg_01,
					background: alpha(theme.color.epos.notification || '', 0.5),
					borderTop: `1px solid ${alpha(theme.color.bg_01 || '', 0.5)}`,
					borderBottom: `1px solid ${theme.color.epos.notification}`,
					backdropFilter: 'blur(5px)', 
				}, (variant === ENotistackVariants.success) && {
					background: alpha(theme.color.status.success || '', 0.5),
					borderBottom: `1px solid ${theme.color.status.success}`,
				}, (variant === ENotistackVariants.error) && {
					background: alpha(theme.color.status.error || '', 0.5),
					borderBottom: `1px solid ${theme.color.status.error}`,
				}, (variant === ENotistackVariants.warning) && {
					background: alpha(theme.color.status.warning || '', 0.5),
					borderBottom: `1px solid ${theme.color.status.warning}`,
				}, (variant === ENotistackVariants.info) && {
					background: alpha(theme.color.epos.notification || '', 0.5),
					borderBottom: `1px solid ${theme.color.epos.notification}`,
				})}
			>
				<Typography variant="subtitle2">{message}</Typography>
			</Stack>
		</SnackbarContent>
	);
});

export { AppNotification };
