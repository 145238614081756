import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { sessionStorageHelper } from 'utils';
// Types
import { IServiceProvider, IUserInfo } from 'apis/cabinetApi/extraTypes';



interface IUserInfoState {
	accessToken:	string | null;
	accessError:  string | null;
	userInfo:			IUserInfo | null;
	userProvider: IServiceProvider | null;
};
const userInfoInitState: IUserInfoState = {
	accessToken:	sessionStorageHelper.getAccessToken() || null,
	accessError:  null,
	userInfo:			null,
	userProvider: null
};

const userInfoSlice = createSlice({
	name: 'userInfo',
	initialState: userInfoInitState,
	reducers: {
		//*	getTokensWithAuthCode / getTokensWithRefreshToken {status: ok}
		accessTokenFetched: (state, action: PayloadAction<string>) => {
			state.accessToken = action.payload;
			sessionStorageHelper.setAccessToken(action.payload);
		},
		//*	getTokensWithAuthCode / getTokensWithRefreshToken {status: error}
		catchAuthError: (state, action: PayloadAction<string>) => {
			state.accessError = action.payload || 'error';
			// state.accessToken = null;
			// sessionStorageHelper.removeAccessToken();
		},

		//*	getUserById {status: ok}
		userInfoFetched: (state, action: PayloadAction<IUserInfo>) => {
			state.userInfo = action.payload;
		},
		//* getServiceProviderById {status: ok}
		userProviderFetched: (state, action: PayloadAction<IServiceProvider>) => {
			state.userProvider = action.payload;
		}

	}
});


const userInfoActions = userInfoSlice.actions;
export { userInfoSlice, userInfoActions };
