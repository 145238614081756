import React from 'react';
import {
	Box,
	CircularProgress,
} from '@mui/material';
import { AppIcon } from 'app/components';

import './SplashScreen.scss';


const SplashScreen: React.FC = () => {
	return (
		<div id="splash-screen">
			<Box
				sx={(theme) => ({
					padding: theme.spacing(5),
					backdropFilter: 'blur(5px)',
				})}
			>
				<AppIcon
					iconType="logotype"
					sx={(theme) => ({
						width: theme.spacing(30),
						height: theme.spacing(30),
						color: theme.color.epos.primary,
					})}
				/>
			</Box>

			<CircularProgress
				size={60}
				sx={(theme) => ({
					color: theme.color.epos.primary,
				})}
			/>
		</div>
	);
}

export { SplashScreen };
