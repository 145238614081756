import { useField } from 'formik';
import { AppNumberField } from '../AppNumberField';
// Types
import { CurrencyRateTypeEnum } from 'apis/cabinetApi/extraTypes';


const CurrencyRateField: React.FC = () => {
	const [currencyRateTypeField] = useField('type');
	const type = currencyRateTypeField.value ?? CurrencyRateTypeEnum.NUMBER_3;
	const [currencyRateRateField] = useField('rate');
	const rate = currencyRateRateField.value;

	const prefix = (type === CurrencyRateTypeEnum.NUMBER_2 && rate > 0) ? '+' : undefined;

	return (
		<AppNumberField
			name="rate"
			rate
			currency={(type === CurrencyRateTypeEnum.NUMBER_2) ? undefined : '933'}
			percent={(type === CurrencyRateTypeEnum.NUMBER_2)}
			prefix={prefix}
			allowNegative={(type === CurrencyRateTypeEnum.NUMBER_2)}
		/>
	);
}
CurrencyRateField.displayName = 'CurrencyRateField';


export { CurrencyRateField };
