import React from 'react';
import { muiEsasStyled } from 'app/theme';
// Types
import { SxProps, Theme } from '@mui/material/styles';


interface IAppTableCellProps extends React.ComponentProps<'td'> {
	sx?: SxProps<Theme>;
};
const AppTableCell: React.FC<IAppTableCellProps> = muiEsasStyled('td', {
	// Перечисляем пропсы, которые не будут отображаться атрибутами в DOM
	shouldForwardProp: (prop) => (
		prop !== 'sx'
	),
	name: 'AppTableCell'
})<IAppTableCellProps>((props) => {
	const {
		theme,
	} = props;

	// Определяем стили в зависимости от пропсов для [sx]
	const general_sx: any = {
		padding: `${theme.spacing(4)} ${theme.spacing(2)}`,
		font: theme.font.small.md,
		color: theme.color.secondary,
		textAlign: 'left',
		verticalAlign: 'top',
		textOverflow: 'ellipsis',
		wordBreak: 'break-word',
	};

	return [
		general_sx,
	];
});

export { AppTableCell };
