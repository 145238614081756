import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';


i18n
	// Подключение бэкенда i18next
	.use(Backend)
	// Автоматическое определение языка 
	.use(LanguageDetector)
	// модуль инициализации
	.use (initReactI18next)
	.init({
		// Стандартный язык
		fallbackLng: 'ru',
		// debug: true,
		backend: {
			loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`,
		},
		// Распознавание и кэширование языковых кук
		detection: {
			// order and from where user language should be detected
			order: ['querystring', 'cookie', 'localStorage'],
			// cache user language on
			caches: ['localStorage', 'cookie'],
		},
		interpolation: {
			escapeValue: false
		},
		// debug: true
	});

enum Ens {
	constants = 'constants',
	schemas   = 'schemas',
};


export { i18n, Ens };
