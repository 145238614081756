import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth, useUserInfoCRUD } from 'hooks';
import {
	Box,
	Stack,
	Typography,
} from '@mui/material';
import {
	AppButton,
	AppDivider,
	AppAvatar,
	AppNavLink,
} from 'app/components';


const HeaderProfileMenu: React.FC = () => {
	const { t } = useTranslation(undefined, { keyPrefix: 'layout.header.profile' });
	const { logOutUser } = useAuth();
	const { userInfo, isAggregator, userProvider } = useUserInfoCRUD();

	return (
		<Box
			sx={(theme) => ({
				transform: `translate(18px, ${theme.spacing(1)})`,
				
			})}
		>
			{/* Стрелка над меню */}
			<Box
				sx={(theme) => ({
					position: 'absolute',
					right: 0,
					width: theme.spacing(5),
					height: theme.spacing(5),
					background: theme.color.bg_01,
					borderLeft: theme.border.primary,
					borderTop: theme.border.primary,
					borderColor: theme.color.tertiary,
					transform: `translate(-${theme.spacing(5)}, -50%) rotate(45deg)`
				})}
			/>

			<Stack
				spacing={6}
				sx={(theme) => ({
					width: theme.spacing(80),
					padding: theme.spacing(6),
					background: theme.color.bg_01,
					border: theme.border.primary,
					borderColor: theme.color.tertiary,
					borderRadius: theme.radius.x4,
					boxShadow: theme.depth.secondary,
				})}
			>
				{/* Аватар и ФИО */}
				<Stack
					direction="row"
					alignItems="center"
					spacing={4}
					style={{ marginTop: 0 }}
				>
					<AppAvatar
						alt={'Profile image'}
						src={''}
						size="large"
						sx={(theme) => ({
							margin: `-${theme.spacing(2)} 0`,
						})}
					/>

					<Typography
						sx={(theme) => ({
							font: theme.font.body.md,
							color: theme.color.primary
						})}
					>
						{userInfo?.person?.fullName}
					</Typography>
				</Stack>

				<AppDivider/>

				{/* <AppNavLink to="/profile" color="secondary">
					{t('settings')}
				</AppNavLink> */}

				<Typography
					sx={(theme) => ({
						font: theme.font.small.md,
						color: theme.color.primary
					})}
				>
					{(isAggregator) ? (
						t('aggregator')
					) : (
						`${t('provider')} "${userProvider?.legalInfo?.shortName}"`
					)}
				</Typography>

				<AppDivider/>

				{/* <AppNavLink to="/help" color="secondary">
					{t('help')}
				</AppNavLink> */}

				<AppButton onClick={logOutUser}>
					{t('logout')}
				</AppButton>
			</Stack>
		</Box>
	);
}

export { HeaderProfileMenu };
