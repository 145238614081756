import React from 'react';
import { Box, Stack } from '@mui/material';
import { AppIconButton } from './';
// Types
import { BoxProps, SxProps, Theme } from '@mui/system';


interface AppListDataCardProps extends BoxProps {
	editAction?:   (event: React.MouseEvent) => void;
	deleteAction?: (event: React.MouseEvent) => void;
	sx?:            SxProps<Theme>;
};
const AppListDataCard: React.FC<AppListDataCardProps> = ({ editAction, deleteAction, sx = [], children, ...other }) => {
	return (
		<Box
			sx={[
				(theme) => ({
					height: '100%',
					minHeight: theme.spacing(11),
					padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
					border: theme.border.primary,
					borderColor: theme.color.tertiary,
					borderRadius: theme.spacing(1),
					background: theme.color.bg_02,
					cursor: 'pointer',
				}),
				...(Array.isArray(sx) ? sx : [sx])
			]}
			{...other}
		>
			<Stack
				direction="row"
				spacing={2}
				sx={(theme) => ({
					float: 'right',
					flexGrow: 0,
					flexShrink: 0,
					marginLeft: theme.spacing(2),
					marginBottom: theme.spacing(1),
				})}
			>
				{(editAction) && (
					<AppIconButton
						size="sm"
						iconType="edit"
						onClick={(event) => {
							event.stopPropagation();
							editAction(event);
						}}
					/>
				)}

				{(deleteAction) && (
					<AppIconButton
						size="sm"
						iconType="delete"
						onClick={(event) => {
							event.stopPropagation();
							deleteAction(event);
						}}
					/>
				)}
			</Stack>

			{children}
		</Box>
	);
}

export { AppListDataCard };
