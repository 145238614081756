import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { invoiceInitialValues } from './initialFormValues';
// Types
import {
	IPaged,
	IInvoice,
	IInvoiceListItem,
	IInvoiceQRCode
} from 'apis/cabinetApi/extraTypes';
import { IInvoicesState, startInvoiceAsyncCall, catchInvoiceAsyncError } from '../types';


const invoicesInitialState: IInvoicesState = {
	actionLoading:  false,
	entityLoading:  false,
	listRecords:    [],
	listLoading:    false,
	listTotal:      0, 
	historyRecords: [],
	historyLoading: false,
	historyTotal:   0, 
	edittingEntity: invoiceInitialValues,
	lastError:      '',

	invoiceQrData:  null
};


const invoicesSlice = createSlice({
	name: 'invoices',
	initialState: invoicesInitialState,

	reducers: {
		startCall:  startInvoiceAsyncCall,
		catchError: catchInvoiceAsyncError,

		setInitEdittingEntity: (state) => {
			state.edittingEntity = invoiceInitialValues;
			state.entityLoading = false;
		},

		getInvoicesAllListFulfilled: (state, action: PayloadAction<IPaged<IInvoiceListItem>>) => {
			const { records, total } = action.payload;
			state.listRecords = records ?? [];
			state.listTotal = total;
			state.listLoading = false;
		},
		getInvoicesAllHistoryFulfilled: (state, action: PayloadAction<IPaged<IInvoiceListItem>>) => {
			const { records, total } = action.payload;
			state.historyRecords = records ?? [];
			state.historyTotal = total;
			state.historyLoading = false;
		},
		getInvoiceByIdFulfilled: (state, action: PayloadAction<IInvoice>) => {
			state.edittingEntity = action.payload;
			state.entityLoading = false;
		},

		saveInvoiceFulfilled: (state, action: PayloadAction<any>) => {
			state.actionLoading = false;
		},

		changeInvoiceStateFulfilled: (state, action: PayloadAction<{ changedEntity: IInvoice, changedRecords: IInvoiceListItem[] }>) => {
			const { changedEntity, changedRecords } = action.payload;
			state.edittingEntity = changedEntity;
			state.listRecords = changedRecords;
			state.actionLoading = false;
		},

		getInvoiceQrCodeFulfilled: (state, action: PayloadAction<IInvoiceQRCode | null>) => {
			state.invoiceQrData = action.payload;
			state.actionLoading = false;
		},
		setInitEdittingEntityQrCode: (state) => {
			state.invoiceQrData = null;
			state.actionLoading = false;
		}

	}
});


const invoicesActions = invoicesSlice.actions;
export { invoicesSlice, invoicesActions };
