import React from 'react';
import { extend } from 'lodash';
import SelectUnstyled, {
  SelectUnstyledProps,
  selectUnstyledClasses,
	SelectUnstyledRootSlotProps,
} from '@mui/base/SelectUnstyled';
import { muiEsasStyled } from 'app/theme';

import { AppIcon, AppMenuListbox } from './';
// Types
import { SxProps, Theme } from '@mui/material/styles';


const AppSelectControlBase = React.forwardRef(function AppSelectControlBase(
	{ children, ownerState, ...props }: SelectUnstyledRootSlotProps<any>,
	ref: React.ForwardedRef<HTMLButtonElement>
) {

	return (
		<button {...props} ref={ref}>
			{children}
			<AppIcon 
				iconType="down"
				sx={(theme) => ({
					width: theme.spacing(5),
					height: theme.spacing(5),
					marginLeft: theme.spacing(4),
					transform: `rotate(${(ownerState.open) ? '180deg' : ''})`,
					transition: 'color 0.3s, transform 0.3s',
				})}
			/>
		</button>
	);
});


interface IAppSelectControlProps extends SelectUnstyledRootSlotProps<any> {
	sx?: SxProps<Theme>;
};
const AppSelectControl: React.FC<IAppSelectControlProps> = muiEsasStyled(AppSelectControlBase, {
	// Перечисляем пропсы, которые не будут отображаться атрибутами в DOM
	shouldForwardProp: (prop) => (
		prop !== 'sx'
	),
	name: 'AppSelectControl'
})<IAppSelectControlProps>(({ theme }) => {

	return extend({
		display: 'inline-flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		font: theme.font.body.md,
		color: theme.color.tertiary,
		background: 'red'
	});
});


interface IAppSelectProps extends SelectUnstyledProps<any> {
	control?: React.ElementType<any>;
	controlProps?: any;
}
const AppSelect: React.FC<IAppSelectProps> = ({ control, controlProps, components, componentsProps, ...props }) => {
	return (
		<SelectUnstyled
			onListboxOpenChange={(e) => {console.log(e)}}
			components={{
				Root: control || AppSelectControl,
				Listbox: AppMenuListbox,
				...components
			}}
			componentsProps={{
				root: controlProps,
				popper: {
					// open: true,
					// placement: 'top'
				},
				...componentsProps
			}}
			{...props}
		/>
	)
}


export { AppSelect };
