import React from 'react';
import { alpha, Stack } from '@mui/material';

import {
	AppBar,
	AppContainer,
	Grid,
} from 'app/components';


const HeaderBar: React.FC = ({ children }) => {
	return (
		<AppBar
			component="header"
			position="sticky"
			sx={(theme) => ({
				color: 'inherit',
				background: alpha(`${theme.color.bg_01}`, 0.8),
				backdropFilter: 'blur(5px)',
				borderBottom: `${theme.border.primary} ${theme.color.quaternary}`,
				boxShadow: theme.depth.secondary,
			})}
		>
			<AppContainer>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					spacing={5}
					sx={(theme) => ({
						padding: `${theme.spacing(2)} 0`,
					})}
				>
					{children}
				</Stack>
			</AppContainer>
		</AppBar>
	);
}

export { HeaderBar };
