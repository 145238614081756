import { at, forOwn, isEqual, isNil, omitBy } from 'lodash';
// Types
import { FormikValues } from 'formik';
import { IGetAllParams } from 'apis/cabinetApi/extraTypes';


/** Массив значений количества строк для таблиц */
export const tableCounts = [10, 20, 30];


/** init-значение useState при создании состояния queryParams таблиц */
export const initQueryParams: IGetAllParams<any> = {
	skip: 0,
	count: tableCounts[0],
	filter: {}
};


/**
 * Функция для создания обработчиков пагинации
 * @param queryParams params для  getAll методов api
 * @param setQueryParams setState метод для применения фильров
 */
export function getPaginationHandlers(
	queryParams: IGetAllParams<any>,
	setQueryParams: (nextQueryParams: IGetAllParams<any>) => void,
) {
	const pageChangeHandler = (nextPage: number, rowsPerPage: number): void => {
		const skip = (nextPage - 1) * rowsPerPage;
		const count = rowsPerPage;
		setQueryParams({
			filter: queryParams.filter,
			skip,
			count
		});
	}

	const rowsPerPageChangeHandler = (nextRowsPerPage: number): void => {
		const skip = Math.floor(queryParams.skip / nextRowsPerPage) * nextRowsPerPage;
		const count = nextRowsPerPage;
		setQueryParams({
			filter: queryParams.filter,
			skip,
			count
		});
	}

	return { pageChangeHandler, rowsPerPageChangeHandler };
}


/**
 * Функция для применения фильтров таблиц
 * @param prevQueryParams params для getAll методов api
 * @param setQueryParams setState метод для применения фильров
 * @param values значения формы formik с фильтрами
 */
export function applyTableFilter(
	prevQueryParams: IGetAllParams<any>,
	setQueryParams: (nextQueryParams: IGetAllParams<any>) => void,
	values: FormikValues
): void {
	// Подготавливаем новый объект queryParams
	const nextQueryParams: IGetAllParams<any> = {
		// Показываем всегда первую страницу, сортировка же остается
		skip: 0,
		count: prevQueryParams.count,
		// Удаляем у фильтров свойства со значениями undefined | null | ''
		filter: {
			...omitBy(values, (filter) => isNil(filter) || filter === '')
		}
	};
	// Тримим строковые значения
	forOwn(nextQueryParams.filter, (value, key) => {
		if (typeof value === 'string' && value) {
			nextQueryParams.filter[key] = value.trim();
		}
	});

	// Применяем фильтр, делаем запрос
	setQueryParams(nextQueryParams);
}
