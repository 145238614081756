import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { isEqual } from 'lodash';
import { applyTableFilter, initQueryParams } from 'utils';
import { useInvoicesCRUD } from 'hooks';
import { invoiceSchema } from 'app/yup';
// Types
import { IGetInvoicesAllParams, IInvoice } from 'apis/cabinetApi/extraTypes'
import { IInvoicesNavigate, IUseInvoicesUIContext } from 'app/modules/invoices/types';


// Трюк с undefined в качестве init value контекста
// https://stackoverflow.com/questions/58193424/passing-state-with-usecontext-in-typescript
const InvoicesContext = createContext<IUseInvoicesUIContext | undefined>(undefined);
const useInvoicesUIContext = () => {
	const contextValue = useContext(InvoicesContext);
	if (contextValue === undefined) throw new Error('Expected context value to be set');
	return contextValue;
};


const InvoicesUIProvider: React.FC<{ navigateActions: IInvoicesNavigate }> = ({ navigateActions, children }) => {
	const {
		getInvoicesForListTable,
		getInvoicesForHistoryTable,
		getInvoiceFullData, 
		saveInvoice
	} = useInvoicesCRUD();

	//* ========== Логика таблиц ==========
	const onTableRowExpand = useCallback((rowId: string) => {
		return getInvoiceFullData(rowId);
	}, []);

	//* ========== Таблица списка ==========
	const [queryParamsList, setQueryParamsListBase] = useState<IGetInvoicesAllParams>(initQueryParams);
	const setQueryParamsList = useCallback((nextQueryParams?: IGetInvoicesAllParams | null) => {
		if (nextQueryParams) {
			setQueryParamsListBase((prevQueryParams) => {
				if (isEqual(prevQueryParams, nextQueryParams)) {
					return prevQueryParams;
				} else {
					return nextQueryParams;
				}
			});
		} 
		if (nextQueryParams === null) {
			setQueryParamsListBase({ ...initQueryParams });
			return;
		}
		if (nextQueryParams === undefined) {
			setQueryParamsListBase((prevQueryParams) => ({ ...prevQueryParams }));
		}
	}, []);

	const onListTableFilterChange = useCallback((values: typeof queryParamsList.filter) => {
		applyTableFilter(queryParamsList, setQueryParamsList, values);
	}, [queryParamsList, setQueryParamsList]);

	// Запрос на обновление listRecords при изменении queryParamsList через пагинацию или фильтры
	useEffect(() => {
		getInvoicesForListTable(queryParamsList);
	}, [queryParamsList]);


	//* ========== Таблица истории ==========
	const [queryParamsHistory, setQueryParamsHistoryBase] = useState<IGetInvoicesAllParams>(initQueryParams);
	const setQueryParamsHistory = useCallback((nextQueryParams?: IGetInvoicesAllParams | null) => {
		if (nextQueryParams) {
			setQueryParamsHistoryBase((prevQueryParams) => {
				if (isEqual(prevQueryParams, nextQueryParams)) {
					return prevQueryParams;
				} else {
					return nextQueryParams;
				}
			});
			return;
		} 
		if (nextQueryParams === null) {
			setQueryParamsHistoryBase({ ...initQueryParams });
			return;
		}
		if (nextQueryParams === undefined) {
			setQueryParamsHistoryBase((prevQueryParams) => ({ ...prevQueryParams }));
		}
	}, []);

	const onHistoryTableFilterChange = useCallback((values: typeof queryParamsHistory.filter) => {
		applyTableFilter(queryParamsHistory, setQueryParamsHistory, values);
	}, [queryParamsList, setQueryParamsList]);

	// Запрос на обновление historyRecords при изменении queryParamsList через пагинацию или фильтры
	useEffect(() => {
		getInvoicesForHistoryTable(queryParamsHistory);
	}, [queryParamsHistory]);


	//* ========== Логика формы ==========
	const onInvoiceFormSubmit = useCallback(async (values: IInvoice) => {
		const castedValues = invoiceSchema.cast(values);
		// 💥 Ошибка Тип "null" не может быть назначен для типа "Discount | undefined".
		// const result = await saveInvoice(castedValues);
		const result = await saveInvoice(castedValues as IInvoice);
		if (result) {
			setQueryParamsList();
			setTimeout(() => {
				navigateActions.navigateToListPage();
			}, 0);
		}
	}, []);


	return (
		<InvoicesContext.Provider
			value={{
				queryParamsList,
				setQueryParamsList,
				onListTableFilterChange,

				queryParamsHistory,
				setQueryParamsHistory,
				onHistoryTableFilterChange,

				onTableRowExpand,
				onInvoiceFormSubmit,

				...navigateActions
			}}
		>
			{children}
		</InvoicesContext.Provider>
	);
}


export { InvoicesUIProvider, useInvoicesUIContext };
