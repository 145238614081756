import React from 'react';
import {
	Box,
	Stack,
} from '@mui/material';


const AsideMenuList: React.FC = ({ children }) => {
	return (
		<Box
			component="nav"
			sx={(theme) => ({
				width: theme.spacing(65),
			})}
		>
			<Stack
				component="ul"
				direction="column"
				spacing={2}
				sx={(theme) => ({
					margin: 0,
					padding: 0,
					listStyleType: 'none',
				})}
			>
				{children}
			</Stack>
		</Box>
	);
}

export { AsideMenuList };
