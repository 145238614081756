import * as Yup from 'yup';
import { errMsg } from './_errorMessages';
// Types
import { ENoticeTypes } from 'app/modules/serviceProviders/types';
import { phoneNumberSchema } from './_shortcuts';


export const noticeSchema = () => Yup.object().shape({
	'type': Yup.string()
		.oneOf(Object.values(ENoticeTypes))
		.default(ENoticeTypes.emails)
		.required(errMsg.required),
	'notice': Yup.string()
		.default(ENoticeTypes.emails)
		.required(errMsg.required)
		.when('type', ([type], schema) => {
			switch(type) {
				case ENoticeTypes.emails:
					return schema
						.email(errMsg.email);
				case ENoticeTypes.urls:
					return schema
						.url(errMsg.url);
				case ENoticeTypes.smses:
					return phoneNumberSchema()
						.required(errMsg.required);
				default:
					return schema;
			}
		})
});

export const notifyParamsSchema = () => Yup.object().shape({
	notices: Yup.array()
		.of(noticeSchema())
		.nullable()
		.default(null)
});
