import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// Types
import {
	IModuleState,
	catchAsyncError,
	startAsyncCall,
} from 'reduxStore/reduxTypes';
import {
	IPaged,
	IUserInfo,
} from 'apis/cabinetApi/extraTypes';
import { userInfoSchema } from 'app/yup';


const usersInitialState: IModuleState<IUserInfo> = {
	listLoading:    false,
	actionLoading:  false,
	entityLoading:  false,
	records:        [],
	total:          0, 
	edittingEntity: userInfoSchema.getDefault(),
	lastError:      ''
};

const usersSlice = createSlice({
	name: 'users',
	initialState: usersInitialState,

	reducers: {
		startCall: startAsyncCall,
		catchError: catchAsyncError,

		setInitEdittingEntity: (state) => {
			state.edittingEntity = userInfoSchema.getDefault();
			state.entityLoading = false;
		},

		getUsersAllFulfilled: (state, action: PayloadAction<IPaged<IUserInfo>>) => {
			const { records, total } = action.payload;
			state.records = records ?? [];
			state.total = total;
			state.listLoading = false;
		},
		getUserByIdFulfilled: (state, action: PayloadAction<IUserInfo>) => {
			state.edittingEntity = action.payload;
			state.entityLoading = false;
		},

		saveUserFulfilled: (state, action: PayloadAction<any>) => {
			console.log(action.payload);
			state.actionLoading = false;
		},

		changeUserStateFulfilled: (state, action: PayloadAction<{ changedRecords: IUserInfo[] }>) => {
			const { changedRecords } = action.payload;
			state.records = changedRecords;
			state.actionLoading = false;
		}

	}
});


const usersActions = usersSlice.actions;
export { usersSlice, usersActions };
