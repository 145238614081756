/* tslint:disable */
/* eslint-disable */
/**
 * EPOS cabinet API
 * Cabinet API сервиса E-POS
 *
 * OpenAPI spec version: v1
 * Contact: support-api@epos.by
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * Тип процента начисления пени.
 * @export
 * @enum {string}
 */
export enum PenaltyTypeEnum {
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

