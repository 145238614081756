import { createTheme, alpha, rgbToHex } from '@mui/material/styles';
import { ruRU } from '@mui/material/locale';
// Types
import './themeAugmentation';

const muiEsasTheme = createTheme({
	//* esas design system params
	radius: {
		x1: '2px',
		x2: '4px',
		x4: '8px',
	},

	border: {
		primary: '1px solid',
	},

	depth: {
		primary:   '0 2px  4px 0 rgba(  0,   0,   0, 0.08)',
		secondary: '0 4px 24px 0 rgba(  0,   0,   0, 0.10)',
		tertiary:  '0 8px 24px 0 rgba( 28,  47, 105, 0.16)',	
	},

	font: {
		header: {//1rem = 16px
			lg: '500 1.5rem "Rubik"',   // 24px
			md: '400 1.5rem "Rubik"',   // 24px
		},
		title: {
			lg: '500 1.375rem "Rubik"', // 22px
			md: '400 1.375rem "Rubik"', // 22px
		},
		body: {
			lg: '500 1.125rem "Rubik"', // 18px
			md: '400 1.125rem "Rubik"', // 18px
			sm: '500 1rem "Rubik"',     // 16px
			xs: '400 1rem "Rubik"',     // 16px
		},
		small: {
			lg: '500 0.875rem "Rubik"', // 14px
			md: '400 0.875rem "Rubik"', // 14px
			sm: '400 0.75rem "Rubik"',  // 12px
		}
	},

	color: {
		epos: {
			primary:      'rgba(236,  62,  55, 1.0)',
			hover:        'rgba(218,  31,  24, 1.0)',
			click:        'rgba(203,  15,   8, 1.0)',
			disabled:     'rgba( 11,  31,  53, 0.3)',
			notification: 'rgba(252, 148, 144, 1.0)',
		},

		primary:        'rgba( 11,  31,  53, 1.0)',
		secondary:      'rgba( 11,  31,  53, 0.6)',
		tertiary:       'rgba(217, 225, 238, 1.0)',
		quaternary:     'rgba(241, 244, 249, 1.0)',

		bg_01:          'rgba(255, 255, 255, 1.0)',
		bg_02:          'rgba(248, 250, 255, 1.0)',

		button: {
			click:        'rgba(174, 187, 209, 1.0)',
			hover:        'rgba(205, 214, 229, 1.0)',
		},

		status: {
			error:        'rgba(255,   0,   0, 1.0)',
			warning:      'rgba(252, 190,  33, 1.0)',
			success:      'rgba( 97, 204, 101, 1.0)',
		},
	},

	//* mui standart theme params 
	typography: {
		fontFamily: ['"Rubik"', '"Roboto"', '"Helvetica"', '"Arial"', 'sans-serif'].join(','),
	},

	palette: {
		primary: {
			main:         rgbToHex('rgba(236,  62,  55, 1.0)'),
			light:        rgbToHex('rgba(203,  15,   8, 1.0)'),
			dark:         rgbToHex('rgba(218,  31,  24, 1.0)'),
			contrastText: rgbToHex('rgba(255, 255, 255, 1.0)'),
		},
	},

	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1200,
			xl: 1400,
		}
	},

	spacing: 4,

	components: {

	},
}, ruRU);

type TMuiEsasTheme = typeof muiEsasTheme;

export { muiEsasTheme };
export type { TMuiEsasTheme };
