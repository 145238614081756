import React from 'react';
import { Dialog, DialogProps } from '@mui/material';


interface IAppModalProps extends DialogProps {};
const AppModal: React.FC<IAppModalProps> = ({ children, ...props }) => {
	return (
		<Dialog
			maxWidth="md"
			PaperProps={{
				sx: (theme) => ({
					// width: theme.spacing(170)
					borderRadius: theme.spacing(2)
				})
			}}
			{...props}
		>
			{children}
		</Dialog>
	);
}


export { AppModal };
export type { IAppModalProps };
