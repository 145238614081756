import React, { useRef } from 'react';
import { SnackbarProvider, useSnackbar } from 'notistack';
import Slide from '@mui/material/Slide';
import { AppButton } from 'app/components';
import { AppNotification } from 'app/components';


const NotistackProvider: React.FC = ({ children }) => {
	// add action to all snackbars
	const notistackRef = useRef<any>(null);
	const onClickDismiss = (key: any) => () => { 
		notistackRef.current.closeSnackbar(key);
	}

	return (
		<SnackbarProvider
			maxSnack={3} // максимальное количество снэкбаров в очереди
			// preventDuplicate={true} // предотвращает создание дублирующих уведомлений
			autoHideDuration={5000} // время жизни уведомлений
			anchorOrigin={{ // положение уведомлений на экране
				vertical: 'top',
				horizontal: 'center',
			}}
			// В Grow и Fade transitionDuration enter не работает, срабатывает мгновенно
			// TransitionComponent={Slide as React.ComponentType} // эффект появления/исчезания уведомления
			// transitionDuration={{ enter: 300, exit: 300 }}
			// TransitionProps={{ enter: true, exit: true }}
			ref={notistackRef} // ссылка на dom-элементы уведомлений
			// компонент как end-adornment для всех уведомлений
			// action={(key) => <Button onClick={onClickDismiss(key)}>ok</Button>}
			// либо полностью кастомный снэкбар
			content={(key, message) => <AppNotification id={key} message={message as any} />}
			classes={{ // классы для вариантов уведомлений
				variantSuccess: 'notistack-success',
				variantError:   'notistack-error',
				variantWarning: 'notistack-warning',
				variantInfo:    'notistack-info',
			}}
		>
			<SnackbarUtilsConfigurator />
			{children}
		</SnackbarProvider>
	);
}

let useSnackbarRef: any;
const SnackbarUtilsConfigurator = () => {
	useSnackbarRef = useSnackbar();
	return null;
} 

enum ENotistackVariants {
	default = 'default',
	success = 'success',
	error   = 'error',
	warning = 'warning',
	info    = 'info'
};

const notistack = {
	toast(message: string, variant: ENotistackVariants = ENotistackVariants.default) {
		useSnackbarRef.enqueueSnackbar({ message, variant });
	},

	success(message: string) {
		this.toast(message, ENotistackVariants.success);
	},
	error(message: string) {
		this.toast(message, ENotistackVariants.error);
	},
	warning(message: string) {
		this.toast(message, ENotistackVariants.warning);
	},
	info(message: string) {
		this.toast(message, ENotistackVariants.info);
	}
};

export { NotistackProvider, notistack, ENotistackVariants };
