import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth, useUserInfoCRUD } from 'hooks';


const RequiredAuthHOC: React.FC = ({ children }) => {
	const { accessToken, postAuthUrl, getTokens } = useAuth();
	const { userInfo } = useUserInfoCRUD();
	const { pathname } = useLocation();

	// Текущий pathname передадим как postAuthUrl
	// для редиректа после авторизации на iii
	postAuthUrl.current = pathname;


	// Если пользователь не авторизован - редирект на страницу авторизации
	if (accessToken === null) {
		return <Navigate to="auth/login" replace/>;
	}
	if (userInfo === null) {
		return null;
	}
	return <>{children}</>;
}

export { RequiredAuthHOC };
