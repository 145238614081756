import { createStyled } from '@mui/system';
import { muiEsasTheme } from './muiEsasTheme';
import { SxProps, Theme } from '@mui/material';


const muiEsasStyled = createStyled({ defaultTheme: muiEsasTheme });

interface ISxAsProp {
	sx?: SxProps<Theme>;
};


export { muiEsasStyled };
export type { ISxAsProp };
