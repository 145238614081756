import { IInvoice } from 'apis/cabinetApi/extraTypes';


export const invoiceInitialValues: IInvoice = {
	merchantInfo: {
		'serviceProviderId': undefined,
		'serviceId': undefined,
		retailOutlet: {
			'code': undefined
		}
	},
	'number': null,
	'currency': null,
	items: [{
		'code': null,
		'name': null,
		unitPrice: {
			'value': undefined
		},
		discount: {
			'percent': null,
			'amount': null,
		},
		'quantity': 1,
		'measure': ''
	}],

	billingInfo: {
		contact: {
			'lastName': null,
			'firstName': null,
			'middleName': null
		}
	},

	paymentRules: {
		'requestAmount': true,
		'isTariff': false,
		'isTariffExpire': false,
		'requestPersonName': false,
		'requestPurpose': false,
		'requestPhone': false,
		'requestEMail': false,
		'requestAddress': false
	},

	'dateInAirUTC': (new Date()).toString() as unknown as Date,

	paymentDueTerms: {
		'dueUTC': null,
		'termsDay': undefined
	},

	'note': null
};
