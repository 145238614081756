import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { muiEsasTheme } from 'app/theme/muiEsasTheme';


const MuiThemeProvider: React.FC = ({ children }) => {
	return (
		<ThemeProvider theme={muiEsasTheme}>
			{children}
		</ThemeProvider>
	);
}

export { MuiThemeProvider };
