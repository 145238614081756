import { useField } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppTextFieldBase } from '../AppTextField';
// Types


interface IEmailsMultiFieldProps {
	emailsName:   string;
	placeholder?: string;
}
const EmailsMultiField: React.FC<IEmailsMultiFieldProps> = ({ emailsName, placeholder }) => {
	const { t } = useTranslation(undefined, { keyPrefix: 'components.EmailsMultiField' })
	const tVal = useTranslation(undefined, { keyPrefix: 'validation' }).t;
	const [emailsField, emailsMeta, emailsHelpers] = useField(emailsName);

	const [inputValue, setUnformattedInputValue] = useState<string>('');
	const setInputValue = (value: string) => {
		const formattedInputValue = value.replace(/^\s/, '').replace(/\s+/g, ' ');
		setUnformattedInputValue(formattedInputValue);
	}

	// Установить начальное значение инпута
	useEffect(() => {
		if (emailsMeta.initialValue?.length) {
			// В отчетах ПУ при повторном открытии существующей
			// отредактированной карточки тогда не показывает изменения
			// const initialInputValue = emailsMeta.initialValue.join(' ');
			// setInputValue(initialInputValue);
			const initialInputValue = emailsField.value.join(' ');
			setInputValue(initialInputValue);
		}
	}, [emailsMeta.initialValue]);

	const createEmailArray = (value: string | null | undefined): Array<string> => {
		if (value == null) return [];
		return value.trim().split(/\s+/).filter((i) => i);
	}

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = event.target.value;
		setInputValue(inputValue);
		const nextFieldValue = createEmailArray(inputValue);
		emailsHelpers.setValue(nextFieldValue);
	}

	const error = Boolean(emailsMeta.touched && emailsMeta.error);
	const errorText = (typeof emailsMeta.error === 'string')
		? emailsMeta.error
		: (emailsMeta.error as any)?.filter?.((i: any) => i)?.[0];
	const helperText = (emailsMeta.touched && emailsMeta.error)
		? tVal(errorText)
		: undefined;


	return (
		<AppTextFieldBase
			name={emailsField.name}
			value={inputValue}
			onChange={onChangeHandler}
			onBlur={emailsField.onBlur}
			error={error}
			helperText={helperText}
			placeholder={placeholder || t('placeholder')}
		/>
	);
}
EmailsMultiField.displayName = 'EmailsMultiField';


export { EmailsMultiField };
