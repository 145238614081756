import React from 'react';
import { useTranslation } from 'react-i18next';
import { extend } from 'lodash';
import { TMuiEsasTheme } from 'app/theme';
import { Field, useField, FieldProps } from 'formik';
import {
	FormControl, FormControlProps, formControlClasses,
	outlinedInputClasses,
	Select, SelectProps, selectClasses,
	FormHelperText, FormHelperTextProps, formHelperTextClasses
} from '@mui/material';
import { AppIcon } from '.';
// Types
import { SxProps, Theme } from '@mui/material';


const IconComponent: React.FC = (props) => {
	return (
		<AppIcon
			iconType="down"
			size="sm"
			sx={(theme) => ({
				[`&.${selectClasses.icon}`]: {
					top: 'auto',
					right: theme.spacing(2),
					color: theme.color.secondary,
					transition: 'color 0.3s, backgroung 0.3s, transform 0.3s',
				}
			})}
			{...props}
		/>
	);
}


//! До создания кастомного инпута использую mui TextField
type TMuiSelectProps = SelectProps & {
	name?:       string;
	helperText?: string | false;
	error?:      boolean;
	errorMessage?: string;
};
const MuiSelect: React.FC<TMuiSelectProps> = ({ sx = [], error, errorMessage, children, ...props }) => {

	return (
		<FormControl
			error={error}
			sx={[
				(theme: TMuiEsasTheme) => extend({
					[`&.${formControlClasses.root}`]: {
						width: '100%',
					},
					[`& .${selectClasses.select}`]: {
						height: 'auto !important',
						minHeight: 'auto !important',
					}

				}, {
					// hover
					'&.MuiFormControl-root:hover': {
						'& .MuiOutlinedInput-notchedOutline': {
							borderColor: theme.color.epos.notification,
						}
					}
				}, {
					// disabled
					'&.MuiFormControl-root .Mui-disabled': {
						'& .MuiInputBase-input': {
							color: theme.color.tertiary,
							WebkitTextFillColor: 'initial',
							background: theme.color.bg_02,
						},
						'& .MuiOutlinedInput-notchedOutline': {
							borderColor: theme.color.tertiary,
						}
					}
				}, {
					// focused
					'&.MuiFormControl-root .Mui-focused': {
						'& .MuiOutlinedInput-notchedOutline': {
							border: theme.border.primary,
							borderColor: theme.color.epos.notification,
							boxShadow: `inset 0 0 1px 1px ${theme.color.epos.notification}`,
						}
					}
				}, {
					// error
					'&.MuiFormControl-root .Mui-error': {
						'& .MuiOutlinedInput-notchedOutline': {
							borderColor: theme.color.status.error,
						},
					},
					// focused + error
					'&.MuiFormControl-root .Mui-focused.Mui-error': {
						'& .MuiOutlinedInput-notchedOutline': {
							boxShadow: `inset 0 0 1px 1px ${theme.color.status.error}`,
						}
					}
				}),
				...(Array.isArray(sx) ? sx : [sx])
			]}
		>
			<Select
				IconComponent={IconComponent}
				sx={(theme: Theme) => extend({
					// Стандартное состояние
					[`& .${selectClasses.select}`]: {
						height: 'auto',
						minHeight: 'auto',
						paddingTop: theme.spacing(3),
						paddingLeft: theme.spacing(2),
						paddingRight: `${theme.spacing(9)} !important`,
						paddingBottom: theme.spacing(3),
						font: theme.font.small.md,
						color: theme.color.primary,
						borderRadius: theme.spacing(2),
						'&:placeholder': {
							font: theme.font.small.md,
							color: theme.color.tertiary,
						}
					},
					[`& .${selectClasses.icon}`]: {

					},
					[`& .${outlinedInputClasses.notchedOutline}`]: {
						border: theme.border.primary,
						borderColor: theme.color.tertiary,
						borderRadius: theme.spacing(2),
					},
				})}
				{...props}
			>
				{children}
			</Select>

			{(errorMessage) && (
				<FormHelperText
					sx={(theme) => extend({
						[`&.${formHelperTextClasses.root}`]: {
							margin: `${theme.spacing(1)} ${theme.spacing(2)} 0`,
							font: theme.font.small.md,
							color: theme.color.secondary,
						}
					}, {
						[`&.${formHelperTextClasses.error}`]: {
							color: theme.color.status.error,
						}
					}, {
						[`&.${formHelperTextClasses.disabled}`]: {
							color: theme.color.tertiary,
						}
					})}
				>
					{errorMessage}
				</FormHelperText>
			)}
		</FormControl>
	);
}


type TMuiFormSelectProps = SelectProps & {
	name:        string;
	helperText?: string;
};
const MuiFormSelect: React.FC<TMuiFormSelectProps> = ({ sx = [], name, helperText, children, ...props }) => {
	const { t } = useTranslation(undefined, { keyPrefix: 'validation' });
	const [formikField, formikMeta] = useField(name);

	const errorMessage = helperText ?? ((name && formikMeta.touched && formikMeta.error) ? t(formikMeta.error) : undefined); 

	return (
		<MuiSelect
			name={formikField.name}
			value={props.value ?? formikField.value ?? (props.multiple ? [] : '')}
			onChange={props.onChange ?? formikField.onChange}
			onBlur={props.onBlur ?? formikField.onBlur}
			error={Boolean(formikMeta.touched && formikMeta.error)}
			errorMessage={errorMessage}
			sx={[
				(theme: TMuiEsasTheme) => extend({}),
				...(Array.isArray(sx) ? sx : [sx])
			]}
			{...props}
		>
			{children}
		</MuiSelect>
	);
}


export {
	MuiSelect as AppBaseSelect,
	MuiFormSelect as AppFormSelect
}; 
