import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { enGB, ru } from 'date-fns/locale'
import { useField, useFormikContext } from 'formik';
import { extend } from 'lodash';
import DatePicker from 'react-datepicker';
import { SxProps, Theme, Typography } from '@mui/material';
import { AppIcon } from '../AppIcon';
import { muiEsasStyled } from 'app/theme';
import 'react-datepicker/dist/react-datepicker.css';
import './DateRangePicker.scss';


type IDateRangePickerProps = {
	beginDateName: string;
	endDateName: string;
	submitOnSelect?: boolean;
};
const DateRangePicker: React.FC<IDateRangePickerProps> = ({beginDateName, endDateName, submitOnSelect}) => {
	const { i18n } = useTranslation();

	const [beginDateField, beginDateMeta, beginDateHelpers] = useField(beginDateName);
	const [endDateField, endDateMeta, endDateHelpers] = useField(endDateName);

	const beginDate = (beginDateField.value) ? new Date(beginDateField.value) : null;
	const endDate = (endDateField.value) ? new Date(endDateField.value) : null;

	const locale = useMemo(() => {
		return (i18n.language === 'en') ? enGB : ru;
	}, [i18n.language])

	const { handleSubmit } = useFormikContext();

	const handleChange = (dates: any): void => {
		const [beginDate, endDate] = dates;
		beginDateHelpers.setValue(beginDate);
		endDateHelpers.setValue(endDate);
		if (submitOnSelect && beginDate && endDate) handleSubmit();
	}


	return (
		<DatePicker
			startDate={beginDate}
      endDate={endDate}

			onChange={handleChange}

			customInput={<AppDateRangeButton/>}

			locale={locale}
			minDate={new Date('2000-01-10T00:00:00')}
			maxDate={new Date('2100-01-10T00:00:00')}
			monthsShown={2}
      selectsRange
			fixedHeight
			popperModifiers={[
				{
					name: "preventOverflow",
					options: {
						rootBoundary: "viewport",
						tether: false,
						altAxis: true,
					},
				},
			]}
		/>
	);
}
DateRangePicker.displayName = 'DateRangePicker';


const AppDateRangeButtonBase = React.forwardRef(function AppDateRangeButtonBase(
	props: any,
	ref: React.ForwardedRef<HTMLButtonElement>
) {
	const { t } = useTranslation();

	return (
		<button
			ref={ref}
			type="button"
			className={props.className}
			onClick={props.onClick}
		>
			<Typography
				component="span"
				sx={(theme) => ({
					font: theme.font.small.md,
					color: theme.color.primary,
				})}
			>
				{t('components.buttons.date')}
			</Typography>

			<AppIcon
				iconType="down"
				sx={(theme) => ({
					width: theme.spacing(5),
					height: theme.spacing(5),
					color: theme.color.secondary,
					transform: `rotate(0)`,
					transition: 'color 0.3s, transform 0.3s',
				})}
			/>
		</button>
	);
});


interface AppPaginationPerPageButton {
	sx?: SxProps<Theme>;
};
const AppDateRangeButton: React.FC<AppPaginationPerPageButton> = muiEsasStyled(AppDateRangeButtonBase, {
	// Перечисляем пропсы, которые не будут отображаться атрибутами в DOM
	shouldForwardProp: (prop) => (
		prop !== 'sx'
	),
	name: 'AppPaginationPerPageButton'
})<AppPaginationPerPageButton>((props) => {
	const { theme } = props;

	return extend({
		display: 'inline-flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		minWidth: theme.spacing(23),
		width: theme.spacing(23),
		height: theme.spacing(10),
		margin: 0,
		marginLeft: theme.spacing(2),
		padding: theme.spacing(2.5),
		font: theme.font.body.xs,
		color: theme.color.primary,
		background: 'none',
		border: theme.border.primary,
		borderColor: theme.color.tertiary,
		borderRadius: theme.spacing(2),
		outline: 'none',
		cursor: 'pointer',
		transition: 'background 0.3s',
		'&:disabled': {
			color: theme.color.tertiary,
			background: theme.color.quaternary,
			cursor: 'default',
		},
		'&.react-datepicker-ignore-onclickoutside': {
			background: theme.color.bg_02,
			'& svg': {
				transform: `rotate(180deg)`,
			}
		}
	});
});


export { DateRangePicker };
