import React, { createContext, useRef, useContext, useEffect } from 'react';
import { useAppSelector } from 'hooks';
import { useUserInfoCRUD } from 'app/modules/profile/redux/useUserInfoCRUD';
import { SplashScreenFallback } from 'app/providers';
import { AuthErrorScreen } from 'app/modules/errors/pages/AuthErrorScreen';
// Types


export interface IAuthContext {
	/** Адрес редиректа после прохождения авторизации */
	readonly postAuthUrl:	React.MutableRefObject<string>;
};
// Трюк с undefined в качестве init value контекста
// https://stackoverflow.com/questions/58193424/passing-state-with-usecontext-in-typescript
const AuthContext = createContext<IAuthContext | undefined>(undefined);
const useAuthContext = () => {
	const contextValue = useContext(AuthContext);
	if (contextValue === undefined) throw new Error('Expected context value to be set');
	return contextValue;
}


const AuthProvider: React.FC = ({ children }) => {
	const postAuthUrl = useRef<string>('/');
	const { accessToken, accessError } = useAppSelector((store) => store.userInfo);
	const { getUserInfo, userInfo } = useUserInfoCRUD();

	// Когда появится accessToken - загрузить пользователя
	useEffect(() => {
		if (accessToken && userInfo === null) {
			getUserInfo();
		}
	}, [accessToken, userInfo]);


	return (
		<AuthContext.Provider
			value={{
				postAuthUrl
			}}
		>
			{(userInfo === null && !accessError) && (<SplashScreenFallback/>)}
			{(accessError) ? (
				<AuthErrorScreen/>
			) : (
				children
			)}
		</AuthContext.Provider>
	);
}

export { AuthProvider, useAuthContext };
