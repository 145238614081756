import React from 'react';
import { extend } from 'lodash';
import { muiEsasStyled } from 'app/theme';
// Types
import { SxProps, Theme } from '@mui/material/styles';
import classNames from 'classnames';


interface IAppStateDotProps extends React.ComponentProps<'span'> {
	state?: boolean;
	sx?:    SxProps<Theme>;
};
const AppStateDotStyled: React.FC<IAppStateDotProps> = muiEsasStyled('span', {
	// Перечисляем пропсы, которые не будут отображаться атрибутами в DOM
	shouldForwardProp: (prop) => (
		prop!== 'sx' && prop!== 'state'
	),
	name: 'AppStateDot'
})<IAppStateDotProps>(({ theme, state }) => {

	return extend({
		display: 'inline-block',
		flexGrow: 0,
		flexShrink: 0,
		width: theme.spacing(2.5),
		height: theme.spacing(2.5),
		borderRadius: '50%',
		userSelect: 'none',
	}, (state === true) && {
		background: theme.color.status.success,
	}, (state === false) && {
		background: theme.color.status.error,
	}, []);
});


const AppStateDot: React.FC<IAppStateDotProps> = (props) => {
	const { state = false, sx = [], ...rest } = props;

	return (
		<AppStateDotStyled
			className={classNames(
				'AppStateDot',
				`AppStateDot-state-${state}`,
			)}
			state={state}
			sx={[
				(theme) => ({}),
				...Array.isArray(sx) ? sx : [sx]
			]}
			{...rest}
		/>
	)
}


export { AppStateDot };
export type { IAppStateDotProps };
