import React from 'react';
import { AppTextField, IAppTextFieldProps } from 'app/components';
import NumberFormat, { InputAttributes } from 'react-number-format';


interface CustomProps {
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
};
const PhoneNumberFormat = React.forwardRef<
	NumberFormat<InputAttributes>,
	CustomProps
>(function PhoneNumberFormat(props, ref) {
	const { onChange, ...other } = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={ref}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			type="tel"
			format="+### (##) ### ## ##"
			isNumericString
			mask="_"
		/>
	);
});


const PhoneNumberField: React.FC<IAppTextFieldProps> = (props) => {
	return (
		<AppTextField
			InputProps={{
				inputComponent: PhoneNumberFormat as any
			}}
			placeholder="+375"
			{...props}
		/>
	);
}
PhoneNumberField.displayName = 'PhoneNumberField';


export { PhoneNumberField };
