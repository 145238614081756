import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline } from '@mui/material';

import { store, persistor } from '../reduxStore/reduxStore';
import {
	SplashScreenFallback,
	MuiThemeProvider,
	SplashScreenProvider,
	NotistackProvider,
	LayoutUIProvider,
	AuthProvider,
} from 'app/providers';
import { Router } from './routes/Router';


const App: React.FC = () => {
	return (<>
		{/* Глобальный сплешскрин */}
		<React.Suspense fallback={<SplashScreenFallback/>}>
			{/* Redux store */}
			<Provider store={store}>
				{/* Синхронизация redux store с local storage для auth */}
				<PersistGate persistor={persistor} loading={<SplashScreenFallback/>}>
					{/* Router-dom, basename берем из PUBLIC_URL */}
					<BrowserRouter basename={process.env.PUBLIC_URL}>
						{/* Провайдер темы для темизации UI */}
						<MuiThemeProvider>
							{/* Normalize и сброс стилей для Mui */}
							<CssBaseline>
								{/* Провайдер глобального экрана загрузки */}
								<SplashScreenProvider>
									{/* Провайдер всплывающих уведомлений */}
									<NotistackProvider>
										{/* isUserAuth, postAuthUrl */}
										<AuthProvider>
											{/* asideMenu, confirmModals */}
											<LayoutUIProvider>
												<Router />
											</LayoutUIProvider>
										</AuthProvider>
									</NotistackProvider>
								</SplashScreenProvider>
							</CssBaseline>
						</MuiThemeProvider>
					</BrowserRouter>
				</PersistGate>
			</Provider>
		</React.Suspense>
	</>);
}

export { App };
