import React, { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks';
import { getQueryStringParams } from 'utils';
import { SplashScreenFallback } from 'app/providers';
// Types


const AuthCodePage: React.FC = () => {
	const { hash } = useLocation();
	const navigate = useNavigate()
	const { getTokens, postAuthUrl } = useAuth();

	// Перенаправлены с iii и в хеше authCode
	useEffect(() => {
		if (hash) {
			const params = getQueryStringParams(hash);
			const authCode = params.code;
			const fromUrl = params.state;
			postAuthUrl.current = fromUrl;
			getTokens(authCode);
		} else {
			navigate('/auth/login');
		}
	}, [hash]);

	return null;
}

export { AuthCodePage };
