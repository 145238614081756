import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
	AppButton,
	AppIcon,
	Stack,
	AppPopover,
	Typography,
} from 'app/components';


const LangToggler: React.FC = () => {
	const keyPrefix = `layout.footer.language`;
	const { t, i18n } = useTranslation(undefined, { keyPrefix });

	const [anchorEl, setAnchorEl] = useState<Element | null>(null);
	const handleClick = (event: React.SyntheticEvent) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	}

	const langs = ['ru', 'en'];
	const langsPersisted = useRef({
		[langs[0]]: t(langs[0]),
		[langs[1]]: t(langs[1]),
	});
	const updateLangsPersisted = () => {
		langsPersisted.current = {
			[langs[0]]: t(langs[0]),
			[langs[1]]: t(langs[1]),
		};
	}

	const toggleLang = (lang: string) => {
		i18n.changeLanguage(lang);
		handleClose();
	}


	return (<>
		<Stack
			component="button"
			onClick={handleClick}
			direction="row"
			alignItems="center"
			sx={(theme) => ({
				margin: `-${theme.spacing(1)} 0`,
				padding: 0,
				color: theme.color.primary,
				background: 'transparent',
				border: 'none',
				cursor: 'pointer',
				'&:hover p': {
					color: theme.color.epos.hover,
				}
			})}
		>
			<AppIcon
				iconType="language"
				sx={(theme) => ({
					width: theme.spacing(5),
					heigth: theme.spacing(5),
				})}
			/>

			<Typography
				sx={(theme) => ({
					margin: `0 ${theme.spacing(1.5)}`,
					font: theme.font.small.md,
				})}
			>
				{t(i18n.language)}
			</Typography>

			<AppIcon
				iconType="triangleDown"
				sx={(theme) => ({
					width: theme.spacing(2),
					height: theme.spacing(1)
				})}
			/>
		</Stack>

		<AppPopover
			open={Boolean(anchorEl)}
			onClose={handleClose}
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'left',
			}}
			transformOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			onTransitionEnd={updateLangsPersisted}
		>
			<Stack
				sx={(theme) => ({
					width: theme.spacing(30),
					overflow: 'hidden',
					border: theme.border.primary,
					borderColor: theme.color.tertiary,
					borderRadius: theme.radius.x2,
					boxShadow: theme.depth.primary
				})}
			>
				{langs.map((lang) => (
					<AppButton
						key={lang}
						variant={(lang === i18n.language) ? 'main' : 'text'}
						onClick={() => toggleLang(lang)}
						sx={(theme) => ({
							justifyContent: "flex-start",
							padding: `4px 6px`,
							borderRadius: 0,
						})}
					>
						<AppIcon
							iconType="done"
							sx={(theme) => ({
								color: (lang === i18n.language) ? theme.color.bg_01 : 'transparent',
							})}
						/>
						{langsPersisted.current[lang]}
					</AppButton>
				))}
			</Stack>
		</AppPopover>
	</>);
}

export { LangToggler };
