import { useCallback } from 'react';
import { useAppDispatch, useAppSelector, shallowEqual } from 'hooks';
import { useAuthContext } from 'app/providers';
// import { tokensService } from 'app/auth/tokens/tokensService';
import { getBaseUrl, sessionStorageHelper } from 'utils';
// Types
import { MutableRefObject } from 'react';
import { eposCabinetApiCalls } from 'apis/cabinetApi/cabinetApiCalls';
import { globalAppConfig } from 'appConfig';
import { authActions } from './authSlice';
import { userInfoActions } from '../../modules/profile/redux/userInfoSlice';


/**
 * @summary Авторизованный пользователь и действия авторизации
 * @property `userInfo` Профиль авторизованного через iii пользователя, полученный с cabinet api
 * @property `postAuthUrl` Адрес редиректа после прохождения авторизации из authContext
 * @property `logInUser` Редирект пользователя на iii для авторизации и получения authCode
 * @property `setUserAuth` Обмен authCode на токены через cabinet api, получение профиля пользователя, запись в стор
 * @property `logOutUser` Удаление токенов и редирект на logout url iii
*/
interface IUseAuthReturn {
	readonly accessToken: string | null;
	/**  */
	readonly accessError: string | null;
	/** Адрес редиректа после прохождения авторизации из authContext */
	readonly postAuthUrl: MutableRefObject<string>;
	/** Редирект пользователя на iii для авторизации и получения authCode */
	readonly logInUser: (fromUrl: string) => void;
	/** Обмен authCode на токены через cabinet api, получение профиля пользователя, запись в стор */
	readonly getTokens: (authCode: string) => Promise<void>;
	/** Удаление токенов и редирект на logout url iii */
	readonly logOutUser: VoidFunction;
};

const useAuth = (): IUseAuthReturn => {
	const dispatch = useAppDispatch();
	const { idToken, refreshToken } = useAppSelector((state) => state.auth, shallowEqual);
	const { accessToken, accessError } = useAppSelector((state) => state.userInfo, shallowEqual);
	const { postAuthUrl } = useAuthContext();

	const { AUTH_URL, CLIENT_ID } = globalAppConfig.appConfig;

	const logInUser = useCallback((fromUrl: string): void => {
		const authorizationUrl = `${AUTH_URL}/connect/authorize`;
		const client_id = CLIENT_ID;
		const response_type = 'code id_token';
		const redirect_uri = getBaseUrl() + 'auth';
		const scope = 'openid offline_access epos.cabinet.serviceprovider epos.cabinet.invoice email';
		const nonce = 'N' + Math.random() + Date.now();
		const state = fromUrl;

		const redirectUrlForLogin = authorizationUrl +
			'?' + 'client_id='     + encodeURI(client_id) +
			'&' + 'response_type=' + encodeURI(response_type) +
			'&' + 'redirect_uri='  + encodeURI(redirect_uri) +
			'&' + 'scope='         + encodeURI(scope) +
			'&' + 'nonce='         + encodeURI(nonce) +
			'&' + 'state='         + encodeURI(state);

		// Редирект на iii для авторизации. fromUrl передадим в params.state для получения в хэше
		window.location.assign(redirectUrlForLogin);
	}, [AUTH_URL, CLIENT_ID]);

	const logOutUser = useCallback((): void => {
		// Отозвать refreshToken
		if (refreshToken) {
			eposCabinetApiCalls.revokeRefreshToken(refreshToken);
		}
		// Удалить токены из local и session storage
		sessionStorageHelper.removeAccessToken();
		dispatch(authActions.clearAuthState());

		const authorizationUrl = `${AUTH_URL}/connect/endsession`;
		const id_token_hint = idToken;
		const baseURL = getBaseUrl();
		const post_logout_redirect_uri = (baseURL.includes('localhost'))
			? baseURL
			: baseURL.replace(/\/$/, '');

		const redirectUrlForLogout = authorizationUrl +
			'?' + 'id_token_hint='            + id_token_hint +
			'&' + 'post_logout_redirect_uri=' + encodeURI(post_logout_redirect_uri);

		// Редирект на iii для логаута
		window.location.assign(redirectUrlForLogout);
	}, [dispatch, idToken, refreshToken, AUTH_URL]);

	const getTokens = useCallback(async (authCode: string): Promise<void> => {
		// Получить на api.identity токены, задать токены в state, session storage
		try {
			const tokens = await eposCabinetApiCalls.getTokensByAuthCode(authCode);
			dispatch(userInfoActions.accessTokenFetched(tokens.accessToken ?? ''));
			dispatch(authActions.tokensFetched(tokens));
		} catch(error) {
			const statusCode = (error as any).response.status;
			dispatch(userInfoActions.catchAuthError('500'));
		}
	}, [dispatch]);


	return {
		accessToken,
		accessError,
		postAuthUrl,
		logInUser,
		getTokens,
		logOutUser
	};
}


export { useAuth };

// https://iii.by/connect/endsession?id_token_hint=eyJhbGciOiJSUzI1NiIsImtpZCI6IkUzMUE3MzRBRjQ2RjkxQjVDMEVDREU0NUUwNDQzNDQ5QTc0RjI1NTFSUzI1NiIsInR5cCI6IkpXVCIsIng1dCI6IjR4cHpTdlJ2a2JYQTdONUY0RVEwU2FkUEpWRSJ9.eyJuYmYiOjE2NTkzNDEyMjYsImV4cCI6MTY1OTM0MTUyNiwiaXNzIjoiaHR0cHM6Ly9paWkuYnkiLCJhdWQiOiJlcG9zLmNhYmluZXQiLCJub25jZSI6Ik4wLjI4MzczMjIxNzY3MTA0ODE3MTY1OTM0MTIxNTA4NyIsImlhdCI6MTY1OTM0MTIyNiwiYXRfaGFzaCI6Im5IOEZKNURZclFobE8tc0JhUExpNVEiLCJzX2hhc2giOiJpbDdhc29KakpFTWhuZ1VlU3Q0dEhRIiwic2lkIjoiOUY5NkE5MDRDQTIwMzZDNUEyQjdBOUIxQUE3MDgxNkIiLCJzdWIiOiI0ZDJkYjk2MS1kMzllLTQ1ZjAtOGY2OC1kYjRhYTg2ZmEzNGQiLCJhdXRoX3RpbWUiOjE2NTkzNDEyMjQsImlkcCI6ImxvY2FsIiwiYW1yIjpbInB3ZCJdfQ.MkMX_nzsJRk0FYXOih5Zpc375HG_2H3n2IpoSgmy-HAiZ84e3848eOW0jBwnCgCXAxNYrG7vr84FUXgvg6KgtmLBKqzKM64-wcN63CQb1bqWklinVk8R4QiGra_MjWe3UXFxET2CyNVaWjYfJdGFOkzT5mwn3asJKKv1MMz0CdEf8WM9liDCYgINERq0OuIXC68d9u8F5ALoHaN2nVq_4-0tE1YVlJAhles_vHYKWOtAD1FO7vDwJNZqKAZC5QxyRQfZbx_1f4g6BKMM1sPWwPLpzFT3pfN6ySpjYHh3NlXeYKnO7fy1lrx4Yh6oDV6K3TYRNuXlhiGXxXwR-pypcg&post_logout_redirect_uri=https://cabinet.e-pos.by/

// https://iii.by/connect/endsession?id_token_hint=eyJhbGciOiJSUzI1NiIsImtpZCI6IkUzMUE3MzRBRjQ2RjkxQjVDMEVDREU0NUUwNDQzNDQ5QTc0RjI1NTFSUzI1NiIsInR5cCI6IkpXVCIsIng1dCI6IjR4cHpTdlJ2a2JYQTdONUY0RVEwU2FkUEpWRSJ9.eyJuYmYiOjE2NTkzNDYzMDcsImV4cCI6MTY1OTM0NjQ4NywiaXNzIjoiaHR0cHM6Ly9paWkuYnkiLCJhdWQiOiJlc2FzLmVwb3MuY2FiaW5ldCIsIm5vbmNlIjoiTjAuNDEyMzM1MTUzOTg1NjAyNjE2NTkzNDYyODc2ODkiLCJpYXQiOjE2NTkzNDYzMDcsImF0X2hhc2giOiIydmtuMXRIYU16anh1el9NN19zeUNRIiwic19oYXNoIjoiTjBuSHNodVAtTFZkamozdTB3dTZaUSIsInNpZCI6IkUwNzVDOEY4RkYzNjhEQ0E2NERCM0E0MTQzQ0VFQjYwIiwic3ViIjoiNGQyZGI5NjEtZDM5ZS00NWYwLThmNjgtZGI0YWE4NmZhMzRkIiwiYXV0aF90aW1lIjoxNjU5MzQ2MzA1LCJpZHAiOiJsb2NhbCIsImFtciI6WyJwd2QiXX0.c9CPw1HMWQJvZM58ZWVcV0KZ7jdSkr3wOqLkFC8NJdVLNWASQHnSg47z5kzlA3DcG9ZtkD9mQ6B5TOe_TafICBZgAPJcAtIH0Cp4BVg_TjSoWcf2uVAAYVMVAIhO9e8X6pJ87zePsDG-6Mxs7lFqF7HJf2lR0MG2aq0hbBCrocVMRaTBD_xKUvymKeWw9lzlrGM01JS_6npaKHWkmoj9y9e7S3TXgzFzhlrRUy4IMgLkcFJ7pBbIQRkR51c_4KWRip82gih6A60fMMuwfpi4SVEsTFxEDuM6I96ANPP84wEtAGGoenD2XJ33payWBY_CJaUyNC2PtZUtjndfJvIy0Q&post_logout_redirect_uri=https://epos.hutkigrosh.by/

// https://ids.iii.by/connect/endsession?id_token_hint=eyJhbGciOiJSUzI1NiIsImtpZCI6IjgxRDI4RkY4OUYyNENCNjk2MDlGNjhCRkQ2QjU5RjcyNkVEOTBERTJSUzI1NiIsInR5cCI6IkpXVCIsIng1dCI6ImdkS1AtSjhreTJsZ24yaV8xcldmY203WkRlSSJ9.eyJuYmYiOjE2NTkzNTc3ODMsImV4cCI6MTY1OTM1Nzk2MywiaXNzIjoiaHR0cHM6Ly9pZHMuaWlpLmJ5IiwiYXVkIjoiZXBvcy5jYWJpbmV0IiwiaWF0IjoxNjU5MzU3NzgzLCJhdF9oYXNoIjoicUJXakotR0ExaDNtWnFsaWFSV3VJQSIsInN1YiI6IjNmMWE2OGM4LTY5NzEtNDUzZi04NDJiLWM4MzI2MjhkZDRhMiIsImF1dGhfdGltZSI6MTY1OTM0NDM5OCwiaWRwIjoibG9jYWwiLCJhbXIiOlsicHdkIl19.gjQVYH37BdinkQ4F6wcgPqpN02MEHGFol94zM8sJ6SdlKuF125-JRr2oGnKnMX4j8mtZL26YPPIItEjV9hCXQUWKgDJB0HoWvosjCXEtVyO883RFpf-dP5HuA6chdpBf_zIjW_oXyND6KWZL0hJGALBgJv0DggQFUfie44WdwUuOB8ZlsoYBo77Us6wkw6PDsDh9qgpm-ysur26P22lApNlBNy1f4ICI43-imnbVOB_NA_U8EWMGqSxNNz-r820Z_mFm2LqbYDqf7Q3Rv0Rbt_hi2_ZU5FU47pKMrdKDRpiPAzip_KzIMnOe_DE-H4lr1JcGxhpGnq5L1qVDrigZjw&post_logout_redirect_uri=http://localhost:3000/