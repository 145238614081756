/* tslint:disable */
/* eslint-disable */
/**
 * EPOS cabinet API
 * Cabinet API сервиса E-POS
 *
 * OpenAPI spec version: v1
 * Contact: support-api@epos.by
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { BiometryQRCode } from '../models';
import { ErrorStatus } from '../models';
import { ServiceProvider } from '../models';
import { ServiceProviderIII } from '../models';
import { ServiceProviderRecords } from '../models';
/**
 * ServiceProviderApi - axios parameter creator
 * @export
 */
export const ServiceProviderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получение списка производителей услуг.
         * @param {number} [skip] Количество записей, которые необходимо пропустить.
         * @param {number} [count] Количество записй, которые необходимо вернуть.
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1serviceProviderServiceProviderGET: async (skip?: number, count?: number, searchString?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/serviceProvider`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            if (searchString !== undefined) {
                localVarQueryParameter['searchString'] = searchString;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Добавление нового поставщика услуг.
         * @param {ServiceProvider} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1serviceProviderServiceProviderPOST: async (body?: ServiceProvider, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/serviceProvider`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers?.['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление поставщика услуг.
         * @param {number} id Идентификатор поставщика услуг.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1serviceProvideridServiceProviderDELETE: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1serviceProvideridServiceProviderDELETE.');
            }
            const localVarPath = `/v1/serviceProvider/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение информации о конкретном производителе услуг.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1serviceProvideridServiceProviderGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1serviceProvideridServiceProviderGET.');
            }
            const localVarPath = `/v1/serviceProvider/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновление информации о производителе услуг.
         * @param {number} id 
         * @param {ServiceProvider} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1serviceProvideridServiceProviderPUT: async (id: number, body?: ServiceProvider, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1serviceProvideridServiceProviderPUT.');
            }
            const localVarPath = `/v1/serviceProvider/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers?.['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение qr-кода для регистрации девайса по биометрии
         * @param {number} id 
         * @param {number} [serviceId] Идентификатор услуги ПУ
         * @param {number} [rttCode] Идентификатор РТТ
         * @param {number} [cashBoxCode] Идентификатор кассы РТТ
         * @param {number} [imgWidth] 
         * @param {number} [imgHeight] 
         * @param {boolean} [getImage] 
         * @param {boolean} [requestERIP] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1serviceProvideridbiometryqrServiceProviderGET: async (id: number, serviceId?: number, rttCode?: number, cashBoxCode?: number, imgWidth?: number, imgHeight?: number, getImage?: boolean, requestERIP?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1serviceProvideridbiometryqrServiceProviderGET.');
            }
            const localVarPath = `/v1/serviceProvider/{id}/biometry/qr`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (serviceId !== undefined) {
                localVarQueryParameter['serviceId'] = serviceId;
            }

            if (rttCode !== undefined) {
                localVarQueryParameter['rttCode'] = rttCode;
            }

            if (cashBoxCode !== undefined) {
                localVarQueryParameter['cashBoxCode'] = cashBoxCode;
            }

            if (imgWidth !== undefined) {
                localVarQueryParameter['imgWidth'] = imgWidth;
            }

            if (imgHeight !== undefined) {
                localVarQueryParameter['imgHeight'] = imgHeight;
            }

            if (getImage !== undefined) {
                localVarQueryParameter['getImage'] = getImage;
            }

            if (requestERIP !== undefined) {
                localVarQueryParameter['requestERIP'] = requestERIP;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выключение ПУ.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1serviceProvideriddisableServiceProviderPATCH: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1serviceProvideriddisableServiceProviderPATCH.');
            }
            const localVarPath = `/v1/serviceProvider/{id}/disable`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Включение ПУ.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1serviceProvideridenableServiceProviderPATCH: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1serviceProvideridenableServiceProviderPATCH.');
            }
            const localVarPath = `/v1/serviceProvider/{id}/enable`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение информации по ПУ на iii
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1serviceProvideridiiiServiceProviderGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1serviceProvideridiiiServiceProviderGET.');
            }
            const localVarPath = `/v1/serviceProvider/{id}/iii`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновление информации по ПУ на iii
         * @param {number} id 
         * @param {ServiceProviderIII} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1serviceProvideridiiiServiceProviderPUT: async (id: number, body?: ServiceProviderIII, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1serviceProvideridiiiServiceProviderPUT.');
            }
            const localVarPath = `/v1/serviceProvider/{id}/iii`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers?.['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Деактивация состояния сервиса.
         * @param {number} id Идентификатор ПУ.
         * @param {number} serviceId Идентификатор сервиса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1serviceProvideridserviceserviceIddisableServiceProviderPATCH: async (id: number, serviceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1serviceProvideridserviceserviceIddisableServiceProviderPATCH.');
            }
            // verify required parameter 'serviceId' is not null or undefined
            if (serviceId === null || serviceId === undefined) {
                throw new RequiredError('serviceId','Required parameter serviceId was null or undefined when calling v1serviceProvideridserviceserviceIddisableServiceProviderPATCH.');
            }
            const localVarPath = `/v1/serviceProvider/{id}/service/{serviceId}/disable`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Активация состояния сервиса.
         * @param {number} id Идентификатор ПУ.
         * @param {number} serviceId Идентификатор сервиса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1serviceProvideridserviceserviceIdenableServiceProviderPATCH: async (id: number, serviceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1serviceProvideridserviceserviceIdenableServiceProviderPATCH.');
            }
            // verify required parameter 'serviceId' is not null or undefined
            if (serviceId === null || serviceId === undefined) {
                throw new RequiredError('serviceId','Required parameter serviceId was null or undefined when calling v1serviceProvideridserviceserviceIdenableServiceProviderPATCH.');
            }
            const localVarPath = `/v1/serviceProvider/{id}/service/{serviceId}/enable`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Деактивация состояния сторно сервиса.
         * @param {number} id Идентификатор ПУ.
         * @param {number} serviceId Идентификатор сервиса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1serviceProvideridserviceserviceIdstornodisableServiceProviderPATCH: async (id: number, serviceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1serviceProvideridserviceserviceIdstornodisableServiceProviderPATCH.');
            }
            // verify required parameter 'serviceId' is not null or undefined
            if (serviceId === null || serviceId === undefined) {
                throw new RequiredError('serviceId','Required parameter serviceId was null or undefined when calling v1serviceProvideridserviceserviceIdstornodisableServiceProviderPATCH.');
            }
            const localVarPath = `/v1/serviceProvider/{id}/service/{serviceId}/storno/disable`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Активация состояния сторно сервиса.
         * @param {number} id Идентификатор ПУ.
         * @param {number} serviceId Идентификатор сервиса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1serviceProvideridserviceserviceIdstornoenableServiceProviderPATCH: async (id: number, serviceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1serviceProvideridserviceserviceIdstornoenableServiceProviderPATCH.');
            }
            // verify required parameter 'serviceId' is not null or undefined
            if (serviceId === null || serviceId === undefined) {
                throw new RequiredError('serviceId','Required parameter serviceId was null or undefined when calling v1serviceProvideridserviceserviceIdstornoenableServiceProviderPATCH.');
            }
            const localVarPath = `/v1/serviceProvider/{id}/service/{serviceId}/storno/enable`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ServiceProviderApi - functional programming interface
 * @export
 */
export const ServiceProviderApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получение списка производителей услуг.
         * @param {number} [skip] Количество записей, которые необходимо пропустить.
         * @param {number} [count] Количество записй, которые необходимо вернуть.
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1serviceProviderServiceProviderGET(skip?: number, count?: number, searchString?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ServiceProviderRecords>>> {
            const localVarAxiosArgs = await ServiceProviderApiAxiosParamCreator(configuration).v1serviceProviderServiceProviderGET(skip, count, searchString, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Добавление нового поставщика услуг.
         * @param {ServiceProvider} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1serviceProviderServiceProviderPOST(body?: ServiceProvider, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ServiceProvider>>> {
            const localVarAxiosArgs = await ServiceProviderApiAxiosParamCreator(configuration).v1serviceProviderServiceProviderPOST(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Удаление поставщика услуг.
         * @param {number} id Идентификатор поставщика услуг.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1serviceProvideridServiceProviderDELETE(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ErrorStatus>>> {
            const localVarAxiosArgs = await ServiceProviderApiAxiosParamCreator(configuration).v1serviceProvideridServiceProviderDELETE(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение информации о конкретном производителе услуг.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1serviceProvideridServiceProviderGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ServiceProvider>>> {
            const localVarAxiosArgs = await ServiceProviderApiAxiosParamCreator(configuration).v1serviceProvideridServiceProviderGET(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Обновление информации о производителе услуг.
         * @param {number} id 
         * @param {ServiceProvider} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1serviceProvideridServiceProviderPUT(id: number, body?: ServiceProvider, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ServiceProvider>>> {
            const localVarAxiosArgs = await ServiceProviderApiAxiosParamCreator(configuration).v1serviceProvideridServiceProviderPUT(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение qr-кода для регистрации девайса по биометрии
         * @param {number} id 
         * @param {number} [serviceId] Идентификатор услуги ПУ
         * @param {number} [rttCode] Идентификатор РТТ
         * @param {number} [cashBoxCode] Идентификатор кассы РТТ
         * @param {number} [imgWidth] 
         * @param {number} [imgHeight] 
         * @param {boolean} [getImage] 
         * @param {boolean} [requestERIP] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1serviceProvideridbiometryqrServiceProviderGET(id: number, serviceId?: number, rttCode?: number, cashBoxCode?: number, imgWidth?: number, imgHeight?: number, getImage?: boolean, requestERIP?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<BiometryQRCode>>> {
            const localVarAxiosArgs = await ServiceProviderApiAxiosParamCreator(configuration).v1serviceProvideridbiometryqrServiceProviderGET(id, serviceId, rttCode, cashBoxCode, imgWidth, imgHeight, getImage, requestERIP, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Выключение ПУ.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1serviceProvideriddisableServiceProviderPATCH(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ErrorStatus>>> {
            const localVarAxiosArgs = await ServiceProviderApiAxiosParamCreator(configuration).v1serviceProvideriddisableServiceProviderPATCH(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Включение ПУ.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1serviceProvideridenableServiceProviderPATCH(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ErrorStatus>>> {
            const localVarAxiosArgs = await ServiceProviderApiAxiosParamCreator(configuration).v1serviceProvideridenableServiceProviderPATCH(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение информации по ПУ на iii
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1serviceProvideridiiiServiceProviderGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ServiceProviderIII>>> {
            const localVarAxiosArgs = await ServiceProviderApiAxiosParamCreator(configuration).v1serviceProvideridiiiServiceProviderGET(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Обновление информации по ПУ на iii
         * @param {number} id 
         * @param {ServiceProviderIII} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1serviceProvideridiiiServiceProviderPUT(id: number, body?: ServiceProviderIII, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ErrorStatus>>> {
            const localVarAxiosArgs = await ServiceProviderApiAxiosParamCreator(configuration).v1serviceProvideridiiiServiceProviderPUT(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Деактивация состояния сервиса.
         * @param {number} id Идентификатор ПУ.
         * @param {number} serviceId Идентификатор сервиса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1serviceProvideridserviceserviceIddisableServiceProviderPATCH(id: number, serviceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ErrorStatus>>> {
            const localVarAxiosArgs = await ServiceProviderApiAxiosParamCreator(configuration).v1serviceProvideridserviceserviceIddisableServiceProviderPATCH(id, serviceId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Активация состояния сервиса.
         * @param {number} id Идентификатор ПУ.
         * @param {number} serviceId Идентификатор сервиса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1serviceProvideridserviceserviceIdenableServiceProviderPATCH(id: number, serviceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ErrorStatus>>> {
            const localVarAxiosArgs = await ServiceProviderApiAxiosParamCreator(configuration).v1serviceProvideridserviceserviceIdenableServiceProviderPATCH(id, serviceId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Деактивация состояния сторно сервиса.
         * @param {number} id Идентификатор ПУ.
         * @param {number} serviceId Идентификатор сервиса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1serviceProvideridserviceserviceIdstornodisableServiceProviderPATCH(id: number, serviceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ErrorStatus>>> {
            const localVarAxiosArgs = await ServiceProviderApiAxiosParamCreator(configuration).v1serviceProvideridserviceserviceIdstornodisableServiceProviderPATCH(id, serviceId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Активация состояния сторно сервиса.
         * @param {number} id Идентификатор ПУ.
         * @param {number} serviceId Идентификатор сервиса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1serviceProvideridserviceserviceIdstornoenableServiceProviderPATCH(id: number, serviceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ErrorStatus>>> {
            const localVarAxiosArgs = await ServiceProviderApiAxiosParamCreator(configuration).v1serviceProvideridserviceserviceIdstornoenableServiceProviderPATCH(id, serviceId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ServiceProviderApi - factory interface
 * @export
 */
export const ServiceProviderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Получение списка производителей услуг.
         * @param {number} [skip] Количество записей, которые необходимо пропустить.
         * @param {number} [count] Количество записй, которые необходимо вернуть.
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1serviceProviderServiceProviderGET(skip?: number, count?: number, searchString?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<ServiceProviderRecords>> {
            return ServiceProviderApiFp(configuration).v1serviceProviderServiceProviderGET(skip, count, searchString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Добавление нового поставщика услуг.
         * @param {ServiceProvider} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1serviceProviderServiceProviderPOST(body?: ServiceProvider, options?: AxiosRequestConfig): Promise<AxiosResponse<ServiceProvider>> {
            return ServiceProviderApiFp(configuration).v1serviceProviderServiceProviderPOST(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удаление поставщика услуг.
         * @param {number} id Идентификатор поставщика услуг.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1serviceProvideridServiceProviderDELETE(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<ErrorStatus>> {
            return ServiceProviderApiFp(configuration).v1serviceProvideridServiceProviderDELETE(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение информации о конкретном производителе услуг.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1serviceProvideridServiceProviderGET(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<ServiceProvider>> {
            return ServiceProviderApiFp(configuration).v1serviceProvideridServiceProviderGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновление информации о производителе услуг.
         * @param {number} id 
         * @param {ServiceProvider} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1serviceProvideridServiceProviderPUT(id: number, body?: ServiceProvider, options?: AxiosRequestConfig): Promise<AxiosResponse<ServiceProvider>> {
            return ServiceProviderApiFp(configuration).v1serviceProvideridServiceProviderPUT(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение qr-кода для регистрации девайса по биометрии
         * @param {number} id 
         * @param {number} [serviceId] Идентификатор услуги ПУ
         * @param {number} [rttCode] Идентификатор РТТ
         * @param {number} [cashBoxCode] Идентификатор кассы РТТ
         * @param {number} [imgWidth] 
         * @param {number} [imgHeight] 
         * @param {boolean} [getImage] 
         * @param {boolean} [requestERIP] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1serviceProvideridbiometryqrServiceProviderGET(id: number, serviceId?: number, rttCode?: number, cashBoxCode?: number, imgWidth?: number, imgHeight?: number, getImage?: boolean, requestERIP?: boolean, options?: AxiosRequestConfig): Promise<AxiosResponse<BiometryQRCode>> {
            return ServiceProviderApiFp(configuration).v1serviceProvideridbiometryqrServiceProviderGET(id, serviceId, rttCode, cashBoxCode, imgWidth, imgHeight, getImage, requestERIP, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выключение ПУ.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1serviceProvideriddisableServiceProviderPATCH(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<ErrorStatus>> {
            return ServiceProviderApiFp(configuration).v1serviceProvideriddisableServiceProviderPATCH(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Включение ПУ.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1serviceProvideridenableServiceProviderPATCH(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<ErrorStatus>> {
            return ServiceProviderApiFp(configuration).v1serviceProvideridenableServiceProviderPATCH(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение информации по ПУ на iii
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1serviceProvideridiiiServiceProviderGET(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<ServiceProviderIII>> {
            return ServiceProviderApiFp(configuration).v1serviceProvideridiiiServiceProviderGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновление информации по ПУ на iii
         * @param {number} id 
         * @param {ServiceProviderIII} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1serviceProvideridiiiServiceProviderPUT(id: number, body?: ServiceProviderIII, options?: AxiosRequestConfig): Promise<AxiosResponse<ErrorStatus>> {
            return ServiceProviderApiFp(configuration).v1serviceProvideridiiiServiceProviderPUT(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Деактивация состояния сервиса.
         * @param {number} id Идентификатор ПУ.
         * @param {number} serviceId Идентификатор сервиса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1serviceProvideridserviceserviceIddisableServiceProviderPATCH(id: number, serviceId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<ErrorStatus>> {
            return ServiceProviderApiFp(configuration).v1serviceProvideridserviceserviceIddisableServiceProviderPATCH(id, serviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Активация состояния сервиса.
         * @param {number} id Идентификатор ПУ.
         * @param {number} serviceId Идентификатор сервиса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1serviceProvideridserviceserviceIdenableServiceProviderPATCH(id: number, serviceId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<ErrorStatus>> {
            return ServiceProviderApiFp(configuration).v1serviceProvideridserviceserviceIdenableServiceProviderPATCH(id, serviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Деактивация состояния сторно сервиса.
         * @param {number} id Идентификатор ПУ.
         * @param {number} serviceId Идентификатор сервиса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1serviceProvideridserviceserviceIdstornodisableServiceProviderPATCH(id: number, serviceId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<ErrorStatus>> {
            return ServiceProviderApiFp(configuration).v1serviceProvideridserviceserviceIdstornodisableServiceProviderPATCH(id, serviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Активация состояния сторно сервиса.
         * @param {number} id Идентификатор ПУ.
         * @param {number} serviceId Идентификатор сервиса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1serviceProvideridserviceserviceIdstornoenableServiceProviderPATCH(id: number, serviceId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<ErrorStatus>> {
            return ServiceProviderApiFp(configuration).v1serviceProvideridserviceserviceIdstornoenableServiceProviderPATCH(id, serviceId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ServiceProviderApi - object-oriented interface
 * @export
 * @class ServiceProviderApi
 * @extends {BaseAPI}
 */
export class ServiceProviderApi extends BaseAPI {
    /**
     * 
     * @summary Получение списка производителей услуг.
     * @param {number} [skip] Количество записей, которые необходимо пропустить.
     * @param {number} [count] Количество записй, которые необходимо вернуть.
     * @param {string} [searchString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderApi
     */
    public async v1serviceProviderServiceProviderGET(skip?: number, count?: number, searchString?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<ServiceProviderRecords>> {
        return ServiceProviderApiFp(this.configuration).v1serviceProviderServiceProviderGET(skip, count, searchString, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Добавление нового поставщика услуг.
     * @param {ServiceProvider} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderApi
     */
    public async v1serviceProviderServiceProviderPOST(body?: ServiceProvider, options?: AxiosRequestConfig) : Promise<AxiosResponse<ServiceProvider>> {
        return ServiceProviderApiFp(this.configuration).v1serviceProviderServiceProviderPOST(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Удаление поставщика услуг.
     * @param {number} id Идентификатор поставщика услуг.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderApi
     */
    public async v1serviceProvideridServiceProviderDELETE(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<ErrorStatus>> {
        return ServiceProviderApiFp(this.configuration).v1serviceProvideridServiceProviderDELETE(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Получение информации о конкретном производителе услуг.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderApi
     */
    public async v1serviceProvideridServiceProviderGET(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<ServiceProvider>> {
        return ServiceProviderApiFp(this.configuration).v1serviceProvideridServiceProviderGET(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Обновление информации о производителе услуг.
     * @param {number} id 
     * @param {ServiceProvider} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderApi
     */
    public async v1serviceProvideridServiceProviderPUT(id: number, body?: ServiceProvider, options?: AxiosRequestConfig) : Promise<AxiosResponse<ServiceProvider>> {
        return ServiceProviderApiFp(this.configuration).v1serviceProvideridServiceProviderPUT(id, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Получение qr-кода для регистрации девайса по биометрии
     * @param {number} id 
     * @param {number} [serviceId] Идентификатор услуги ПУ
     * @param {number} [rttCode] Идентификатор РТТ
     * @param {number} [cashBoxCode] Идентификатор кассы РТТ
     * @param {number} [imgWidth] 
     * @param {number} [imgHeight] 
     * @param {boolean} [getImage] 
     * @param {boolean} [requestERIP] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderApi
     */
    public async v1serviceProvideridbiometryqrServiceProviderGET(id: number, serviceId?: number, rttCode?: number, cashBoxCode?: number, imgWidth?: number, imgHeight?: number, getImage?: boolean, requestERIP?: boolean, options?: AxiosRequestConfig) : Promise<AxiosResponse<BiometryQRCode>> {
        return ServiceProviderApiFp(this.configuration).v1serviceProvideridbiometryqrServiceProviderGET(id, serviceId, rttCode, cashBoxCode, imgWidth, imgHeight, getImage, requestERIP, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Выключение ПУ.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderApi
     */
    public async v1serviceProvideriddisableServiceProviderPATCH(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<ErrorStatus>> {
        return ServiceProviderApiFp(this.configuration).v1serviceProvideriddisableServiceProviderPATCH(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Включение ПУ.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderApi
     */
    public async v1serviceProvideridenableServiceProviderPATCH(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<ErrorStatus>> {
        return ServiceProviderApiFp(this.configuration).v1serviceProvideridenableServiceProviderPATCH(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Получение информации по ПУ на iii
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderApi
     */
    public async v1serviceProvideridiiiServiceProviderGET(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<ServiceProviderIII>> {
        return ServiceProviderApiFp(this.configuration).v1serviceProvideridiiiServiceProviderGET(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Обновление информации по ПУ на iii
     * @param {number} id 
     * @param {ServiceProviderIII} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderApi
     */
    public async v1serviceProvideridiiiServiceProviderPUT(id: number, body?: ServiceProviderIII, options?: AxiosRequestConfig) : Promise<AxiosResponse<ErrorStatus>> {
        return ServiceProviderApiFp(this.configuration).v1serviceProvideridiiiServiceProviderPUT(id, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Деактивация состояния сервиса.
     * @param {number} id Идентификатор ПУ.
     * @param {number} serviceId Идентификатор сервиса.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderApi
     */
    public async v1serviceProvideridserviceserviceIddisableServiceProviderPATCH(id: number, serviceId: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<ErrorStatus>> {
        return ServiceProviderApiFp(this.configuration).v1serviceProvideridserviceserviceIddisableServiceProviderPATCH(id, serviceId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Активация состояния сервиса.
     * @param {number} id Идентификатор ПУ.
     * @param {number} serviceId Идентификатор сервиса.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderApi
     */
    public async v1serviceProvideridserviceserviceIdenableServiceProviderPATCH(id: number, serviceId: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<ErrorStatus>> {
        return ServiceProviderApiFp(this.configuration).v1serviceProvideridserviceserviceIdenableServiceProviderPATCH(id, serviceId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Деактивация состояния сторно сервиса.
     * @param {number} id Идентификатор ПУ.
     * @param {number} serviceId Идентификатор сервиса.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderApi
     */
    public async v1serviceProvideridserviceserviceIdstornodisableServiceProviderPATCH(id: number, serviceId: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<ErrorStatus>> {
        return ServiceProviderApiFp(this.configuration).v1serviceProvideridserviceserviceIdstornodisableServiceProviderPATCH(id, serviceId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Активация состояния сторно сервиса.
     * @param {number} id Идентификатор ПУ.
     * @param {number} serviceId Идентификатор сервиса.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderApi
     */
    public async v1serviceProvideridserviceserviceIdstornoenableServiceProviderPATCH(id: number, serviceId: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<ErrorStatus>> {
        return ServiceProviderApiFp(this.configuration).v1serviceProvideridserviceserviceIdstornoenableServiceProviderPATCH(id, serviceId, options).then((request) => request(this.axios, this.basePath));
    }
}
