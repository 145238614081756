import { extend } from 'lodash';
import { SystemStyleObject } from '@mui/system';
import { SxProps, Theme } from '@mui/material';
import { TMuiEsasTheme } from 'app/theme';


//* Primary - темный  текст карточек 
export const sxBlockMainText = (theme: TMuiEsasTheme): SystemStyleObject<TMuiEsasTheme> => ({
	font: theme.font.small.md,
	color: theme.color.primary,
	whiteSpace: 'pre-line',
	wordBreak: 'break-word'
});

//* Secondary - светлый мелкий текст карточек
export const sxblockSmallText = (theme: TMuiEsasTheme): SystemStyleObject<TMuiEsasTheme> => ({
	font: theme.font.small.sm,
	color: theme.color.secondary,
	whiteSpace: 'pre-line',
	wordBreak: 'break-word'
});


export const textFieldSxProp = (theme: TMuiEsasTheme): SystemStyleObject<TMuiEsasTheme> => extend({
	width: '100%',
	'& .MuiInputBase-root': {
		padding: 0,
		whiteSpace: 'nowrap',
		font: theme.font.small.md,
		color: theme.color.secondary,
	},
	// Стандартное состояние
	'& .MuiInputBase-input': {
		padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
		// paddingLeft: 0,
		height: 'auto',
		font: theme.font.small.md,
		color: theme.color.primary,
		borderRadius: theme.spacing(2),
		'&:placeholder': {
			font: theme.font.small.md,
			color: theme.color.tertiary,
		}
	},
	'& .MuiOutlinedInput-notchedOutline': {
		border: theme.border.primary,
		borderColor: theme.color.tertiary,
		borderRadius: theme.spacing(2),
	},
	'& .MuiFormHelperText-root': {
		margin: `${theme.spacing(1)} ${theme.spacing(2)} 0`,
		font: theme.font.small.md,
		color: theme.color.secondary,
	}
}, {
	// hover
	'&.MuiFormControl-root:hover': {
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: theme.color.epos.notification,
		}
	}
}, {
	// disabled
	'&.MuiFormControl-root .Mui-disabled': {
		'& .MuiInputBase-input': {
			color: theme.color.tertiary,
			WebkitTextFillColor: 'initial',
			background: theme.color.bg_02,
		},
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: theme.color.tertiary,
		}
	}
}, {
	// focused
	'&.MuiFormControl-root .Mui-focused': {
		'& .MuiOutlinedInput-notchedOutline': {
			border: theme.border.primary,
			borderColor: theme.color.epos.notification,
			boxShadow: `inset 0 0 1px 1px ${theme.color.epos.notification}`,
		}
	}
}, {
	// error
	'&.MuiFormControl-root .Mui-error': {
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: theme.color.status.error,
		},
		'&.MuiFormHelperText-root': {
			color: theme.color.status.error,
		}
	},
	// focused + error
	'&.MuiFormControl-root .Mui-focused.Mui-error': {
		'& .MuiOutlinedInput-notchedOutline': {
			boxShadow: `inset 0 0 1px 1px ${theme.color.status.error}`,
		}
	}
});


export const autocompleteSxProp: SxProps<Theme> = (theme) => extend({
	width: '100%',
	'& .MuiInputBase-root': {
		paddingLeft: theme.spacing(2),
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	// Стандартное состояние
	'& .MuiInputBase-input': {
		padding: '0 !important',
		height: 'auto',
		font: theme.font.small.md,
		color: theme.color.primary,
		'&:placeholder': {
			font: theme.font.small.md,
			color: theme.color.tertiary,
		}
	},
	'& .MuiOutlinedInput-notchedOutline': {
		border: theme.border.primary,
		borderColor: theme.color.tertiary,
		borderRadius: theme.spacing(2),
	},
	'& .MuiFormHelperText-root': {
		margin: `${theme.spacing(1)} ${theme.spacing(2)} 0`,
		font: theme.font.small.md,
		color: theme.color.secondary,
	}
}, {
	// hover
	'&.MuiFormControl-root:hover': {
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: theme.color.epos.notification,
		}
	}
}, {
	// disabled
	'&.MuiFormControl-root .Mui-disabled': {
		'&.MuiInputBase-root': {
			color: theme.color.tertiary,
			WebkitTextFillColor: 'initial',
			background: theme.color.bg_02,
		},
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: theme.color.tertiary,
		}
	}
}, {
	// focused
	'&.MuiFormControl-root .Mui-focused': {
		'& .MuiOutlinedInput-notchedOutline': {
			border: theme.border.primary,
			borderColor: theme.color.epos.notification,
			boxShadow: `inset 0 0 1px 1px ${theme.color.epos.notification}`,
		}
	}
}, {
	// error
	'&.MuiFormControl-root .Mui-error': {
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: theme.color.status.error,
		},
		'&.MuiFormHelperText-root': {
			color: theme.color.status.error,
		}
	},
	// focused + error
	'&.MuiFormControl-root .Mui-focused.Mui-error': {
		'& .MuiOutlinedInput-notchedOutline': {
			boxShadow: `inset 0 0 1px 1px ${theme.color.status.error}`,
		}
	}
});