import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { isValid } from 'date-fns';
import { globalAppConfig } from 'appConfig';
import { sessionStorageHelper } from 'utils';
import { store } from 'reduxStore';
import { authActions } from 'app/auth/redux/authSlice';
import * as swagger from 'apis/cabinetApi/epos_cabinet_api';
// Types
import * as I from './extraTypes';
import { userInfoActions } from 'app/modules/profile/redux/userInfoSlice';


class CabinetApiCalls {
	readonly axiosInstance:      AxiosInstance;
	readonly HelpersApi:         swagger.HelpersApi;
	readonly IIIApi:             swagger.IIIApi;
	readonly InvoiceApi:         swagger.InvoiceApi;
	readonly PayApi:             swagger.PayApi;
	readonly ReportApi:          swagger.ReportApi;
	readonly ServiceProviderApi: swagger.ServiceProviderApi;
	readonly UserManageApi:      swagger.UserManageApi;

	constructor() {
		const axiosInstance = axios.create({
			timeout: 10 * 60 * 1000, // 10 минут
			headers: {
				'Content-Type': 'application/json'
			}
		});
		axiosInstance.defaults.baseURL = globalAppConfig.appConfig.API_URL;

		//* Перехватчик запроса
		axiosInstance.interceptors.request.use(
			// request fulfilled
			(config) => {
				const accessToken = sessionStorageHelper.getAccessToken();
				if (accessToken && config.headers) {
					config.headers['Authorization'] = `Bearer ${accessToken}`;
				}
				config.baseURL = globalAppConfig.appConfig.API_URL;
				return config;
			},
			// request rejected
			(error) => {
				debugger
				Promise.reject(error);
			}
		);

		//* Перехватчик ответа
		axiosInstance.interceptors.response.use(
			// response fulfilled
			(value): AxiosResponse => {
				return value;
			},
			// response rejected
			async (error): Promise<AxiosResponse> => {
				const originalConfig = error.config;
				console.log(originalConfig)

				debugger
				//* Запрос завершен, сервер вернул response status code вне 2xx
				if (error.response) {
					console.log('error.response', error.response);
					console.log('error.request', error.request);

					// Если status==401, попытаться обновить токен и повторить запрос единожды
					if (error.response.status === 401 && !originalConfig._retry) {
						originalConfig._retry = true;
						try {
							const currentRefreshToken = store.getState().auth.refreshToken;
							const tokens = await this.getTokensByRefreshToken(currentRefreshToken);
							store.dispatch(userInfoActions.accessTokenFetched(tokens.accessToken ?? ''));
							store.dispatch(authActions.tokensFetched(tokens));
							return axiosInstance(originalConfig);
						} catch(error) {
							console.log(error)
							store.dispatch(userInfoActions.catchAuthError('error getTokensByRefreshToken'));
						}
					}

					// Если все еще 401, значит что-то не так с пользователем
					if (error.response.status === 401 && originalConfig._retry === true) {
						store.dispatch(userInfoActions.catchAuthError('401'));
					}

					// При отсутствии прав показать экран с переходом на iii
					if (error.response.status === 403) {
						store.dispatch(userInfoActions.catchAuthError('403'));
					}

				//* Запрос совершен, но ответ не получен
				} else if(error.request) {
					// error.request — это экземпляр класса XMLHttpRequest в браузере
					console.log('error.request', error.request);
					store.dispatch(userInfoActions.catchAuthError('noResponse'));

				//* Иначе запрос не совершен, ошибка при настройке запроса
				} else {
					console.log('error.message', error.message, error);
				}

				return Promise.reject(error);
			}
		);

		/**
		 * Клиент сваггера создан для dev api. В base.ts отпределяется относительный путь
		 * BASE_PATH = "/epos/cabinet".replace(/\/+$/, "")
		 * На проде нужно переопределить
		 */
		
		const apiBasePath = (process.env.NODE_ENV === 'production' && !process.env.PUBLIC_URL.includes('epos.cabinet.v2'))
		? "/".replace(/\/+$/, "")
		: "/epos/cabinet".replace(/\/+$/, "");

		this.axiosInstance = axiosInstance;
		this.HelpersApi = new swagger.HelpersApi(undefined, apiBasePath, this.axiosInstance);
		this.IIIApi = new swagger.IIIApi(undefined, apiBasePath, this.axiosInstance);
		this.InvoiceApi = new swagger.InvoiceApi(undefined, apiBasePath, this.axiosInstance);
		this.PayApi = new swagger.PayApi(undefined, apiBasePath, this.axiosInstance);
		this.ReportApi = new swagger.ReportApi(undefined, apiBasePath, this.axiosInstance);
		this.ServiceProviderApi = new swagger.ServiceProviderApi(undefined, apiBasePath, this.axiosInstance);
		this.UserManageApi = new swagger.UserManageApi(undefined, apiBasePath, this.axiosInstance);
	}

	/**Метод преобразовывает fulfilled AxiosResponse для использования в приложении
	 * @param response AxiosResponse, возвращаемый api
	 * @returns преобразованный response
	 */
	private _convertFulfilledResponse(response: AxiosResponse): I.IConvertedResponse {
		const convertedResponse: I.IConvertedResponse = {
			ok:    true,
			data:  response.data,
			error: ''
		};
		return convertedResponse;
	}

	/**Метод преобразовывает rejected AxiosResponse и подбирает текст ошибки
	 * @param error ошибка, пойманнаая при запросе на api
	 * @returns преобразованный response
	 */
	private _convertRejectedResponse(error: any): I.IConvertedResponse {
		let errorMessage: string;
		if (error.response) {
			const errResp = error.response;
			if (errResp.data?.code && errResp.data?.message) {
				errorMessage = `Ошибка ${errResp.data.code}: ${errResp.data.message}`;
			} else {
				errorMessage = `Ошибка ${errResp.status}: ${errResp.statusText}`;
			}
		} else if (error.request) {
			errorMessage = `Ошибка запроса на сервер`;
		} else {
			errorMessage = `Неизвестная ошибка в процессе выполнения запроса`;
		}

		const convertedResponse: I.IConvertedResponse = {
			ok:    false,
			data:  null,
			error: errorMessage
		};
		return convertedResponse;
	}

	private _prepareValidUTCDateString(dateStr: Date | null | undefined): Date | null | undefined {
		// приходит string, обращаемся как к string, но переопределяем тип в Date для соответствия схеме сваггера
		if (!dateStr) return dateStr;
		if (!isValid(new Date(dateStr))) return null;
		const dateTimeRegExp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;
		const dateRegExp = /^\d{4}-\d{2}-\d{2}/;
		const safeDateTimeStr = (dateStr as unknown as string).match(dateTimeRegExp)?.[0];
		const safeDateStr = (dateStr as unknown as string).match(dateRegExp)?.[0];
		if (safeDateTimeStr) {
			return safeDateTimeStr + 'Z' as unknown as Date;
		}
		if (safeDateStr) {
			return safeDateStr + 'Z' as unknown as Date;
		}
		return null;
	}

	/**
	 * Метод конвертирует все даты в локальные. Сваггер перед отправкой сместит в UTC через .toISOString()
	 */
	private _prepareInvoice(invoice: I.IInvoice): void {
		if (invoice.addDateUTC) {
			invoice.addDateUTC = this._prepareValidUTCDateString(invoice.addDateUTC);
		}
		if (invoice.paymentDueTerms?.dueUTC) {
			invoice.paymentDueTerms.dueUTC = this._prepareValidUTCDateString(invoice.paymentDueTerms?.dueUTC);
		}
		if (invoice.dateInAirUTC) {
			invoice.dateInAirUTC = this._prepareValidUTCDateString(invoice.dateInAirUTC);
		}
		if (invoice?.paymentData?.payDate) {
			invoice.paymentData.payDate = this._prepareValidUTCDateString(invoice.paymentData?.payDate) ?? undefined;
		}
		if (invoice?.paymentData?.verifyDate) {
			invoice.paymentData.verifyDate = this._prepareValidUTCDateString(invoice.paymentData?.verifyDate);
		}
		if (invoice?.paymentData?.memOrderDate) {
			invoice.paymentData.memOrderDate = this._prepareValidUTCDateString(invoice.paymentData?.memOrderDate);
		}
	}

	/**
	 * Метод конвертирует все даты в локальные. Сваггер перед отправкой сместит в UTC через .toISOString()
	 */
	private _prepareInvoiceListItem(invoice: I.IInvoiceListItem): void {
		if (invoice.addDateUTC) {
			invoice.addDateUTC = this._prepareValidUTCDateString(invoice.addDateUTC) ?? undefined;
		}
		if (invoice.payDateUTC) {
			invoice.payDateUTC = this._prepareValidUTCDateString(invoice.payDateUTC);
		}
		if (invoice.dateInAirUTC) {
			invoice.dateInAirUTC = this._prepareValidUTCDateString(invoice.dateInAirUTC);
		}
		if (invoice.paymentDueTerms?.dueUTC) {
			invoice.paymentDueTerms.dueUTC = this._prepareValidUTCDateString(invoice.paymentDueTerms?.dueUTC);
		}
		if (invoice.memOrderDate) {
			invoice.memOrderDate = this._prepareValidUTCDateString(invoice.memOrderDate) ?? undefined;
		}
	}


	//! ========== Helpers API ==========
	/**[ GET  ] /v1/helpers/reference/mcc				|	Возвращает справочник категорий MCC
	 * @param params пагинация и фильтры
	 */
	async getMCCsAll(params: I.IGetMCCsAllParams): Promise<I.IConvertedResponse<I.IPaged<I.IMCC>>> {
		try {
			const MCCs = await this.HelpersApi.v1helpersreferencemccHelpersGET(
				params.skip,
				params.count,
				params.filter.code
			);
			return this._convertFulfilledResponse(MCCs);
		} catch(error) {
			return this._convertRejectedResponse(error);
		}
	}

	/**[ GET  ] /v1/helpers/reference/language	|	Возвращает список доступных локалей
	 * @param params пагинация и фильтры
	 */
	async getCurrenciesAll(params: I.IGetCurrenciesAllParams): Promise<I.IConvertedResponse<I.IPaged<I.ICurrency>>> {
		try {
			const currencies = await this.HelpersApi.v1helpersreferencecurrencyHelpersGET(
				params.skip,
				params.count,
				params.filter.code
			);
			return this._convertFulfilledResponse(currencies);
		} catch(error) {
			return this._convertRejectedResponse(error);
		}
	}

	/**[ GET  ] /v1/helpers/reference/currency	|	Возвращает список доступных валют
	 * @param params пагинация и фильтры
	 */
	async getLocalesAll(params: I.IGetLocalesAllParams): Promise<I.IConvertedResponse<I.IPaged<I.ILocale>>> {
		try {
			const locales = await this.HelpersApi.v1helpersreferencelanguageHelpersGET(
				params.skip,
				params.count
			);
			return this._convertFulfilledResponse(locales);
		} catch(error) {
			return this._convertRejectedResponse(error);
		}
	}

	/**[ GET  ] /v1/helpers/reference/bank			|	Возвращает список банков
	 * @param params пагинация и фильтры
	 */
	async getBanksAll(params: I.IGetBanksAllParams): Promise<I.IConvertedResponse<I.IPaged<I.IBank>>> {
		try {
			const banks = await this.HelpersApi.v1helpersreferencebankHelpersGET(
				params.skip,
				params.count,
				params.filter.bic,
				params.filter.iban
			);
			return this._convertFulfilledResponse(banks);
		} catch(error) {
			return this._convertRejectedResponse(error);
		}
	}

	/**[ GET  ] /v1/helpers/reference/report		|	Возвращает список доступных отчетов
	 * @param params пагинация и фильтры
	 */
	async getReportsAll(params: I.IGetReportsAllParams): Promise<I.IConvertedResponse<I.IPaged<I.IReport>>> {
		try {
			const reports = await this.HelpersApi.v1helpersreferencereportHelpersGET(
				params.skip,
				params.count
			);
			return this._convertFulfilledResponse(reports);
		} catch(error) {
			return this._convertRejectedResponse(error);
		}
	}

	/**[ GET  ] /v1/helpers/reference/currencyrate	|	Получение курсов валют
	 * @param params пагинация и фильтры
	 */
	async getCurrencyRatesAll(params: I.IGetCurrencyRatesAllParams): Promise<I.IConvertedResponse<I.IPaged<I.ICurrencyRate>>> {
		try {
			const currencyRates = await this.HelpersApi.v1helpersreferencecurrencyrateHelpersGET(
				params.skip,
				params.count,
				params.filter.beginDate,
				params.filter.endDate,
				params.filter.searchString
			);
			return this._convertFulfilledResponse(currencyRates);
		} catch(error) {
			return this._convertRejectedResponse(error);
		}
	}

	/**[ POST ] /v1/helpers/reference/currencyrate	|	Добавление курса валюты
	 * @param currencyRate курс по отношению к национальной валюте
	 */
	async createCurrencyRate(currencyRate: I.ICurrencyRate): Promise<I.IConvertedResponse<I.ICurrencyRate>> {
		try {
			const createdCurrencyRate = await this.HelpersApi.v1helpersreferencecurrencyrateHelpersPOST(
				currencyRate
			);
			return this._convertFulfilledResponse(createdCurrencyRate);
		} catch(error) {
			return this._convertRejectedResponse(error);
		}
	}

	/**[ PUT  ] /v1/helpers/reference/currencyrate/{id}	|	Редактирование курса валюты
	 * @param id Идентификатор записи
	 * @param currencyRate курс по отношению к национальной валюте
	 */
	async updateCurrencyRate(id: string, currencyRate: I.ICurrencyRate): Promise<I.IConvertedResponse<I.ICurrencyRate>> {
		try {
			const updatedCurrencyRate = await this.HelpersApi.v1helpersreferencecurrencyrateidHelpersPUT(
				id,
				currencyRate
			);
			return this._convertFulfilledResponse(updatedCurrencyRate);
		} catch(error) {
			return this._convertRejectedResponse(error);
		}
	}

	/**[ GET  ]	/v1/helpers/reference/currencyrate/{id}	|	Получение курса валюты
	 * @param id Идентификатор записи
	 */
	async getCurrencyRateById(id: string): Promise<I.IConvertedResponse<I.ICurrencyRate>> {
		try {
			const currencyRate = await this.HelpersApi.v1helpersreferencecurrencyrateidHelpersGET(
				id
			);
			return this._convertFulfilledResponse(currencyRate);
		} catch(error) {
			return this._convertRejectedResponse(error);
		}
	}

	/**[DELETE]	/v1/helpers/reference/currencyrate/{id}	|	Удаление курса валюты
	 * @param id Идентификатор записи
	 */
	async deleteCurrencyRate(id: string): Promise<I.IConvertedResponse<I.ICurrencyRate>> {
		try {
			const responce = await this.HelpersApi.v1helpersreferencecurrencyrateidHelpersDELETE(
				id
			);
			return this._convertFulfilledResponse(responce);
		} catch(error) {
			return this._convertRejectedResponse(error);
		}
	}


	//! ========== Identity API ==========
	/**[ GET  ] /v1/iii/accesstoken/authcode/{authcode}	|	Получение токена доступа по коду авторизации
	 * @param authCode Код авторизации
	 */
	async getTokensByAuthCode(authCode: string): Promise<I.ITokenResponse> {
		const testMode = (window.location.hostname === 'localhost') ? true : false;
		try {
			const response = await this.IIIApi.v1iiiaccesstokenauthcodeauthcodeIIIGET(
				authCode,
				testMode
			);
			return response.data;
		} catch(error) {
			return Promise.reject(error);
		}
	}

	/**[ GET  ] /v1/iii/accesstoken/refreshtoken/{refreshtoken}	| Получение токена доступа по refresh-токену
	 * @param refreshToken refreshToken
	 */
	async getTokensByRefreshToken(refreshToken: string): Promise<I.ITokenResponse> {
		const testMode = (window.location.hostname === 'localhost') ? true : false;
		try {
			const response = await this.IIIApi.v1iiiaccesstokenrefreshtokenrefreshtokenIIIGET(
				refreshToken,
				testMode
			);
			return response.data;
		} catch(error) {
			return Promise.reject(error);
		}
	}

		/**[DELETE] /v1/iii/token/revoke	|	Отзыв токена
	 * @param refreshToken refreshToken
	 */
	async revokeRefreshToken(refreshToken: string): Promise<I.IConvertedResponse<I.IErrorStatus>> {
		try {
			const response = await this.IIIApi.v1iiitokenrevokeIIIDELETE(
				refreshToken,
				'refresh_token'
			);
			return this._convertFulfilledResponse(response);
		} catch(error) {
			return this._convertRejectedResponse(error);
		}
	}


	//! ========== Invoice API ==========
	/**[ GET  ]	/v1/invoicing/invoice	|	Возвращает список счетов
	 * @param params пагинация и фильтры
	 */
	async getInvoicesAll(params: I.IGetInvoicesAllParams): Promise<I.IConvertedResponse<I.IPaged<I.IInvoiceListItem>>> {
		const states: string | undefined = (params.filter.states?.length)
			? params.filter.states.join(';')
			: undefined;

		try {
			const invoices = await this.InvoiceApi.v1invoicinginvoiceInvoiceGET(
				params.filter.beginDate,
				params.filter.endDate,
				params.skip,
				params.count,
				params.filter.searchString,
				states,
				params.filter.requestType
			);
			invoices.data.records?.map((i) => {
				this._prepareInvoiceListItem(i);
			});
			return this._convertFulfilledResponse(invoices);
		} catch(error) {
			return this._convertRejectedResponse(error);
		}
	}

	/**[ POST ]	/v1/invoicing/invoice	|	Добавляет новый счет
	 * @summary Счет выставляется в `draft` режиме, в этом режиме счет можно удалить из системы или внести изменения
	 * @param invoice Информация о выставляемом счете
	 * @param canPayAtOnce Признак выставления инвойса сразу на оплату
	 */
	async createInvoice(invoice: I.IInvoice, canPayAtOnce: boolean = false): Promise<I.IConvertedResponse<I.IInvoice>> {
		try {
			const createdInvoice = await this.InvoiceApi.v1invoicinginvoiceInvoicePOST(
				invoice,
				canPayAtOnce
			);
			return this._convertFulfilledResponse(createdInvoice);
		} catch(error) {
			return this._convertRejectedResponse(error);
		}
	}

	/**[ GET  ]	/v1/invoicing/invoice/{id}	|	Детальная информация о счете
	 * @param id Внутренний идентификатор счета
	 */
	async getInvoiceById(id: string): Promise<I.IConvertedResponse<I.IInvoice>> {
		try {
			const invoice = await this.InvoiceApi.v1invoicinginvoiceidInvoiceGET(
				id
			);
			this._prepareInvoice(invoice.data);
			return this._convertFulfilledResponse(invoice);
		} catch(error) {
			return this._convertRejectedResponse(error);
		}
	}

	/**[ PUT  ]	/v1/invoicing/invoice/{id}	|	Редактирование счета
	 * @summary Счет можно изменить только если он находится в `draft` режиме
	 * @param id Внутренний идентификатор счета
	 * @param invoice Инвойс для редактирования
	 * @param notifyServiceProvider Инвойс для редактирования
	 */
	async updateInvoice(id: string, invoice: I.IInvoice, notifyServiceProvider: boolean = false): Promise<I.IConvertedResponse<I.IServiceProvider>> {
		try {
			const updatedInvoice = await this.InvoiceApi.v1invoicinginvoiceidInvoicePUT(
				id,
				invoice,
				notifyServiceProvider
			);
			return this._convertFulfilledResponse(updatedInvoice);
		} catch(error) {
			return this._convertRejectedResponse(error);
		}
	}

	/**[DELETE]	/v1/invoicing/invoice/{id}	|	Удаление счета
	 * @summary Счет можно удалить только если он находится в `draft` режиме
	 * @param id Внутренний идентификатор счета
	 */
	async deleteInvoice(id: string): Promise<I.IConvertedResponse<I.IErrorStatus>> {
		try {
			const result = await this.InvoiceApi.v1invoicinginvoiceidInvoiceDELETE(
				id
			);
			return this._convertFulfilledResponse(result);
		} catch(error) {
			return this._convertRejectedResponse(error);
		}
	}

	/**[ POST ]	/v1/invoicing/invoice/{id}/send	|	Перевод счета из draft режима в состояние, когда счет можно оплачивать
	 * @param id Внутренний идентификатор счета
	 */
	async setInvoiceToSend(id: string): Promise<I.IConvertedResponse<any>> {
		try {
			const responce = await this.InvoiceApi.v1invoicinginvoiceidsendInvoicePOST(
				id
			);
			return this._convertFulfilledResponse(responce);
		} catch(error) {
			return this._convertRejectedResponse(error);
		}
	}

	/**[ POST ]	/v1/invoicing/invoice/{id}/cancel	|	Перевод счета из состояния "ождание оплаты" в "черновик"
	 * @param id Внутренний идентификатор счета
	 */
	async setInvoiceToCancel(id: string): Promise<I.IConvertedResponse<any>> {
		try {
			const responce = await this.InvoiceApi.v1invoicinginvoiceidcancelInvoicePOST(
				id
			);
			return this._convertFulfilledResponse(responce);
		} catch(error) {
			return this._convertRejectedResponse(error);
		}
	}

	/**[ GET  ]	/v1/invoicing/invoice/{id}/qrcode	|	Получение данных для формирования QR-кода
	 * @param id Внутренний идентификатор счета
	 * @param imgWidth Ширина изображения
	 * @param imgHeight Высота изображения
	 * @param getImage Признак запроса изображения qr-кода
	 */
	async getInvoiceQrCode(id: string, imgWidth: number = 174, imgHeight: number = 306, getImage: boolean = false): Promise<I.IConvertedResponse<I.IInvoiceQRCode>> {
		try {
			const invoiceQRCode = await this.InvoiceApi.v1invoicinginvoiceidqrcodeInvoiceGET(
				id,
				imgWidth,
				imgHeight,
				getImage
			);
			return this._convertFulfilledResponse(invoiceQRCode);
		} catch(error) {
			return this._convertRejectedResponse(error);
		}
	}

	async stornoInvoice(id: string): Promise<I.IConvertedResponse<any>> {
		try {
			const response = await this.InvoiceApi.v1invoicinginvoiceidstornoInvoicePOST(
				id
			);
			return this._convertFulfilledResponse(response);
		} catch(error) {
			return this._convertRejectedResponse(error);
		}
	}


	//! ========== Report Api ==========
	/**[ GET  ]	/v1/report/serviceprovider/{id}/reestr	|	Формирвоание реестра сверенных транзакций по ПУ
	 * @param params параметры отбора данных для отчета
	 */
	async getReportReestr(params: I.IGetReportServiceProviderParams): Promise<I.IConvertedResponse<any>> {
		// debugger
		try {
			const response = await this.ReportApi.v1reportserviceprovideridreestrReportGET(
				params.id,
				params.utcBeginDate,
				params.utcEndDate,
				params.sendByEmail,
				params.emails?.join(';'),
				params.isAsync
			);
			return this._convertFulfilledResponse(response);
		} catch(error) {
			return this._convertRejectedResponse(error);
		}
	}

	/**[ GET  ]	/v1/report/serviceprovider/{id}/reestr/csv	|	Формирвоание реестра сверенных транзакций по ПУ в формате csv
	 * @param params параметры отбора данных для отчета
	 */
	async getReportReestrCsv(params: I.IGetReportServiceProviderParams): Promise<I.IConvertedResponse<any>> {
		try {
			const response = await this.ReportApi.v1reportserviceprovideridreestrcsvReportGET(
				params.id,
				params.utcBeginDate,
				params.utcEndDate,
				params.sendByEmail,
				params.emails?.join(';'),
				params.isAsync
			);
			return this._convertFulfilledResponse(response);
		} catch(error) {
			return this._convertRejectedResponse(error);
		}
	}

	/**[ GET  ]	/v1/report/serviceprovider/{id}/reestrwithclient	|	Формирвоание реестра сверенных транзакций по ПУ
	 * @param params параметры отбора данных для отчета
	 */
	async getReportReestrWithClient(params: I.IGetReportServiceProviderParams): Promise<I.IConvertedResponse<any>> {
		try {
			const response = await this.ReportApi.v1reportserviceprovideridreestrwithclientReportGET(
				params.id,
				params.utcBeginDate,
				params.utcEndDate,
				params.sendByEmail,
				params.emails?.join(';'),
				params.isAsync
			);
			return this._convertFulfilledResponse(response);
		} catch(error) {
			return this._convertRejectedResponse(error);
		}
	}

	/**[ GET  ]	/v1/report/serviceprovider/{id}/monthlyact	|	Формирование ежемесячного акта по ПУ
	 * @param params параметры отбора данных для отчета
	 */
	async getReportMonthlyAct(params: I.IGetReportServiceProviderParams): Promise<I.IConvertedResponse<any>> {
		try {
			const response = await this.ReportApi.v1reportserviceprovideridmonthlyactReportGET(
				params.id,
				params.utcBeginDate,
				params.utcEndDate,
				params.sendByEmail,
				params.emails?.join(';'),
				params.isAsync
			);
			return this._convertFulfilledResponse(response);
		} catch(error) {
			return this._convertRejectedResponse(error);
		}
	}


	//! ========== ServiceProvider Api ==========
	/**[ GET  ] /v1/serviceProvider | Получение списка производителей услуг
	 * @param params пагинация и фильтры
	 */
	async getServiceProvidersAll(params: I.IGetServiceProvidersAllParams): Promise<I.IConvertedResponse<I.IPaged<I.IServiceProvider>>> {
		try {
			const providers = await this.ServiceProviderApi.v1serviceProviderServiceProviderGET(
				params.skip,
				params.count,
				params.filter.searchString
			);
			return this._convertFulfilledResponse(providers);
		} catch(error) {
			return this._convertRejectedResponse(error);
		}
	}

	/**[ POST ] /v1/serviceProvider | Добавление нового поставщика услуг
	 * @param serviceProvider Поставщик услуг
	 */
	async createServiceProvider(serviceProvider: I.IServiceProvider): Promise<I.IConvertedResponse<I.IServiceProvider>> {
		try {
			const createdServiceProvider = await this.ServiceProviderApi.v1serviceProviderServiceProviderPOST(
				serviceProvider
			);
			return this._convertFulfilledResponse(createdServiceProvider);
		} catch(error) {
			return this._convertRejectedResponse(error);
		}
	}

	/**[ GET  ] /v1/serviceProvider/{id} | Получение информации о конкретном производителе услуг
	 * @param id Идентификатор поставщика услуг
	 */
	async getServiceProviderById(id: number): Promise<I.IConvertedResponse<I.IServiceProvider>> {
		try {
			const serviceProvider = await this.ServiceProviderApi.v1serviceProvideridServiceProviderGET(
				id
			);
			return this._convertFulfilledResponse(serviceProvider);
		} catch(error) {
			return this._convertRejectedResponse(error);
		}
	}

	/**[ PUT  ] /v1/serviceProvider/{id} | Обновление информации о производителе услуг
	 * @param id Идентификатор поставщика услуг
	 * @param serviceProvider Поставщик услуг
	 */
	async updateServiceProvider(id: number, serviceProvider: I.IServiceProvider): Promise<I.IConvertedResponse<I.IServiceProvider>> {
		try {
			const updatedServiceProvider = await this.ServiceProviderApi.v1serviceProvideridServiceProviderPUT(
				id,
				serviceProvider
			);
			return this._convertFulfilledResponse(updatedServiceProvider);
		} catch(error) {
			return this._convertRejectedResponse(error);
		}
	}

	/**[DELETE] /v1/serviceProvider/{id} | Удаление поставщика услуг
	 * @param id Идентификатор поставщика услуг
	 */
	async deleteServiceProvider(id: number): Promise<I.IConvertedResponse<I.IErrorStatus>> {
		try {
			const result = await this.ServiceProviderApi.v1serviceProvideridServiceProviderDELETE(
				id
			);
			return this._convertFulfilledResponse(result);
		} catch(error) {
			return this._convertRejectedResponse(error);
		}
	}

	/**[ GET  ] /v1/serviceProvider/{id}/biometry/qr | Получение qr-кода для регистрации девайса по биометрии
	 * @param id Идентификатор поставщика услуг
	 * @param params настройки запрашиваемого qr-кода
	 */
	async getBiometryQrCodeById(id: number, params: I.IGetBiometryQrCodeByIdParams): Promise<I.IConvertedResponse<I.IBiometryQRCode>> {
		try {
			const biometryQRCode = await this.ServiceProviderApi.v1serviceProvideridbiometryqrServiceProviderGET(
				id,
				params.serviceId,
				params.rttCode,
				params.cashBoxCode,
				params.imgWidth,
				params.imgHeight,
				params.getImage
			);
			return this._convertFulfilledResponse(biometryQRCode);
		} catch(error) {
			return this._convertRejectedResponse(error);
		}
	}

	/**[PATCH ] /v1/serviceProvider/{id}/enable | Включение поставщика услуг
	 * @param id Идентификатор поставщика услуг
	 */
	async enableServiceProviderById(id: number): Promise<I.IConvertedResponse<I.IErrorStatus>> {
		try {
			const result = await this.ServiceProviderApi.v1serviceProvideridenableServiceProviderPATCH(
				id
			);
			return this._convertFulfilledResponse(result);
		} catch(error) {
			return this._convertRejectedResponse(error);
		}
	}

	/**[PATCH ] /v1/serviceProvider/{id}/disable | Выключение поставщика услуг
	 * @param id Идентификатор поставщика услуг
	 */
	async disableServiceProviderById(id: number): Promise<I.IConvertedResponse<I.IErrorStatus>> {
		try {
			const result = await this.ServiceProviderApi.v1serviceProvideriddisableServiceProviderPATCH(
				id
			);
			return this._convertFulfilledResponse(result);
		} catch(error) {
			return this._convertRejectedResponse(error);
		}
	}

	/**[ PUT  ] /v1/serviceProvider/{id}/iii | Обновление информации по ПУ на iii
	 * @param id Идентификатор поставщика услуг
	 */
	async updateServiceProviderIIIById(id: number, serviceProviderIII: I.IServiceProviderIII): Promise<I.IConvertedResponse<any>> {
		try {
			const result = await this.ServiceProviderApi.v1serviceProvideridiiiServiceProviderPUT(
				id,
				serviceProviderIII
			);
			return this._convertFulfilledResponse(result);
		} catch(error) {
			return this._convertRejectedResponse(error);
		}
	}

	/**[ GET  ] /v1/serviceProvider/{id}/iii | Получение информации по ПУ на iii
	 * @param id Идентификатор поставщика услуг
	 */
	async getServiceProviderIIIById(id: number): Promise<I.IConvertedResponse<any>> {
		try {
			const result = await this.ServiceProviderApi.v1serviceProvideridiiiServiceProviderGET(
				id
			);
			return this._convertFulfilledResponse(result);
		} catch(error) {
			return this._convertRejectedResponse(error);
		}
	}


	//! ========== UserManageApi ==========
	/**[ GET  ] /v1/user | Получение списка пользователей
	 * @param params пагинация и фильтры
	 */
	async getUsersAll(params: I.IGetUsersAllParams): Promise<I.IConvertedResponse<I.IPaged<I.IUserInfo>>> {
		try {
			const users = await this.UserManageApi.v1userUserManageGET(
				params.skip,
				params.count,
				params.filter.searchString
			);
			return this._convertFulfilledResponse(users);
		} catch(error) {
			return this._convertRejectedResponse(error);
		}
	}

	/**[ POST ] /v1/user | Добавление нового пользователя
	 * @param user Пользователь
	 */
	async createUser(user: I.IUserInfo): Promise<I.IConvertedResponse<I.IUserInfo>> {
		try {
			const createdUser = await this.UserManageApi.v1userUserManagePOST(
				user
			);
			return this._convertFulfilledResponse(createdUser);
		} catch(error) {
			return this._convertRejectedResponse(error);
		}
	}

	/**[ GET  ] /v1/user/{id} | Информация о пользователе
	 * @param id Идентификатор пользователя
	 */
	async getUserById(id: string): Promise<I.IConvertedResponse<I.IUserInfo>> {
		try {
			const user = await this.UserManageApi.v1useridUserManageGET(
				id
			);
			return this._convertFulfilledResponse(user);
		} catch(error) {
			return this._convertRejectedResponse(error);
		}
	}

	/**[ PUT  ] /v1/user/{id} | Изменение информации о пользователе
	 * @param id Идентификатор пользователя
	 * @param user Информация о пользователе
	 */
	async updateUser(id: string, user: I.IUserInfo): Promise<I.IConvertedResponse<I.IUserInfo>> {
		try {
			const updatedUser = await this.UserManageApi.v1useridUserManagePUT(
				id,
				user
			);
			return this._convertFulfilledResponse(updatedUser);
		} catch(error) {
			return this._convertRejectedResponse(error);
		}
	}

	/**[PATCH ] /v1/user/{id} | Изменение владельца
	 * @param id Идентификатор пользователя
	 * @param isOwner Признак владельца
	 */
	async changeUserOwner(id: string, isOwner: boolean): Promise<I.IConvertedResponse<I.IUserInfo>> {
		try {
			const request = await this.UserManageApi.v1useridUserManagePATCH(
				id,
				isOwner
			);
			return this._convertFulfilledResponse(request);
		} catch(error) {
			return this._convertRejectedResponse(error);
		}
	}

	/**[PATCH ] /v1/user/{id}/enable | Установка активности пользователя
	 * @param id Идентификатор пользователя
	 */
	async enableUser(id: string): Promise<I.IConvertedResponse<any>> {
		try {
			const request = await this.UserManageApi.v1useridenableUserManagePATCH(
				id
			);
			return this._convertFulfilledResponse(request);
		} catch(error) {
			return this._convertRejectedResponse(error);
		}
	}

	/**[PATCH ] /v1/user/{id}/disable | Деактивация пользователя
	 * @param id Идентификатор пользователя
	 */
	async disableUser(id: string): Promise<I.IConvertedResponse<any>> {
		try {
			const request = await this.UserManageApi.v1useriddisableUserManagePATCH(
				id
			);
			return this._convertFulfilledResponse(request);
		} catch(error) {
			return this._convertRejectedResponse(error);
		}
	}

	//*	Не нужно реализовывать на стороне клиента
	/**[ POST ] /v1/user/onesignal/register | Регистрация пользователя в OneSignal
	 */

	//*	Не нужно реализовывать на стороне клиента
	/**[ POST ] /v1/user/onesignal/unregister | Отмена регистрации пользователя в OneSignal
	 */


}

const eposCabinetApiCalls = new CabinetApiCalls();

export { eposCabinetApiCalls };
