import * as Yup from 'yup';
import { errMsg } from './_errorMessages';
import {
	nullableString,
	nullableArray,
	phoneNumberSchema,
} from './_shortcuts';


export const phoneSchema = () => Yup.object().shape({
	'nationalNumber': phoneNumberSchema()
});

export const contactSchema = () => Yup.object().shape({
	//*	ФИО
	person: Yup.object().shape({
		'lastName': nullableString()
			.required(errMsg.required),
		'firstName': nullableString(),
		'middleName': nullableString()
	}),
	//*	Тип контакта
	'type': nullableString()
		.required(errMsg.required),
	//*	Email
	emails: nullableArray() // <EmailsMultiField/>
		.of(Yup.string()
			.email(errMsg.email)
			.required(errMsg.required)
		)
		.min(1, errMsg.required),
	//*	Номер телефона
	phones: nullableArray().of(phoneSchema()),
});
