import React from 'react';
import { Box, Grid } from '@mui/material';
// Types
import { SxProps, Theme } from '@mui/material';


const DataCardsList: React.FC<{ sx?: SxProps<Theme> }> = ({ sx = [], children }) => {
	return (
		<Box>
			<Grid
				component="ul"
				container
				spacing={2}
				sx={[
					{
						marginBottom: 0,
						paddingLeft: 0,
						listStyle: 'none'
					},
					...Array.isArray(sx) ? sx : [sx]
				]}
			>
				{children}
			</Grid>
		</Box>
	);
}

DataCardsList.displayName = 'DataCardsList';
export { DataCardsList };
