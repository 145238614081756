import React, { useCallback } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { extend } from 'lodash';
import { muiEsasStyled, TMuiEsasTheme } from 'app/theme';
import { Field, useField, FieldProps } from 'formik';
import InputUnstyled, {
	InputUnstyledProps,
	InputUnstyledClasses
} from '@mui/base/InputUnstyled';
import {
	TextField, TextFieldProps,
} from '@mui/material';
import { AppIcon } from './';
import { textFieldSxProp } from './epos/_sxProps';
// Types
import { SxProps, Theme } from '@mui/material';


const IconComponent: React.FC = (props) => {
	return (
		<AppIcon
			iconType="down"
			size="sm"
			sx={(theme) => ({
				top: 'auto',
				right: theme.spacing(2),
				color: theme.color.secondary,
				transition: 'color 0.3s, transform 0.3s',
			})}
			{...props}
		/>
	);
}


interface IAppInputRootProps extends React.ComponentProps<'div'> {
	sx?: SxProps<Theme>;
};
const AppInputRoot: React.FC<IAppInputRootProps> = muiEsasStyled('div', {
	shouldForwardProp: (prop) => (
		prop!== 'sx'
	),
	name: 'AppInputRoot'
})<IAppInputRootProps>(({theme}) => extend({
	
}));


type IAppInputBaseProps = InputUnstyledProps & {
	sx?: SxProps<Theme>;
};
const AppInputBase: React.FC<IAppInputBaseProps> = muiEsasStyled(InputUnstyled, {
	shouldForwardProp: (prop) => (
		prop!== 'sx'
	),
	name: 'AppInputBase'
})<IAppInputBaseProps>(({ theme, disabled, error }) => {
	return extend({

	});
});


const AppInputHelperTextAnimated: React.FC = ({ children }) => {
	return (
		<motion.p
			animate={{ opacity: 1, y: 0 }}
			initial={{ opacity: 0, y: 10 }}
			exit={{ opacity: 0, y: -10 }}
			transition={{ duration: 0.5 }}
		>
			{children}
		</motion.p>
	)
}
interface IAppInputHelperTextProps {
	sx?:       SxProps<Theme>;
	error?:    IAppInputBaseProps['error'];
	disabled?: IAppInputBaseProps['disabled'];
};
const AppInputHelperText: React.FC<IAppInputHelperTextProps> = muiEsasStyled(AppInputHelperTextAnimated, {
	shouldForwardProp: (prop) => (
		prop!== 'sx' && prop !== 'error' && prop !== 'disabled'
	),
	name: 'AppInputHelperText'
})<IAppInputHelperTextProps>(({ theme, error, disabled }) => extend({

}));


type IAppInputProps = IAppInputBaseProps & {
	helperText?: string;
};
const AppInput: React.FC<IAppInputProps> = (props) => {
	const {
		sx = [],
		error = false,
		disabled = false,
		helperText,
		...other
	} = props

	return (
		<AppInputRoot>
			<AppInputBase
				{...other}
			/>

			{(helperText) && (
				<AppInputHelperText
					disabled={disabled}
					error={error}
				>
					{helperText}
				</AppInputHelperText>
			)}
		</AppInputRoot>
	);
}


//! До создания кастомного инпута использую mui TextField
type TMuiTextFieldProps = TextFieldProps & {
	name:         string;
	noSymbols?:   boolean;
	toUpperCase?: boolean;
};
const MuiTextField = React.forwardRef<HTMLDivElement, TMuiTextFieldProps>(function MuiTextField(props, ref) {
	const {sx = [], name, noSymbols, toUpperCase, ...rest} = props
	const { t } = useTranslation(undefined, { keyPrefix: 'validation' });
	const [{ value, onBlur }, formikMeta, { setValue }] = useField(name);

	const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		let value = event.target.value;
		if (noSymbols) {
			value = value.replace(/[^A-Za-z0-9]+/, '');
		}
		if (toUpperCase) {
			value = value.toUpperCase();
		}
		setValue(value);
	}, []);

	const errorMessage = props.helperText || ((formikMeta.touched && formikMeta.error) ? t(formikMeta.error) : undefined); 


	return (
		<TextField
			ref={ref}
			name={name}
			value={value ?? ''}
			onChange={onChange}
			onBlur={onBlur}

			error={(formikMeta.touched && Boolean(formikMeta.error))}
			helperText={errorMessage}
			sx={[
				textFieldSxProp,
				...(Array.isArray(sx) ? sx : [sx])
			]}
			{...rest}
		/>
	);
});


const MuiTextFieldBase = React.forwardRef<HTMLDivElement, TextFieldProps>(function MuiTextFieldBase({sx = [], ...props}, ref) {
	return (
		<TextField
			sx={[
				textFieldSxProp,
				...(Array.isArray(sx) ? sx : [sx])
			]}
			{...props}
		/>
	);
});


export { MuiTextField as AppTextField, MuiTextFieldBase as AppTextFieldBase }; 
export type { TMuiTextFieldProps as IAppTextFieldProps };
