/* tslint:disable */
/* eslint-disable */
/**
 * EPOS cabinet API
 * Cabinet API сервиса E-POS
 *
 * OpenAPI spec version: v1
 * Contact: support-api@epos.by
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ErrorStatus } from '../models';
import { Invoice } from '../models';
import { InvoiceListItemRecords } from '../models';
import { InvoiceQRCode } from '../models';
import { InvoiceQueryType } from '../models';
/**
 * InvoiceApi - axios parameter creator
 * @export
 */
export const InvoiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Возвращает список счетов.
         * @param {Date} [beginDate] Начальная дата для выборки данных.
         * @param {Date} [endDate] Конечная дата для выборки данных.
         * @param {number} [skip] Количество записей, которые необходимо пропустить.
         * @param {number} [count] Количество записй, которые необходимо вернуть.
         * @param {string} [searchString] Строка для поиска
         * @param {string} [states] Необходимые состояния
         * @param {InvoiceQueryType} [requestType] Тип запроса (по дате создания, по дате оплаты)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1invoicinginvoiceInvoiceGET: async (beginDate?: Date, endDate?: Date, skip?: number, count?: number, searchString?: string, states?: string, requestType?: InvoiceQueryType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/invoicing/invoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (beginDate !== undefined) {
                localVarQueryParameter['beginDate'] = (beginDate as any instanceof Date) ?
                    (beginDate as any).toISOString() :
                    beginDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            if (searchString !== undefined) {
                localVarQueryParameter['searchString'] = searchString;
            }

            if (states !== undefined) {
                localVarQueryParameter['states'] = states;
            }

            if (requestType !== undefined) {
                localVarQueryParameter['requestType'] = requestType;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Счет выставляется в draft режиме, в этом режиме счет можно удалить из системы или внести изменения.
         * @summary Добавляет новый счет.
         * @param {Invoice} [body] Информация о выставляемом счете.
         * @param {boolean} [canPayAtOnce] Признак выставления инвойса сразу на оплату
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1invoicinginvoiceInvoicePOST: async (body?: Invoice, canPayAtOnce?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/invoicing/invoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (canPayAtOnce !== undefined) {
                localVarQueryParameter['canPayAtOnce'] = canPayAtOnce;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers?.['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Счет можно удалить только если он находится в draft режиме.
         * @summary Удаление счета.
         * @param {string} id Внутренний идентификатор счета.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1invoicinginvoiceidInvoiceDELETE: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1invoicinginvoiceidInvoiceDELETE.');
            }
            const localVarPath = `/v1/invoicing/invoice/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Детальная информация о счете.
         * @param {string} id Внутренний идентификатор счета.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1invoicinginvoiceidInvoiceGET: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1invoicinginvoiceidInvoiceGET.');
            }
            const localVarPath = `/v1/invoicing/invoice/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Счет можно изменить только если он находится в draft режиме.
         * @summary Редактирование счета.
         * @param {string} id Внутренний идентификатор счета.
         * @param {Invoice} [body] Инвойс для редактирования
         * @param {boolean} [notifyServiceProvider] Флаг, указывающий о необходимости информирования производителя услуг об изменениях.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1invoicinginvoiceidInvoicePUT: async (id: string, body?: Invoice, notifyServiceProvider?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1invoicinginvoiceidInvoicePUT.');
            }
            const localVarPath = `/v1/invoicing/invoice/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (notifyServiceProvider !== undefined) {
                localVarQueryParameter['notifyServiceProvider'] = notifyServiceProvider;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers?.['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Перевод счета из состояния \"ождание оплаты\" в \"черновик\"
         * @param {string} id Внутренний идентификатор счета.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1invoicinginvoiceidcancelInvoicePOST: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1invoicinginvoiceidcancelInvoicePOST.');
            }
            const localVarPath = `/v1/invoicing/invoice/{id}/cancel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение данных для формирования QR-кода.
         * @param {string} id Внутренний идентификатор счета.
         * @param {number} [imgWidth] Ширина изображения
         * @param {number} [imgHeight] Высота изображения
         * @param {boolean} [getImage] Признак запроса изображения qr-кода
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1invoicinginvoiceidqrcodeInvoiceGET: async (id: string, imgWidth?: number, imgHeight?: number, getImage?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1invoicinginvoiceidqrcodeInvoiceGET.');
            }
            const localVarPath = `/v1/invoicing/invoice/{id}/qrcode`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (imgWidth !== undefined) {
                localVarQueryParameter['imgWidth'] = imgWidth;
            }

            if (imgHeight !== undefined) {
                localVarQueryParameter['imgHeight'] = imgHeight;
            }

            if (getImage !== undefined) {
                localVarQueryParameter['getImage'] = getImage;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Перевод счета из draft режима в состояние, когда счет можно оплачивать.
         * @param {string} id Внутренний идентификатор счета.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1invoicinginvoiceidsendInvoicePOST: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1invoicinginvoiceidsendInvoicePOST.');
            }
            const localVarPath = `/v1/invoicing/invoice/{id}/send`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Инициирование операции сторно по инвойсу.
         * @param {string} id Внутренний идентификатор счета.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1invoicinginvoiceidstornoInvoicePOST: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1invoicinginvoiceidstornoInvoicePOST.');
            }
            const localVarPath = `/v1/invoicing/invoice/{id}/storno`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoiceApi - functional programming interface
 * @export
 */
export const InvoiceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Возвращает список счетов.
         * @param {Date} [beginDate] Начальная дата для выборки данных.
         * @param {Date} [endDate] Конечная дата для выборки данных.
         * @param {number} [skip] Количество записей, которые необходимо пропустить.
         * @param {number} [count] Количество записй, которые необходимо вернуть.
         * @param {string} [searchString] Строка для поиска
         * @param {string} [states] Необходимые состояния
         * @param {InvoiceQueryType} [requestType] Тип запроса (по дате создания, по дате оплаты)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1invoicinginvoiceInvoiceGET(beginDate?: Date, endDate?: Date, skip?: number, count?: number, searchString?: string, states?: string, requestType?: InvoiceQueryType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<InvoiceListItemRecords>>> {
            const localVarAxiosArgs = await InvoiceApiAxiosParamCreator(configuration).v1invoicinginvoiceInvoiceGET(beginDate, endDate, skip, count, searchString, states, requestType, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Счет выставляется в draft режиме, в этом режиме счет можно удалить из системы или внести изменения.
         * @summary Добавляет новый счет.
         * @param {Invoice} [body] Информация о выставляемом счете.
         * @param {boolean} [canPayAtOnce] Признак выставления инвойса сразу на оплату
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1invoicinginvoiceInvoicePOST(body?: Invoice, canPayAtOnce?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Invoice>>> {
            const localVarAxiosArgs = await InvoiceApiAxiosParamCreator(configuration).v1invoicinginvoiceInvoicePOST(body, canPayAtOnce, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Счет можно удалить только если он находится в draft режиме.
         * @summary Удаление счета.
         * @param {string} id Внутренний идентификатор счета.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1invoicinginvoiceidInvoiceDELETE(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ErrorStatus>>> {
            const localVarAxiosArgs = await InvoiceApiAxiosParamCreator(configuration).v1invoicinginvoiceidInvoiceDELETE(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Детальная информация о счете.
         * @param {string} id Внутренний идентификатор счета.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1invoicinginvoiceidInvoiceGET(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Invoice>>> {
            const localVarAxiosArgs = await InvoiceApiAxiosParamCreator(configuration).v1invoicinginvoiceidInvoiceGET(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Счет можно изменить только если он находится в draft режиме.
         * @summary Редактирование счета.
         * @param {string} id Внутренний идентификатор счета.
         * @param {Invoice} [body] Инвойс для редактирования
         * @param {boolean} [notifyServiceProvider] Флаг, указывающий о необходимости информирования производителя услуг об изменениях.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1invoicinginvoiceidInvoicePUT(id: string, body?: Invoice, notifyServiceProvider?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Invoice>>> {
            const localVarAxiosArgs = await InvoiceApiAxiosParamCreator(configuration).v1invoicinginvoiceidInvoicePUT(id, body, notifyServiceProvider, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Перевод счета из состояния \"ождание оплаты\" в \"черновик\"
         * @param {string} id Внутренний идентификатор счета.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1invoicinginvoiceidcancelInvoicePOST(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await InvoiceApiAxiosParamCreator(configuration).v1invoicinginvoiceidcancelInvoicePOST(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение данных для формирования QR-кода.
         * @param {string} id Внутренний идентификатор счета.
         * @param {number} [imgWidth] Ширина изображения
         * @param {number} [imgHeight] Высота изображения
         * @param {boolean} [getImage] Признак запроса изображения qr-кода
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1invoicinginvoiceidqrcodeInvoiceGET(id: string, imgWidth?: number, imgHeight?: number, getImage?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await InvoiceApiAxiosParamCreator(configuration).v1invoicinginvoiceidqrcodeInvoiceGET(id, imgWidth, imgHeight, getImage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Перевод счета из draft режима в состояние, когда счет можно оплачивать.
         * @param {string} id Внутренний идентификатор счета.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1invoicinginvoiceidsendInvoicePOST(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await InvoiceApiAxiosParamCreator(configuration).v1invoicinginvoiceidsendInvoicePOST(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Инициирование операции сторно по инвойсу.
         * @param {string} id Внутренний идентификатор счета.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1invoicinginvoiceidstornoInvoicePOST(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ErrorStatus>>> {
            const localVarAxiosArgs = await InvoiceApiAxiosParamCreator(configuration).v1invoicinginvoiceidstornoInvoicePOST(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * InvoiceApi - factory interface
 * @export
 */
export const InvoiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Возвращает список счетов.
         * @param {Date} [beginDate] Начальная дата для выборки данных.
         * @param {Date} [endDate] Конечная дата для выборки данных.
         * @param {number} [skip] Количество записей, которые необходимо пропустить.
         * @param {number} [count] Количество записй, которые необходимо вернуть.
         * @param {string} [searchString] Строка для поиска
         * @param {string} [states] Необходимые состояния
         * @param {InvoiceQueryType} [requestType] Тип запроса (по дате создания, по дате оплаты)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1invoicinginvoiceInvoiceGET(beginDate?: Date, endDate?: Date, skip?: number, count?: number, searchString?: string, states?: string, requestType?: InvoiceQueryType, options?: AxiosRequestConfig): Promise<AxiosResponse<InvoiceListItemRecords>> {
            return InvoiceApiFp(configuration).v1invoicinginvoiceInvoiceGET(beginDate, endDate, skip, count, searchString, states, requestType, options).then((request) => request(axios, basePath));
        },
        /**
         * Счет выставляется в draft режиме, в этом режиме счет можно удалить из системы или внести изменения.
         * @summary Добавляет новый счет.
         * @param {Invoice} [body] Информация о выставляемом счете.
         * @param {boolean} [canPayAtOnce] Признак выставления инвойса сразу на оплату
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1invoicinginvoiceInvoicePOST(body?: Invoice, canPayAtOnce?: boolean, options?: AxiosRequestConfig): Promise<AxiosResponse<Invoice>> {
            return InvoiceApiFp(configuration).v1invoicinginvoiceInvoicePOST(body, canPayAtOnce, options).then((request) => request(axios, basePath));
        },
        /**
         * Счет можно удалить только если он находится в draft режиме.
         * @summary Удаление счета.
         * @param {string} id Внутренний идентификатор счета.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1invoicinginvoiceidInvoiceDELETE(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<ErrorStatus>> {
            return InvoiceApiFp(configuration).v1invoicinginvoiceidInvoiceDELETE(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Детальная информация о счете.
         * @param {string} id Внутренний идентификатор счета.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1invoicinginvoiceidInvoiceGET(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Invoice>> {
            return InvoiceApiFp(configuration).v1invoicinginvoiceidInvoiceGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Счет можно изменить только если он находится в draft режиме.
         * @summary Редактирование счета.
         * @param {string} id Внутренний идентификатор счета.
         * @param {Invoice} [body] Инвойс для редактирования
         * @param {boolean} [notifyServiceProvider] Флаг, указывающий о необходимости информирования производителя услуг об изменениях.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1invoicinginvoiceidInvoicePUT(id: string, body?: Invoice, notifyServiceProvider?: boolean, options?: AxiosRequestConfig): Promise<AxiosResponse<Invoice>> {
            return InvoiceApiFp(configuration).v1invoicinginvoiceidInvoicePUT(id, body, notifyServiceProvider, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Перевод счета из состояния \"ождание оплаты\" в \"черновик\"
         * @param {string} id Внутренний идентификатор счета.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1invoicinginvoiceidcancelInvoicePOST(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return InvoiceApiFp(configuration).v1invoicinginvoiceidcancelInvoicePOST(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение данных для формирования QR-кода.
         * @param {string} id Внутренний идентификатор счета.
         * @param {number} [imgWidth] Ширина изображения
         * @param {number} [imgHeight] Высота изображения
         * @param {boolean} [getImage] Признак запроса изображения qr-кода
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1invoicinginvoiceidqrcodeInvoiceGET(id: string, imgWidth?: number, imgHeight?: number, getImage?: boolean, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return InvoiceApiFp(configuration).v1invoicinginvoiceidqrcodeInvoiceGET(id, imgWidth, imgHeight, getImage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Перевод счета из draft режима в состояние, когда счет можно оплачивать.
         * @param {string} id Внутренний идентификатор счета.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1invoicinginvoiceidsendInvoicePOST(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return InvoiceApiFp(configuration).v1invoicinginvoiceidsendInvoicePOST(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Инициирование операции сторно по инвойсу.
         * @param {string} id Внутренний идентификатор счета.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1invoicinginvoiceidstornoInvoicePOST(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<ErrorStatus>> {
            return InvoiceApiFp(configuration).v1invoicinginvoiceidstornoInvoicePOST(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvoiceApi - object-oriented interface
 * @export
 * @class InvoiceApi
 * @extends {BaseAPI}
 */
export class InvoiceApi extends BaseAPI {
    /**
     * 
     * @summary Возвращает список счетов.
     * @param {Date} [beginDate] Начальная дата для выборки данных.
     * @param {Date} [endDate] Конечная дата для выборки данных.
     * @param {number} [skip] Количество записей, которые необходимо пропустить.
     * @param {number} [count] Количество записй, которые необходимо вернуть.
     * @param {string} [searchString] Строка для поиска
     * @param {string} [states] Необходимые состояния
     * @param {InvoiceQueryType} [requestType] Тип запроса (по дате создания, по дате оплаты)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public async v1invoicinginvoiceInvoiceGET(beginDate?: Date, endDate?: Date, skip?: number, count?: number, searchString?: string, states?: string, requestType?: InvoiceQueryType, options?: AxiosRequestConfig) : Promise<AxiosResponse<InvoiceListItemRecords>> {
        return InvoiceApiFp(this.configuration).v1invoicinginvoiceInvoiceGET(beginDate, endDate, skip, count, searchString, states, requestType, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Счет выставляется в draft режиме, в этом режиме счет можно удалить из системы или внести изменения.
     * @summary Добавляет новый счет.
     * @param {Invoice} [body] Информация о выставляемом счете.
     * @param {boolean} [canPayAtOnce] Признак выставления инвойса сразу на оплату
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public async v1invoicinginvoiceInvoicePOST(body?: Invoice, canPayAtOnce?: boolean, options?: AxiosRequestConfig) : Promise<AxiosResponse<Invoice>> {
        return InvoiceApiFp(this.configuration).v1invoicinginvoiceInvoicePOST(body, canPayAtOnce, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Счет можно удалить только если он находится в draft режиме.
     * @summary Удаление счета.
     * @param {string} id Внутренний идентификатор счета.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public async v1invoicinginvoiceidInvoiceDELETE(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<ErrorStatus>> {
        return InvoiceApiFp(this.configuration).v1invoicinginvoiceidInvoiceDELETE(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Детальная информация о счете.
     * @param {string} id Внутренний идентификатор счета.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public async v1invoicinginvoiceidInvoiceGET(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Invoice>> {
        return InvoiceApiFp(this.configuration).v1invoicinginvoiceidInvoiceGET(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Счет можно изменить только если он находится в draft режиме.
     * @summary Редактирование счета.
     * @param {string} id Внутренний идентификатор счета.
     * @param {Invoice} [body] Инвойс для редактирования
     * @param {boolean} [notifyServiceProvider] Флаг, указывающий о необходимости информирования производителя услуг об изменениях.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public async v1invoicinginvoiceidInvoicePUT(id: string, body?: Invoice, notifyServiceProvider?: boolean, options?: AxiosRequestConfig) : Promise<AxiosResponse<Invoice>> {
        return InvoiceApiFp(this.configuration).v1invoicinginvoiceidInvoicePUT(id, body, notifyServiceProvider, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Перевод счета из состояния \"ождание оплаты\" в \"черновик\"
     * @param {string} id Внутренний идентификатор счета.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public async v1invoicinginvoiceidcancelInvoicePOST(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return InvoiceApiFp(this.configuration).v1invoicinginvoiceidcancelInvoicePOST(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Получение данных для формирования QR-кода.
     * @param {string} id Внутренний идентификатор счета.
     * @param {number} [imgWidth] Ширина изображения
     * @param {number} [imgHeight] Высота изображения
     * @param {boolean} [getImage] Признак запроса изображения qr-кода
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public async v1invoicinginvoiceidqrcodeInvoiceGET(id: string, imgWidth?: number, imgHeight?: number, getImage?: boolean, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return InvoiceApiFp(this.configuration).v1invoicinginvoiceidqrcodeInvoiceGET(id, imgWidth, imgHeight, getImage, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Перевод счета из draft режима в состояние, когда счет можно оплачивать.
     * @param {string} id Внутренний идентификатор счета.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public async v1invoicinginvoiceidsendInvoicePOST(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return InvoiceApiFp(this.configuration).v1invoicinginvoiceidsendInvoicePOST(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Инициирование операции сторно по инвойсу.
     * @param {string} id Внутренний идентификатор счета.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public async v1invoicinginvoiceidstornoInvoicePOST(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<ErrorStatus>> {
        return InvoiceApiFp(this.configuration).v1invoicinginvoiceidstornoInvoicePOST(id, options).then((request) => request(this.axios, this.basePath));
    }
}
