import React from 'react';
import { muiEsasStyled } from 'app/theme';
import { SxProps, Theme } from '@mui/material/styles';

import { NavLink, NavLinkProps } from 'react-router-dom';


interface IAppNavLinkProps extends NavLinkProps {
	color?: 'primary' | 'secondary' | 'tertiary'; 
	sx?: SxProps<Theme>;
};
const AppNavLink: React.FC<IAppNavLinkProps> = muiEsasStyled(NavLink, {
	// Перечисляем пропсы, которые не будут отображаться атрибутами в DOM
	shouldForwardProp: (prop) => (
		prop !== 'sx' && prop !== 'color'
	),
	name: 'AppNavLink'
})<IAppNavLinkProps>((props) => {
	const {
		theme,
		color = 'primary',
	} = props;

	// Определяем стили в зависимости от пропсов для [sx] 
	const general_sx = {
		font: theme.font.small.md,
		color: theme.color[color],
		transition: 'color 0.3s',
		'&:hover': {
			color: theme.color.epos.hover
		},
		'&.active': {
			color: theme.color.epos.primary
		}
	};

	return [
		general_sx,
		{},
	];
});

export { AppNavLink };
