import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { isEqual } from 'lodash';
import { applyTableFilter, initQueryParams } from 'utils';
import { useCurrencyRatesCRUD } from 'hooks';
import { currencyRateSchema } from 'app/yup';
// Types
import { IGetCurrencyRatesAllParams, ICurrencyRate} from 'apis/cabinetApi/extraTypes'
import { IUseCurrencyRatesUIContext, ICurrencyRatesNavigate } from 'app/modules/currencyRates/types';


// Трюк с undefined в качестве init value контекста
// https://stackoverflow.com/questions/58193424/passing-state-with-usecontext-in-typescript
const CurrencyRatesContext = createContext<IUseCurrencyRatesUIContext | undefined>(undefined);
const useCurrencyRatesUIContext = () => {
	const contextValue = useContext(CurrencyRatesContext);
	if (contextValue === undefined) throw new Error('Expected context value to be set');
	return contextValue;
};


const CurrencyRatesUIProvider: React.FC<{ navigateActions: ICurrencyRatesNavigate }> = ({ navigateActions, children }) => {
	const { getCurrencyRatesForTable, saveCurrencyRate } = useCurrencyRatesCRUD();

	//* ========== Логика таблицы ==========
	const [queryParams, setQueryParamsBase] = useState<IGetCurrencyRatesAllParams>(initQueryParams);
	const setQueryParams = useCallback((nextQueryParams?: IGetCurrencyRatesAllParams | null) => {
		if (nextQueryParams) {
			setQueryParamsBase((prevQueryParams) => {
				if (isEqual(prevQueryParams, nextQueryParams)) {
					return prevQueryParams;
				} else {
					return nextQueryParams;
				}
			});
			return;
		} 
		if (nextQueryParams === null) {
			setQueryParamsBase({ ...initQueryParams });
			return;
		}
		if (nextQueryParams === undefined) {
			setQueryParamsBase((prevQueryParams) => ({ ...prevQueryParams }));
		}
	}, []);

	const onTableFilterChange = useCallback((values: typeof queryParams.filter) => {
		applyTableFilter(queryParams, setQueryParams, values);
	}, [queryParams, setQueryParams]);

	// Запрос на обновление records при изменении queryParams через пагинацию или фильтры
	useEffect(() => {
		getCurrencyRatesForTable((queryParams));
	}, [queryParams]);


	//* ========== Логика формы ==========
	const onCurrencyRateFormSubmit = useCallback(async (values: ICurrencyRate) => {
		const castedValues = currencyRateSchema.cast(values);
		const result = await saveCurrencyRate(castedValues);
		if (result) {
			setQueryParams();
			setTimeout(() => {
				navigateActions.navigateToListPage();
			}, 0);
		}
	}, []);


	return (
		<CurrencyRatesContext.Provider
			value={{
				queryParams,
				setQueryParams,
				onTableFilterChange,
				onCurrencyRateFormSubmit,

				...navigateActions
			}}
		>
			{children}
		</CurrencyRatesContext.Provider>
	);
}


export { CurrencyRatesUIProvider, useCurrencyRatesUIContext };
