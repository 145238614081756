import React from 'react';
import { muiEsasStyled } from 'app/theme';
import { SxProps, Theme } from '@mui/material/styles';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';


const AppTableContainerBase: React.FC = ({ children, ...props }) => {
	return (
		<div {...props}>
			<PerfectScrollbar>
				{children}
			</PerfectScrollbar>
		</div>
	);
}


interface IAppTableContainerProps extends React.ComponentProps<'div'> {
	sx?: SxProps<Theme>;
};
const AppTableContainer: React.FC<IAppTableContainerProps> = muiEsasStyled(AppTableContainerBase, {
	// Перечисляем пропсы, которые не будут отображаться атрибутами в DOM
	shouldForwardProp: (prop) => (
		prop !== 'sx'
	),
	name: 'AppTableContainer'
})<IAppTableContainerProps>((props) => {
	const {
		theme,
	} = props;

	const general_sx: any = {
		width: '100%',
		overflowX: 'auto',
	};

	return [
		general_sx,
	];
});

export { AppTableContainer };
