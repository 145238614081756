import React from 'react'
import { Outlet, Navigate } from 'react-router-dom';

import { useAuth } from 'hooks';
// Types


//* location.pathname === '/auth*' && useAuth().accessToken === '' 
const Auth: React.FC = () => {
	const { accessToken, postAuthUrl } = useAuth();

	/**
	 * Текущий pathname === '/auth*'
	 * - пользователь не авторизован - нет accessToken:
	 *   - редирект RequiredAuthHOC на /auth/login => редирект на iii для авторизации
	 * - пользователь авторизован - есть accessToken:
	 *   - редирект iii на /auth#code => получение и запись токенов, редирект на fromUrl
	 *   - переход на /auth => редирект на /
	 */
	return (accessToken) ? (
		<Navigate to={postAuthUrl.current} replace />
	) : (
		<Outlet />
	);
}

export { Auth };
