// import { i18n } from '../../../i18n/i18n';
// const t = (text: string) => i18n.t('validation.' + text);

//! Доделать с i18n
const errMsg = {
	required: 'required',
	email: 'email',
	phone: 'phone',
	url: 'url',
	laterNow: 'laterNow',
	typeErr: {
		string: 'not-string',
		number: 'not-number',
		boolean: 'not-boolean',
		date: 'not-date',
	},
	length: {
		unp: 'not-unp'
	},
	min: {
		than0: 'more-than-0',
		more1: 'min-1',
	},
	max: {
		than100: 'less-than-100'
	},
	iban: 'not-iban'
};


export { errMsg };
