import * as Yup from 'yup';
import { errMsg } from './_errorMessages';
import {
	nullableString,
	ibanSchema,
} from './_shortcuts';


export const accountSchema = () => Yup.object().shape({
//!		Банковские реквизиты
	//*	IBAN
	'iban': ibanSchema(), // <TextField/>
	//*	Валюта *
	'currency': nullableString() // <Select/>
		.required(errMsg.required),

	bank: Yup.object().shape({
	//*	BIC *
		'bic': nullableString() // <Autocomplete/>
			.required(errMsg.required),
	//*	Код банка
		'code': nullableString(), // <TextField disabled/>
	//*	Наименование банка
		'name': nullableString() // <TextField/>
			.required(errMsg.required),

//!		Адрес банка
		address: Yup.object().shape({
	//*	Город *
			'city': nullableString() // <TextField/>
				.required(errMsg.required),
	//*	Улица, номер дома *
			'line1': nullableString() // <TextField/>
				.required(errMsg.required)
		})
	})
});
