import React from 'react';
import { Container, ContainerProps } from '@mui/material';


const AppContainer: React.FC<ContainerProps> = ({ children, sx = [], ...props }) => {
	return (
		<Container
			maxWidth="xl"
			sx={[
				(theme) => ({
					position: 'relative',
					[theme.breakpoints.up('xs')]: {
						padding: `0 ${theme.spacing(6)}`,
					},
					[theme.breakpoints.up('md')]: {
						padding: `0 ${theme.spacing(10)}`,
					},
				}),
				...(Array.isArray(sx) ? sx : [sx])
			]}
			{...props}
		>
			{children}
		</Container>
	);
}

export { AppContainer };
