import * as Yup from 'yup';
import { errMsg } from './_errorMessages';
import {
	nullableString,
	nullableDate,
	nullableArray,
	nullableNumber,
} from './_shortcuts';


export const reportSchema = () => Yup.object().shape({
	report: Yup.object().shape({
//!		Отчет
	//*	Отчет (выбираем код, подпись name)
		'code': nullableNumber() // <Select/>
			.required(errMsg.required),
	//	Нет инпута, подставляем из справочника
		'defaultTemplateName': nullableString(),
	//*	Типы вложений
		attachTypes: nullableArray() // <MultiSelect/>
			.of(Yup.number()
				.oneOf([10, 20, 30, 40, 50])
			)
			.min(1, errMsg.min.more1)
			.default([])
			.required(errMsg.required)
	}),
	//*	Emails
	emails: nullableArray() // <EmailsMultiField/>
		.of(Yup.string()
			.email(errMsg.email)
			.required(errMsg.required)
		)
		.min(1, errMsg.required),
	//*	
	'lastSendDate': nullableDate() // <DateInput/>
});
