import { useCallback } from 'react';
import { cloneDeep } from 'lodash';
import { IComfirmModalParams, notistack, useLayoutUIContext } from 'app/providers';
import { useAppDispatch, useAppSelector, shallowEqual } from 'hooks';
import { eposCabinetApiCalls } from 'apis/cabinetApi/cabinetApiCalls';
import { serviceProvidersActions } from './providersSlice';
// Types
import { TThunkAction, EApiCallTypes } from 'reduxStore';
import {
	IGetServiceProvidersAllParams,
	IServiceProvider,
	IServiceProviderIII,
	IUserInfo,
	ServiceProviderStateEnum
} from 'apis/cabinetApi/extraTypes';
import { IUseProvidersCRUDReturn } from 'app/modules/serviceProviders/types';
import { useTranslation } from 'react-i18next';


const useServiceProvidersCRUD = (): IUseProvidersCRUDReturn => {
	const { t } = useTranslation(undefined, { keyPrefix: 'schemas.ServiceProvider' });
	const providersState = useAppSelector((store) => store.serviceProviders, shallowEqual);
	const { showConfirmModal } = useLayoutUIContext();
	const appDispatch = useAppDispatch();

	const getServiceProvidersForTable = useCallback<IUseProvidersCRUDReturn['getServiceProvidersForTable']>((params) => {
		const thunkAction = (params: IGetServiceProvidersAllParams): TThunkAction<Promise<void>> => async (dispatch) => {
			const callType = EApiCallTypes.list;
			dispatch(serviceProvidersActions.startCall({ callType }));
			const response = await eposCabinetApiCalls.getServiceProvidersAll(params);
			if (response.ok && response.data) {
				dispatch(serviceProvidersActions.getServiceProvidersAllFulfilled(response.data));
			} else {
				dispatch(serviceProvidersActions.catchError({ callType, errorMessage: response.error }));
				notistack.error(response.error);
			}
		}
		
		return appDispatch(thunkAction(params));
	}, [appDispatch]);

	const getServiceProviderFullData = useCallback<IUseProvidersCRUDReturn['getServiceProviderFullData']>((code) => {
		const thunkAction = (code: number): TThunkAction<Promise<void>> => async (dispatch) => {
			const callType = EApiCallTypes.entity;
			dispatch(serviceProvidersActions.startCall({ callType }));
			const response = await eposCabinetApiCalls.getServiceProviderById(code);
			if (response.ok && response.data) {
				// Собрать все notifyParams.users в один массив, получить через Promise.all
				// по id массив пользователей, задиспатчить в store.directory.users
				// Уже там искать пользователей по id для получения ФИО
				// TODO После создания оссинхронного автокомплита на юзеров вернуть логику
				// const userIds: Array<string> = [];
				// const serviceProvider = response.data;
				// serviceProvider.notifyParams?.users?.forEach((id) => userIds.push(id));
				// serviceProvider.services?.forEach((S) => S.notifyParams?.users?.forEach((id) => userIds.push(id)));
				// serviceProvider.retailOutlets?.forEach((RO) => RO.notifyParams?.users?.forEach((id) => userIds.push(id)));
				// const promises = userIds.map((id) => eposCabinetApiCalls.getUserById(id));
				// const responses = await Promise.all(promises);
				// const users = responses.map((response) => response.data).filter(user => user);
				// dispatch(directoryActions.getUsersAllFulfilled(users as Array<IUserInfo>));

				dispatch(serviceProvidersActions.getServiceProviderByIdFulfilled(response.data));
			} else {
				dispatch(serviceProvidersActions.catchError({ callType, errorMessage: response.error }));
				notistack.error(response.error);
			}
		}

		return appDispatch(thunkAction(code));
	}, [appDispatch]);

	const setInitServiceProvider = useCallback<IUseProvidersCRUDReturn['setInitServiceProvider']>(() => {
		appDispatch(serviceProvidersActions.setInitEdittingEntity());
	}, [appDispatch]);

	const saveServiceProvider = useCallback<IUseProvidersCRUDReturn['saveServiceProvider']>((values) => {
		const thunkAction = (values: IServiceProvider): TThunkAction<Promise<boolean>> => async (dispatch) => {
			const callType = EApiCallTypes.action;
			dispatch(serviceProvidersActions.startCall({ callType }));
			const code = values.code;
			if (code) {
				const response = await eposCabinetApiCalls.updateServiceProvider(code, values);
				if (response.ok && response.data) {
					dispatch(serviceProvidersActions.saveServiceProviderFulfilled(response.data));
					notistack.success(t('NOTICES.UPDATE_OK'));
					return true;
				} else {
					dispatch(serviceProvidersActions.catchError({ callType, errorMessage: response.error }));
					notistack.error(response.error);
				}
			} else {
				const response = await eposCabinetApiCalls.createServiceProvider(values);
				if (response.ok && response.data) {
					dispatch(serviceProvidersActions.saveServiceProviderFulfilled(response.data));
					notistack.success(t('NOTICES.CREATE_OK'));
					return true;
				} else {
					dispatch(serviceProvidersActions.catchError({ callType, errorMessage: response.error }));
					notistack.error(response.error);
				}
			}
			return false;
		}
		
		return appDispatch(thunkAction(values));
	}, [appDispatch]);

	//* С модальным окном подтверждения действия
	const changeServiceProviderState = useCallback<IUseProvidersCRUDReturn['changeServiceProviderState']>((code, state) => {
		const thunkAction = (code: number): TThunkAction<Promise<void>> => async (dispatch, getState) => {
			// Если ПУ на удалении, ничего не происходит
			if (state === undefined) return;

			const callType = EApiCallTypes.action;
			dispatch(serviceProvidersActions.startCall({ callType }));

			const { edittingEntity, records } = getState().serviceProviders;
			// edittingEntity и records readOnly, потому для изменения state клонирую
			const changedEntity = cloneDeep(edittingEntity);
			changedEntity.state = state;
			const changedRecords = cloneDeep(records);
			(changedRecords.find((serviceProvider) => serviceProvider.code === code) ?? edittingEntity).state = state;

			if (state === ServiceProviderStateEnum.NUMBER_0) {
				const response = await eposCabinetApiCalls.disableServiceProviderById(code);
				if (response.ok) {
					dispatch(serviceProvidersActions.changeServiceProviderStateFulfilled({ changedEntity, changedRecords }));
					notistack.success(t('NOTICES.DISABLE_OK'));
				} else {
					dispatch(serviceProvidersActions.catchError({ callType, errorMessage: response.error }));
					notistack.error(response.error);
				}
			}

			if (state === ServiceProviderStateEnum.NUMBER_1) {
				const response = await eposCabinetApiCalls.enableServiceProviderById(code);
				if (response.ok) {
					dispatch(serviceProvidersActions.changeServiceProviderStateFulfilled({ changedEntity, changedRecords }));
					notistack.success(t('NOTICES.ENABLE_OK'));
				} else {
					dispatch(serviceProvidersActions.catchError({ callType, errorMessage: response.error }));
					notistack.error(response.error);
				}
			}

			if (state === ServiceProviderStateEnum.NUMBER_2) {
				const response = await eposCabinetApiCalls.deleteServiceProvider(code);
				if (response.ok) {
					dispatch(serviceProvidersActions.changeServiceProviderStateFulfilled({ changedEntity, changedRecords }));
					notistack.success(t('NOTICES.DELETE_OK'));
				} else {
					dispatch(serviceProvidersActions.catchError({ callType, errorMessage: response.error }));
					notistack.error(response.error);
				}
			}
		}

		const showConfirmModalParams: IComfirmModalParams = {
			title:    '',
			text:     '',
			callback: () => appDispatch(thunkAction(code)),
			action:   ''
		};

		if (state === ServiceProviderStateEnum.NUMBER_0) {
			showConfirmModalParams.title = t('ACTIONS.DISABLE');
			showConfirmModalParams.text = t('CONFIRM_MODAL.DISABLE_?');
			showConfirmModalParams.action = t('CONFIRM_MODAL.DISABLE_!');
		}
		if (state === ServiceProviderStateEnum.NUMBER_1) {
			showConfirmModalParams.title = t('ACTIONS.ENABLE');
			showConfirmModalParams.text = t('CONFIRM_MODAL.ENABLE_?');
			showConfirmModalParams.action = t('CONFIRM_MODAL.ENABLE_!');
		}
		if (state === ServiceProviderStateEnum.NUMBER_2) {
			showConfirmModalParams.title = t('ACTIONS.DELETE');
			showConfirmModalParams.text = t('CONFIRM_MODAL.DELETE_?');
			showConfirmModalParams.action = t('CONFIRM_MODAL.DELETE_!');
		}

		showConfirmModal(showConfirmModalParams);
	}, [appDispatch]);

	//* С модальным окном подтверждения действия
	const updateServiceProviderIII = useCallback<IUseProvidersCRUDReturn['updateServiceProviderIII']>((code, iiiData) => {
		const thunkAction = (code: number, iiiData?: IServiceProviderIII | null): TThunkAction<Promise<boolean>> => async (dispatch) => {
			if (iiiData == null) return false;
			const callType = EApiCallTypes.action;
			dispatch(serviceProvidersActions.startCall({ callType }));
			const response = await eposCabinetApiCalls.updateServiceProviderIIIById(code, iiiData);
			if (response.ok) {
				dispatch(serviceProvidersActions.saveServiceProviderFulfilled(response.data));
				notistack.success(t('NOTICES.III_OK'));
				return true;
			} else {
				dispatch(serviceProvidersActions.catchError({ callType, errorMessage: response.error }));
				notistack.error(response.error);
				return false;
			}
		}

		return appDispatch(thunkAction(code, iiiData));
	}, [appDispatch]);


	return {
		...providersState,

		getServiceProvidersForTable,
		getServiceProviderFullData,
		setInitServiceProvider,
		saveServiceProvider,
		changeServiceProviderState,
		updateServiceProviderIII
	};
}


export { useServiceProvidersCRUD };
