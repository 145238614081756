import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { serviceProviderInitialValues } from './initialFormValues';
// Types
import {
	IModuleState,
	catchAsyncError,
	startAsyncCall,
} from 'reduxStore/reduxTypes';
import {
	IPaged,
	IServiceProvider,
} from 'apis/cabinetApi/extraTypes';


const serviceProvidersInitialState: IModuleState<IServiceProvider> = {
	listLoading:    false,
	actionLoading:  false,
	entityLoading:  false,
	records:        [],
	total:          0, 
	edittingEntity: serviceProviderInitialValues,
	lastError:      ''
};


const serviceProvidersSlice = createSlice({
	name: 'serviceProviders',
	initialState: serviceProvidersInitialState,

	reducers: {
		startCall: startAsyncCall,
		catchError: catchAsyncError,

		setInitEdittingEntity: (state) => {
			state.edittingEntity = serviceProviderInitialValues;
			state.entityLoading = false;
		},

		getServiceProvidersAllFulfilled: (state, action: PayloadAction<IPaged<IServiceProvider>>) => {
			const { records, total } = action.payload;
			state.records = records ?? [];
			state.total = total;
			state.listLoading = false;
		},
		getServiceProviderByIdFulfilled: (state, action: PayloadAction<IServiceProvider>) => {
			state.edittingEntity = action.payload;
			state.entityLoading = false;
		},

		saveServiceProviderFulfilled: (state, action: PayloadAction<any>) => {
			state.actionLoading = false;
		},

		changeServiceProviderStateFulfilled: (state, action: PayloadAction<{ changedEntity: IServiceProvider, changedRecords: IServiceProvider[] }>) => {
			const { changedEntity, changedRecords } = action.payload;
			state.edittingEntity = changedEntity;
			state.records = changedRecords;
			state.actionLoading = false;
		},

	}
});


const serviceProvidersActions = serviceProvidersSlice.actions;
export { serviceProvidersSlice, serviceProvidersActions };
