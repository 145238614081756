import { useCallback } from 'react';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { prepareEndDate } from 'utils';
import { IComfirmModalParams, notistack, useLayoutUIContext } from 'app/providers';
import { useAppDispatch, useAppSelector, shallowEqual } from 'hooks';
import { eposCabinetApiCalls } from 'apis/cabinetApi/cabinetApiCalls';
import { currencyRatesActions } from './currencyRatesSlice';
// Types
import { EApiCallTypes, TThunkAction } from 'reduxStore';
import { ICurrencyRate, IGetCurrencyRatesAllParams } from 'apis/cabinetApi/extraTypes';
import { IUseCurrencyRatesCRUDReturn } from '../types';


const useCurrencyRatesCRUD = (): IUseCurrencyRatesCRUDReturn => {
	const { t } = useTranslation(undefined, { keyPrefix: 'schemas.CurrencyRate' });
	const currenciesState = useAppSelector((store) => store.currencyRates, shallowEqual);
	const { showConfirmModal } = useLayoutUIContext();
	const appDispatch = useAppDispatch();

	const getCurrencyRatesForTable = useCallback<IUseCurrencyRatesCRUDReturn['getCurrencyRatesForTable']>((params) => {
		const thunkAction = (params: IGetCurrencyRatesAllParams): TThunkAction<Promise<void>> => async (dispatch) => {
			// Включить endDate в интервал поиска
			const nextParams = cloneDeep(params);
			const { endDate } = nextParams.filter;
			if (endDate) {
				nextParams.filter.endDate = prepareEndDate(endDate) ?? undefined;
			}

			const callType = EApiCallTypes.list;
			dispatch(currencyRatesActions.startCall({ callType }));
			const response = await eposCabinetApiCalls.getCurrencyRatesAll(nextParams);
			if (response.ok && response.data) {
				dispatch(currencyRatesActions.getCurrencyRatesAllFulfilled(response.data));
			} else {
				dispatch(currencyRatesActions.catchError({ callType, errorMessage: response.error }));
				notistack.error(response.error);
			}
		}
		
		return appDispatch(thunkAction(params));
	}, [appDispatch]);

	const getCurrencyRateFullData = useCallback<IUseCurrencyRatesCRUDReturn['getCurrencyRateFullData']>((id) => {
		const thunkAction = (id: string): TThunkAction<Promise<void>> => async (dispatch) => {
			const callType = EApiCallTypes.entity;
			dispatch(currencyRatesActions.startCall({ callType }));
			const response = await eposCabinetApiCalls.getCurrencyRateById(id);
			if (response.ok && response.data) {
				dispatch(currencyRatesActions.getCurrencyRateByIdFulfilled(response.data));
			} else {
				dispatch(currencyRatesActions.catchError({ callType, errorMessage: response.error }));
				notistack.error(response.error);
			}
		}
		
		return appDispatch(thunkAction(id));
	}, [appDispatch]);

	const setInitCurrencyRate = useCallback<IUseCurrencyRatesCRUDReturn['setInitCurrencyRate']>(() => {
		appDispatch(currencyRatesActions.setInitEdittingEntity());
	}, [appDispatch]);

	const saveCurrencyRate = useCallback<IUseCurrencyRatesCRUDReturn['saveCurrencyRate']>((values) => {
		const thunkAction = (values: ICurrencyRate): TThunkAction<Promise<boolean>> => async (dispatch) => {
			const callType = EApiCallTypes.action;
			dispatch(currencyRatesActions.startCall({ callType }));
			const id = values.id;
			if (id) {
				const response = await eposCabinetApiCalls.updateCurrencyRate(id, values);
				if (response.ok && response.data) {
					dispatch(currencyRatesActions.currencyRateActionFulfilled());
					notistack.success(t('NOTICES.UPDATE_OK'));
					return true;
				} else {
					dispatch(currencyRatesActions.catchError({ callType, errorMessage: response.error }));
					notistack.error(response.error);
				}
			} else {
				const response = await eposCabinetApiCalls.createCurrencyRate(values);
				if (response.ok && response.data) {
					dispatch(currencyRatesActions.currencyRateActionFulfilled());
					notistack.success(t('NOTICES.CREATE_OK'));
					return true;
				} else {
					dispatch(currencyRatesActions.catchError({ callType, errorMessage: response.error }));
					notistack.error(response.error);
				}
			}
			return false;
		}
		
		return appDispatch(thunkAction(values));
	}, [appDispatch]);

	//* С модальным окном подтверждения действия
	const deleteCurrencyRate = useCallback<IUseCurrencyRatesCRUDReturn['deleteCurrencyRate']>((id) => {
		const thunkAction = (id: string): TThunkAction<Promise<boolean>> => async (dispatch) => {
			const callType = EApiCallTypes.action;
			dispatch(currencyRatesActions.startCall({ callType }));
			const response = await eposCabinetApiCalls.deleteCurrencyRate(id);
			if (response.ok && response.data) {
				dispatch(currencyRatesActions.currencyRateActionFulfilled());
				notistack.success(t('NOTICES.DELETE_OK'));
				return true;
			} else {
				dispatch(currencyRatesActions.catchError({ callType, errorMessage: response.error }));
				notistack.error(response.error);
				return false;
			}
		}
		
		const showConfirmModalParams: IComfirmModalParams = {
			title:    t('ACTIONS.DELETE'),
			text:     t('CONFIRM_MODAL.DELETE_?'),
			callback: () => appDispatch(thunkAction(id)),
			action:   t('CONFIRM_MODAL.DELETE_!')
		};
		showConfirmModal(showConfirmModalParams);
	}, [appDispatch]);


	return {
		...currenciesState,

		getCurrencyRatesForTable,
		getCurrencyRateFullData,
		setInitCurrencyRate,
		saveCurrencyRate,
		deleteCurrencyRate
	};
}


export { useCurrencyRatesCRUD };
