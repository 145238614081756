import * as Yup from 'yup';
import { errMsg } from './_errorMessages';
import {
	nullableNumber,
	nullableString,
} from './_shortcuts';


const cashBoxNotifySchema = () => Yup.object().shape({
	'isActive': Yup.boolean().default(false)
}).nullable().default(null);

export const cashBoxSchema = () => Yup.object().shape({
	//*	Код кассы
	'code': nullableNumber()
		.required(errMsg.required),
	//*	Идентификатор
	'id': nullableString()
		.required(errMsg.required),
	//* Наименование кассы
	'name': nullableString()
		.required(errMsg.required),
	//* Описание
	'descr': nullableString(),

//!		Уведомления
	notifiers: Yup.object().shape({
		createInvoiceNotify: cashBoxNotifySchema(),
		startPayInvoiceNotify: cashBoxNotifySchema(),
		cancelPayInvoiceNotify: cashBoxNotifySchema(),
		confirmPayInvoiceNotify: cashBoxNotifySchema(),
		deleteInvoiceNotify: cashBoxNotifySchema(),
		stornoInvoiceNotify: cashBoxNotifySchema(),
	//* URL нотификации кассы после оплаты счета
		'urlNotify': nullableString()
			.url(errMsg.url)
	}).nullable().default(null)
});
