import React from 'react';
import { NavLink } from 'react-router-dom';
import {
	Box,
	Stack,
	Typography,
} from '@mui/material';
// Types
import { To } from 'react-router-dom';


interface IAsideMenuSubitemProps {
	to:   To;
	text: string;
};
const AsideMenuSubitem: React.FC<IAsideMenuSubitemProps> = ({ to, text }) => {
	return (
		<Box
			component="li"
			sx={(theme) => ({
				borderRadius: theme.radius.x1
			})}
		>
			<Stack
				component={NavLink}
				to={to}
				direction="row"
				justifyContent="flex-start"
				alignItems="center"
				sx={(theme) => ({
					minHeight: theme.spacing(10),
					padding: `${theme.spacing(2)} 0`,
					font: theme.font.body.xs,
					color: theme.color.primary,
					transition: 'color 0.3s',
					'&:hover': {
						color: theme.color.epos.hover,
					},
					'&.active': {
						color: theme.color.epos.primary,
					}
				})}
			>

				<Typography
					component="span"
					sx={(theme) => ({
						marginLeft: theme.spacing(9),
						font: 'inherit',
						color: 'inherit',
					})}
				>
					{text}
				</Typography>
			</Stack>
		</Box>
	);
}

export { AsideMenuSubitem };
