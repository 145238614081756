import React from 'react';
import { extend } from 'lodash';
import { muiEsasStyled } from 'app/theme';
import { alpha } from '@mui/material/styles';
// Types
import { SxProps, Theme } from '@mui/material/styles';
import { toAbsoluteUrl } from 'utils';
import { useTranslation } from 'react-i18next';


const GooglePlayLinkBase: React.FC<React.ComponentProps<'a'>> = (props) => {
	const { i18n } = useTranslation();
	const lang = i18n.language;

	return (
		<a
			target="_blank"
			href={`https://play.google.com/store/apps/details?id=by.esas.eposmobile&hl=${lang}&gl=US`}
			{...props}
		>
			<img
				src={toAbsoluteUrl(`/images/google-play-badge_${lang}.png`)}
				alt="get it on google play link"
				style={{
					width: '100%',
					objectFit: 'cover',
				}}
			/>
		</a>
	);
}


interface IGooglePlayLinkProps extends React.ComponentProps<'a'> {
	sx?: SxProps<Theme>;
};
const GooglePlayLink: React.FC<IGooglePlayLinkProps> = muiEsasStyled(GooglePlayLinkBase, {
	// Перечисляем пропсы, которые не будут отображаться атрибутами в DOM
	shouldForwardProp: (prop) => (
		prop !== 'sx'
	),
	name: 'GooglePlayLink'
})<IGooglePlayLinkProps>((props) => {
	const { theme, sx = [] } = props;

	return extend({
		display: 'inline-block',
		maxWidth: theme.spacing(100),
		userSelect: 'none',
		cursor: 'pointer',
	},
	sx, []);
});


export { GooglePlayLink };
