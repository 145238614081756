import React, { useEffect } from 'react';
import { useAuth } from 'hooks';


//* Попадаем только после редирект неавторизированного пользователя
//* с RequiredAuthHOC для перенаправления на логин iii
const LoginUserPage: React.FC = () => {
	const { logInUser, postAuthUrl } = useAuth();

	useEffect(() => {
		logInUser(postAuthUrl.current);
	}, []);

	return null;
}

export { LoginUserPage };
