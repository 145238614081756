import React from 'react';
import { extend } from 'lodash';
import { muiEsasStyled } from 'app/theme';
import OptionUnstyled, {
	OptionUnstyledProps,
	optionUnstyledClasses,
} from '@mui/base/OptionUnstyled';
// Types
import { ISxAsProp } from 'app/theme';


interface IAppSelectOptionProps extends OptionUnstyledProps<any>, ISxAsProp {};
const AppSelectOption: React.FC<IAppSelectOptionProps> = muiEsasStyled(OptionUnstyled, {
	// Перечисляем пропсы, которые не будут отображаться атрибутами в DOM
	shouldForwardProp: (prop) => (
		prop !== 'sx'
	),
	name: 'AppSelectOption'
})<IAppSelectOptionProps>(({ theme, sx = [] }) => {
	return extend(
		{
			display: 'flex',
			justifyContent: 'flex-start',
			alignItems: 'center',
			minHeight: theme.spacing(12),
			margin: 0,
			padding: `0 ${theme.spacing(4)}`,

			font: theme.font.small.md,
			lineHeight: theme.spacing(4),
			color: theme.color.primary,

			listStyle: 'none',
			cursor: 'pointer',
			transition: 'color 0.3s, background 0.3s',

			[`&:hover:not(.${optionUnstyledClasses.disabled})`]: {
				background: theme.color.quaternary,
			},

			[`&.${optionUnstyledClasses.selected}`]: {
				color: theme.color.epos.primary,
			},

			[`&.${optionUnstyledClasses.highlighted}`]: {
				background: theme.color.quaternary,
			},

			[`&.${optionUnstyledClasses.disabled}`]: {
				color: theme.color.tertiary,
				background: theme.color.bg_02,
			},
		},
		...Array.isArray(sx) ? sx : [sx]
	);
});


export { AppSelectOption };
