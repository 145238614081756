import React, { useCallback, useEffect } from 'react';
import { useField } from 'formik';
import { eposCabinetApiCalls } from 'apis/cabinetApi/cabinetApiCalls';
import { AppAsyncAutocomplete, IAppAsyncAutocompleteProps } from 'app/components';
import { useServiceProvidersCRUD, useUserInfoCRUD } from 'hooks';
// Types
import { IServiceProvider } from 'apis/cabinetApi/extraTypes';


interface IServiceProviderAutocompleteProps {
	name:                  string;
	onAutocompleteChange?: IAppAsyncAutocompleteProps<IServiceProvider>['onAutocompleteChange'];
	disabled?:             boolean;
};
const ServiceProviderAutocomplete: React.FC<IServiceProviderAutocompleteProps> = (props) => {
	const { name, onAutocompleteChange, disabled } = props;

	const [{value}, meta, {setValue}] = useField(name);

	const {
		edittingEntity,
		getServiceProviderFullData,
		setInitServiceProvider
	} = useServiceProvidersCRUD();

	const { isProvider, userInfo, userProvider } = useUserInfoCRUD();

	// При изменении value получить и задиспатчить edittingEntity, как autocompleteValue
	useEffect(() => {
		if (value) {
			getServiceProviderFullData(value);
		}	else {
			setInitServiceProvider();
		}
	}, [value]);

	// Если пользователь ПУ - записать в value его serviceProviderId,
	// после чего сработает useEffect и обновит edittingEntity
	useEffect(() => {
		if (isProvider && userInfo?.serviceProviderId) {
			setValue(userInfo.serviceProviderId);
		}
	}, [userInfo?.serviceProviderId]);

	// При выборе значения автокомплита обновить value и отработать внешнюю логику
	const modifiedAutocompleteChange = useCallback((value: IServiceProvider | null) => {
		const serviceProviderCode = value?.code ?? undefined;
		setValue(serviceProviderCode);
		onAutocompleteChange?.(value);
	}, [setValue]);

	// Очистка state.serviceProvider.edittingEntity
	useEffect(() => {
		return setInitServiceProvider;
	}, []);


	return (
		<AppAsyncAutocomplete
			name={name}
			autocompleteValue={(edittingEntity.code) ? edittingEntity : null}
			onAutocompleteChange={modifiedAutocompleteChange}
			getOptionsAsync={(searchString, count) => {
				return eposCabinetApiCalls.getServiceProvidersAll({
					skip: 0,
					count,
					filter: { searchString }
				});
			}}
			getOptionLabel={(option: IServiceProvider) => {
				return (option.legalInfo?.name) ? `${option.legalInfo?.name} (${option.legalInfo?.unp})` : '';
			}}
			renderOption={(props, option: IServiceProvider) => (
				<li {...props} key={option.code}>
					{(option.legalInfo?.name) ? `${option.legalInfo?.name} (${option.legalInfo?.unp})` : ''}
				</li>
			)}
			disabled={isProvider || disabled}
			// disabled={disabled}
		/>
	);
}


ServiceProviderAutocomplete.displayName = 'ServiceProviderAutocomplete';
export { ServiceProviderAutocomplete };
