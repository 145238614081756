import { set } from 'date-fns';
import { prepareDate } from 'utils';
import * as Yup from 'yup';
import { errMsg } from './_errorMessages';


const IBAN = require('iban');


export const nullableString = () => Yup
	.string()
	.typeError(errMsg.typeErr.string)
	.nullable()
	.default(null)
	.trim()
	.transform((value, originalValue) => (value) ? String(originalValue).trim() : null);


export const nullableNumber = () => Yup
	.number()
	.typeError(errMsg.typeErr.number)
	.nullable()
	.default(null)
	.transform((value, originalValue) => {
		return (originalValue === '' || originalValue == null) ? null : value
	});


export const nullableDate = () => Yup
	.date()
	.typeError(errMsg.typeErr.date)
	.nullable()
	.default(null)
	.transform((value, originalValue) => (originalValue === '') ? null : value);

export const today = set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });

export const nullableArray = () => Yup
	.array()
	// .typeError('Not array')
	.nullable()
	.default(null)
	.ensure();


export const percentSchema = () => nullableNumber()
	.min(0, errMsg.min.than0)
	.max(100, errMsg.max.than100);


export const ibanSchema = () => nullableString()
	.test('IBAN', errMsg.iban, (value) => IBAN.isValid(value) || !value)
	.required(errMsg.required);


export const phoneNumberSchema = () => nullableString()
	.min(12, errMsg.phone);


export const prepareBeginDate = (date: Date | null | undefined): Date | null => {
	return (date) ? prepareDate(date, { midnight: true }) : null;
}
export const prepareUtcBeginDate = (date: Date | null | undefined): Date | null => {
	return (date) ? prepareDate(date, { midnight: true, utc: true }) : null;
}

export const prepareEndDate = (date: Date | null | undefined): Date | null => {
	return (date) ? prepareDate(date, { midnight: true, add: { days: 1, seconds: -1 } }) : null;
}
export const prepareUtcEndDate = (date: Date | null | undefined): Date | null => {
	return (date) ? prepareDate(date, { midnight: true, utc: true, add: { days: 1, seconds: -1 } }) : null;
}
