import React from 'react';
import { muiEsasStyled } from 'app/theme';
import { SxProps, Theme } from '@mui/material/styles';


interface IAppTableHeadProps extends React.ComponentProps<'thead'> {
	sx?: SxProps<Theme>;
};
const AppTableHead: React.FC<IAppTableHeadProps> = muiEsasStyled('thead', {
	// Перечисляем пропсы, которые не будут отображаться атрибутами в DOM
	shouldForwardProp: (prop) => (
		prop !== 'sx'
	),
	name: 'AppTableHead'
})<IAppTableHeadProps>((props) => {
	const {
		theme,
	} = props;

	const general_sx: any = {
		display: 'table-header-group',
		borderBottom: theme.border.primary,
		borderColor: theme.color.tertiary,
	};

	return [
		general_sx,
	];
});

export { AppTableHead };
