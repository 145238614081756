import React, { useState } from 'react';
import {
	Box,
	Collapse,
	Stack,
	Typography,
} from '@mui/material';
import { AppIcon } from './';
// Types
import { SxProps, Theme } from '@mui/system';


interface IAppExpandableBlockProps {
	title:     React.ReactNode;
	disabled?: boolean;
	sx?:       SxProps<Theme>;
};
const AppExpandableBlock: React.FC<IAppExpandableBlockProps> = ({ title, disabled, sx = [], children }) => {
	const [isExpanded, setExpanded] = useState<boolean>(true);

	const titleClickHandler = (event: React.MouseEvent) => {
		if (disabled) return;
		event.stopPropagation();
		setExpanded((prev) => !prev);
	}

	return (
		<Box sx={[ ...Array.isArray(sx) ? sx : [sx] ]}>
			<Stack
				onClick={titleClickHandler}
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				spacing={4}
				sx={(theme) => ({
					padding: `${theme.spacing(2)} 0`,	
					cursor: (disabled) ? 'default' : 'pointer',
					'& > p': {
						font: theme.font.body.md,
						color: theme.color[(disabled) ? 'secondary' : 'primary'],
					},
					'& > svg': {
						width: theme.spacing(6),
						height: theme.spacing(6),
						color: theme.color[(disabled) ? 'tertiary' : 'secondary'],
						transition: 'color 0.3s, transform 0.3s',
						transform: `rotate(${(isExpanded && !disabled) ? '180deg' : 0})`,
					},
					'&:hover': {
						'& > svg': {
							color: (disabled) ? theme.color.secondary : theme.color.epos.hover,
						},
					}
				})}
			>
				<Typography
					sx={(theme) => ({
						font: theme.font.body.md,
						color: theme.color.primary,
					})}
				>
					{title}
				</Typography>

				<AppIcon iconType="down"/>
			</Stack>

			<Collapse in={isExpanded}>
				{children}
			</Collapse>
		</Box>
	);
}


export { AppExpandableBlock };
