import React from 'react';
import {
	Avatar, AvatarProps,
} from '@mui/material';


interface IAppAvatarProps extends AvatarProps {
	size?: 'small' | 'medium' | 'large';
}
const AppAvatar: React.FC<IAppAvatarProps> = ({ children, src, size = 'medium', sx = [] }) => {
	const sizes = {
		'small':  20,
		'medium': 40,
		'large':  60,
	};

	return (
		<Avatar
			src={src}
			sx={[
				{
					width: `${sizes[size]}px`,
					height: `${sizes[size]}px`,
					background: 'rgba(217, 225, 238, 1.0)', 
				},
				...(Array.isArray(sx) ? sx : [sx])
			]}
		>
			{(children) || (
				<svg
					height="57.5%"
					viewBox="0 0 20 23"
					fill="none"
					stroke="#0B1F35"
					strokeOpacity="0.6"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path d="M19 22.0625C19 19.6756 18.0518 17.3864 16.364 15.6985C14.6761 14.0107 12.3869 13.0625 10 13.0625C7.61305 13.0625 5.32387 14.0107 3.63604 15.6985C1.94821 17.3864 1 19.6756 1 22.0625"/>
					<circle cx="10" cy="6" r="5.5"/>
				</svg>
			)}
		</Avatar>
	);
}

export { AppAvatar };
