import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { currencyRateSchema } from 'app/yup';
// Types
import {
	IModuleState,
	startAsyncCall,
	catchAsyncError
} from 'reduxStore/reduxTypes';
import {
	IPaged,
	ICurrencyRate,
} from 'apis/cabinetApi/extraTypes';


const currencyRatesInitialState: IModuleState<ICurrencyRate> = {
	listLoading:    false,
	actionLoading:  false,
	entityLoading:  false,
	records:        [],
	total:          0, 
	edittingEntity: currencyRateSchema.getDefault(),
	lastError:      ''
};


const currencyRatesSlice = createSlice({
	name: 'currencyRates',
	initialState: currencyRatesInitialState,

	reducers: {
		startCall: startAsyncCall,
		catchError: catchAsyncError,

		setInitEdittingEntity: (state) => {
			state.edittingEntity = currencyRateSchema.getDefault();
			state.entityLoading = false;
		},

		getCurrencyRatesAllFulfilled: (state, action: PayloadAction<IPaged<ICurrencyRate>>) => {
			const { records, total } = action.payload;
			state.records = records ?? [];
			state.total = total;
			state.listLoading = false;
		},
		getCurrencyRateByIdFulfilled: (state, action: PayloadAction<ICurrencyRate>) => {
			const currencyInfo = action.payload;
			state.entityLoading = false;
			state.edittingEntity = currencyInfo;
		},
		currencyRateActionFulfilled: (state) => {
			state.actionLoading = false;
		}

	}
});


const currencyRatesActions = currencyRatesSlice.actions;
export { currencyRatesSlice, currencyRatesActions };
