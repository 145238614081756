import React from 'react';
import {
	Grid,
	Stack,
	Typography,
} from '@mui/material';
import { sxBlockMainText } from './epos/_sxProps';
// Types
import { SxProps, Theme } from '@mui/system';


interface IAppDataListItem {
	key:   string;
	value: React.ReactNode;
};
interface IAppDataListProps {
	data:  Array<IAppDataListItem>;
	ratio?: [11, 1] | [10, 2] | [9, 3] | [8, 4] | [7, 5] | [6, 6] | [5, 7] | [4, 8] | [3, 9] | [2, 10] | [1, 11];
	sx?: SxProps<Theme>;
};
const AppDataList: React.FC<IAppDataListProps> = ({ data, sx = [], ratio = [6, 6] }) => {

	return (
		<Stack
			component="ul"
			spacing={4}
			sx={[
				(theme) => ({
					margin: 0,
					padding: `${theme.spacing(1)} 0`,
				}),
				...(Array.isArray(sx) ? sx : [sx])
			]}
		>
			{data.map(({ key, value }) => (
				<Grid
					key={key}
					component="li"
					container
					flexWrap="nowrap"
					justifyContent="space-between"
					alignItems="center"
					columnGap={4}
				>
					<Grid
						component={Typography}
						item
						xs={ratio[0]}
						sx={(theme) => ({
							flexGrow: 0,
							flexShrink: 0,
							font: theme.font.small.md,
							color: theme.color.secondary,
							overflow: 'hidden'
						})}
					>
						{key}
					</Grid>

					<Grid
						item
						xs={ratio[1]}
						sx={(theme) => ({
							flexGrow: 0,
							flexShrink: 0,
							font: theme.font.small.md,
							color: theme.color.primary,
							verticalAlign: 'millde',
							overflow: 'hidden'
						})}
					>
						{value}
					</Grid>
				</Grid>
			))}
		</Stack>
	);
}


interface IAppDescrListProps {
	ratio?: [11, 1] | [10, 2] | [9, 3] | [8, 4] | [7, 5] | [6, 6] | [5, 7] | [4, 8] | [3, 9] | [2, 10] | [1, 11];
	ySpacing?: number;
	sx?: SxProps<Theme>;
};
const AppDescrList: React.FC<IAppDescrListProps> = ({ ratio = [6, 6], ySpacing = 6, sx = [], children }) => {
	return (
		<Grid
			component="dl"
			container
			flexWrap="wrap"
			justifyContent="space-between"
			alignItems="center"
			sx={[
				(theme) => ({
					margin: 0,
					padding: `${theme.spacing(1)} 0`,
					// Стили для key
					'& > dt': {
						minWidth: `calc(${ratio[0] / 12 * 100}% - ${theme.spacing(1)})`,
						maxWidth: `calc(${ratio[0] / 12 * 100}% - ${theme.spacing(1)})`,
						flexBasis: `calc(${ratio[0] / 12 * 100}% - ${theme.spacing(1)})`,
						'&:not(:last-of-type)': {
							marginBottom: theme.spacing(ySpacing),
						}
					},
					// Стили для value
					'& > dd': {
						minWidth: `calc(${ratio[1] / 12 * 100}% - ${theme.spacing(1)})`,
						maxWidth: `calc(${ratio[1] / 12 * 100}% - ${theme.spacing(1)})`,
						flexBasis: `calc(${ratio[1] / 12 * 100}% - ${theme.spacing(1)})`,
						'&:not(:last-of-type)': {
							marginBottom: theme.spacing(ySpacing),
						}
					}
				}),
				...(Array.isArray(sx) ? sx : [sx])
			]}
		>
			{children}
		</Grid>
	);
}


interface IAppDataListItemProps {
	req?: boolean;
	sx?: SxProps<Theme>;
};
const AppDescrTerm: React.FC<IAppDataListItemProps> = ({ req, sx = [], children }) => {
	return (
		<Grid
			component="dt"
			item
			sx={[
				sxBlockMainText,
				(theme) => ({
					color: theme.color.secondary,
				}),
				...(Array.isArray(sx) ? sx : [sx])
			]}
		>
			{children}{(req) ? ' *' : ''}
		</Grid>
	);
}


const AppDescrDefinition: React.FC<IAppDataListItemProps> = ({ sx = [], children }) => {
	const ifEmpty = '-';
	
	return (
		<Grid
			component="dd"
			item
			sx={[
				sxBlockMainText,
				...(Array.isArray(sx) ? sx : [sx])
			]}
		>
			{children || ifEmpty}
		</Grid>
	);
}


export {
	AppDataList,
	AppDescrList,
	AppDescrTerm,
	AppDescrDefinition,
};
export type { IAppDataListItem };
