import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { styled } from '@mui/material';


const StyledLink = styled(Link)(({theme}) => ({
	font: theme.font.small.md,
	color: theme.color.primary,
	transition: 'color 0.3s',
	'&:hover': {
		color: theme.color.epos.primary,
	}
}));

interface IFooterLinkProps extends LinkProps {}
const FooterLink: React.FC<IFooterLinkProps> = ({ children, to }) => {
	return (
		<StyledLink to={to}>
			{children}
		</StyledLink>
	);
}

export { FooterLink };
