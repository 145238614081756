import React from 'react';
import { extend } from 'lodash';
import { useThrottle } from 'hooks';
import { muiEsasStyled } from 'app/theme';
import { InputUnstyled, InputUnstyledProps } from '@mui/base';
import { AppIcon } from './AppIcon';
// Types
import { SxProps, Theme } from '@mui/material/styles';
import { useField, useFormikContext } from 'formik';


type IAppSearchFieldBaseProps = InputUnstyledProps & {
	name: string;
	fullWidth?: boolean;
	submitDelay?: number;
};
const AppSearchFieldBase: React.FC<IAppSearchFieldBaseProps> = (props) => {
	const { name, onChange, submitDelay, ...other } = props;
	const { handleSubmit } = useFormikContext();
	const [formikField, formikMeta, formikHelpers] = useField(name);


	const runWithDelay = useThrottle(submitDelay || 1500);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const value = event.target.value;
		formikHelpers.setValue(value);
		if (value)  runWithDelay(handleSubmit);
		if (!value) handleSubmit();
	}


	return (
		<InputUnstyled
			component="label"
			startAdornment={<AppIcon iconType="search" sx={{ color: 'inherit' }}/>}
			componentsProps={{
				input: {
					type: 'search'
				}
			}}

			name={name}
			value={formikField.value ?? ''}
			onChange={handleChange}
			{...other}
		/>
	);
}


type IAppSearchFieldProps = IAppSearchFieldBaseProps & {
	sx?: SxProps<Theme>;
};
const AppSearchField: React.FC<IAppSearchFieldProps> = muiEsasStyled(AppSearchFieldBase, {
	// Перечисляем пропсы, которые не будут отображаться атрибутами в DOM
	shouldForwardProp: (prop) => (
		prop !== 'sx' && prop !== 'fullWidth'
	),
	name: 'AppSearchField'
})<IAppSearchFieldProps>((props) => {
	const {
		theme,
		fullWidth = false,
	} = props;

	// Определяем стили в зависимости от пропсов для [sx] 
	return extend({
		position: 'relative',
		display: 'flex',
		flexWrap: 'nowrap',
		jusTifyContent: 'flex-start',
		alignItems: 'center',
		font: theme.font.small.md,
		border: theme.border.primary,
		borderColor: theme.color.tertiary,
		borderRadius: theme.radius.x4,
		transition: `0.3s`,
		'& > input': {
			width: '100%',
			margin: 0,
			paddingTop: theme.spacing(3),
			paddingBottom: theme.spacing(3),
			// Иконка спозиционирована абсолютно, отступ от икноки
			paddingLeft: theme.spacing(9),
			paddingRight: theme.spacing(2),
			font: theme.font.small.md,
			color: theme.color.primary,
			background: 'none',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			border: 'none',
			outline: 'none',
			':focus': {
				border: 'none',
				outline: 'none',
			},
			'::placeholder': {
				font: theme.font.small.md,
				color: theme.color.secondary,
			}
		},
		'& > svg': {
			position: 'absolute',
			marginLeft: '8px',
			width: theme.spacing(5),
			height: theme.spacing(5),
			color: theme.color.secondary,
		},

		'&.Mui-disabled': {
			background: theme.color.bg_02,
			'& > input::placeholder': {
				color: theme.color.tertiary,
			},
			'& > svg': {
				color: theme.color.tertiary,
			},
		},

		'&.Mui-focused': {
			borderColor: theme.color.epos.notification,
			boxShadow: `inset 0 0 1px 1px ${theme.color.epos.notification}`,
		},
		'&:hover': {
			borderColor: theme.color.epos.notification,
		},
	},
	(fullWidth === true) && {
		width: '100%'
	}) as any;
});


AppSearchField.displayName = 'AppSearchField';
export { AppSearchField };
