import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useLayoutUIContext } from 'app/providers';
import { useDirectoryCRUD } from 'hooks';

import {
	Stack,
	Grid,
} from '@mui/material';

import { AppContainer, GetMobileAppModal } from 'app/components';
import { AppHeader } from './AppHeader';
import { AppFooter } from './AppFooter';
import { AppAsideMenu } from './AppAsideMenu';
import { AppConfirmModal } from './AppConfirmModal';
import { AppScrollToTop } from 'app/components/AppScrollToTop';


const Layout: React.FC = () => {
	const { isAsideMenuVisible } = useLayoutUIContext();
	const { fetchDirectory } = useDirectoryCRUD();

	// При монтировании лэйаута - загрузить справочники
	useEffect(() => {
		console.log('get directory');
		fetchDirectory();
	}, []);

	return (<>
		<AppHeader/>
		
		<AppContainer>
			<Stack
				direction="row"
				justifyContent="space-between"
				spacing={5}
				sx={(theme) => ({
					paddingTop: theme.spacing(10),
					paddingBottom: theme.spacing(22),
				})}
			>
				{(isAsideMenuVisible) && (
					<Grid
						component="aside"
						item
						flexGrow={0}
						flexShrink={0}
						sx={(theme) => ({
							flexGrow: 0,
							flexShrink: 0,
							flexBasis: 'auto'
						})}
					>
						<AppAsideMenu/>
					</Grid>
				)}

				<Grid
					component="main"
					item
					sx={(theme) => ({
						// Если main 860px, как в дизайне
						// maxWidth: theme.spacing(215),
						margin: '0 auto',
						flexGrow: 1,
						flexShrink: 1,
						flexBasis: '100%',
						// Ломает select DatePicker
						overflowX: 'auto',
						overflowY: 'hidden',
					})}
				>
					<Outlet/>
				</Grid>
			</Stack>
		</AppContainer>
		
		<AppFooter/>

		{/* Модальное окно подтверждения patch/delete запросов */}
		<AppConfirmModal/>

		<GetMobileAppModal/>

		<AppScrollToTop/>
	</>);
}

export { Layout };
