import React from 'react';
import { muiEsasStyled } from 'app/theme';
import { SxProps, Theme } from '@mui/material/styles';


interface IAppTableProps extends React.ComponentProps<'table'> {
	sx?: SxProps<Theme>;
};
const AppTable: React.FC<IAppTableProps> = muiEsasStyled('table', {
	// Перечисляем пропсы, которые не будут отображаться атрибутами в DOM
	shouldForwardProp: (prop) => (
		prop !== 'sx'
	),
	name: 'AppTable'
})<IAppTableProps>((props) => {
	const {
		theme
	} = props;

	const general_sx: any = {
		display: 'table',
    width: '100%',
    borderCollapse: 'collapse',
    borderSpacing: 0,
	};

	return [
		general_sx,
	];
});

export { AppTable };
