import React, { useCallback, useEffect, useState } from 'react';
import { useField } from 'formik';
import {
	AutocompleteChangeReason,
} from '@mui/material';
// Types
import { IBank } from 'apis/cabinetApi/extraTypes';
import { AppAsyncAutocomplete } from '../AppAsyncAutocomplete';
import { eposCabinetApiCalls } from 'apis/cabinetApi/cabinetApiCalls';


interface IBicAutocompleteProps {
	bankName: string;
	setBankCodeDisabled: any;
};
const BicAutocomplete: React.FC<IBicAutocompleteProps> = ({ bankName, setBankCodeDisabled }) => {
	const [bankField, bankMeta, { setValue }] = useField<IBank>(bankName);
	const [bicField, bikMeta] = useField(`${bankName}.bic`);
	const [codeField, codeMeta] = useField(`${bankName}.code`);

	const [autocompleteValue, setAutocompleteValue] = useState<IBank | null>(null);

	const autocompleteChangeHandler = useCallback((value: IBank | null, reason: AutocompleteChangeReason) => {
		setAutocompleteValue(value);
		if (value) {
			setBankCodeDisabled(true);
			setValue(value);
		} else {
			setBankCodeDisabled(false);
			setValue({
				address: {
					city: null,
					line1: null
				},
				bic: null,
				code: null,
				name: null
			});
		}
	}, [setValue, setBankCodeDisabled]);

	// Установка init значения автокомплита
	useEffect(() => {
		const initAutocompleteValue = (bankMeta.initialValue?.bic)
			? bankMeta.initialValue
			: null;
		setAutocompleteValue(initAutocompleteValue);
	}, [bikMeta.initialValue]);

	// Если bank.code был пришел извне, дизейблим поле ввода bank.code
	useEffect(() => {
		if (codeMeta.initialValue) {
			setBankCodeDisabled(true);
		} else {
			setBankCodeDisabled(false);
		}
	}, [codeMeta.initialValue]);


	return (
		<AppAsyncAutocomplete
			name={`${bankName}.bic`}
			autocompleteValue={autocompleteValue}
			onAutocompleteChange={autocompleteChangeHandler}
			getOptionsAsync={(bic, count) => {
				return eposCabinetApiCalls.getBanksAll({
					skip: 0,
					count,
					filter: { bic }
				});
			}}
			getOptionLabel={(option: IBank) => `(${option.bic}) ${option.name}`}
			freeSolo // Позволяет оставлять значение инпута как value
			maxOptionsLength={100}
		/>
	);
}


BicAutocomplete.displayName = 'BicAutocomplete';
export { BicAutocomplete };
