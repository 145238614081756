import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isValid, parseISO, set, setDate } from 'date-fns';
import { enGB, ru } from 'date-fns/locale'
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { AppTextField, IAppTextFieldProps } from './AppTextField';
import { useField, useFormikContext } from 'formik';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import NumberFormat, { InputAttributes } from 'react-number-format';
import { useThrottle } from 'hooks';


type TAppNumberFormatProps = {
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
};
const AppNumberFormat = React.forwardRef<
	NumberFormat<InputAttributes>,
	TAppNumberFormatProps
>(function PhoneNumberFormat(props, ref) {
	const { onChange, ...other } = props;



	return (
		<NumberFormat
			{...other}
			getInputRef={ref}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			isNumericString
			// thousandSeparator=" "
			// decimalSeparator=","
			// decimalScale={decimalScale}
			// fixedDecimalScale
			allowNegative={false}
			// suffix={suffix}
			format="##.##.####" // "dd.MM.yyyy"
		/>
	);
});


type AppDateField = {
	name:            string;
	placeholder?:    string;
	minToday?:       boolean;
	submitOnSelect?: boolean;
	utc?:            boolean;
	disabled?:       boolean;
};
const AppDateField: React.FC<AppDateField> = ({name, placeholder, minToday, submitOnSelect, utc, disabled}) => {
	const [formikField, formikMeta, formikHelpers] = useField(name);
	const { i18n } = useTranslation();

	const selected = (formikField.value) ? new Date(formikField.value) : null;
	const locale = useMemo(() => {
		return (i18n.language === 'en') ? enGB : ru;
	}, [i18n.language])

	const minDate = useMemo(() => {
		return (minToday) ? set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }) : null;
	}, [minToday]);

	const { handleSubmit } = useFormikContext();

	const runWithDelay = useThrottle(200);
	const handleChange = (date: Date): void => {
		formikHelpers.setValue(date);
	}

	const handleSelect = (date: Date, event: React.ChangeEvent<HTMLInputElement>): void => {
		const value = event.target.value;
		formikHelpers.setValue(date);
		if (submitOnSelect) {
			if (value)  runWithDelay(handleSubmit);
			if (!value) handleSubmit();
		}
	}


	return (
		<DatePicker
			name={formikField.name}
			// value как inputValue, заменяется на selected, преобразовывается через format
			selected={selected}
			dateFormat="dd.MM.yyyy"
			onChange={handleChange}
			onSelect={handleSelect}
			onBlur={formikField.onBlur}
			disabled={disabled}
			customInput={(
				<AppTextField
					name={formikField.name}
					inputProps={{ autoComplete: 'off' }}
				/>
			)}

			locale={locale}
			maxDate={new Date('2100-01-10T00:00:00')}
			minDate={minDate ?? new Date('2000-01-10T00:00:00')}

			placeholderText={placeholder}
		/>
	);
}


AppDateField.defaultProps = {
	minToday: false,
	submitOnSelect: false
};

AppDateField.displayName = 'AppDateField';
export { AppDateField };


//* dateInAirUTC | Доступен на оплату с даты
//? DatePicker
// input value   09.06.2022
// casted value  Tue Sep 06 2022 00:00:00 GMT+0300 (Москва, стандартное время) Date
// payload value 2022-09-05T21:00:00.000Z
// saved value   2022-09-05T21:00:00

//? native
// input value   2022-09-06
// casted value  Tue Sep 06 2022 00:00:00 GMT+0300 (Москва, стандартное время) Date
// payload value 2022-09-05T21:00:00.000Z
// saved value   2022-09-05T21:00:00
