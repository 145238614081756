import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	Box,
	Stack,
	Typography,
} from '@mui/material';
import {
	AppButton,
	AppNavLink,
} from 'app/components';
import { useAuth } from 'hooks';


const AuthErrorScreen: React.FC = () => {
	const { t } = useTranslation(undefined, { keyPrefix: 'errors' });

	const { accessError, logOutUser } = useAuth();


	return (
		<Stack
			justifyContent="center"
			alignItems="center"
			spacing={4}
			sx={{
				position: 'relative',
				minHeight: '100vh',
			}}
		>
			<Typography
				sx={(theme) => ({
					padding: '0.5rem 1.5rem',
					color: theme.color.epos.notification,
					fontSize: '5rem',
					fontWeight: 500,
					border: `0.5rem solid ${theme.color.epos.notification}`,
					borderRadius: '3rem',

				})}
			>
				{t(`${accessError}.title`)}
			</Typography>

			<Typography
				sx={(theme) => ({
					padding: '2rem',
					color: theme.color.primary,
					font: theme.font.header.md,
					borderRadius: '2rem',
					backdropFilter: "blur(5px)",
					transform: 'translateY(-6rem)',
					boxShadow: theme.depth.primary
				})}
			>
				{t(`${accessError}.descr`)}
			</Typography>

			{(accessError === '401' || accessError === '403') && (
				<AppButton onClick={logOutUser}>{t(`${accessError}.action`)}</AppButton>
			)}
		</Stack>
	);
}
AuthErrorScreen.displayName = 'AuthErrorScreen';


export { AuthErrorScreen };
