import * as Yup from 'yup';
import { prepareDate } from 'utils';
import { errMsg } from './_errorMessages';
import {
	nullableNumber, 
	nullableDate,
	prepareBeginDate,
	prepareEndDate,
} from './_shortcuts';
import { IGetReportServiceProviderParams } from 'apis/cabinetApi/extraTypes';


export const reportParamsSchema: Yup.ObjectSchema<IGetReportServiceProviderParams> = Yup.object({
	//*	Поставщик услуг
	'id': nullableNumber()
		.required(errMsg.required),

	//*	Доступен на оплату с даты
	'utcBeginDate': nullableDate()
		.default(prepareDate(null))
		.required(errMsg.required)
		.transform(prepareBeginDate),

	//*	Доступен на оплату с даты
	'utcEndDate': nullableDate()
		.default(prepareDate(null, { add: { months: 1 } }))
		.required(errMsg.required)
		.transform(prepareEndDate),

	'sendByEmail': Yup.boolean()
		.default(true),

	emails: Yup.array() // <EmailsMultiField/>
		.of(Yup.string()
			.email(errMsg.email)
			.required(errMsg.required)
		)
		.min(1, errMsg.required),
});
