import * as Yup from 'yup';
import { errMsg } from './_errorMessages';
import {
	nullableNumber,
	nullableString,
} from './_shortcuts';
// Types
import { IUserInfo, EUserRoles } from 'apis/cabinetApi/extraTypes';


export const userInfoSchema: Yup.ObjectSchema<IUserInfo> = Yup.object({
	//*	Роль
	'role': nullableString() // <Select/>
		.oneOf([EUserRoles.sp, EUserRoles.aggregator])
		.default(EUserRoles.sp) 
		.required(errMsg.required),

	//*	Организация (* для ПУ)
	'serviceProviderId': nullableNumber() // <Autocomplete/>
		.when('role', {
			is: EUserRoles.sp, 
			then: (schema) => schema
				.required(errMsg.required),
		}),

	person: Yup.object().shape({
	//*	ФИО *
		'lastName': nullableString() // <FullNameTextField/>
			.required(errMsg.required),
		'firstName': nullableString(),
		'middleName': nullableString(),
	}),

	loginInfo: Yup.object().shape({
	//*	Email *
		'email': nullableString() // <TextField/>
			.email(errMsg.email)
			.required(errMsg.required),
	}),

	//*	Владелец
	'isOwner': Yup.boolean() // <Switch/>
		.default(false),
	//*	Активен
	'isActive': Yup.boolean() // <Switch/>
		.default(true)
});
