import * as Yup from 'yup';
import { errMsg } from './_errorMessages';
import {
	nullableString,
	nullableArray,
} from './_shortcuts';
import { contactSchema } from './contactSchema';
import { notifyParamsSchema } from './notifyParamsSchema';
import { cashBoxSchema } from './cashBoxSchema';


export const retailOutletSchema = () => Yup.object().shape({
	//* Признак активности РТТ
	'state': Yup // <Select/>
		.number()
		.oneOf([0, 1, 2])
		.default(1),

	retailOutletMerchantInfo: Yup.object().shape({
//!		РТТ
	//*	Наименование торговца (EN) *
		'storeName': nullableString() // <TextField/>
			.required(errMsg.required),
	//*	Наименование торговца (RU) *
		'storeLocalityName': nullableString() // <TextField/>
			.required(errMsg.required),
	//* Локаль *
		'localityCode': nullableString() // <Select/>
			.required(errMsg.required),

//!		Адрес
	//*	Город (EN) *
		'storeCity': nullableString() // <TextField/>
			.required(errMsg.required),
		'storeLocalityCity': nullableString()
	}),

	address: Yup.object().shape({
	//*	Город (RU) *
		'city': nullableString() // <TextField/>
			.required(errMsg.required),
	//*	Улица, номер дома *
		'line1': nullableString()
			.required(errMsg.required),
	//*	Код страны (2 симв.) *
		'country': nullableString(),
	//*	Почтовый индекс
		'postalCode': nullableString()
	}),

//!		Категория торговой точки
	mcc: Yup.object().shape({
	//*	Код категории
		'code': nullableString()
			.required(errMsg.required),
	}).nullable().default(null),

//!		Дополнительные параметры
	//*	Примечание
	'descr': nullableString(),

	businessCard: Yup.object().shape({
//!		Контакты
		contacts: nullableArray()
			.of(contactSchema())
	}).nullable().default(null),

//!		Уведомления
	notifyParams: notifyParamsSchema(),

//!		Кассовые места
	cashBoxes: nullableArray()
		.of(cashBoxSchema())
});
