import React from 'react';
import { extend } from 'lodash';
import { muiEsasStyled } from 'app/theme';

import SelectUnstyled, {
	SelectUnstyledProps,
} from '@mui/base/SelectUnstyled';
import { AppSelectControl } from './AppSelectControl';
import { AppSelectListbox } from './AppSelectListbox';
// Types
import { ISxAsProp } from 'app/theme';
import { AppSelectPopper } from './AppSelectPopper';


interface IAppSelectProps extends SelectUnstyledProps<{}>, ISxAsProp {};
const StyledAppSelect: React.FC<IAppSelectProps> = muiEsasStyled(SelectUnstyled, {
	name: 'AppSelect'
})<IAppSelectProps>(({ theme, sx = [] }) => {
	return extend(
		{

		},
		...Array.isArray(sx) ? sx : [sx]
	);
});


const AppSelect = React.forwardRef(function AppSelectBase(
  { components, componentsProps, ...props }: IAppSelectProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
	return (
		<StyledAppSelect
			components={{
				Root: AppSelectControl,
				Listbox: AppSelectListbox,
				Popper: AppSelectPopper,
				...components
			}}
			componentsProps={{
				popper: {
					modifiers: [
						{
							name: 'offset',
							options: {
								offset: [0, 4],
							},
						},
					]
				},
				...componentsProps
			}}
			{...props}
			name="sfdf"
			ref={ref}
		/>
	);
})


// const AppSelect: React.FC<IAppSelectProps> = muiEsasStyled(AppSelectBase, {
// 	name: 'AppSelect'
// })<IAppSelectProps>(({ theme, sx = [] }) => {
// 	return extend(
// 		{

// 		},
// 		...Array.isArray(sx) ? sx : [sx]
// 	);
// });


// const AppSelect: React.FC<IAppSelectProps> = ({ components, componentsProps, ...props }) => {
// 	return (
// 		<StyledAppSelect
// 			components={{
// 				Root: AppSelectControl,
// 				Listbox: AppSelectListbox,
// 				Popper: AppSelectPopper,
// 				...components
// 			}}
// 			componentsProps={{
// 				popper: {
// 					modifiers: [
// 						{
// 							name: 'offset',
// 							options: {
// 								offset: [0, 4],
// 							},
// 						},
// 					]
// 				},
// 				...componentsProps
// 			}}
// 			defaultValue
// 			{...props}
// 		/>
// 	);
// }
AppSelect.displayName = 'AppSelect'; 


export type { IAppSelectProps };
export { AppSelect };
