import React from 'react';
import { muiEsasStyled } from 'app/theme';
import { SxProps, Theme } from '@mui/material/styles';


interface IAppTableHeaderCellProps extends React.ComponentProps<'th'> {
	sx?: SxProps<Theme>;
};
const AppTableHeaderCell: React.FC<IAppTableHeaderCellProps> = muiEsasStyled('th', {
	// Перечисляем пропсы, которые не будут отображаться атрибутами в DOM
	shouldForwardProp: (prop) => (
		prop !== 'sx'
	),
	name: 'AppTableHeaderCell'
})<IAppTableHeaderCellProps>((props) => {
	const { theme } = props;

	// Определяем стили в зависимости от пропсов для [sx]
	const general_sx: any = {
		padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
		// если высота менее 40px, perfect scrollbar показывает вертикальный скролл
		height: theme.spacing(10),
		font: theme.font.small.sm,
		fontWeight: 500,
		color: theme.color.primary,
		whiteSpace: 'nowrap',
		textAlign: 'left',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	};

	return [
		general_sx,
	];
});

export { AppTableHeaderCell };
