import React from 'react';
import { NavLink } from 'react-router-dom';
import {
	Box,
	Stack,
	Typography,
} from '@mui/material';
import {
	AppIcon,
} from 'app/components';
// Types
import { To } from 'react-router-dom';
import { IAppIconProps } from 'app/components';


interface IAsideMenuItemProps {
	iconType?: IAppIconProps['iconType'];
	to:        To;
	text:      string;
};
const AsideMenuItem: React.FC<IAsideMenuItemProps> = ({ iconType, to, text }) => {
	return (
		<Box
			component="li"
			sx={(theme) => ({
				borderRadius: theme.radius.x1
			})}
		>
			<Stack
				component={NavLink}
				to={to}
				direction="row"
				justifyContent="flex-start"
				alignItems="center"
				sx={(theme) => ({
					minHeight: theme.spacing(10),
					padding: `${theme.spacing(2)} 0`,
					font: theme.font.body.lg,
					color: theme.color.primary,
					transition: 'color 0.3s',
					'&:hover': {
						color: theme.color.epos.hover,
					},
					'&.active': {
						color: theme.color.epos.primary,
					}
				})}
			>
				{(iconType) && (
					<AppIcon 
						iconType={iconType}
						sx={(theme) => ({
							width: theme.spacing(5),
							height: theme.spacing(5),
						})}
					/>
				)}

				<Typography
					component="span"
					sx={(theme) => ({
						marginLeft: (iconType) ? theme.spacing(4) : theme.spacing(9),
						font: 'inherit',
						color: 'inherit',
					})}
				>
					{text}
				</Typography>
			</Stack>
		</Box>
	);
}

export { AsideMenuItem };
