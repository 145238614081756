import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
	Box,
	Collapse,
	Stack,
	Typography,
} from '@mui/material';
import {
	AppIcon,
} from 'app/components';
// Types
import { To } from 'react-router-dom';
import { IAppIconProps } from 'app/components';


interface IAsideMenuSublistProps {
	iconType?: IAppIconProps['iconType'];
	to:        To;
	text:      string;
};
const AsideMenuSublist: React.FC<IAsideMenuSublistProps> = ({ iconType, to, text, children }) => {
	const { pathname } = useLocation();
	const isSublistActive = pathname.includes(String(to));
	
	const [isExpanded, setExpanded] = useState<boolean>(isSublistActive);

	useEffect(() => {
		setExpanded(isSublistActive);
	}, [isSublistActive]);

	const handleItemClick = (): void => {
		if (children) {
			setExpanded((prev) => !prev);
		}
	}


	return (
		<Box
			component="li"
			sx={(theme) => ({
				borderRadius: theme.radius.x1
			})}
		>
			<Stack
				className={(isSublistActive) ? 'active' : ''}
				onClick={handleItemClick}
				direction="row"
				justifyContent="flex-start"
				alignItems="center"
				sx={(theme) => ({
					height: theme.spacing(10),
					padding: `${theme.spacing(2)} 0`,
					font: theme.font.body.lg,
					color: theme.color.primary,
					transition: 'color 0.3s',
					'&:hover': {
						color: theme.color.epos.hover,
					},
					'&.active': {
						color: theme.color.epos.primary,
					},
					cursor: 'pointer',
				})}
			>
				{(iconType) && (
					<AppIcon 
						iconType={iconType}
						sx={(theme) => ({
							width: theme.spacing(5),
							height: theme.spacing(5),
						})}
					/>
				)}

				<Typography
					component="span"
					sx={(theme) => ({
						marginLeft: (iconType) ? theme.spacing(4) : theme.spacing(9),
						font: 'inherit',
						color: 'inherit',
					})}
				>
					{text}
				</Typography>

				<AppIcon 
					iconType="down"
					sx={(theme) => ({
						width: theme.spacing(5),
						height: theme.spacing(5),
						marginLeft: theme.spacing(4),
						transform: `rotate(${(isExpanded) ? '180deg' : ''})`,
						transition: 'color 0.3s, transform 0.3s',
					})}
				/>
			</Stack>

			{(children) && (
				<Collapse
					in={isExpanded}
				>
					<Stack
						component="ul"
						direction="column"
						spacing={2}
						sx={(theme) => ({
							margin: `${theme.spacing(2)} 0`,
							padding: 0,
							listStyleType: 'none',
						})}
					>

					{children}
					</Stack>
				</Collapse>
			)}
		</Box>
	);
}

export { AsideMenuSublist };
