import { useCallback } from 'react';
import { useAppDispatch, useAppSelector, shallowEqual } from 'hooks';
import * as directoryThunkActions from './directoryThunkActions';
// Types
import { IUseDirectoryCRUDReturn } from '../types';


const useDirectoryCRUD = (): IUseDirectoryCRUDReturn => {
	const dispatch = useAppDispatch();

	const {
		MCCs,
		currencies,
		locales,
		// banks,
		reports,
		// users
	} = useAppSelector((store) => store.directory, shallowEqual);

	const fetchDirectory = useCallback((): Promise<void> => {
		return dispatch(directoryThunkActions.fetchDirectory());
	}, []);

	return {
		MCCs,
		currencies,
		locales,
		// banks,
		reports,
		// users,

		fetchDirectory
	};
}


export { useDirectoryCRUD };
