import * as Yup from 'yup';
import { errMsg } from './_errorMessages';
import {
	nullableString,
	nullableDate,
	nullableArray,
} from './_shortcuts';
import { accountSchema } from './accountSchema';
import { serviceSchema } from './serviceSchema';
import { retailOutletSchema } from './retailOutletSchema';
import { contactSchema } from './contactSchema';
import { notifyParamsSchema } from './notifyParamsSchema';
import { reportSchema } from './reportSchema';
import { serviceProviderIIISchema } from './serviceProviderIIISchema';


export const serviceProviderSchema = Yup.object().shape({
	'code': Yup.number(),
//!   Информация об организации
	//* Признак активности ПУ
	'state': Yup // <Select/>
		.number()
		.oneOf([0, 1, 2])
		.default(1),
	legalInfo: Yup.object().shape({
	//* Организация *
		'name': nullableString() // <TextField/>
			.required(errMsg.required),
	//* Краткое наименование *
		'shortName': nullableString() // <TextField/>
			.required(errMsg.required),
		'unp': nullableString() // <TextField/>
	//* УНП *
			.length(9, errMsg.length.unp)
			.required(errMsg.required),
	//* ОКПО
		'okpo': nullableString(), // <TextField/>
	//* Форма собственности *
		'type': Yup.number() // <Select/>
			.typeError(errMsg.typeErr.number)
			.oneOf([10, 20])
			.default(10)
			.required(errMsg.required),

//!   Юридический адрес
		address: Yup.object().shape({
	//* Город *
			'city': nullableString() // <TextField/>
				.required(errMsg.required),
	//* Улица, номер дома *
			'line1': nullableString() // <TextField/>
				.required(errMsg.required),
		}),

//!   Банковские реквизиты
//!   Адрес банка
		account: accountSchema()
	}),

//!   Почтовый адрес
	businessCard: Yup.object().shape({
		postAddress: Yup.object().shape({
	//* Город
			'city': nullableString(), // <TextField/>
	//* Улица, номер дома
			'line1': nullableString(), // <TextField/>
		}),

		contacts: nullableArray().of(contactSchema())
	}).nullable(),

//!   Договор
	contract: Yup.object().shape({
	//* Номер
		'num': nullableString(), // <TextField/>
	//* Дата подписания акта
		'signActDate': nullableDate(),
		// 'signActDate': Yup.mixed()
		// 	.transform((date) => {
		// 		if (date && date instanceof Date) {
		// 			const dateStr = date.toLocaleDateString();
		// 			console.log(dateStr);
		// 			return dateStr
		// 		}
		// 		return date;
		// 	}), // <DateField/>
	//* Дата заключения
		'beginDate': nullableDate(), // <DateField/>
	//* Дата расторжения
		'endDate': nullableDate(), // <DateField/>
	}).nullable(),

//!   Данные для QR-кода
	merchant: Yup.object().shape({
	//* Наименование торговца (EN)
		'merchantName': nullableString() // <TextField/>
			.required(errMsg.required),
	//* Наименование торговца (RUS)
		'merchantLocalityName': nullableString() // <TextField/>
			.required(errMsg.required),
	//* Локаль
		'localityCode': nullableString(), // <Select/>
	//*	Признак работы с мобильными терминалами EPOS
		'isMobile': Yup.boolean()
	}).nullable(),

//!   Дополнительные параметры
	//* Доступные валюты для выставления счетов *
	currencies: nullableArray() // <MultiplySelect/>
		.of(Yup.object().shape({
			'code': Yup.number()
		}))
		.min(1, errMsg.min.more1),

		//* Код производителя услуг в системе лояльности
	'loyalityCode': nullableString(), // <TextField/>
	//* Разрешить платежи с помощью биометрии
	'canBiometryPay': Yup.boolean(), // <Switch/>


//! 	Перечень услуг
	services: nullableArray() // <TabPanel/>
		.of(serviceSchema()),

//! 	Торговые точки
	retailOutlets: nullableArray() // <TabPanel/>
		.of(retailOutletSchema()),

//! 	Контактная информация в businessCard.contacts

//! 	Уведомления
	notifyParams: notifyParamsSchema(), // <TabPanel/>

//! 	Отчеты
	reports: nullableArray() // <TabPanel/>
		.of(reportSchema()),

//!		III
	iiiInfo: serviceProviderIIISchema() // <TabPanel/>
});
