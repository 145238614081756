import React from 'react';
import { extend } from 'lodash';
import { muiEsasStyled } from 'app/theme';
import OptionUnstyled, {
	OptionUnstyledProps,
	optionUnstyledClasses,
} from '@mui/base/OptionUnstyled';
// Types
import { SxProps, Theme } from '@mui/material/styles';


interface IAppSelectOptionProps extends OptionUnstyledProps<any> {
	sx?: SxProps<Theme>;
}
const AppSelectOption: React.FC<IAppSelectOptionProps> = muiEsasStyled(OptionUnstyled, {
	// Перечисляем пропсы, которые не будут отображаться атрибутами в DOM
	shouldForwardProp: (prop) => (
		prop !== 'sx'
	),
	name: 'AppSelectOption'
})<IAppSelectOptionProps>((props) => {
	const {
		theme,
	} = props;

	return extend({
		padding: theme.spacing(4),
		font: theme.font.small.md,
		color: theme.color.primary,
		listStyle: 'none',
		cursor: 'pointer',
		transition: 'color 0.3s, background 0.3s',
		'&:hover': {
			background: theme.color.quaternary,
		},
		[`&.${optionUnstyledClasses.selected}`]: {
			color: theme.color.epos.primary,
		},
		// [`&.${optionUnstyledClasses.disabled}`]: {
		// 	color: theme.color.tertiary,
		// },
	});
});


export { AppSelectOption };
