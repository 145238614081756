import { useCallback } from 'react';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { notistack } from 'app/providers';
import { useAppDispatch, useAppSelector, shallowEqual } from 'hooks';
import { eposCabinetApiCalls } from 'apis/cabinetApi/cabinetApiCalls';
import { usersActions } from './usersSlice';
// Types
import { TThunkAction, EApiCallTypes } from 'reduxStore';
import { IGetUsersAllParams, IUserInfo } from 'apis/cabinetApi/extraTypes';
import { IUseUsersCRUDReturn } from '../types';


const useUsersCRUD = (): IUseUsersCRUDReturn => {
	const { t } = useTranslation(undefined, { keyPrefix: 'schemas.UserInfo.NOTICES' });
	const usersState = useAppSelector((store) => store.users, shallowEqual);
	const appDispatch = useAppDispatch();

	const getUsersForTable = useCallback<IUseUsersCRUDReturn['getUsersForTable']>((params) => {
		const thunkAction = (params: IGetUsersAllParams): TThunkAction<Promise<void>> => async (dispatch) => {
			const callType = EApiCallTypes.list;
			dispatch(usersActions.startCall({ callType }));
			const response = await eposCabinetApiCalls.getUsersAll(params);
			if (response.ok && response.data) {
				dispatch(usersActions.getUsersAllFulfilled(response.data));
			} else {
				dispatch(usersActions.catchError({ callType, errorMessage: response.error }));
				notistack.error(response.error);
			}
		}
		
		return appDispatch(thunkAction(params));
	}, [appDispatch]);

	const getUserFullData = useCallback<IUseUsersCRUDReturn['getUserFullData']>((id) => {
		const thunkAction = (id: string): TThunkAction<Promise<void>> => async (dispatch) => {
			const callType = EApiCallTypes.entity;
			dispatch(usersActions.startCall({ callType }));
			const response = await eposCabinetApiCalls.getUserById(id);
			if (response.ok && response.data) {
				dispatch(usersActions.getUserByIdFulfilled(response.data));
			} else {
				dispatch(usersActions.catchError({ callType, errorMessage: response.error }));
				notistack.error(response.error);
			}
		}
		
		return appDispatch(thunkAction(id));
	}, [appDispatch]);

	const setInitUser = useCallback<IUseUsersCRUDReturn['setInitUser']>(() => {
		appDispatch(usersActions.setInitEdittingEntity());
	}, [appDispatch]);

	const saveUser = useCallback<IUseUsersCRUDReturn['saveUser']>((values) => {
		const thunkAction = (values: IUserInfo): TThunkAction<Promise<boolean>> => async (dispatch) => {
			const callType = EApiCallTypes.action;
			dispatch(usersActions.startCall({ callType }));
			const id = values.id;
			if (id) {
				const response = await eposCabinetApiCalls.updateUser(id, values);
				if (response.ok && response.data) {
					dispatch(usersActions.saveUserFulfilled(response.data));
					notistack.success(t('UPDATE_OK'));
					return true;
				} else {
					dispatch(usersActions.catchError({ callType, errorMessage: response.error }));
					notistack.error(response.error);
				}
			} else {
				const response = await eposCabinetApiCalls.createUser(values);
				if (response.ok && response.data) {
					dispatch(usersActions.saveUserFulfilled(response.data));
					notistack.success(t('CREATE_OK'));
					return true;
				} else {
					dispatch(usersActions.catchError({ callType, errorMessage: response.error }));
					notistack.error(response.error);
				}
			}
			return false;
		}
		
		return appDispatch(thunkAction(values));
	}, [appDispatch]);

	const changeUserIsActive = useCallback<IUseUsersCRUDReturn['changeUserIsActive']>((id, isActive) => {
		const thunkAction = (id: string): TThunkAction<Promise<void>> => async (dispatch, getState) => {
			const callType = EApiCallTypes.action;
			dispatch(usersActions.startCall({ callType }));

			const { edittingEntity, records } = getState().users;
			// records readOnly, потому для изменения state клонирую
			const changedRecords = cloneDeep(records);
			(changedRecords.find((user) => user.id === id) ?? edittingEntity).isActive = isActive;

			if (isActive === true) {
				const response = await eposCabinetApiCalls.enableUser(id);
				if (response.ok) {
					dispatch(usersActions.changeUserStateFulfilled({ changedRecords }));
					notistack.success(t('ENABLE_OK'));
				} else {
					dispatch(usersActions.catchError({ callType, errorMessage: response.error }));
					notistack.error(response.error);
				}
			}

			if (isActive === false) {
				const response = await eposCabinetApiCalls.disableUser(id);
				if (response.ok) {
					dispatch(usersActions.changeUserStateFulfilled({ changedRecords }));
					notistack.success(t('DISABLE_OK'));
				} else {
					dispatch(usersActions.catchError({ callType, errorMessage: response.error }));
					notistack.error(response.error);
				}
			}
		}
		
		return appDispatch(thunkAction(id));
	}, [appDispatch]);

	const toggleUserIsOwner = useCallback<IUseUsersCRUDReturn['toggleUserIsOwner']>((id, isOwner) => {
		const thunkAction = (id: string): TThunkAction<Promise<void>> => async (dispatch, getState) => {
			const callType = EApiCallTypes.action;
			dispatch(usersActions.startCall({ callType }));

			const { edittingEntity, records } = getState().users;
			// records readOnly, потому для изменения state клонирую
			const changedRecords = cloneDeep(records);
			(changedRecords.find((user) => user.id === id) ?? edittingEntity).isOwner = isOwner;

			const response = await eposCabinetApiCalls.changeUserOwner(id, isOwner);
			if (response.ok) {
				dispatch(usersActions.changeUserStateFulfilled({ changedRecords }));
				notistack.success(t('OWNER_OK'));
			} else {
				dispatch(usersActions.catchError({ callType, errorMessage: response.error }));
				notistack.error(response.error);
			}
		}
		
		return appDispatch(thunkAction(id));
	}, [appDispatch]);


	return {
		...usersState,

		getUsersForTable,
		getUserFullData,
		setInitUser,
		saveUser,
		changeUserIsActive,
		toggleUserIsOwner
	};
}

export { useUsersCRUD };
