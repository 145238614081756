import React from 'react';
import { muiEsasStyled } from 'app/theme';
import { ButtonUnstyled, ButtonUnstyledProps } from '@mui/base';
import {
	Stack,
} from '@mui/material';
import {
	AppIcon,
} from './';
// Types
import { SxProps, Theme } from '@mui/material/styles';
import { TIconType } from './';
import { extend } from 'lodash';


interface IAppButtonBaseProps extends ButtonUnstyledProps {
	iconType?: TIconType;
};
const AppButtonBase: React.FC<IAppButtonProps> = ({ children, iconType, type, ...props }) => {
	return (
		<ButtonUnstyled type={type ?? 'button'} {...props}>
			{(iconType) ? (
				<Stack 
					direction="row"
					alignItems="center"
				>
					<AppIcon
						iconType={iconType}
						size="sm"
						sx={(theme) => ({
							// spacing={2} для отступа от текста кнопки
							marginRight: theme.spacing(2),
							color: theme.color.secondary
						})}
					/>
					{children}
				</Stack>
			) : (
				children
			)}
		</ButtonUnstyled>
	);
}


interface IAppButtonProps extends IAppButtonBaseProps {
	variant?: 'main' | 'icon' | 'text' | 'list';
	fullWidth?: boolean;
	size?: 'md' | 'sm';
	sx?: SxProps<Theme>;
};
const AppButton: React.FC<IAppButtonProps> = muiEsasStyled(AppButtonBase, {
	// Перечисляем пропсы, которые не будут отображаться атрибутами в DOM
	shouldForwardProp: (prop) => (
		prop !== 'variant' && prop !== 'fullWidth' && prop !== 'size' && prop !== 'sx'
	),
	name: 'AppButton'
})<IAppButtonProps>((props) => {
	const {
		theme,
		variant = 'main',
		fullWidth = false,
		size = 'md',
		disabled = false,
	} = props;

	// Определяем стили в зависимости от пропсов для [sx] 
	const general_sx = {
		position: 'relative',
		display: 'inline-flex',
		flexShrink: 0,
		height: '100%',
		alignItems: 'center',
		font: theme.font.small.md,
		transition: 'background 0.3s, color 0.3s',
	};

	const sx_variant: any = {
		'main': {
			justifyContent: 'center',
			padding: `${theme.spacing(3)} ${theme.spacing(6)}`,
			color: theme.color.bg_01,
			border: 'none',
			borderRadius: theme.radius.x4,
		},
		'icon': {
			justifyContent: 'center',
			padding: `${parseInt(`${theme.spacing(2)}`) - 1}px ${parseInt(`${theme.spacing(2)}`) - 1}px`,
			background: theme.color.bg_01,
			border: theme.border.primary,
			borderColor: theme.color.tertiary,
			borderRadius: theme.radius.x4,
		},
		'text': {
			justifyContent: 'center',
			padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
			border: 'none',
			borderRadius: theme.radius.x4,
		},
		'list': {
			justifyContent: 'flex-start',
			padding: `${theme.spacing(4)} ${theme.spacing(2)}`,
			border: 'none',
			borderRadius: 0,
		}
	};

	const sx_disabled: any = {
		'false': {
			cursor: 'pointer',
		},
		'true': {
			cursor: 'default',
		}
	};

	const sx_variant_disabled: any = {
		'main_false': {
			background: theme.color.epos.primary,
			'&:hover': {
				background: theme.color.epos.hover,
			},
			'&:active': {
				background: theme.color.epos.click,
			}
		},
		'main_true': {
			background: theme.color.epos.notification,
		},

		'icon_false': {
			color: theme.color.primary,
			'&:hover': {
				color: theme.color.epos.hover,
				'& svg': {
					color: theme.color.epos.hover,
				}
			},
			'&:active': {
				color: theme.color.epos.click,
			},
		},
		'icon_true': {
			color: theme.color.secondary,
			'& svg': {
				color: theme.color.tertiary,
			}
		},

		'text_false': {
			color: theme.color.epos.primary,
			background: theme.color.bg_01,
			'&:hover': {
				color: theme.color.bg_01,
				background: theme.color.epos.notification,
			},
			'&:active': {
				color: theme.color.bg_01,
				background: theme.color.epos.primary,
			}
		},
		'text_true': {
			color: theme.color.secondary,
			background: theme.color.tertiary,
		},

		'list_false': {
			color: theme.color.primary,
			background: theme.color.bg_01,
			'&:hover': {
				background: theme.color.quaternary,
			},
			'&:active': {
				background: theme.color.tertiary,
			}
		},
		'list_true': {
			color: theme.color.secondary,
			background: theme.color.tertiary,
		}
	};

	const sx_size: any = {
		'sm': {
			paddingTop: theme.spacing(2),
			paddingBottom: theme.spacing(2)
		}
	};

	const sx_fullWidth: any = {
		'true': {
			width: '100%'
		},
		'false': {},
	};

	return extend(
		general_sx,
		sx_variant[`${variant}`],
		sx_disabled[`${disabled}`],
		sx_variant_disabled[`${variant}_${disabled}`],
		sx_size[`${size}`],
		sx_fullWidth[`${fullWidth}`],
	);
});


interface IAppLinkButtonProps extends React.ComponentProps<'button'> {
	active?: boolean;
	sx?: SxProps<Theme>;
};
const AppLinkButtonBase: React.FC<IAppLinkButtonProps> = muiEsasStyled('button', {
	shouldForwardProp: (prop) => prop !== 'sx' && prop !== 'active',
	name: 'AppLinkButton'
})<IAppLinkButtonProps>(({ theme, active = false, disabled = false }) => extend({
	display: 'inline-block',
	margin: 0,
	padding: 0,
	font: theme.font.body.md,
	color: theme.color.secondary,
	border: 'none',
	background: 'none',
	transition: 'color 0.3s',
}, (active === false) && {
	cursor: 'pointer',
	'&:hover': {
		color: theme.color.epos.hover,
	}
}, (active === true) && {
	color: theme.color.primary,
}, (disabled === true) && {
	color: theme.color.tertiary,
	cursor: 'default',
}));

const AppLinkButton: React.FC<IAppLinkButtonProps> = ({ children, ...props }) => {
	return (
		<AppLinkButtonBase type="button" {...props}>
			{children}
		</AppLinkButtonBase>
	)
}


export { AppButton, AppLinkButton };
