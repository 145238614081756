interface DynamicAppConfig {
	environment: "DEV" | "TST" | "PROD";
	API_URL:     string;
	AUTH_URL:    string;
	CLIENT_ID:   string;
};

const defaultAppConfig: DynamicAppConfig = {
	environment: "DEV",
	API_URL:     "https://api-dev.hgrosh.by",
	AUTH_URL:    "https://ids.iii.by",
	CLIENT_ID:   "epos.cabinet"
}

class GlobalAppConfig {
	appConfig: DynamicAppConfig = defaultAppConfig;
}

export const globalAppConfig = new GlobalAppConfig();

export const globalAppConfigUrl = 'appConfig.json';
