import { Switch, SwitchProps } from '@mui/material';
import { useField } from 'formik';
// Types
import { SxProps, Theme } from '@mui/material';


interface IAppFormSwitchProps extends SwitchProps {
	name: string;
};
const AppFormSwitch: React.FC<IAppFormSwitchProps> = ({ name, ...props }) => {
	const [field, meta, helpers] = useField(name);

	return (
		<Switch
			{...field}
			checked={(field.value == null) ? true : Boolean(field.value)}
			value={(field.value == null) ? true : Boolean(field.value)}
			sx={(theme: any) => ({
				marginLeft: theme.spacing(-3),
				marginRight: theme.spacing(2)
			})}
			{...props}
		/>
	)
}
AppFormSwitch.displayName = 'AppFormSwitch';


interface IAppSwitchProps extends SwitchProps {
	value: any;
	sx?:   SxProps<Theme>;
};
const AppSwitch: React.FC<IAppSwitchProps> = ({ value, sx = [], ...props }) => {
	return (
		<Switch
			checked={(value == null) ? true : Boolean(value)}
			value={(value == null) ? true : Boolean(value)}
			onClick={(event) => {
				event.stopPropagation();
				props.onClick?.(event);
			}}
			sx={[
				(theme: any) => ({
					marginLeft: theme.spacing(-2),
					marginRight: theme.spacing(2)
				}),
				...(Array.isArray(sx) ? sx : [sx])
			]}
			{...props}
		/>
	)
}
AppFormSwitch.displayName = 'AppFormSwitch';


export { AppFormSwitch, AppSwitch };
