import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { AppTextFieldBase } from '../AppTextField';
// Types
import { IPerson } from 'apis/cabinetApi/extraTypes';


// const FullNameTextField: React.FC<IFullNameTextFieldProps> = ({ personName, disabled }) => {
// 	const { t } = useTranslation(undefined, { keyPrefix: 'validation' });

// 	const { setFieldValue } = useFormikContext();
// 	const [{ onBlur }, { touched, error }] = useField(`${personName}.lastName`);
// 	const lastNameValue = useField(`${personName}.lastName`)[0].value;
// 	const firstNameValue = useField(`${personName}.firstName`)[0].value;
// 	const middleNameValue = useField(`${personName}.middleName`)[0].value;

// 	// inputValue в локальном стейте
// 	const [inputValue, setInputValue] = useState<string>('');
// 	const inputChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
// 		setInputValue(event.target.value);
// 	}, []);

// 	// После загрузки сущности и записи в formikValues обновить inputValue
// 	useLayoutEffect(() => {
// 		const safeLastName = lastNameValue?.trim() ?? '';
// 		const safeFirstName = firstNameValue?.trim() ?? '';
// 		const safeMiddleName = middleNameValue?.trim() ?? '';
// 		setInputValue(`${safeLastName} ${safeFirstName} ${safeMiddleName}`.trim());
// 	}, [lastNameValue, firstNameValue, middleNameValue]);

// 	// setFieldValue всех свойств только при потере фокуса
// 	const inputBlurHandler = useCallback((event: React.FocusEvent<any, Element>) => {
// 		const [lastName, firstName, middleName] = inputValue.split(/\s+/).filter(name => name);
// 		const safeLastName = lastName?.trim() ?? '';
// 		const safeFirstName = firstName?.trim() ?? '';
// 		const safeMiddleName = middleName?.trim() ?? '';
// 		// Дополнительно присвоить инпуту затримленные значения
// 		setInputValue(`${safeLastName} ${safeFirstName} ${safeMiddleName}`.trim());

// 		setFieldValue(`${personName}.lastName`, safeLastName);
// 		setFieldValue(`${personName}.firstName`, safeFirstName);
// 		setFieldValue(`${personName}.middleName`, safeMiddleName);
// 		setTimeout(() => onBlur(event), 0);
// 	}, [inputValue, personName, setFieldValue, onBlur]);

// 	const errorMessage = (touched && error) ? t(error) : undefined; 


// 	return (
// 		<TextField
// 			name={`${personName}.lastName`}
// 			value={inputValue}
// 			onBlur={inputBlurHandler}
// 			onChange={inputChangeHandler}
// 			error={touched && Boolean(error)}
// 			helperText={errorMessage}
// 			sx={textFieldSxProp}
// 			disabled={disabled}
// 		/>
// 	);
// }


interface IFullNameTextFieldProps {
	/** name структуры Person в formikValues, свойства которой будет заполнять TextField */
	personName: string;
	disabled?: boolean;
}
const FullNameTextFieldTest: React.FC<IFullNameTextFieldProps> = ({ personName, disabled }) => {
	const { t } = useTranslation(undefined, { keyPrefix: 'validation' });

	const [personField, personMeta, personHelpers] = useField<IPerson>(personName);
	const { value } = personField;
	const { initialValue, error, touched } = personMeta;
	// const [lastNameField, lastNameMeta] = useField<IPerson['lastName']>(`${personName}.lastName`);
	// const [firstNameField, firstNameMeta] = useField<IPerson['firstName']>(`${personName}.firstName`);
	// const [middleNameField, middleNameMeta] = useField<IPerson['middleName']>(`${personName}.middleName`);

	const getInitInputValue = useCallback((person: IPerson | null | undefined): string => {
		const { lastName, firstName, middleName } = person ?? {};
		return [lastName, firstName, middleName].filter(i => i).map(i => i?.trim()).join(' ');
	}, []);
	
	// Установить начальное значение инпута из текущего Person
	// Решение кажется странным, но реализовано так потому, что ФИО в контактах
	// формы ПУ может монтироваться несколько при переходах между режимами редактирования
	// и просмотра карточки контакта
	const initInputValue = getInitInputValue(value);
	const [inputValue, setInputValue] = useState<string>(initInputValue);

	// При изменении formik.initialValues задать инпуту значение последнего Person
	// при первом рендере взять names из value во время инициализации state.
	const isFirstRender = useRef<boolean>(true);
	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
		} else {
			const inputValue = getInitInputValue(initialValue);
			setInputValue(inputValue);
		}
	}, [initialValue]);

	// Обработчик инпута
	const onInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		// Изменить строку: не допускать пробелов в начале и более одного пробела далее
		let nextValue: string = value.replace(/^\s+/, '').replace(/\s+/g, ' ');

		// Если в строке уже три или более набора символов между пробелами,
		// не допускать в значение инпута остоток строки
		const words = nextValue.trim().split(/\s+/);
		if (words.length >= 3) {
			nextValue = words.slice(0, 3).join(' ');
		}
		setInputValue(nextValue);

		// Присвоить имена по порядку в formik.values<IPerson>
		const [lastName, firstName, middleName] = words;
		personHelpers.setValue({
			...personField.value,
			lastName: lastName|| null,
			firstName: firstName || null,
			middleName: middleName || null
		});
	}, [personField.value]);

	// Видимое сообщение ошибки: последняя из ошибок набора имен (обратный порядок)
	const err = error as any;
	const errorValue = err?.middleName || err?.firstName || err?.lastName;
	const errorMessage = (errorValue) ? t(errorValue) : undefined;


	return (
		<AppTextFieldBase
			name={personField.name}
			value={inputValue}
			onBlur={personField.onBlur}
			onChange={onInputChange}

			error={touched && Boolean(error)}
			helperText={touched && errorMessage}
			disabled={disabled}
		/>
	);
} 


// FullNameTextField.displayName = 'FullNameTextField';
// export { FullNameTextField };
export { FullNameTextFieldTest as FullNameTextField };
