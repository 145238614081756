class sessionStorageHelper {
	static setAccessToken = (accessToken: string): void => {
		window.sessionStorage.setItem('accessToken', accessToken);
	};

	static getAccessToken = (): string => {
		return window.sessionStorage.getItem('accessToken') || '';
	};

	static removeAccessToken = (): void => {
		window.sessionStorage.removeItem('accessToken');
	};

}

export { sessionStorageHelper };
