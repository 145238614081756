import React from 'react';
import { extend } from 'lodash';
import { muiEsasStyled } from 'app/theme';
import { SxProps, Theme, alpha } from '@mui/material/styles';


const AppChipBase: React.FC = ({ children, ...props }) => {
	return (
		<div {...props}>
			{children}
		</div>
	);
}


interface IAppChipProps extends React.ComponentProps<'div'> {
	sx?:			SxProps<Theme>;
	size?:		'sm' | 'md' | 'lg';
	status?:	'success' | 'error' | 'default';
};
const AppChip: React.FC<IAppChipProps> = muiEsasStyled(AppChipBase, {
	// Перечисляем пропсы, которые не будут отображаться атрибутами в DOM
	shouldForwardProp: (prop) => (
		prop !== 'sx' && prop !== 'size' && prop !== 'status'
	),
	name: 'AppChip'
})<IAppChipProps>((props) => {
	const {
		theme,
		size = 'md',
		status = 'success',
		sx = []
	} = props;

	return extend({
		textAlign: 'center',
		border: 'none',
		borderRadius: theme.spacing(10),
		userSelect: 'none',
	//===== Size =====
	}, (size === 'sm') && {
		minWidth: theme.spacing(4),
		minHeight: theme.spacing(4),
		padding: theme.spacing(0.5),
		font: theme.font.small.md,
	}, (size === 'md') && {
		minWidth: theme.spacing(6),
		minHeight: theme.spacing(6),
		padding: theme.spacing(1),
		font: theme.font.small.md,
	}, (size === 'lg') && {
		minWidth: theme.spacing(8),
		minHeight: theme.spacing(8),
		padding: theme.spacing(1),
		font: theme.font.body.md,
	//===== status =====
	}, (status === 'default') && {
		color: theme.color.secondary,
		background: alpha(theme.color.secondary || '', 0.2),
	}, (status === 'success') && {
		color: theme.color.status.success,
		background: alpha(theme.color.status.success || '', 0.2),
	}, (status === 'error') && {
		color: theme.color.status.error,
		background: alpha(theme.color.status.error || '', 0.2),
	},
	sx);
});


export { AppChip };
export type { IAppChipProps };
