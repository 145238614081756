/* tslint:disable */
/* eslint-disable */
/**
 * EPOS cabinet API
 * Cabinet API сервиса E-POS
 *
 * OpenAPI spec version: v1
 * Contact: support-api@epos.by
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { BankIEnumerableRecords } from '../models';
import { CurrencyIEnumerableRecords } from '../models';
import { CurrencyRate } from '../models';
import { CurrencyRateRecords } from '../models';
import { ErrorStatus } from '../models';
import { LocaleIEnumerableRecords } from '../models';
import { MCCIEnumerableRecords } from '../models';
import { ReportRecords } from '../models';
/**
 * HelpersApi - axios parameter creator
 * @export
 */
export const HelpersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Возвращает список банков.
         * @param {number} [skip] Количество записей, которые необходимо пропустить.
         * @param {number} [count] Количество записй, которые необходимо вернуть.
         * @param {string} [bic] Шаблон для поиска банка по BIC.
         * @param {string} [iban] Шаблон поиска банка по номеру счета.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1helpersreferencebankHelpersGET: async (skip?: number, count?: number, bic?: string, iban?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/helpers/reference/bank`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            if (bic !== undefined) {
                localVarQueryParameter['bic'] = bic;
            }

            if (iban !== undefined) {
                localVarQueryParameter['iban'] = iban;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Возвращает список доступных валют.
         * @param {number} [skip] Количество записей, которые необходимо пропустить.
         * @param {number} [count] Количество записй, которые необходимо вернуть.
         * @param {number} [code] Код валюты для поиска
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1helpersreferencecurrencyHelpersGET: async (skip?: number, count?: number, code?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/helpers/reference/currency`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение курсов валют
         * @param {number} [skip] Количество записей, которые необходимо пропустить.
         * @param {number} [count] Количество записй, которые необходимо вернуть.
         * @param {Date} [beginDate] 
         * @param {Date} [endDate] 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1helpersreferencecurrencyrateHelpersGET: async (skip?: number, count?: number, beginDate?: Date, endDate?: Date, searchString?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/helpers/reference/currencyrate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            if (beginDate !== undefined) {
                localVarQueryParameter['beginDate'] = (beginDate as any instanceof Date) ?
                    (beginDate as any).toISOString() :
                    beginDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (searchString !== undefined) {
                localVarQueryParameter['searchString'] = searchString;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Добавление курса валюты
         * @param {CurrencyRate} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1helpersreferencecurrencyrateHelpersPOST: async (body?: CurrencyRate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/helpers/reference/currencyrate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers?.['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление курса валюты
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1helpersreferencecurrencyrateidHelpersDELETE: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1helpersreferencecurrencyrateidHelpersDELETE.');
            }
            const localVarPath = `/v1/helpers/reference/currencyrate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение курса валюты
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1helpersreferencecurrencyrateidHelpersGET: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1helpersreferencecurrencyrateidHelpersGET.');
            }
            const localVarPath = `/v1/helpers/reference/currencyrate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Редактирование курса валюты
         * @param {string} id 
         * @param {CurrencyRate} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1helpersreferencecurrencyrateidHelpersPUT: async (id: string, body?: CurrencyRate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1helpersreferencecurrencyrateidHelpersPUT.');
            }
            const localVarPath = `/v1/helpers/reference/currencyrate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers?.['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Возвращает список доступных локалей.
         * @param {number} [skip] Количество записей, которые необходимо пропустить.
         * @param {number} [count] Количество записй, которые необходимо вернуть.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1helpersreferencelanguageHelpersGET: async (skip?: number, count?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/helpers/reference/language`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Возвращает справочник категорий MCC.
         * @param {number} [skip] Количество записей, которые необходимо пропустить.
         * @param {number} [count] Количество записй, которые необходимо вернуть.
         * @param {string} [code] Код MCC для поиска
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1helpersreferencemccHelpersGET: async (skip?: number, count?: number, code?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/helpers/reference/mcc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Возвращает список доступных отчетов.
         * @param {number} [skip] Количество записей, которые необходимо пропустить.
         * @param {number} [count] Количество записй, которые необходимо вернуть.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1helpersreferencereportHelpersGET: async (skip?: number, count?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/helpers/reference/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HelpersApi - functional programming interface
 * @export
 */
export const HelpersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Возвращает список банков.
         * @param {number} [skip] Количество записей, которые необходимо пропустить.
         * @param {number} [count] Количество записй, которые необходимо вернуть.
         * @param {string} [bic] Шаблон для поиска банка по BIC.
         * @param {string} [iban] Шаблон поиска банка по номеру счета.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1helpersreferencebankHelpersGET(skip?: number, count?: number, bic?: string, iban?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<BankIEnumerableRecords>>> {
            const localVarAxiosArgs = await HelpersApiAxiosParamCreator(configuration).v1helpersreferencebankHelpersGET(skip, count, bic, iban, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Возвращает список доступных валют.
         * @param {number} [skip] Количество записей, которые необходимо пропустить.
         * @param {number} [count] Количество записй, которые необходимо вернуть.
         * @param {number} [code] Код валюты для поиска
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1helpersreferencecurrencyHelpersGET(skip?: number, count?: number, code?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<CurrencyIEnumerableRecords>>> {
            const localVarAxiosArgs = await HelpersApiAxiosParamCreator(configuration).v1helpersreferencecurrencyHelpersGET(skip, count, code, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение курсов валют
         * @param {number} [skip] Количество записей, которые необходимо пропустить.
         * @param {number} [count] Количество записй, которые необходимо вернуть.
         * @param {Date} [beginDate] 
         * @param {Date} [endDate] 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1helpersreferencecurrencyrateHelpersGET(skip?: number, count?: number, beginDate?: Date, endDate?: Date, searchString?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<CurrencyRateRecords>>> {
            const localVarAxiosArgs = await HelpersApiAxiosParamCreator(configuration).v1helpersreferencecurrencyrateHelpersGET(skip, count, beginDate, endDate, searchString, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Добавление курса валюты
         * @param {CurrencyRate} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1helpersreferencecurrencyrateHelpersPOST(body?: CurrencyRate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<CurrencyRate>>> {
            const localVarAxiosArgs = await HelpersApiAxiosParamCreator(configuration).v1helpersreferencecurrencyrateHelpersPOST(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Удаление курса валюты
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1helpersreferencecurrencyrateidHelpersDELETE(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ErrorStatus>>> {
            const localVarAxiosArgs = await HelpersApiAxiosParamCreator(configuration).v1helpersreferencecurrencyrateidHelpersDELETE(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение курса валюты
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1helpersreferencecurrencyrateidHelpersGET(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<CurrencyRate>>> {
            const localVarAxiosArgs = await HelpersApiAxiosParamCreator(configuration).v1helpersreferencecurrencyrateidHelpersGET(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Редактирование курса валюты
         * @param {string} id 
         * @param {CurrencyRate} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1helpersreferencecurrencyrateidHelpersPUT(id: string, body?: CurrencyRate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<CurrencyRate>>> {
            const localVarAxiosArgs = await HelpersApiAxiosParamCreator(configuration).v1helpersreferencecurrencyrateidHelpersPUT(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Возвращает список доступных локалей.
         * @param {number} [skip] Количество записей, которые необходимо пропустить.
         * @param {number} [count] Количество записй, которые необходимо вернуть.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1helpersreferencelanguageHelpersGET(skip?: number, count?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<LocaleIEnumerableRecords>>> {
            const localVarAxiosArgs = await HelpersApiAxiosParamCreator(configuration).v1helpersreferencelanguageHelpersGET(skip, count, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Возвращает справочник категорий MCC.
         * @param {number} [skip] Количество записей, которые необходимо пропустить.
         * @param {number} [count] Количество записй, которые необходимо вернуть.
         * @param {string} [code] Код MCC для поиска
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1helpersreferencemccHelpersGET(skip?: number, count?: number, code?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<MCCIEnumerableRecords>>> {
            const localVarAxiosArgs = await HelpersApiAxiosParamCreator(configuration).v1helpersreferencemccHelpersGET(skip, count, code, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Возвращает список доступных отчетов.
         * @param {number} [skip] Количество записей, которые необходимо пропустить.
         * @param {number} [count] Количество записй, которые необходимо вернуть.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1helpersreferencereportHelpersGET(skip?: number, count?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ReportRecords>>> {
            const localVarAxiosArgs = await HelpersApiAxiosParamCreator(configuration).v1helpersreferencereportHelpersGET(skip, count, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * HelpersApi - factory interface
 * @export
 */
export const HelpersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Возвращает список банков.
         * @param {number} [skip] Количество записей, которые необходимо пропустить.
         * @param {number} [count] Количество записй, которые необходимо вернуть.
         * @param {string} [bic] Шаблон для поиска банка по BIC.
         * @param {string} [iban] Шаблон поиска банка по номеру счета.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1helpersreferencebankHelpersGET(skip?: number, count?: number, bic?: string, iban?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<BankIEnumerableRecords>> {
            return HelpersApiFp(configuration).v1helpersreferencebankHelpersGET(skip, count, bic, iban, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Возвращает список доступных валют.
         * @param {number} [skip] Количество записей, которые необходимо пропустить.
         * @param {number} [count] Количество записй, которые необходимо вернуть.
         * @param {number} [code] Код валюты для поиска
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1helpersreferencecurrencyHelpersGET(skip?: number, count?: number, code?: number, options?: AxiosRequestConfig): Promise<AxiosResponse<CurrencyIEnumerableRecords>> {
            return HelpersApiFp(configuration).v1helpersreferencecurrencyHelpersGET(skip, count, code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение курсов валют
         * @param {number} [skip] Количество записей, которые необходимо пропустить.
         * @param {number} [count] Количество записй, которые необходимо вернуть.
         * @param {Date} [beginDate] 
         * @param {Date} [endDate] 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1helpersreferencecurrencyrateHelpersGET(skip?: number, count?: number, beginDate?: Date, endDate?: Date, searchString?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<CurrencyRateRecords>> {
            return HelpersApiFp(configuration).v1helpersreferencecurrencyrateHelpersGET(skip, count, beginDate, endDate, searchString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Добавление курса валюты
         * @param {CurrencyRate} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1helpersreferencecurrencyrateHelpersPOST(body?: CurrencyRate, options?: AxiosRequestConfig): Promise<AxiosResponse<CurrencyRate>> {
            return HelpersApiFp(configuration).v1helpersreferencecurrencyrateHelpersPOST(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удаление курса валюты
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1helpersreferencecurrencyrateidHelpersDELETE(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<ErrorStatus>> {
            return HelpersApiFp(configuration).v1helpersreferencecurrencyrateidHelpersDELETE(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение курса валюты
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1helpersreferencecurrencyrateidHelpersGET(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<CurrencyRate>> {
            return HelpersApiFp(configuration).v1helpersreferencecurrencyrateidHelpersGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Редактирование курса валюты
         * @param {string} id 
         * @param {CurrencyRate} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1helpersreferencecurrencyrateidHelpersPUT(id: string, body?: CurrencyRate, options?: AxiosRequestConfig): Promise<AxiosResponse<CurrencyRate>> {
            return HelpersApiFp(configuration).v1helpersreferencecurrencyrateidHelpersPUT(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Возвращает список доступных локалей.
         * @param {number} [skip] Количество записей, которые необходимо пропустить.
         * @param {number} [count] Количество записй, которые необходимо вернуть.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1helpersreferencelanguageHelpersGET(skip?: number, count?: number, options?: AxiosRequestConfig): Promise<AxiosResponse<LocaleIEnumerableRecords>> {
            return HelpersApiFp(configuration).v1helpersreferencelanguageHelpersGET(skip, count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Возвращает справочник категорий MCC.
         * @param {number} [skip] Количество записей, которые необходимо пропустить.
         * @param {number} [count] Количество записй, которые необходимо вернуть.
         * @param {string} [code] Код MCC для поиска
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1helpersreferencemccHelpersGET(skip?: number, count?: number, code?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<MCCIEnumerableRecords>> {
            return HelpersApiFp(configuration).v1helpersreferencemccHelpersGET(skip, count, code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Возвращает список доступных отчетов.
         * @param {number} [skip] Количество записей, которые необходимо пропустить.
         * @param {number} [count] Количество записй, которые необходимо вернуть.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1helpersreferencereportHelpersGET(skip?: number, count?: number, options?: AxiosRequestConfig): Promise<AxiosResponse<ReportRecords>> {
            return HelpersApiFp(configuration).v1helpersreferencereportHelpersGET(skip, count, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HelpersApi - object-oriented interface
 * @export
 * @class HelpersApi
 * @extends {BaseAPI}
 */
export class HelpersApi extends BaseAPI {
    /**
     * 
     * @summary Возвращает список банков.
     * @param {number} [skip] Количество записей, которые необходимо пропустить.
     * @param {number} [count] Количество записй, которые необходимо вернуть.
     * @param {string} [bic] Шаблон для поиска банка по BIC.
     * @param {string} [iban] Шаблон поиска банка по номеру счета.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpersApi
     */
    public async v1helpersreferencebankHelpersGET(skip?: number, count?: number, bic?: string, iban?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<BankIEnumerableRecords>> {
        return HelpersApiFp(this.configuration).v1helpersreferencebankHelpersGET(skip, count, bic, iban, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Возвращает список доступных валют.
     * @param {number} [skip] Количество записей, которые необходимо пропустить.
     * @param {number} [count] Количество записй, которые необходимо вернуть.
     * @param {number} [code] Код валюты для поиска
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpersApi
     */
    public async v1helpersreferencecurrencyHelpersGET(skip?: number, count?: number, code?: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<CurrencyIEnumerableRecords>> {
        return HelpersApiFp(this.configuration).v1helpersreferencecurrencyHelpersGET(skip, count, code, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Получение курсов валют
     * @param {number} [skip] Количество записей, которые необходимо пропустить.
     * @param {number} [count] Количество записй, которые необходимо вернуть.
     * @param {Date} [beginDate] 
     * @param {Date} [endDate] 
     * @param {string} [searchString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpersApi
     */
    public async v1helpersreferencecurrencyrateHelpersGET(skip?: number, count?: number, beginDate?: Date, endDate?: Date, searchString?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<CurrencyRateRecords>> {
        return HelpersApiFp(this.configuration).v1helpersreferencecurrencyrateHelpersGET(skip, count, beginDate, endDate, searchString, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Добавление курса валюты
     * @param {CurrencyRate} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpersApi
     */
    public async v1helpersreferencecurrencyrateHelpersPOST(body?: CurrencyRate, options?: AxiosRequestConfig) : Promise<AxiosResponse<CurrencyRate>> {
        return HelpersApiFp(this.configuration).v1helpersreferencecurrencyrateHelpersPOST(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Удаление курса валюты
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpersApi
     */
    public async v1helpersreferencecurrencyrateidHelpersDELETE(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<ErrorStatus>> {
        return HelpersApiFp(this.configuration).v1helpersreferencecurrencyrateidHelpersDELETE(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Получение курса валюты
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpersApi
     */
    public async v1helpersreferencecurrencyrateidHelpersGET(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<CurrencyRate>> {
        return HelpersApiFp(this.configuration).v1helpersreferencecurrencyrateidHelpersGET(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Редактирование курса валюты
     * @param {string} id 
     * @param {CurrencyRate} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpersApi
     */
    public async v1helpersreferencecurrencyrateidHelpersPUT(id: string, body?: CurrencyRate, options?: AxiosRequestConfig) : Promise<AxiosResponse<CurrencyRate>> {
        return HelpersApiFp(this.configuration).v1helpersreferencecurrencyrateidHelpersPUT(id, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Возвращает список доступных локалей.
     * @param {number} [skip] Количество записей, которые необходимо пропустить.
     * @param {number} [count] Количество записй, которые необходимо вернуть.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpersApi
     */
    public async v1helpersreferencelanguageHelpersGET(skip?: number, count?: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<LocaleIEnumerableRecords>> {
        return HelpersApiFp(this.configuration).v1helpersreferencelanguageHelpersGET(skip, count, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Возвращает справочник категорий MCC.
     * @param {number} [skip] Количество записей, которые необходимо пропустить.
     * @param {number} [count] Количество записй, которые необходимо вернуть.
     * @param {string} [code] Код MCC для поиска
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpersApi
     */
    public async v1helpersreferencemccHelpersGET(skip?: number, count?: number, code?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<MCCIEnumerableRecords>> {
        return HelpersApiFp(this.configuration).v1helpersreferencemccHelpersGET(skip, count, code, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Возвращает список доступных отчетов.
     * @param {number} [skip] Количество записей, которые необходимо пропустить.
     * @param {number} [count] Количество записй, которые необходимо вернуть.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpersApi
     */
    public async v1helpersreferencereportHelpersGET(skip?: number, count?: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<ReportRecords>> {
        return HelpersApiFp(this.configuration).v1helpersreferencereportHelpersGET(skip, count, options).then((request) => request(this.axios, this.basePath));
    }
}
