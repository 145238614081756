export function getQueryStringParams(query: string) {
	return query
		? (/^[?#]/.test(query) ? query.slice(1) : query)
			.split('&')
			.reduce<Record<string, string>>((params, param) => {
				const [key, value] = param.split('=');
				params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
				return params;
			}, {}
			)
		: {};
}


export function getBaseUrl(): string {
	return document.getElementsByTagName('base')[0].href;
}


export function urlBase64Decode(str: string) {
	let output = str.replace(/-/g, '+').replace(/_/g, '/');
	switch (output.length % 4) {
		case 0:
			break;
		case 2:
			output += '==';
			break;
		case 3:
			output += '=';
			break;
		default:
			throw 'Illegal base64url string!';
	}
	const result = window.atob(output); //polifyll https://github.com/davidchambers/Base64.js
	try{
		return decodeURIComponent(encodeURI(result));
	} catch (err) {
		return result;
	}
}


export function decodeTokenClaims(accessToken: string) {
	if (accessToken === '') return {};

	const tokenPayload = accessToken.split('.')[1];
	const tokenClaims = JSON.parse(urlBase64Decode(tokenPayload));

	return tokenClaims;
}


export function toAbsoluteUrl(pathname: string): string {
	return process.env.PUBLIC_URL + pathname;
}
