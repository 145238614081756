import React from 'react';
import { PopperUnstyled, PopperUnstyledProps } from '@mui/base';
import { AnimatePresence, motion } from 'framer-motion';


const AppSelectPopper: React.FC<PopperUnstyledProps> = (props) => {
	// console.log(props)
	return (
		<AnimatePresence>
			{(props.open) && (
				<motion.div
					key="AppSelectPopper"
					initial={{
						opacity: 0,
						transform: 'scale(0.5)'
					}}
					animate={{
						opacity: 1,
						transform: 'scale(1)',
					}}
					exit={{
						opacity: 0,
						transform: 'scale(0.8)',
					}}
					transition={{
						duration: 0.2,
					}}
				>
					<PopperUnstyled {...props} />
				</motion.div>
			)}
		</AnimatePresence>
	);
}


export { AppSelectPopper };
