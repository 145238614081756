import { useRef, useCallback } from 'react';


type TUseThrottleReturn = (callback: VoidFunction, ...params: Array<any>) => any;

/**
 * Hook для создания функции-троттлера
 * @param {number} delayMs задержка вызова коллбэка в милисекундах
 * @returns функция-декоратор для вызова коллбэка не чаще чем в `delayMs` милисекунд
 */
const useThrottle = (delayMs: number): TUseThrottleReturn => {
	// Создаем реф для метки таймера
	const delayTimer: any = useRef();

	const runCallbackWithThrottle = useCallback((callback, ...params) => {
		// Обнуляем таймер на срабатывание колбэка,
		// если не вышел таймер после срабатывания предыдущего вызова
		clearTimeout(delayTimer.current);
		// Задаем срабатывание колбэка после таймера
		delayTimer.current = setTimeout(() => {
			callback(...params);
		}, delayMs);
	}, []);

	return runCallbackWithThrottle;
}

export { useThrottle };
