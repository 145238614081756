import React, { useMemo } from 'react';
import NumberFormat, { InputAttributes } from 'react-number-format';
import { useDirectoryCRUD } from 'hooks';
import { AppTextField, IAppTextFieldProps } from './';
import { useTranslation } from 'react-i18next';
// Types


type TAppNumberFormatProps = {
	onChange: (event: { target: { name: string; value?: string } }) => void;
	name:      string;
	percent?:  boolean;
	currency?: string;
	integer?:  boolean;
	rate?:     boolean;
	prefix?:   string;
	allowNegative?: boolean;
};
const AppNumberFormat = React.forwardRef<
	NumberFormat<InputAttributes>,
	TAppNumberFormatProps
>(function PhoneNumberFormat(props, ref) {
	const { onChange, percent, currency, integer, rate, prefix, allowNegative, ...other } = props;

	let decimalScale: number = 2;
	if (rate) decimalScale = 4;
	if (percent) decimalScale = 2;
	if (integer) decimalScale = 0;

	let suffix: string = '';
	if (percent) suffix = ` %`;
	if (currency) suffix = ` ${currency}`;


	return (
		<NumberFormat
			{...other}
			getInputRef={ref}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value || undefined,
					},
				});
			}}
			isNumericString
			thousandSeparator=" "
			decimalSeparator=","
			decimalScale={decimalScale}
			fixedDecimalScale
			allowNegative={allowNegative ?? false}
			suffix={suffix}
			prefix={prefix}
		/>
	);
});


type IAppNumberFieldProps = IAppTextFieldProps & {
	percent?:  boolean;
	rate?:     boolean;
	currency?: string | null;
	integer?:  boolean;
	prefix?:   string;
	allowNegative?: boolean;
};
const AppNumberField: React.FC<IAppNumberFieldProps> = (props) => {
	const { percent, rate, currency, integer, prefix, allowNegative, ...other } = props;
	const { currencies } = useDirectoryCRUD();
	const { t } = useTranslation(undefined, { keyPrefix: 'components.AppNumberField' });

	const currencyAbbr = useMemo<string | undefined>(() => {
		if (currency) {
			const currencyAbbr = currencies.find((cur) => String(cur.code) == currency)?.abbr;
			return currencyAbbr ?? '';
		}
		return '';
	}, [percent, currency, currencies]);

	const placeholder = useMemo<string>(() => {
		if (percent) return '%';
		if (currency) {
			return t('currency') + ' ' + currencyAbbr; 
		}
		return '';
	}, [percent, currencyAbbr]);


	return (
		<AppTextField
			InputProps={{
				inputComponent: AppNumberFormat as any,
				autoComplete: 'off'
			}}
			inputProps={{
				percent,
				rate,
				currency: currencyAbbr,
				integer,
				prefix,
				allowNegative,
			}}
			placeholder={props.placeholder ?? placeholder}
			{...other}
		/>
	)
}


AppNumberField.displayName = 'AppNumberField';
export { AppNumberField }; 
