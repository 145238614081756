/* tslint:disable */
/* eslint-disable */
/**
 * EPOS cabinet API
 * Cabinet API сервиса E-POS
 *
 * OpenAPI spec version: v1
 * Contact: support-api@epos.by
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * Состояние счета.  0 - Счет создан (доступен для изменения)  10 - Счет активен (доступен для оплаты)  20 - Счет оплачен  30 - Счет удален  40 - Счет частично оплачен  50 - Счет в состоянии оплаты (недоступен для повторной оплаты)  60 - Счет в состоянии начала оплаты через WebPay  70 - Оплата сверена  80 - Отмена оплаты  -10 - Инвойс сперва был оплачен, но было произведено сторно оплаты  100 - Инвойс создан автоматически, при внешнем роутинге и проверке счета  110 - Счет \"просрочен\"
 * @export
 * @enum {string}
 */
export enum InvoiceStateEnum {
    NUMBER_0 = 0,
    NUMBER_10 = 10,
    NUMBER_20 = 20,
    NUMBER_30 = 30,
    NUMBER_40 = 40,
    NUMBER_50 = 50,
    NUMBER_60 = 60,
    NUMBER_70 = 70,
    NUMBER_80 = 80,
    NUMBER_100 = 100,
    NUMBER_110 = 110,
    NUMBER_MINUS_10 = -10
}

