import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { Layout } from 'app/layout/Layout';
import { Auth } from 'app/auth/Auth';
import { AuthCodePage } from 'app/auth/pages/AuthCodePage';
import { LoginUserPage } from 'app/auth/pages/LoginUserPage';
import { RequiredAuthHOC } from 'app/auth/pages/RequiredAuthHOC';
import { SplashScreenFallback } from 'app/providers';


const Profile = React.lazy(() =>	import('app/modules/profile/Profile'));
const Analytics = React.lazy(() =>	import('app/modules/analytics/Analytics'));
const Invoices = React.lazy(() =>	import('app/modules/invoices/Invoices'));
const ServiceProviders = React.lazy(() =>	import('app/modules/serviceProviders/ServiceProviders'));
const Users = React.lazy(() =>	import('app/modules/users/Users'));
const CurrencyRates = React.lazy(() =>	import('app/modules/currencyRates/CurrencyRates'));

const Error404 = React.lazy(() =>	import('app/modules/errors/pages/Error404'));


const Router: React.FC = () => {
	return (
		<React.Suspense fallback={<SplashScreenFallback/>}>
			<Routes>
				<Route path="/auth" element={<Auth/>}>
					<Route index element={<AuthCodePage/>} />
					<Route path="login" element={<LoginUserPage/>} />
				</Route>

				<Route path="/" element={
					// Если нету токена - редирект на auth/login 
					<RequiredAuthHOC>
						<Layout/>
					</RequiredAuthHOC>
				}>
					<Route index element={<Navigate to="invoices" replace/>} />

					<Route path="profile" element={<Profile/>} />
						<Route path="analytics/*" element={<Analytics/>} />

					<Route path="invoices/*" element={<Invoices/>} />
					<Route path="providers/*" element={<ServiceProviders/>} />
					<Route path="users/*" element={<Users/>} />
					<Route path="currencies/*" element={<CurrencyRates/>} />
				</Route>

				<Route path="*" element={<Error404/>} />
			</Routes>
		</React.Suspense>
	);
}

export { Router };
