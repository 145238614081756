import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// Types
import {
	IMCC,
	ICurrency,
	ILocale,
	IReport,
} from 'apis/cabinetApi/extraTypes';
import { IDirectoryState } from '../types';


const directoryInitialState: IDirectoryState = {
	MCCs:				[],
	currencies:	[],
	locales:		[],
	reports:		[],
};

const directorySlice = createSlice({
	name: 'directory',
	initialState: directoryInitialState,

	reducers: {
		getMCCsAllFulfilled: (state, action: PayloadAction<Array<IMCC>>) => {
			state.MCCs = action.payload;
		},
		getCurrenciesAllFulfilled: (state, action: PayloadAction<Array<ICurrency>>) => {
			state.currencies = action.payload;
		},
		getLocalesAllFulfilled: (state, action: PayloadAction<Array<ILocale>>) => {
			state.locales = action.payload;
		},
		getReportsAllFulfilled: (state, action: PayloadAction<Array<IReport>>) => {
			state.reports = action.payload;
		},

	}
});

const directoryActions = directorySlice.actions;

export { directorySlice, directoryActions };
