import React from 'react';
import { muiEsasStyled } from 'app/theme';
import { SxProps, Theme } from '@mui/material/styles';


interface IAppTableRowProps extends React.ComponentProps<'tr'> {
	selected?: boolean;
	sx?: SxProps<Theme>;
};
const AppTableRow: React.FC<IAppTableRowProps> = muiEsasStyled('tr', {
	// Перечисляем пропсы, которые не будут отображаться атрибутами в DOM
	shouldForwardProp: (prop) => (
		prop !== 'sx' && prop !== 'selected'
	),
	name: 'AppTableRow'
})<IAppTableRowProps>((props) => {
	const {
		theme,
		selected = false,
	} = props;

	const general_sx: any = {
		position: 'relative',
		display: 'table-row',
		color: 'inherit',
		verticalAlign: 'middle',
		outline: 0,
		'&:not(:last-of-type)': {
			borderBottom: theme.border.primary,
			borderColor: theme.color.tertiary,
		}
	};

	const sx_selected: any = {
		'false': {
			background: theme.color.bg_01,
			'&:hover': {
				background: theme.color.bg_02,
			},
		},

		'true': {
			background: theme.color.bg_02,
		}
	}

	return [
		general_sx,
		sx_selected[`${selected}`],
	];
});

export { AppTableRow };
export type { IAppTableRowProps };
