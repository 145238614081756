import React from 'react';

import { 
	AppBar,
	AppContainer,
	Grid,
} from 'app/components';


const FooterBar: React.FC = ({ children }) => {
	return (
		<AppBar
			component="footer"
			position="sticky"
			sx={(theme) => ({
				position: 'absolute',
				top: 'auto',
				bottom: 0,
				color: 'inherit',
				background: theme.color.quaternary,
				boxShadow: 'none',
			})}
		>
			<AppContainer>
				<Grid
					container
					flexWrap="nowrap"
					justifyContent="space-between"
					alignItems="center"
					sx={(theme) => ({
						padding: `${theme.spacing(4)} 0`,
					})}
				>
					{children}
				</Grid>
			</AppContainer>
		</AppBar>
	);
}

export { FooterBar };
