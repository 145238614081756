/* tslint:disable */
/* eslint-disable */
/**
 * EPOS cabinet API
 * Cabinet API сервиса E-POS
 *
 * OpenAPI spec version: v1
 * Contact: support-api@epos.by
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * 
 * @export
 * @enum {string}
 */
export enum ReportAttachFileType {
    NUMBER_10 = 10,
    NUMBER_20 = 20,
    NUMBER_30 = 30,
    NUMBER_40 = 40,
    NUMBER_50 = 50
}

