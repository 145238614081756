import React from 'react';
import {
	Box,
	Stack,
	Typography,
} from '@mui/material';
// Types
import { SxProps, Theme } from '@mui/system';
import { StackProps } from '@mui/material';


interface IAppCardProps extends StackProps {
	sx?: SxProps<Theme>;
};
const AppCardContainer: React.FC<IAppCardProps> = ({ sx = [], children, ...props }) => {
	return (
		<Stack
			sx={[
				(theme) => ({
					background: theme.color.bg_01,
					border: theme.border.primary,
					borderColor: theme.color.tertiary,
					borderRadius: theme.spacing(2),
					overflow: 'hidden',
				}),
				...(Array.isArray(sx) ? sx : [sx])
			]}
			{...props}
		>
			{children}
		</Stack>
	);
}
AppCardContainer.displayName = 'AppCardContainer';


interface IAppCardHeaderProps extends IAppCardProps {
	title: string;
}
const AppCardHeader: React.FC<IAppCardHeaderProps> = ({ sx = [], title, children, ...props }) => {
	return (
		<Stack
			direction="row"
			justifyContent="space-between"
			alignItems="center"
			sx={[
				(theme) => ({
					padding: `${theme.spacing(6)} ${theme.spacing(10)}`,
					background: theme.color.bg_02,
					borderBottom: theme.border.primary,
					borderColor: theme.color.tertiary,
				}),
				...(Array.isArray(sx) ? sx : [sx])
			]}
			{...props}
		>
			<Typography
				component="h3"
				sx={(theme) => ({
					flexGrow: 0,
					flexShrink: 1,
					font: theme.font.body.lg,
					color: theme.color.primary,
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
				})}
			>
				{title}
			</Typography>

			{children}
		</Stack>
	);
}
AppCardHeader.displayName = 'AppCardHeader';


const AppCardContent: React.FC<{sx?: SxProps<Theme>}> = ({ sx = [], children }) => {
	return (
		<Stack
			spacing={3}
			sx={[
				(theme) => ({
					padding: `${theme.spacing(6)} ${theme.spacing(10)}`,
				}),
				...(Array.isArray(sx) ? sx : [sx])
			]}
		>
			{children}
		</Stack>
	);
}
AppCardContent.displayName = 'AppCardContent';


const AppCardFooter: React.FC<IAppCardProps> = ({ sx = [], children, ...props }) => {
	return (
		<Stack
			direction="row"
			justifyContent="space-between"
			alignItems="center"
			sx={[
				(theme) => ({
					padding: `${theme.spacing(6)} ${theme.spacing(10)}`,
					background: theme.color.bg_01,
				}),
				...(Array.isArray(sx) ? sx : [sx])
			]}
			{...props}
		>
			{children}
		</Stack>
	);
}
AppCardFooter.displayName = 'AppCardFooter';


const AppCardActions: React.FC<IAppCardProps> = ({ sx = [], children, ...props }) => {
	return (
		<Stack
			direction="row"
			justifyContent="flex-end"
			alignItems="center"
			spacing={4}
			sx={(theme) => ({
				flexGrow: 1,
				flexShrink: 0,
				margin: `${theme.spacing(-2)} 0`,
			})}
			{...props}
		>
			{children}
		</Stack>
	)
}
AppCardActions.displayName = 'AppCardActions';


export {
	AppCardContainer,
	AppCardHeader,
	AppCardContent,
	AppCardFooter,
	AppCardActions,
};
