import React, { useState, useRef, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
	AppTable,
	AppTableHead,
	AppTableBody,
	AppTableRow,
	AppTableCell,
	AppTableHeaderCell,
	AppTableContainer,
	AppTableExpandableRow,
} from './tableComponents';
// Types
import { SxProps, Theme } from '@mui/material/styles';
import { Skeleton, Typography } from '@mui/material';


interface IAppDataGridColumn {
	field:        string;
	headerName:   string;
	description?: string;
	width?:       number;
	thSx?:        SxProps<Theme>;
	tdSx?:        SxProps<Theme>;
};

interface IAppDataGridRowBase {
	id: string;
	rowMenu?: React.ReactNode;
	rowExpand?: React.ReactNode;
};
interface IAppDataGridRow extends IAppDataGridRowBase {
	[key: string]: React.ReactNode;
};

interface IAppDataGridProps {
	rows:      		Array<IAppDataGridRow>;
	columns:   		Array<IAppDataGridColumn>;
	onRowExpand?: (id: string) => void | Promise<void | boolean>;
	onRowNarrow?: (id: string) => void | Promise<void>;
	isLoading?:		boolean;
};

const AppDataGrid: React.FC<IAppDataGridProps> = (props) => {
	const { rows, columns, onRowExpand, onRowNarrow, isLoading } = props;
	const { t } = useTranslation(undefined, { keyPrefix: 'components.table' });
	
	//* Условия состояний таблицы
	const showSkeleton = Boolean(isLoading);
	const showTable = Boolean(!isLoading && rows.length > 0);
	const showEmpty = Boolean(!isLoading && rows.length === 0);

	//* Количество рядов скелетона. Минимум 1, либо количество рядов прошлого records.length
	const skeletonRowCount = useRef<number>(1);
	useLayoutEffect(() => {
		skeletonRowCount.current = rows.length || 1;
	}, [rows.length]);

	//* Экспанд ряда. Одновременно развернут один ряд. 
	//* Разворачивается после onRowExpand, скрывается после onRowNarrow
	const [expandedRow, setExpandedRow] = useState<string | undefined>(undefined);
	const [expandLoading, setExpandLoading] = useState<string | undefined>(undefined);

	const rowClickHandler = async (event: React.MouseEvent, rowId: string): Promise<void> => {
		event.stopPropagation();
		// Если ряд не развернут, значит запрос еще обрабатывается
		if (expandLoading) return;
		setExpandLoading(rowId);
		if (expandedRow === rowId) {
			await onRowNarrow?.(rowId);
			setExpandedRow(undefined);
		} else {
			const result = await onRowExpand?.(rowId);
			// Если на загрузку данных ошибка - не разворачиваем ряд
			if (result !== false) {
				setExpandedRow(rowId);
			}
		}
		setExpandLoading(undefined);
	}


	return (
		<AppTableContainer>
			<AppTable>
				<AppTableHead>
					<AppTableRow>
						{columns.map(({ field, headerName, width, thSx = [] }) => (
							<AppTableHeaderCell
								key={field}
								sx={[
									// sx для всех заголовков столбцов
									(theme) => ({}),
									// фиксированная ширина столбца выбранного столбца
									(width) ? (theme) => ({
										width: theme.spacing(width),
										minWidth: theme.spacing(width),
										maxWidth: theme.spacing(width),
									}) : {},
									// sx для переопределения стилей выбранного столбца
									...(Array.isArray(thSx) ? thSx : [thSx])
								]}
							>
								{headerName}
							</AppTableHeaderCell>
						))}
					</AppTableRow>
				</AppTableHead>

				<AppTableBody>
					{/* Прелоадер таблицы при первом рендере и загрузке */}
					{(showSkeleton) && [...Array(skeletonRowCount.current)].map((_, index) => (
						<AppTableRow
							key={index}
							id={String(index)}
						>
							{columns.map(({ width = 20 }, index) => (
								<AppTableCell key={index}>
									{(width <= 10) ? (
										<Skeleton
											variant='circular'
											width={width * 3}
											height={width * 3}
											sx={(theme) => ({ background: theme.color.quaternary })}
										/>
									) : (<>
										<Skeleton
											variant={'text'}
											sx={(theme) => ({ background: theme.color.quaternary })}
										/>
										{((index + 1) % 3 !== 0) && (
											<Skeleton
												variant={'text'}
												sx={(theme) => ({
													width: '80%',
													background: theme.color.quaternary
												})}
											/>
										)}
									</>)}
								</AppTableCell>
							))}
						</AppTableRow>
					))}

					{/* Таблица при ненулевом результате */}
					{(showTable) && (
						rows.map((row) => (row.rowExpand) ? (
							<AppTableExpandableRow
								key={row.id}
								rowId={row.id}
								isExpanded={Boolean(expandedRow === row.id)}
								isLoading={Boolean(expandLoading === row.id)}
								expandColSpan={columns.length}
								expandChildren={row.rowExpand}
								onRowClick={rowClickHandler}
							>
								{columns.map(({ field, tdSx = [] }) => (
									<AppTableCell
										key={field}
										sx={[
											(theme) => ({}),
											...(Array.isArray(tdSx) ? tdSx : [tdSx])
										]}
									>
										{row[field]}
									</AppTableCell>
								))}
							</AppTableExpandableRow>
						) : (
							<AppTableRow
								key={row.id}
								id={row.id}
							>
								{columns.map(({ field, tdSx = [] }) => (
									<AppTableCell
										key={field}
										sx={[
											(theme) => ({}),
											...(Array.isArray(tdSx) ? tdSx : [tdSx])
										]}
									>
										{row[field]}
									</AppTableCell>
								))}
							</AppTableRow>
						))
					)}

					{/* Сообщение при нулевом результате */}
					{(showEmpty) && (
						<AppTableRow>
							<AppTableCell colSpan={columns.length}>
								<Typography
									sx={(theme) => ({
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										minHeight: theme.spacing(8),
										font: theme.font.small.md,
										textAlign: 'center'
									})}
								>
									{t('emptyResult')}
								</Typography>
							</AppTableCell>
						</AppTableRow>
					)}
				</AppTableBody>
			</AppTable>
		</AppTableContainer>
	);
}


export { AppDataGrid };
export type {
	IAppDataGridColumn,
	IAppDataGridRow,
	IAppDataGridProps,
};
