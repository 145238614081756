import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// Types
import { ITokenResponse } from 'apis/cabinetApi/extraTypes';


//* persisted reducer, сохраняется между сессиями в local storage. accessToken в session storage
interface IAuthInitialState {
	refreshToken: string;
	idToken:      string;
};
const authInitialState: IAuthInitialState = {
	refreshToken: '',
	idToken:      '',
};

const authSlice = createSlice({
	name: 'auth',
	initialState: authInitialState,
	reducers: {
		//* getTokensWithAuthCode / getTokensWithRefreshToken {status: ok}
		tokensFetched: (state, action: PayloadAction<ITokenResponse>) => {
			const { refreshToken, idToken } = action.payload;
			state.refreshToken = refreshToken || '';
			state.idToken = idToken || '';
		},
		clearAuthState: (state) => {
			state.refreshToken = '';
			state.idToken = '';
		}

	}
});


const authActions = authSlice.actions;
export { authSlice, authActions };
