import React from 'react';
import { muiEsasStyled } from 'app/theme';
import { SxProps, Theme } from '@mui/material/styles';


interface IAppTableBodyProps extends React.ComponentProps<'tbody'> {
	sx?: SxProps<Theme>;
};
const AppTableBody: React.FC<IAppTableBodyProps> = muiEsasStyled('tbody', {
	// Перечисляем пропсы, которые не будут отображаться атрибутами в DOM
	shouldForwardProp: (prop) => (
		prop !== 'sx'
	),
	name: 'AppTableBody'
})<IAppTableBodyProps>((props) => {
	const {
		theme,
	} = props;

	const general_sx: any = {
		display: 'table-row-group',
	};

	return [
		general_sx,
	];
});

export { AppTableBody };
