import { configureStore, Action, ThunkDispatch } from '@reduxjs/toolkit';
import { 
	persistStore, persistReducer,
  FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, // configureStore ignoredActions
} from 'redux-persist'
import storage from 'redux-persist/lib/storage';

import { AnyAction } from 'redux';
import { rootReducer } from './rootReducer';

// redux-persist - синхронизация redux-store с local storage
// https://www.youtube.com/watch?v=sdlYNKjCGU0&t=2s
const persistConfig = {
	key:       'root',
	storage:   storage,
	whitelist: ['auth'],
	// debug:     true,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// При configureStore redux-thunk и redux-dev-tools применяеются по дефолту
const store =  configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}),
});

// @ts-ignores
window.store = store;

const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type TState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type TDispatch = typeof store.dispatch;
// https://redux.js.org/usage/usage-with-typescript#type-checking-redux-thunks
type ThunkAction<
  R, // Return type of the thunk function
  S, // state type used by getState
  E, // any "extra argument" injected into the thunk
  A extends Action // known types of actions that can be dispatched
> = (dispatch: ThunkDispatch<S, E, A>, getState: () => S, extraArgument: E) => R;
export type TThunkAction<ReturnType = void> = ThunkAction<ReturnType, TState, unknown, AnyAction>;

export { store, persistor };
