import * as Yup from 'yup';
import { errMsg } from './_errorMessages';
import {
	nullableString,
	nullableNumber, 
	percentSchema,
	nullableArray,
} from './_shortcuts';
import { accountSchema } from './accountSchema';
import { notifyParamsSchema } from './notifyParamsSchema';


export const serviceSchema = () => Yup.object().shape({
	'id': nullableNumber(),
//!		Услуга
	//*	Наименование услуги*
	'name': nullableString() // <TextField/>
		.required(errMsg.required),
	//*	Состояние услуги
	'state': Yup // <Select/>
		.number()
		.oneOf([0, 1, 2])
		.default(1),

//!		Банковские реквизиты
//!		Адрес банка
	account: accountSchema(),

	comissions: Yup.object().shape({
//!		Комиссия системы "Расчет"
		erip: Yup.object().shape({
	//*	% комиссии / min / max
			'percent': percentSchema(), // <TextField/>
			'minAmount': nullableNumber(), // <TextField/>
			'maxAmount': nullableNumber() // <TextField/>
		}).nullable().default(null),
//!		Комиссия агрегатора
		aggregator: Yup.object().shape({
	//*	% комиссии / min / max
			'percent': percentSchema(), // <TextField/>
			'minAmount': nullableNumber(), // <TextField/>
			'maxAmount': nullableNumber() // <TextField/>
		}).nullable().default(null)
	}).nullable().default(null),

//!		Ограничения
	restrictions: Yup.object().shape({
	//*	Минимальная сумма выставления*
		'minAmount': nullableNumber() // <TextField/>
			.min(0, errMsg.min.than0)
			.required(errMsg.required),
	//*	Максимальная сумма выставления*
		'maxAmount': nullableNumber() // <TextField/>
			.min(0, errMsg.min.than0)
			.required(errMsg.required),
	//*	Доступность сторно
		'aggregatorStorno': Yup
			.boolean()
			.default(false)
	}).nullable().default(null),

//!		Внешний роутинг
	//*	Внешний роутинг
	'requestServiceProvider': Yup // <Switch/>
		.boolean(),
	//*	URL запроса(*)
	'requestUrl': nullableString() // <TextField/>
		.url(errMsg.url)
		.when('requestServiceProvider', {
			is: true,
			then: (schema) => schema
				.required(errMsg.required)
		}),

//!		Уведомления услуги
	notifyParams: notifyParamsSchema(),
});
