import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { historyStates } from 'app/modules/invoices/types';
import { Checkbox, ListItemText } from '@mui/material';
import {
	AppFormSelect,
	MenuItem,
} from 'app/components';
// Types
import { InvoiceStateEnum } from 'apis/cabinetApi/extraTypes';
import { IInvoiceTablesAsProp } from 'app/modules/invoices/types';


const InvoicesStateSelect: React.FC<IInvoiceTablesAsProp> = ({ table }) => {
	const { t } = useTranslation(undefined, { keyPrefix: 'schemas.Invoice' });

	const { submitForm } = useFormikContext();
	const [ formikField ] = useField('states');

	const listStates = Object.keys(InvoiceStateEnum).filter((key) => !isNaN(+key)).map(n => +n);
	const states = (table === 'list') ? listStates : historyStates;


	return (
		<AppFormSelect
			name={formikField.name}
			value={formikField.value ?? []}
			onChange={formikField.onChange}
			multiple
			displayEmpty={true}
			renderValue={() => t('TABLE.states')}
			onClose={submitForm}
			sx={(theme) => ({
				marginLeft: theme.spacing(2),
				width: theme.spacing(23),
				minWidth: theme.spacing(23),
				maxWidth: theme.spacing(23),
				'& [aria-expanded="true"]': {
					background: theme.color.bg_02
				}
			})}

			MenuProps={{
				anchorOrigin: {
					vertical: 'bottom',
					horizontal: 'right',
				},
				transformOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
				sx: (theme) => ({
					'& .MuiList-root': {
						maxHeight: theme.spacing(20 * 4)
					}
				})
			}}
		>
			{states.map((state) => (
				<MenuItem key={state} value={state}>
					<Checkbox checked={Boolean(formikField.value?.includes(state))}/>
					<ListItemText primary={t(`STATES.${InvoiceStateEnum[state ?? 0]}`)}/>
				</MenuItem>
			))}
		</AppFormSelect>
	);
}


export { InvoicesStateSelect };
