import React, { useMemo, useCallback } from 'react';
import { extend } from 'lodash';
import { useTranslation } from 'react-i18next';
import { muiEsasStyled } from 'app/theme';
import { useMatchMedia } from 'hooks';
import { tableCounts } from 'utils';
import {
	Box, BoxProps,
	Stack,
	Typography,
} from '@mui/material';
import {
	SelectUnstyledRootSlotProps,
} from '@mui/base/SelectUnstyled';
import {
	AppIcon,
	// AppSelect,
	// AppSelectOption,
} from '../';
import {
	AppSelect,
	AppSelectOption,
} from 'app/components/AppSelectNew';
// Types
import { ISxAsProp } from 'app/theme';


const AppPaginationPerPageButtonBase = React.forwardRef(function AppPaginationPerPageButtonBase(
	{ children, ownerState, ...props }: SelectUnstyledRootSlotProps<any>,
	ref: React.ForwardedRef<HTMLButtonElement>
) {
	return (
		<button {...props} ref={ref}>
			<Typography
				component="span"
				sx={(theme) => ({
					font: theme.font.body.xs,
					color: theme.color.secondary,
				})}
			>
				{children}
			</Typography>

			<AppIcon
				iconType="down"
				sx={(theme) => ({
					width: theme.spacing(5),
					height: theme.spacing(5),
					color: theme.color.secondary,
					transform: `rotate(${(ownerState.open) ? '180deg' : ''})`,
					transition: 'color 0.3s, transform 0.3s',
				})}
			/>
		</button>
	);
});


interface AppPaginationPerPageButton extends SelectUnstyledRootSlotProps<any>, ISxAsProp {};
const AppPaginationPerPageButton: React.FC<AppPaginationPerPageButton> = muiEsasStyled(AppPaginationPerPageButtonBase, {
	// Перечисляем пропсы, которые не будут отображаться атрибутами в DOM
	shouldForwardProp: (prop) => (
		prop !== 'sx'
	),
	name: 'AppPaginationPerPageButton'
})<AppPaginationPerPageButton>((props) => {
	const {
		theme,
	} = props;

	return extend({
		display: 'inline-flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		minWidth: theme.spacing(20),
		height: theme.spacing(10),
		margin: 0,
		padding: theme.spacing(2.5),
		font: theme.font.body.xs,
		color: theme.color.secondary,
		background: 'none',
		border: theme.border.primary,
		borderColor: theme.color.tertiary,
		borderRadius: theme.spacing(2),
		outline: 'none',
		cursor: 'pointer',
		'&:disabled': {
			color: theme.color.tertiary,
			background: theme.color.quaternary,
			cursor: 'default',
		}
	});
});



interface IAppTablePaginationButtonProps extends React.ComponentProps<'button'>, ISxAsProp {};
const AppTablePaginationButton: React.FC<IAppTablePaginationButtonProps> = muiEsasStyled('button', {
	// Перечисляем пропсы, которые не будут отображаться атрибутами в DOM
	shouldForwardProp: (prop) => (
		prop !== 'sx'
	),
	name: 'AppTablePaginationButton'
})<IAppTablePaginationButtonProps>((props) => {
	const {
		theme,
		disabled = false,
	} = props;

	return extend({
		display: 'inline-flex',
		flexShrink: 0,
		justifyContent: 'center',
		alignItems: 'center',
		minWidth: theme.spacing(4),
		padding: 0,
		font: theme.font.small.md,
		color: theme.color.primary,
		background: 'none',
		border: 'none',
		outline: 'none',
		cursor: 'pointer',
		userSelect: 'none',
		transition: 'color 0.3s',
		'&:hover': {
			color: theme.color.epos.hover,
		}
	}, (disabled === true) && {
		'&:disabled': {
			color: theme.color.secondary,
			cursor: 'default',
			'&:hover': {
				color: theme.color.secondary,
			}
		}
	}) as any;
});


interface IPaginationEllipsisProps extends BoxProps {
	isVisible: boolean;
}
const PaginationEllipsis: React.FC<IPaginationEllipsisProps> = ({ isVisible }) => (
	<Box
		sx={(theme) => ({
			minWidth: theme.spacing(4),
			font: theme.font.small.md,
			color: (isVisible) ? theme.color.secondary : 'transparent',
			textAlign: 'center',
			userSelect: 'none',
		})}
	>
		{'...'}
	</Box>
);


interface IAppTablePaginationProps {
	total: number; // count
	page: number;
	onPageChange: (nextPage: number, rowsPerPage: number) => void;
	rowsPerPage: number;
	onRowsPerPageChange?: (nextRowsPerPage: number) => void;
	rowsPerPageOptions?: Array<number>;
	isLoading?: boolean;
	perPageText?: string;
};
const AppTablePagination: React.FC<IAppTablePaginationProps> = (props) => {
	const {
		total,
		page,
		onPageChange,
		rowsPerPage,
		onRowsPerPageChange,
		rowsPerPageOptions = tableCounts,
		isLoading,
		perPageText
	} = props;

	const keyPrefix = `components.table-pagination`;
	const { t } = useTranslation(undefined, { keyPrefix });

	const { isDesktop } = useMatchMedia();

	function pageChangeHandler (nextPage: number | null): void {
		if (nextPage === null) return;
		onPageChange(nextPage, rowsPerPage);
	}

	const totalPages: number = Math.ceil(total / rowsPerPage);
	const noPages: boolean = (totalPages === 0);
	const allButtonsDisabled: boolean = Boolean(noPages || isLoading);

	const firstPage: number | null = (noPages) ? null : 1;
	const lastPage: number | null = (noPages) ? null : totalPages;

	const prevPage: number | null = (page === firstPage) ? null : page - 1;
	const nextPage: number | null = (page === lastPage)  ? null : page + 1;

	const isCurrentPageFirst: boolean = Boolean(page && page === firstPage);
	const isCurrentPageLast: boolean = Boolean(page && page === lastPage);

	const isLeftEllipsisVisible = Boolean(firstPage && page - 1 > firstPage);
	const isRightEllipsisVisible = Boolean(lastPage && page + 1 < lastPage);

	const fillPagesLinksArray = useCallback((page: number, totalPages: number): Array<number> => {
		let pagesLinks: Array<number> = [];
		for (let i = 0; pagesLinks.length < 3 && pagesLinks.length < totalPages;) {
			const num = page + i;
			if (num > 0 && num <= totalPages) {
				if (num >= page) {
					pagesLinks.push(num);
				} else {
					pagesLinks.unshift(num);
				}
			}
			i = (i >= 0) ? -(i + 1) : -i;
		}
		return pagesLinks;
	}, [page, rowsPerPage]);

	const pagesLinks = useMemo(() => {
		return fillPagesLinksArray(page, totalPages);
	}, [page, rowsPerPage, isLoading]); 


	return (
		<Stack
			direction="row"
			justifyContent="space-between"
			alignItems="center"
			spacing={4}
			sx={(theme) => ({
				width: '100%'
			})}
		>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
			>
				{(onRowsPerPageChange) && (<>
					<Typography
						sx={(theme) => ({
							font: theme.font.small.md,
							color: theme.color.primary,
							marginRight: theme.spacing(4)
						})}
					>
						{perPageText || t('per-page')}
					</Typography>

					<AppSelect
						value={rowsPerPage}
						onChange={onRowsPerPageChange}
						sx={(theme) => ({
							minWidth: theme.spacing(20),
							paddingLeft: theme.spacing(3),
							paddingRight: theme.spacing(2)
						})}
					>
						{rowsPerPageOptions.map((rowsNum) => (
							<AppSelectOption
								key={rowsNum}
								value={rowsNum}
								sx={(theme) => ({
									minWidth: theme.spacing(20),
								})}
							>
								{rowsNum}
							</AppSelectOption>
						))}
					</AppSelect>
				</>)}
			</Stack>

			<Stack
				direction="row"
				justifyContent="flex-end"
				alignItems="center"
				spacing={2}
			>
				<AppTablePaginationButton
					disabled={isCurrentPageFirst || allButtonsDisabled}
					onClick={() => pageChangeHandler(firstPage)}
				>
					{(isDesktop) ? (
						t('first')
					) : (
						<AppIcon iconType="first"/>
					)}
				</AppTablePaginationButton>

				<AppTablePaginationButton
					disabled={isCurrentPageFirst || allButtonsDisabled}
					onClick={() => pageChangeHandler(prevPage)}
				>
					<AppIcon
						iconType="down"
						sx={{ transform: 'rotate(90deg)' }}
					/>
					{(isDesktop) && t('prev')}
				</AppTablePaginationButton>

				<PaginationEllipsis isVisible={isLeftEllipsisVisible}/>

				{(noPages) ? (
					<PaginationEllipsis isVisible={true}/>
				) : (
					pagesLinks.map((pageNumber, index) => (
						<AppTablePaginationButton
							key={index}
							onClick={() => pageChangeHandler(pageNumber)}
							sx={(theme) => ({
								color: (pageNumber === page) ? theme.color.epos.primary : 'inherit'
							})}
							disabled={allButtonsDisabled}
						>
							{pageNumber}
						</AppTablePaginationButton>
					))
				)}

				<PaginationEllipsis isVisible={isRightEllipsisVisible}/>

				<AppTablePaginationButton
					disabled={isCurrentPageLast || allButtonsDisabled}
					onClick={() => pageChangeHandler(nextPage)}
				>
					{(isDesktop) && t('next')}
					<AppIcon
						iconType="down"
						sx={{ transform: 'rotate(270deg)' }}
					/>
				</AppTablePaginationButton>

				<AppTablePaginationButton
					disabled={isCurrentPageLast || allButtonsDisabled}
					onClick={() => pageChangeHandler(lastPage)}
				>
					{(isDesktop) ? (
						t('last')
					) : (
						<AppIcon iconType="last"/>
					)}
				</AppTablePaginationButton>
			</Stack>
		</Stack>
	);
}


AppTablePagination.displayName = 'AppTablePagination';
export { AppTablePagination };
