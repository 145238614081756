import React from 'react';
import classNames from 'classnames';
import { extend } from 'lodash';
import { muiEsasStyled } from 'app/theme';
import { ButtonUnstyled, ButtonUnstyledProps } from '@mui/base';
import { AppIcon, TIconType } from './AppIcon';
// Types
import { SxProps, Theme } from '@mui/material/styles';


interface IAppIconButtonProps extends ButtonUnstyledProps {
	iconType:  TIconType;
	size?:     'sm' | 'md' | 'lg';
	sx?:       SxProps<Theme>;
};
const AppIconButtonStyled: React.FC<IAppIconButtonProps> = muiEsasStyled(ButtonUnstyled, {
	// Перечисляем пропсы, которые не будут отображаться атрибутами в DOM
	shouldForwardProp: (prop) => (
		prop !== 'iconType' && prop!== 'size' && prop!== 'sx'
	),
	name: 'AppIconButton'
})<IAppIconButtonProps>(({ theme, size }) => {

	return extend({ 
		position: 'relative',
		display: 'inline-flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexGrow: 0,
		flexShrink: 0,
		margin: 0,
		padding: 0,
		color: theme.color.secondary,
		background: 'none',
		border: 'none',
		borderRadius: '50%',
		cursor: 'pointer',
		transition: 'background 0.2s, color 0.1s, opacity 0.2s, transform 0.2s',
		'& > svg': {
			zIndex: 0,
			width: '100%',
			height: '100%',
		},
		'&::before': {
			content: '""',
			position: 'absolute',
			background: theme.color.quaternary,
			width: '160%',
			height: '160%',
			borderRadius: '50%',
			transition: 'inherit',
			opacity: 0,
			transform: 'scale(0)',
		},
		'&:hover:not(:disabled)': {
			zIndex: 1,
			color: theme.color.primary,
			'&::before': {
				opacity: 1,
				transform: 'scale(1)',
			}
		},
		'&:active': {
			color: theme.color.epos.click,
		},
		'&:focus-visible': {
			zIndex: 1,
			color: theme.color.primary,
			outline: 'none',
			'&::before': {
				opacity: 1,
				transform: 'scale(1)',	
				border: theme.border.primary,
				borderColor: theme.color.secondary,
			}
		},

		'&:disabled': {
			color: theme.color.tertiary,
			cursor: 'default'
		},

	}, (size === 'lg') && {
		width: theme.spacing(10),
		height: theme.spacing(10),
	}, (size === 'md') && {
		width: theme.spacing(6),
		height: theme.spacing(6),
	}, (size === 'sm') && {
		width: theme.spacing(5),
		height: theme.spacing(5),
	}, []);
});


const AppIconButton: React.FC<IAppIconButtonProps> = (props) => {
	const { iconType, size = 'md', disabled = false, sx = [], ...rest } = props;

	return (
		<AppIconButtonStyled
			{...rest}
			className={classNames(
				'AppIconButton',
				`AppIconButton-size-${size}`,
				{ 'AppIconButton-disabled': disabled }
			)}
			size={size}
			iconType={iconType}
			disabled={disabled}
			sx={[
				(theme) => ({}),
				...Array.isArray(sx) ? sx : [sx]
			]}
		>
			<AppIcon
				iconType={iconType}
				sx={{
					width: '100%',
					height: '100%'
				}}
			/>
		</AppIconButtonStyled>
	)
}


export { AppIconButton };
export type { IAppIconButtonProps };
