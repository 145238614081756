import React from 'react';
import { useTranslation } from 'react-i18next';

import {
	Grid,
	AppIcon,
	Typography,
} from 'app/components';


const EposLogotype: React.FC = () => {
	const keyPrefix = 'layout.header';
	const { t } = useTranslation(undefined, { keyPrefix });

	return (
		<Grid container flexWrap="nowrap">
			<AppIcon
				iconType="logotype"
				sx={(theme) => ({
					fill: theme.color.epos.primary,
					width: theme.spacing(12),
					height: theme.spacing(12),
				})}
			/>
			
			<Grid
				item
				container flexDirection="column" justifyContent="center"
				sx={(theme) => ({
						ml: theme.spacing(4)
					})}
				>
				<Typography
					sx={(theme) => ({
						color: theme.palette.common.black,
						fontSize: '1.4rem',
						lineHeight: '1.2rem',
					})}
				>
					{'epos'}
				</Typography>

				<Typography
					sx={(theme) => ({
						color: 'rgba(91, 94, 103, 1)',
						fontSize: '0.65rem',
						lineHeight: '1rem',
					})}
				>
					{t('logo')}
				</Typography>
			</Grid>
		</Grid>
	);
}

export { EposLogotype };
