import { Checkbox, CheckboxProps, FormControlLabel, FormControlLabelProps } from '@mui/material';
import { Field, useField } from 'formik';


interface IAppFormCheckboxProps {
	name: string;
	label: string;
	disabled?: boolean;
};
const AppFormCheckbox: React.FC<IAppFormCheckboxProps> = ({ name, ...props }) => {
	const [field, meta, helpers] = useField(name);

	return (
		<FormControlLabel
			{...field}
			{...props}
			labelPlacement="end"
			control={
				<Checkbox
					checked={(field.value == null) ? true : Boolean(field.value)}
					value={(field.value == null) ? true : Boolean(field.value)}
				/>
			}
			sx={(theme) => ({
				'& .MuiTypography-root': {
					font: theme.font.small.md
				}
			})}
		/>
	)
}
AppFormCheckbox.displayName = 'AppFormCheckbox';


interface IAppCheckboxProps {
	value: any;
	label: string;
	disabled?: boolean;
};
const AppCheckbox: React.FC<IAppCheckboxProps> = ({ value, ...props }) => {
	return (
		<FormControlLabel
			{...props}
			labelPlacement="end"
			control={
				<Checkbox
					checked={(value == null) ? true : Boolean(value)}
					value={(value == null) ? true : Boolean(value)}
				/>
			}
			sx={(theme) => ({
				'& .MuiTypography-root': {
					font: theme.font.small.md
				}
			})}
		/>
	)
}
AppCheckbox.displayName = 'AppCheckbox';


export { AppFormCheckbox, AppCheckbox };
