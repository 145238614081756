import React from 'react';
import { useDirectoryCRUD } from 'hooks';
import { MenuItem } from '@mui/material';
import { AppFormSelect } from 'app/components';


const LocalesFormSelect: React.FC<{ name: string }> = ({ name }) => {
	const { locales } = useDirectoryCRUD();

	return (
		<AppFormSelect name={name}>
			{locales.map((locale) => (
				<MenuItem key={locale.code} value={locale.code || ''}>
					{locale.name}
				</MenuItem>
			))}
		</AppFormSelect>
	)
}


LocalesFormSelect.displayName = 'LocalesFormSelect';
export { LocalesFormSelect };
