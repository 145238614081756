import React from 'react';
import { extend } from 'lodash';
import { muiEsasStyled } from 'app/theme';
import { alpha } from '@mui/material/styles';
import { AppIcon } from '../AppIcon';
// Types
import { SxProps, Theme } from '@mui/material/styles';
import { TIconType } from '../AppIcon';


interface IAppChipButtonBaseProps {
	iconType?: TIconType;
};
const AppChipButtonBase: React.FC<IAppChipButtonBaseProps> = ({ children, iconType, ...props }) => {
	return (
		<button type="button" {...props}>
			{children}
			{(iconType) && (
				<AppIcon size="xs" iconType={iconType} sx={{ ml: 2 }}/>
			)}
		</button>
	);
}


interface IAppChipButtonProps extends React.ComponentProps<'button'>, IAppChipButtonBaseProps {
	sx?: SxProps<Theme>;
};
const AppChipButton: React.FC<IAppChipButtonProps> = muiEsasStyled(AppChipButtonBase, {
	// Перечисляем пропсы, которые не будут отображаться атрибутами в DOM
	shouldForwardProp: (prop) => (
		prop !== 'sx'
	),
	name: 'AppChip'
})<IAppChipButtonProps>((props) => {
	const { theme, sx = [] } = props;

	return extend({
		display: 'inline-flex',
		padding: `${theme.spacing(1)} ${theme.spacing(4)}`,
		alignItems: 'center',
		
		font: theme.font.small.md,
		color: theme.color.secondary,
		background: theme.color.tertiary,
		border: 'none',
		borderRadius: theme.spacing(10),
		userSelect: 'none',
		cursor: 'pointer',
	//===== Size =====
	// }, (size === 'sm') && {
	// 	minWidth: theme.spacing(4),
	// 	minHeight: theme.spacing(4),
	// 	padding: theme.spacing(0.5),
	// 	font: theme.font.small.md,
	// }, (size === 'md') && {
	// 	minWidth: theme.spacing(6),
	// 	minHeight: theme.spacing(6),
	// 	padding: theme.spacing(1),
	// 	font: theme.font.small.md,
	// }, (size === 'lg') && {
	// 	minWidth: theme.spacing(8),
	// 	minHeight: theme.spacing(8),
	// 	padding: theme.spacing(1),
	// 	font: theme.font.body.md,
	//===== status =====
	// }, (status === 'default') && {
	// 	color: theme.color.secondary,
	// 	background: alpha(theme.color.secondary || '', 0.2),
	// }, (status === 'success') && {
	// 	color: theme.color.status.success,
	// 	background: alpha(theme.color.status.success || '', 0.2),
	// }, (status === 'error') && {
	// 	color: theme.color.status.error,
	// 	background: alpha(theme.color.status.error || '', 0.2),
	},
	sx, []);
});


export { AppChipButton };
export type { IAppChipButtonProps };
