/* tslint:disable */
/* eslint-disable */
/**
 * EPOS cabinet API
 * Cabinet API сервиса E-POS
 *
 * OpenAPI spec version: v1
 * Contact: support-api@epos.by
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ErrorStatus } from '../models';
import { TokenResponse } from '../models';
/**
 * IIIApi - axios parameter creator
 * @export
 */
export const IIIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получение токена доступа по коду авторизации
         * @param {string} authcode 
         * @param {boolean} [testMode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1iiiaccesstokenauthcodeauthcodeIIIGET: async (authcode: string, testMode?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authcode' is not null or undefined
            if (authcode === null || authcode === undefined) {
                throw new RequiredError('authcode','Required parameter authcode was null or undefined when calling v1iiiaccesstokenauthcodeauthcodeIIIGET.');
            }
            const localVarPath = `/v1/iii/accesstoken/authcode/{authcode}`
                .replace(`{${"authcode"}}`, encodeURIComponent(String(authcode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (testMode !== undefined) {
                localVarQueryParameter['testMode'] = testMode;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение токена доступа по refresh-токену
         * @param {string} refreshtoken 
         * @param {boolean} [testMode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1iiiaccesstokenrefreshtokenrefreshtokenIIIGET: async (refreshtoken: string, testMode?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refreshtoken' is not null or undefined
            if (refreshtoken === null || refreshtoken === undefined) {
                throw new RequiredError('refreshtoken','Required parameter refreshtoken was null or undefined when calling v1iiiaccesstokenrefreshtokenrefreshtokenIIIGET.');
            }
            const localVarPath = `/v1/iii/accesstoken/refreshtoken/{refreshtoken}`
                .replace(`{${"refreshtoken"}}`, encodeURIComponent(String(refreshtoken)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (testMode !== undefined) {
                localVarQueryParameter['testMode'] = testMode;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отзыв токена
         * @param {string} [token] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1iiitokenrevokeIIIDELETE: async (token?: string, type?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/iii/token/revoke`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IIIApi - functional programming interface
 * @export
 */
export const IIIApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получение токена доступа по коду авторизации
         * @param {string} authcode 
         * @param {boolean} [testMode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1iiiaccesstokenauthcodeauthcodeIIIGET(authcode: string, testMode?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<TokenResponse>>> {
            const localVarAxiosArgs = await IIIApiAxiosParamCreator(configuration).v1iiiaccesstokenauthcodeauthcodeIIIGET(authcode, testMode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение токена доступа по refresh-токену
         * @param {string} refreshtoken 
         * @param {boolean} [testMode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1iiiaccesstokenrefreshtokenrefreshtokenIIIGET(refreshtoken: string, testMode?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<TokenResponse>>> {
            const localVarAxiosArgs = await IIIApiAxiosParamCreator(configuration).v1iiiaccesstokenrefreshtokenrefreshtokenIIIGET(refreshtoken, testMode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Отзыв токена
         * @param {string} [token] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1iiitokenrevokeIIIDELETE(token?: string, type?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ErrorStatus>>> {
            const localVarAxiosArgs = await IIIApiAxiosParamCreator(configuration).v1iiitokenrevokeIIIDELETE(token, type, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * IIIApi - factory interface
 * @export
 */
export const IIIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Получение токена доступа по коду авторизации
         * @param {string} authcode 
         * @param {boolean} [testMode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1iiiaccesstokenauthcodeauthcodeIIIGET(authcode: string, testMode?: boolean, options?: AxiosRequestConfig): Promise<AxiosResponse<TokenResponse>> {
            return IIIApiFp(configuration).v1iiiaccesstokenauthcodeauthcodeIIIGET(authcode, testMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение токена доступа по refresh-токену
         * @param {string} refreshtoken 
         * @param {boolean} [testMode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1iiiaccesstokenrefreshtokenrefreshtokenIIIGET(refreshtoken: string, testMode?: boolean, options?: AxiosRequestConfig): Promise<AxiosResponse<TokenResponse>> {
            return IIIApiFp(configuration).v1iiiaccesstokenrefreshtokenrefreshtokenIIIGET(refreshtoken, testMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отзыв токена
         * @param {string} [token] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1iiitokenrevokeIIIDELETE(token?: string, type?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<ErrorStatus>> {
            return IIIApiFp(configuration).v1iiitokenrevokeIIIDELETE(token, type, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IIIApi - object-oriented interface
 * @export
 * @class IIIApi
 * @extends {BaseAPI}
 */
export class IIIApi extends BaseAPI {
    /**
     * 
     * @summary Получение токена доступа по коду авторизации
     * @param {string} authcode 
     * @param {boolean} [testMode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IIIApi
     */
    public async v1iiiaccesstokenauthcodeauthcodeIIIGET(authcode: string, testMode?: boolean, options?: AxiosRequestConfig) : Promise<AxiosResponse<TokenResponse>> {
        return IIIApiFp(this.configuration).v1iiiaccesstokenauthcodeauthcodeIIIGET(authcode, testMode, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Получение токена доступа по refresh-токену
     * @param {string} refreshtoken 
     * @param {boolean} [testMode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IIIApi
     */
    public async v1iiiaccesstokenrefreshtokenrefreshtokenIIIGET(refreshtoken: string, testMode?: boolean, options?: AxiosRequestConfig) : Promise<AxiosResponse<TokenResponse>> {
        return IIIApiFp(this.configuration).v1iiiaccesstokenrefreshtokenrefreshtokenIIIGET(refreshtoken, testMode, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Отзыв токена
     * @param {string} [token] 
     * @param {string} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IIIApi
     */
    public async v1iiitokenrevokeIIIDELETE(token?: string, type?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<ErrorStatus>> {
        return IIIApiFp(this.configuration).v1iiitokenrevokeIIIDELETE(token, type, options).then((request) => request(this.axios, this.basePath));
    }
}
