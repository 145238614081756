import { cloneDeep } from 'lodash';
import {
	IAccount,
	ICashBox,
	OwnerShipTypeEnum,
	ServiceProviderStateEnum,
	ServiceProviderERIPStateEnum,
	IServiceProvider,
	IService,
	IRetailOutlet,
	IContact,
	IPhone,
	IServiceProviderReport,
} from 'apis/cabinetApi/extraTypes';
// Types
import {
	NotifyParamsDTO,
	Notice,
	ENoticeTypes,
} from 'app/modules/serviceProviders/types';


export const initAccountValues: IAccount = {
	'iban': null,
	'currency': null,
	bank: {
		'bic': null,
		'code': null,
		'name': null,
		address: {
			'city': null,
			'line1': null
		}
	}
};

export const noticeInitialValues: Notice = {
	type: ENoticeTypes.emails,
	notice: null
}

export const notifyParamsInitialValues: NotifyParamsDTO = {
	notices: [],
	emails:  [],
	smses:   [],
	urls:    [],
	users:   [],
};

export const cashBoxInitialValues: ICashBox = {
	// 'code': 0,
	'id': null,
	'name': null,
	'descr': null,
	notifiers: {
		'urlNotify': null,
		createInvoiceNotify: {
			'isActive': false,
			// oneSignalAppNotify: {
			// 	'appId': null,
			// 	'apiKey': null,
			// 	'url': null
			// }
		},
		startPayInvoiceNotify: {
			'isActive': false,
			// oneSignalAppNotify: {
			// 	'appId': null,
			// 	'apiKey': null,
			// 	'url': null
			// }
		},
		cancelPayInvoiceNotify: {
			'isActive': false,
			// oneSignalAppNotify: {
			// 	'appId': null,
			// 	'apiKey': null,
			// 	'url': null
			// }
		},
		confirmPayInvoiceNotify: {
			'isActive': false,
			// oneSignalAppNotify: {
			// 	'appId': null,
			// 	'apiKey': null,
			// 	'url': null
			// }
		},
		deleteInvoiceNotify: {
			'isActive': false,
			// oneSignalAppNotify: {
			// 	'appId': null,
			// 	'apiKey': null,
			// 	'url': null
			// }
		},
		stornoInvoiceNotify: {
			'isActive': false,
			// oneSignalAppNotify: {
			// 	'appId': null,
			// 	'apiKey': null,
			// 	'url': null
			// }
		}
	}
};

export const serviceProviderInitialValues: IServiceProvider = {
	// 'code': 0,
	'state': ServiceProviderStateEnum.NUMBER_0,
	'eripState': ServiceProviderStateEnum.NUMBER_0,
	legalInfo: {
		'name': null,
		'shortName': null,
		'unp': null,
		'okpo': null,
		'type': OwnerShipTypeEnum.NUMBER_10,

		address: {
			'city': null,
			'line1': null
		},

		account: cloneDeep(initAccountValues)
	},

	businessCard: {
		postAddress: {
			'city': null,
			'line1': null
		},

		contacts: []
	},

	contract: {
		'num': null,
		'signActDate': null,
		'beginDate': null,
		'endDate': null
	},

	merchant: {
		'merchantName': null,
		'merchantLocalityName': null,
		'localityCode': null,
		'isMobile': false
	},

	currencies: [],
	'loyalityCode': null,
	'canBiometryPay': false,

	services: [],
	retailOutlets: [],
	contacts: [],
	notifyParams: cloneDeep(notifyParamsInitialValues),
	reports: []
};


export const serviceInitialValues: IService = {
	'name': null,
	'state': ServiceProviderStateEnum.NUMBER_0,
	'eripState': ServiceProviderERIPStateEnum.NUMBER_0,

	account: cloneDeep(initAccountValues),

	comissions: {
		erip: {
			'percent': null,
			'minAmount': null,
			'maxAmount': null
		},
		aggregator: {
			'percent': null,
			'minAmount': null,
			'maxAmount': null
		}
	},

	restrictions: {
		'minAmount': undefined,
		'maxAmount': undefined,
		'canMakeStorno': undefined, // readonly
		'eripStorno': false,
		'aggregatorStorno': false
	},

	'requestServiceProvider': false,
	'requestUrl': null,

	notifyParams: notifyParamsInitialValues
};


export const retailOutletInitialValues: IRetailOutlet = {
	'code': null,
	'state': ServiceProviderStateEnum.NUMBER_1,
	retailOutletMerchantInfo: {
	// Наименование торговца (EN)*
		'storeName': null,
	// Наименование торговца (RU)*
		'storeLocalityName': null,
	// Локаль*
		'localityCode': null,
		
	// Город (EN)*
		'storeCity': null,
	// Город (RU)*
		'storeLocalityCity': null,
	},
	address: {
	// Улица, номер дома*
		'line1': null,
	// Код страны (2 симв.)*
		'country': 'BY',
	// Почтовый индекс
		'postalCode': null
	},
	mcc: {
	// Код категории
		'code': null
	},
	// Примечание
	'descr': null,

	businessCard: {
	// Контакты
		contacts: []
	},
	// Уведомления
	notifyParams: cloneDeep(notifyParamsInitialValues)
};


export const contactInitialValues: IContact = {
	'type': "contact_person",
	person: {
		'lastName': null,
		'firstName': null,
		'middleName': null
	},
	emails: [''],
	phones: []
};
export const initialPhone: IPhone = {
	'nationalNumber': null
};

export const reportInitialValues: IServiceProviderReport = {
	report: {
		// 'code': undefined,
		'name': null,
		'defaultTemplateName': null,
		'attachTypes': [],
		'isActive': false
	},
	emails: [''],
	'lastSendDate': null
};
