import React from 'react';
import { extend } from 'lodash';
import { muiEsasStyled } from 'app/theme';

import {
	selectUnstyledClasses,
	SelectUnstyledRootSlotProps,
} from '@mui/base/SelectUnstyled';
import { AppSelectIcon } from '.';
// Types
import { ISxAsProp } from 'app/theme';


const AppSelectControlBase = React.forwardRef(function AppSelectControlBase(
	{ children, ownerState, ...props }: SelectUnstyledRootSlotProps<any>,
	ref: React.ForwardedRef<HTMLButtonElement>
) {
	return (
		<button
			tabIndex={0}
			{...props}
			ref={ref}
		>
			{children}
			<AppSelectIcon ownerState={ownerState}/>
		</button>
	);
});


interface IAppSelectControlProps extends SelectUnstyledRootSlotProps<any>, ISxAsProp {};
const AppSelectControl: React.FC<IAppSelectControlProps> = muiEsasStyled(AppSelectControlBase, {
	// Props, которые не будут отображаться атрибутами в DOM
	shouldForwardProp: (prop) => (
		prop !== 'sx'
	),
	name: 'AppSelectControl'
})<IAppSelectControlProps>(({ theme, ownerState, sx = [] }) => {

	return extend(
		{
			position: 'relative',
			flexShrink: 0,
			display: 'inline-flex',
			justifyContent: 'space-between',
			alignItems: 'center',

			minHeight: theme.spacing(10),
			margin: 0,
			padding: `0 ${theme.spacing(4)}`,

			font: theme.font.small.md,
			lineHeight: theme.spacing(4),
			color: theme.color.primary,
			
			background: 'none',
			border: `${theme.border.primary} ${theme.color.tertiary}`,
			borderRadius: theme.spacing(2),
			outline: 'none',

			transition: `color 0.3s, background 0.3s, border 0.3s, box-shadow 0.3s`,
			cursor: 'pointer',

			[`&.${selectUnstyledClasses.expanded}`]: {
				background: theme.color.quaternary,
			},

			[`&.${selectUnstyledClasses.focusVisible}`]: {
				borderColor: theme.color.epos.notification,
				boxShadow: `inset 0 0 4px ${theme.color.epos.notification}`
			},

			[`&.${selectUnstyledClasses.disabled}`]: {
				color: theme.color.tertiary,
				cursor: 'default',
			},

		},
		...Array.isArray(sx) ? sx : [sx] 
	);
});


export { AppSelectControl };
