import React from 'react';
import { Button } from '@mui/material';
import { extend } from 'lodash';
import { muiEsasStyled, ISxAsProp } from 'app/theme';
// Mui-TabsUnstyled как основа, стилизация через system
// https://mui.com/base/react-tabs/
import TabsUnstyled, {
	TabsUnstyledProps,
	tabsUnstyledClasses
} from '@mui/base/TabsUnstyled';
import TabsListUnstyled, {
	TabsListUnstyledProps,
	tabsListUnstyledClasses
} from '@mui/base/TabsListUnstyled';
import TabUnstyled, {
	TabUnstyledProps,
	tabUnstyledClasses
} from '@mui/base/TabUnstyled';
import TabPanelUnstyled, {
	TabPanelUnstyledProps,
	tabPanelUnstyledClasses
} from '@mui/base/TabPanelUnstyled';
// Types
import { SxProps, Theme } from '@mui/material';


interface IAppTabsProps extends TabsUnstyledProps, ISxAsProp {};
const AppTabs: React.FC<IAppTabsProps> = muiEsasStyled(TabsUnstyled, {
	shouldForwardProp: (prop) => (prop !== 'sx'),
	name: 'AppTabs'
})<IAppTabsProps>(({ theme }) => extend({

}));


interface IAppTabsListProps extends TabsListUnstyledProps, ISxAsProp {};
const AppTabsList: React.FC<IAppTabsListProps> = muiEsasStyled(TabsListUnstyled, {
	shouldForwardProp: (prop) => (prop !== 'sx'),
	name: 'AppTabsList'
})<IAppTabsListProps>(({ theme }) => extend({
	display: 'flex',
	flexWrap: 'nowrap',

}));


interface IAppTabProps extends TabUnstyledProps, ISxAsProp {};
const AppTabBase: React.FC<IAppTabProps> = muiEsasStyled(TabUnstyled, {
	shouldForwardProp: (prop) => (prop !== 'sx'),
	name: 'AppTab'
})<IAppTabProps>(({ theme }) => extend({
	position: 'relative',
	padding: `${theme.spacing(2)} 0 ${theme.spacing(4)}`,
	font: theme.font.body.xs,
	color: theme.color.primary,
	background: 'none',
	border: 'none',
	borderTopLeftRadius: theme.spacing(2),
	borderTopRightRadius: theme.spacing(2),
	cursor: 'pointer',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	transition: 'color 0.2s',
	'&:not(:last-child)': {
		marginRight: theme.spacing(4),
	},
	'&:hover': {
		color: theme.color.epos.hover,
	}
}, {
	[`&.${tabUnstyledClasses.selected}`]: {
		color: theme.color.epos.primary,
		'&::after': {
			content: '""',
			position: 'absolute',
			left: 0,
			right: 0,
			bottom: 0,
			height: '3px',
			background: theme.color.epos.primary,
		}
	}
}, {
	[`&.${tabUnstyledClasses.disabled}`]: {
		color: theme.color.tertiary,
		cursor: 'default',
	}
}, []));

const AppTab: React.FC<IAppTabProps> = (props) => {
	return <AppTabBase type="button" {...props}/>
}


interface IAppTabPanelProps extends TabPanelUnstyledProps, ISxAsProp {};
const AppTabPanel: React.FC<IAppTabPanelProps> = muiEsasStyled(TabPanelUnstyled, {
	shouldForwardProp: (prop) => (prop !== 'sx'),
	name: 'AppTabPanel'
})<IAppTabPanelProps>(({ theme }) => extend({

}));


export {
	AppTabs,
	AppTabsList,
	AppTab,
	AppTabPanel,
};
