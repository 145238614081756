import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Stack, Typography } from '@mui/material';
import { useMatchMedia } from 'hooks';
import {
	AppModal,
	AppCardContainer,
	AppCardHeader,
	AppCardContent,
	AppCardFooter,
	AppCardActions,
	AppButton,
	AppIconButton,
	GooglePlayLink,
} from 'app/components';
import { useLayoutUIContext } from 'app/providers';


const GetMobileAppModal: React.FC = () => {
	const { t } = useTranslation(undefined, { keyPrefix: 'layout.modals.getMobileApp' });
	const { isMobile } = useMatchMedia();
	const { isGetMobileAppModalOpen, hideGetMobileAppModal } = useLayoutUIContext();


	return (isMobile) ? (
		<AppModal
			open={isGetMobileAppModalOpen}
			onClose={hideGetMobileAppModal}
			scroll="body"
		>
			<AppCardContainer >
				<AppCardHeader title={t('title')}>
					<AppIconButton iconType="close" onClick={hideGetMobileAppModal}/>
				</AppCardHeader>

				<AppCardContent>
					<Typography
						sx={(theme) => ({
							font: theme.font.small.md,
							color: theme.color.primary,
						})}
					>
						{t('text')}
					</Typography>
				</AppCardContent>

				<AppCardFooter>
					<Stack alignItems="center" width="100%">
						<GooglePlayLink/>
					</Stack>
				</AppCardFooter>
			</AppCardContainer>
		</AppModal>
	) : (
		null
	);
}


GetMobileAppModal.displayName = 'GetMobileAppModal';
export { GetMobileAppModal };
