/* tslint:disable */
/* eslint-disable */
/**
 * EPOS cabinet API
 * Cabinet API сервиса E-POS
 *
 * OpenAPI spec version: v1
 * Contact: support-api@epos.by
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ErrorStatus } from '../models';
import { UserInfo } from '../models';
import { UserInfoRecords } from '../models';
/**
 * UserManageApi - axios parameter creator
 * @export
 */
export const UserManageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получение списка пользователей.
         * @param {number} [skip] Количество записей, которые необходимо пропустить.
         * @param {number} [count] Количество записй, которые необходимо вернуть.
         * @param {string} [searchString] Строка поиска
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1userUserManageGET: async (skip?: number, count?: number, searchString?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            if (searchString !== undefined) {
                localVarQueryParameter['searchString'] = searchString;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Добавление пользователей различно в разных режимах.  Если пользователь с правами агрегатора, то он может создавать пользователей ПУ, даже если у него нет флага \"владелец\",  Если же у пользователя право ерип или ПУ, то добавлять он может пользователей с аналогичными правами.
         * @summary Добавление нового пользователя.
         * @param {UserInfo} [body] Информация о пользователе.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1userUserManagePOST: async (body?: UserInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers?.['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Информация о пользователе.
         * @param {string} id Идентификатор пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1useridUserManageGET: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1useridUserManageGET.');
            }
            const localVarPath = `/v1/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Владелец, это пользователь, который наделен правом добавлять новых пользователей  в рамках своего режима работы. Например:  Если это агрегатор - то можно добавлять пользователей и назначать им права агрегатора и ерип,  если это ерип - то можно добавлять пользователей с правом ерип,  если это ПУ - то пользователей с правом ПУ.
         * @summary Изменение владельца.
         * @param {string} id Идентификатор пользователя.
         * @param {boolean} [body] Признак владельца.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1useridUserManagePATCH: async (id: string, body?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1useridUserManagePATCH.');
            }
            const localVarPath = `/v1/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers?.['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Изменить можно только принадлежность к группам или правам.  Профиль пользователя изменить нельзя, так как это личные данные пользователя,  которые он самостоятельно может изменить на сервере iii.by.
         * @summary Изменение информации о пльзователе.
         * @param {string} id Идентификатор пользователя.
         * @param {UserInfo} [body] Информация о пользователе.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1useridUserManagePUT: async (id: string, body?: UserInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1useridUserManagePUT.');
            }
            const localVarPath = `/v1/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers?.['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Деактивация пользователя
         * @param {string} id Идентификатор пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1useriddisableUserManagePATCH: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1useriddisableUserManagePATCH.');
            }
            const localVarPath = `/v1/user/{id}/disable`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Установка активности пользователя
         * @param {string} id Идентификатор пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1useridenableUserManagePATCH: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1useridenableUserManagePATCH.');
            }
            const localVarPath = `/v1/user/{id}/enable`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Регистрация пользователя в OneSignal
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1useronesignalregisterUserManagePOST: async (body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user/onesignal/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers?.['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отмена регистрации пользователя в OneSignal
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1useronesignalunregisterUserManagePOST: async (body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user/onesignal/unregister`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers?.['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserManageApi - functional programming interface
 * @export
 */
export const UserManageApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получение списка пользователей.
         * @param {number} [skip] Количество записей, которые необходимо пропустить.
         * @param {number} [count] Количество записй, которые необходимо вернуть.
         * @param {string} [searchString] Строка поиска
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1userUserManageGET(skip?: number, count?: number, searchString?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<UserInfoRecords>>> {
            const localVarAxiosArgs = await UserManageApiAxiosParamCreator(configuration).v1userUserManageGET(skip, count, searchString, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Добавление пользователей различно в разных режимах.  Если пользователь с правами агрегатора, то он может создавать пользователей ПУ, даже если у него нет флага \"владелец\",  Если же у пользователя право ерип или ПУ, то добавлять он может пользователей с аналогичными правами.
         * @summary Добавление нового пользователя.
         * @param {UserInfo} [body] Информация о пользователе.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1userUserManagePOST(body?: UserInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await UserManageApiAxiosParamCreator(configuration).v1userUserManagePOST(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Информация о пользователе.
         * @param {string} id Идентификатор пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1useridUserManageGET(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<UserInfo>>> {
            const localVarAxiosArgs = await UserManageApiAxiosParamCreator(configuration).v1useridUserManageGET(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Владелец, это пользователь, который наделен правом добавлять новых пользователей  в рамках своего режима работы. Например:  Если это агрегатор - то можно добавлять пользователей и назначать им права агрегатора и ерип,  если это ерип - то можно добавлять пользователей с правом ерип,  если это ПУ - то пользователей с правом ПУ.
         * @summary Изменение владельца.
         * @param {string} id Идентификатор пользователя.
         * @param {boolean} [body] Признак владельца.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1useridUserManagePATCH(id: string, body?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await UserManageApiAxiosParamCreator(configuration).v1useridUserManagePATCH(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Изменить можно только принадлежность к группам или правам.  Профиль пользователя изменить нельзя, так как это личные данные пользователя,  которые он самостоятельно может изменить на сервере iii.by.
         * @summary Изменение информации о пльзователе.
         * @param {string} id Идентификатор пользователя.
         * @param {UserInfo} [body] Информация о пользователе.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1useridUserManagePUT(id: string, body?: UserInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<UserInfo>>> {
            const localVarAxiosArgs = await UserManageApiAxiosParamCreator(configuration).v1useridUserManagePUT(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Деактивация пользователя
         * @param {string} id Идентификатор пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1useriddisableUserManagePATCH(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ErrorStatus>>> {
            const localVarAxiosArgs = await UserManageApiAxiosParamCreator(configuration).v1useriddisableUserManagePATCH(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Установка активности пользователя
         * @param {string} id Идентификатор пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1useridenableUserManagePATCH(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ErrorStatus>>> {
            const localVarAxiosArgs = await UserManageApiAxiosParamCreator(configuration).v1useridenableUserManagePATCH(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Регистрация пользователя в OneSignal
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1useronesignalregisterUserManagePOST(body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await UserManageApiAxiosParamCreator(configuration).v1useronesignalregisterUserManagePOST(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Отмена регистрации пользователя в OneSignal
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1useronesignalunregisterUserManagePOST(body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await UserManageApiAxiosParamCreator(configuration).v1useronesignalunregisterUserManagePOST(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UserManageApi - factory interface
 * @export
 */
export const UserManageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Получение списка пользователей.
         * @param {number} [skip] Количество записей, которые необходимо пропустить.
         * @param {number} [count] Количество записй, которые необходимо вернуть.
         * @param {string} [searchString] Строка поиска
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1userUserManageGET(skip?: number, count?: number, searchString?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<UserInfoRecords>> {
            return UserManageApiFp(configuration).v1userUserManageGET(skip, count, searchString, options).then((request) => request(axios, basePath));
        },
        /**
         * Добавление пользователей различно в разных режимах.  Если пользователь с правами агрегатора, то он может создавать пользователей ПУ, даже если у него нет флага \"владелец\",  Если же у пользователя право ерип или ПУ, то добавлять он может пользователей с аналогичными правами.
         * @summary Добавление нового пользователя.
         * @param {UserInfo} [body] Информация о пользователе.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1userUserManagePOST(body?: UserInfo, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return UserManageApiFp(configuration).v1userUserManagePOST(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Информация о пользователе.
         * @param {string} id Идентификатор пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1useridUserManageGET(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<UserInfo>> {
            return UserManageApiFp(configuration).v1useridUserManageGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Владелец, это пользователь, который наделен правом добавлять новых пользователей  в рамках своего режима работы. Например:  Если это агрегатор - то можно добавлять пользователей и назначать им права агрегатора и ерип,  если это ерип - то можно добавлять пользователей с правом ерип,  если это ПУ - то пользователей с правом ПУ.
         * @summary Изменение владельца.
         * @param {string} id Идентификатор пользователя.
         * @param {boolean} [body] Признак владельца.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1useridUserManagePATCH(id: string, body?: boolean, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return UserManageApiFp(configuration).v1useridUserManagePATCH(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Изменить можно только принадлежность к группам или правам.  Профиль пользователя изменить нельзя, так как это личные данные пользователя,  которые он самостоятельно может изменить на сервере iii.by.
         * @summary Изменение информации о пльзователе.
         * @param {string} id Идентификатор пользователя.
         * @param {UserInfo} [body] Информация о пользователе.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1useridUserManagePUT(id: string, body?: UserInfo, options?: AxiosRequestConfig): Promise<AxiosResponse<UserInfo>> {
            return UserManageApiFp(configuration).v1useridUserManagePUT(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Деактивация пользователя
         * @param {string} id Идентификатор пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1useriddisableUserManagePATCH(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<ErrorStatus>> {
            return UserManageApiFp(configuration).v1useriddisableUserManagePATCH(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Установка активности пользователя
         * @param {string} id Идентификатор пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1useridenableUserManagePATCH(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<ErrorStatus>> {
            return UserManageApiFp(configuration).v1useridenableUserManagePATCH(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Регистрация пользователя в OneSignal
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1useronesignalregisterUserManagePOST(body?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return UserManageApiFp(configuration).v1useronesignalregisterUserManagePOST(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отмена регистрации пользователя в OneSignal
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1useronesignalunregisterUserManagePOST(body?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return UserManageApiFp(configuration).v1useronesignalunregisterUserManagePOST(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserManageApi - object-oriented interface
 * @export
 * @class UserManageApi
 * @extends {BaseAPI}
 */
export class UserManageApi extends BaseAPI {
    /**
     * 
     * @summary Получение списка пользователей.
     * @param {number} [skip] Количество записей, которые необходимо пропустить.
     * @param {number} [count] Количество записй, которые необходимо вернуть.
     * @param {string} [searchString] Строка поиска
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManageApi
     */
    public async v1userUserManageGET(skip?: number, count?: number, searchString?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<UserInfoRecords>> {
        return UserManageApiFp(this.configuration).v1userUserManageGET(skip, count, searchString, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Добавление пользователей различно в разных режимах.  Если пользователь с правами агрегатора, то он может создавать пользователей ПУ, даже если у него нет флага \"владелец\",  Если же у пользователя право ерип или ПУ, то добавлять он может пользователей с аналогичными правами.
     * @summary Добавление нового пользователя.
     * @param {UserInfo} [body] Информация о пользователе.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManageApi
     */
    public async v1userUserManagePOST(body?: UserInfo, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return UserManageApiFp(this.configuration).v1userUserManagePOST(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Информация о пользователе.
     * @param {string} id Идентификатор пользователя.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManageApi
     */
    public async v1useridUserManageGET(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<UserInfo>> {
        return UserManageApiFp(this.configuration).v1useridUserManageGET(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Владелец, это пользователь, который наделен правом добавлять новых пользователей  в рамках своего режима работы. Например:  Если это агрегатор - то можно добавлять пользователей и назначать им права агрегатора и ерип,  если это ерип - то можно добавлять пользователей с правом ерип,  если это ПУ - то пользователей с правом ПУ.
     * @summary Изменение владельца.
     * @param {string} id Идентификатор пользователя.
     * @param {boolean} [body] Признак владельца.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManageApi
     */
    public async v1useridUserManagePATCH(id: string, body?: boolean, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return UserManageApiFp(this.configuration).v1useridUserManagePATCH(id, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Изменить можно только принадлежность к группам или правам.  Профиль пользователя изменить нельзя, так как это личные данные пользователя,  которые он самостоятельно может изменить на сервере iii.by.
     * @summary Изменение информации о пльзователе.
     * @param {string} id Идентификатор пользователя.
     * @param {UserInfo} [body] Информация о пользователе.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManageApi
     */
    public async v1useridUserManagePUT(id: string, body?: UserInfo, options?: AxiosRequestConfig) : Promise<AxiosResponse<UserInfo>> {
        return UserManageApiFp(this.configuration).v1useridUserManagePUT(id, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Деактивация пользователя
     * @param {string} id Идентификатор пользователя.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManageApi
     */
    public async v1useriddisableUserManagePATCH(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<ErrorStatus>> {
        return UserManageApiFp(this.configuration).v1useriddisableUserManagePATCH(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Установка активности пользователя
     * @param {string} id Идентификатор пользователя.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManageApi
     */
    public async v1useridenableUserManagePATCH(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<ErrorStatus>> {
        return UserManageApiFp(this.configuration).v1useridenableUserManagePATCH(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Регистрация пользователя в OneSignal
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManageApi
     */
    public async v1useronesignalregisterUserManagePOST(body?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return UserManageApiFp(this.configuration).v1useronesignalregisterUserManagePOST(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Отмена регистрации пользователя в OneSignal
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManageApi
     */
    public async v1useronesignalunregisterUserManagePOST(body?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return UserManageApiFp(this.configuration).v1useronesignalunregisterUserManagePOST(body, options).then((request) => request(this.axios, this.basePath));
    }
}
