import React from 'react';
import {
	Popover, PopoverProps,
} from '@mui/material';


interface IPopoverProps extends PopoverProps {
	onClose: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
};
const AppPopover: React.FC<IPopoverProps> = ({ children, open, anchorEl, onClose, sx = [], ...props }) => {
	return (
		<Popover
			open={open}
			onClose={onClose}
			anchorEl={anchorEl}
			PaperProps={{
				elevation: 0,
				sx: { background: 'none', overflow: 'visible' }
			}}
			sx={[
				(theme) => ({

				}),
				...(Array.isArray(sx) ? sx : [sx])
			]}
			{...props}
		>
			{children}
		</Popover>
	);
}

export { AppPopover };
