import { SvgIcon, SvgIconProps } from '@mui/material';

import { ReactComponent as Down } from 'svg/down.svg';
import { ReactComponent as Next } from 'svg/next.svg';
import { ReactComponent as Up } from 'svg/up.svg';
import { ReactComponent as First } from 'svg/first.svg';
import { ReactComponent as Last } from 'svg/last.svg';
import { ReactComponent as TriangleDown } from 'svg/triangleDown.svg';

import { ReactComponent as Account } from 'svg/account.svg';
import { ReactComponent as Logotype } from 'svg/logotype.svg';
import { ReactComponent as Language } from 'svg/language.svg';
import { ReactComponent as Done } from 'svg/done.svg';
import { ReactComponent as More } from 'svg/more.svg';
import { ReactComponent as On } from 'svg/on.svg';
import { ReactComponent as Edit } from 'svg/edit.svg';
import { ReactComponent as Delete } from 'svg/delete.svg';
import { ReactComponent as Search } from 'svg/search.svg';
import { ReactComponent as Close } from 'svg/close.svg';
import { ReactComponent as Copy } from 'svg/copy.svg';
import { ReactComponent as Download } from 'svg/download.svg';
import { ReactComponent as Qr } from 'svg/qr.svg';
import { ReactComponent as Send } from 'svg/send.svg';
import { ReactComponent as Share } from 'svg/share.svg';
import { ReactComponent as Cancel } from 'svg/cancel.svg';
import { ReactComponent as Repeat } from 'svg/repeat.svg';
import { ReactComponent as Settings } from 'svg/settings.svg';

//* aside-menu
//  Аналитика
import { ReactComponent as Organisation } from 'svg/organisation.svg';
//  Счета
import { ReactComponent as Home } from 'svg/home.svg';
//  Поставщики услуг
import { ReactComponent as Card } from 'svg/card.svg';
//  Пользователи
import { ReactComponent as Users } from 'svg/users.svg';


const icons = {
	down:         () => <Down/>,
	next:         () => <Next/>,
	up:           () => <Up/>,
	first:        () => <First/>,
	last:         () => <Last/>,
	triangleDown: () => <TriangleDown/>,
	account:      () => <Account/>,
	logotype:     () => <Logotype/>,
	language:     () => <Language/>,
	done:         () => <Done/>,
	more:         () => <More/>,
	on:           () => <On/>,
	edit:         () => <Edit/>,
	delete:       () => <Delete/>,
	search:       () => <Search/>,
	close:        () => <Close/>,
	copy:         () => <Copy/>,
	download:     () => <Download/>,
	qr:           () => <Qr/>,
	send:         () => <Send/>,
	share:        () => <Share/>,
	cancel:       () => <Cancel/>,
	repeat:       () => <Repeat/>,
	settings:     () => <Settings/>,

	organisation: () => <Organisation/>,
	home:         () => <Home/>,
	card:         () => <Card/>,
	users:        () => <Users/>,
} as const;

type TIconType = keyof typeof icons;

interface IAppIconProps extends SvgIconProps {
	iconType: TIconType;
	size?:    'xs' | 'sm' | 'md' | 'lg';
};
const AppIcon: React.FC<IAppIconProps> = ({ iconType, size = 'md', sx = [], ...props }) => {
	const sizeSpacings = {
		xs:  4,
		sm:  5,
		md:  6,
		lg: 10,
	};
	
	return (
		<SvgIcon
			sx={[
				(theme) => ({
					width:  theme.spacing(sizeSpacings[size]),
					height: theme.spacing(sizeSpacings[size]),
				}),
				...Array.isArray(sx) ? sx : [sx] 
			]}
			{...props}>
				{icons[iconType]()}
			</SvgIcon>
	);
}


export { AppIcon };
export type { TIconType, IAppIconProps };
