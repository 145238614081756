import * as Yup from 'yup';
import { errMsg } from './_errorMessages';
import {
	nullableDate,
	nullableNumber,
	prepareBeginDate,
	today,
} from './_shortcuts';
// Types
import { CurrencyRateTypeEnum, ICurrencyRate } from 'apis/cabinetApi/extraTypes';


export const currencyRateSchema: Yup.ObjectSchema<ICurrencyRate> = Yup.object({
	'id': Yup.string()
		.optional(),

	//* Начало действия курса
	'beginDate': nullableDate()
		// .default(today)
		.transform(prepareBeginDate)
		.required(errMsg.required),

	//* Окончание действия курса
	'endDate': nullableDate()
		.transform(prepareBeginDate),

	//* Курс 
	'rate': nullableNumber()
		.required(errMsg.required)
		.positive(errMsg.min.than0), 

	currency: Yup.object({
	//* Валюта
		'code': nullableNumber()
			.required(errMsg.required)
	}),

	//* За сколько единиц иностранной валюты устанавливается курс
	'units': Yup.number()
		// .default(1)
		.required(errMsg.required)
		.positive(errMsg.min.than0), 

	//*	Тип курса валют
	'type': Yup.mixed<CurrencyRateTypeEnum>()
		.oneOf(Object.values(CurrencyRateTypeEnum).filter(e => typeof e === 'number').map(e => +e))
		.default(CurrencyRateTypeEnum.NUMBER_3)
		.required(errMsg.required), 

	spInfo: Yup.object({
	//* Принадлежность к ПУ
		'serviceProviderId': nullableNumber(),

	//* Принадлежность к услуге
		'serviceId': nullableNumber()
	}).transform((value) => value || undefined)

});
