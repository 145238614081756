import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppChip } from 'app/components';
// Types
import { InvoiceStateEnum } from 'apis/cabinetApi/extraTypes';
import { SxProps, Theme } from '@mui/material';


interface IInvoiceStateChipProps {
	state?: InvoiceStateEnum;
	sx?: SxProps<Theme>;
}
export const InvoiceStateChip: React.FC<IInvoiceStateChipProps> = ({ state, sx = [] }) => {
	const { t } = useTranslation(undefined, { keyPrefix: 'schemas.Invoice.STATES' });

	const getStatus = (state?: InvoiceStateEnum) => {
		if (state === 0) return 'default';
		if (state === 10) return 'success';
		if (state === 20) return 'success';
		if (state === 30) return 'error';
		if (state === 40) return 'default';
		if (state === 50) return 'default';
		if (state === 60) return 'default';
		if (state === 70) return 'success';
		if (state === 80) return 'error';
		if (state === 100) return 'success';
		if (state === 110) return 'error';
		if (state === -10) return 'error';
		return 'default';
	}

	return (
		<AppChip
			status={getStatus(state)}
			size="md"
			sx={[
				(theme) => ({ maxWidth: theme.spacing(26) }),
				...(Array.isArray(sx) ? sx : [sx])
			]}
		>
			{t(InvoiceStateEnum[state ?? 0])}
		</AppChip>
	);
}
InvoiceStateChip.displayName = 'InvoiceStateChip';
