import { useCallback } from 'react';
import { cloneDeep, intersection } from 'lodash';
import { useTranslation } from 'react-i18next';
import { isDateToday, prepareEndDate } from 'utils';
import { notistack, useLayoutUIContext } from 'app/providers';
import { useAppDispatch, useAppSelector, shallowEqual } from 'hooks';
import { eposCabinetApiCalls } from 'apis/cabinetApi/cabinetApiCalls';
import { invoicesActions } from './invoicesSlice';
// Types
import { TThunkAction, EApiCallTypes } from 'reduxStore';
import { IGetInvoicesAllParams, IInvoice, InvoiceStateEnum } from 'apis/cabinetApi/extraTypes';
import { IComfirmModalParams } from 'app/providers';
import { IUseInvoicesCRUDReturn, historyStates, EHistoryApiCallType } from '../types';


const useInvoicesCRUD = (): IUseInvoicesCRUDReturn => {
	const { t } = useTranslation(undefined, { keyPrefix: 'schemas.Invoice' });
	const invoicesState = useAppSelector((store) => store.invoices, shallowEqual);
	const { showConfirmModal } = useLayoutUIContext();
	const appDispatch = useAppDispatch();

	const getInvoicesForListTable = useCallback<IUseInvoicesCRUDReturn['getInvoicesForListTable']>((params) => {
		const thunkAction = (params: IGetInvoicesAllParams): TThunkAction<Promise<void>> => async (dispatch) => {
			// Включить endDate в интервал поиска
			const nextParams = cloneDeep(params);
			const { endDate } = nextParams.filter;
			if (endDate) {
				nextParams.filter.endDate = prepareEndDate(endDate) ?? undefined;
			}

			const callType = EApiCallTypes.list;
			dispatch(invoicesActions.startCall({ callType }));
			const response = await eposCabinetApiCalls.getInvoicesAll(nextParams);
			if (response.ok && response.data) {
				dispatch(invoicesActions.getInvoicesAllListFulfilled(response.data));
			} else {
				dispatch(invoicesActions.catchError({ callType, errorMessage: response.error }));
				notistack.error(response.error);
			}
		}
		
		return appDispatch(thunkAction(params));
	}, [appDispatch]);

	const getInvoicesForHistoryTable = useCallback<IUseInvoicesCRUDReturn['getInvoicesForHistoryTable']>((params) => {
		const thunkAction = (params: IGetInvoicesAllParams): TThunkAction<Promise<void>> => async (dispatch) => {
			// Подготовить фильтр states:
			const nextParams = cloneDeep(params);
			const { states } = nextParams.filter;
			const intersections = intersection(states, historyStates);
			nextParams.filter.states = (intersections.length === 0) ? historyStates : intersections;

			// Включить endDate в интервал поиска
			const { endDate } = nextParams.filter;
			if (endDate) {
				nextParams.filter.endDate = prepareEndDate(endDate) ?? undefined;
			}

			const callType = EHistoryApiCallType.history;
			dispatch(invoicesActions.startCall({ callType }));
			const response = await eposCabinetApiCalls.getInvoicesAll(nextParams);
			if (response.ok && response.data) {
				dispatch(invoicesActions.getInvoicesAllHistoryFulfilled(response.data));
			} else {
				dispatch(invoicesActions.catchError({ callType, errorMessage: response.error }));
				notistack.error(response.error);
			}
		}
		
		return appDispatch(thunkAction(params));
	}, [appDispatch]);

	const getInvoiceFullData = useCallback<IUseInvoicesCRUDReturn['getInvoiceFullData']>((id) => {
		const thunkAction = (id: string): TThunkAction<Promise<boolean>> => async (dispatch) => {
			const callType = EApiCallTypes.entity;
			dispatch(invoicesActions.startCall({ callType }));
			const response = await eposCabinetApiCalls.getInvoiceById(id);
			if (response.ok && response.data) {
				dispatch(invoicesActions.getInvoiceByIdFulfilled(response.data));
				return true;
			} else {
				dispatch(invoicesActions.catchError({ callType, errorMessage: response.error }));
				notistack.error(response.error);
				return false;
			}
		}
		
		return appDispatch(thunkAction(id));
	}, [appDispatch]);

	const setInitInvoice = useCallback<IUseInvoicesCRUDReturn['setInitInvoice']>(() => {
		appDispatch(invoicesActions.setInitEdittingEntity());
	}, [appDispatch]);

	const saveInvoice = useCallback<IUseInvoicesCRUDReturn['saveInvoice']>((values) => {
		const thunkAction = (values: IInvoice): TThunkAction<Promise<boolean>> => async (dispatch) => {
			const callType = EApiCallTypes.action;
			dispatch(invoicesActions.startCall({ callType }));
			const id = values.id;
			if (id) {
				const response = await eposCabinetApiCalls.updateInvoice(id, values);
				if (response.ok && response.data) {
					dispatch(invoicesActions.saveInvoiceFulfilled(response.data));
					notistack.success(t('NOTICES.UPDATE_OK'));
					return true;
				} else {
					dispatch(invoicesActions.catchError({ callType, errorMessage: response.error }));
					notistack.error(response.error);
				}
			} else {
				const response = await eposCabinetApiCalls.createInvoice(values, values.canPayAtOnce);
				if (response.ok && response.data) {
					dispatch(invoicesActions.saveInvoiceFulfilled(response.data));
					notistack.success(t('NOTICES.CREATE_OK'));
					return true;
				} else {
					dispatch(invoicesActions.catchError({ callType, errorMessage: response.error }));
					notistack.error(response.error);
				}
			}
			return false;
		}
		
		return appDispatch(thunkAction(values));
	}, [appDispatch]);

	//* С модальным окном подтверждения действия
	const changeInvoiceState = useCallback<IUseInvoicesCRUDReturn['changeInvoiceState']>((id, state) => {
		const thunkAction = (id: string): TThunkAction<Promise<void>> => async (dispatch, getState) => {
			const callType = EApiCallTypes.action;
			dispatch(invoicesActions.startCall({ callType }));

			const { edittingEntity, listRecords } = getState().invoices;
			// edittingEntity и records readOnly, потому для изменения state клонирую
			const changedEntity = cloneDeep(edittingEntity);
			changedEntity.state = state;
			const changedRecords = cloneDeep(listRecords);
			(changedRecords.find((invoiceListItem) => invoiceListItem.id === id) ?? edittingEntity).state = state;

			if (state === InvoiceStateEnum.NUMBER_0) {
				const response = await eposCabinetApiCalls.setInvoiceToCancel(id);
				if (response.ok) {
					dispatch(invoicesActions.changeInvoiceStateFulfilled({ changedEntity, changedRecords }));
					notistack.success(t('NOTICES.CANCEL_OK'));
				} else {
					dispatch(invoicesActions.catchError({ callType, errorMessage: response.error }));
					notistack.error(response.error);
				}
			}

			if (state === InvoiceStateEnum.NUMBER_10) {
				const response = await eposCabinetApiCalls.setInvoiceToSend(id);
				if (response.ok) {
					dispatch(invoicesActions.changeInvoiceStateFulfilled({ changedEntity, changedRecords }));
					notistack.success(t('NOTICES.SEND_OK'));
				} else {
					dispatch(invoicesActions.catchError({ callType, errorMessage: response.error }));
					notistack.error(response.error);
				}
			}

			if (state === InvoiceStateEnum.NUMBER_30) {
				const response = await eposCabinetApiCalls.deleteInvoice(id);
				if (response.ok) {
					dispatch(invoicesActions.changeInvoiceStateFulfilled({ changedEntity, changedRecords }));
					notistack.success(t('NOTICES.DELETE_OK'));
				} else {
					dispatch(invoicesActions.catchError({ callType, errorMessage: response.error }));
					notistack.error(response.error);
				}
			}
		}
		
		const showConfirmModalParams: IComfirmModalParams = {
			title:    '',
			text:     '',
			callback: () => appDispatch(thunkAction(id)),
			action:   ''
		};

		if (state === InvoiceStateEnum.NUMBER_0) {
			showConfirmModalParams.title = t('ACTIONS.CANCEL');
			showConfirmModalParams.text = t('CONFIRM_MODAL.CANCEL_?');
			showConfirmModalParams.action = t('CONFIRM_MODAL.CANCEL_!');
		}
		if (state === InvoiceStateEnum.NUMBER_10) {
			showConfirmModalParams.title = t('ACTIONS.SEND');
			showConfirmModalParams.text = t('CONFIRM_MODAL.SEND_?');
			showConfirmModalParams.action = t('CONFIRM_MODAL.SEND_!');
		}
		if (state === InvoiceStateEnum.NUMBER_30) {
			showConfirmModalParams.title = t('ACTIONS.DELETE');
			showConfirmModalParams.text = t('CONFIRM_MODAL.DELETE_?');
			showConfirmModalParams.action = t('CONFIRM_MODAL.DELETE_!');
		}

		showConfirmModal(showConfirmModalParams);
	}, [appDispatch]);

	//* С модальным окном qr-кода
	const getInvoiceQrCode = useCallback<IUseInvoicesCRUDReturn['getInvoiceQrCode']>((id) => {
		// Если не передается id, очистка при закрытии модального окна qrCode
		if (!id) {
			appDispatch(invoicesActions.getInvoiceQrCodeFulfilled(null));
			return;
		}
		
		const thunkAction = (id: string): TThunkAction<Promise<void>> => async (dispatch) => {
			const callType = EApiCallTypes.action;
			dispatch(invoicesActions.startCall({ callType }));
			const response = await eposCabinetApiCalls.getInvoiceQrCode(id);
			if (response.ok && response.data) {
				dispatch(invoicesActions.getInvoiceQrCodeFulfilled(response.data));
			} else {
				dispatch(invoicesActions.catchError({ callType, errorMessage: response.error }));
				notistack.error(response.error);
			}
		}
		
		return appDispatch(thunkAction(id));
	}, [appDispatch]);

	const clearQrCode = useCallback<IUseInvoicesCRUDReturn['clearQrCode']>(() => {
		appDispatch(invoicesActions.setInitEdittingEntityQrCode());
	}, [appDispatch]);

	//* С модальным окном подтверждения действия
	const stornoInvoice = useCallback<IUseInvoicesCRUDReturn['stornoInvoice']>((id, payDateUTC) => {
		const thunkAction = (id: string): TThunkAction<Promise<void>> => async (dispatch) => {
			const callType = EApiCallTypes.action;
			dispatch(invoicesActions.startCall({ callType }));
			const response = await eposCabinetApiCalls.stornoInvoice(id);
			if (response.ok) {
				dispatch(invoicesActions.saveInvoiceFulfilled(response.data));
				notistack.success(t('NOTICES.STORNO_OK'));
			} else {
				dispatch(invoicesActions.catchError({ callType, errorMessage: response.error }));
				notistack.error(response.error);
			}
		}

		// Сторнировать можно лишь счет, оплаченный сегодня (по UTC),
		// соответственно с payDateUTC < завтра и state === 20
		const isToday = isDateToday(payDateUTC);
		if (isToday) {
			showConfirmModal({
				title: t('ACTIONS.STORNO'),
				text:  t('CONFIRM_MODAL.STORNO_?'),
				action: t('CONFIRM_MODAL.STORNO_!'),
				callback: () => appDispatch(thunkAction(id))
			});
		}	else {
			showConfirmModal({
				title: t('ACTIONS.STORNO'),
				text:  t('CONFIRM_MODAL.STORNO_X'),
			});
		}
	}, [appDispatch]);


	return {
		...invoicesState,

		getInvoicesForListTable,
		getInvoicesForHistoryTable,
		getInvoiceFullData,
		setInitInvoice,
		saveInvoice,
		changeInvoiceState,
		getInvoiceQrCode,
		clearQrCode,
		stornoInvoice
	};
}

export { useInvoicesCRUD };
