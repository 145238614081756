import React from 'react';
import { extend } from 'lodash';
import { muiEsasStyled } from 'app/theme';
// Types
import { ISxAsProp } from 'app/theme';


interface IAppMenuListboxProps extends React.ComponentProps<'ul'>, ISxAsProp {};
const AppSelectListbox: React.FC<IAppMenuListboxProps> = muiEsasStyled('ul', {
	// Перечисляем пропсы, которые не будут отображаться атрибутами в DOM
	shouldForwardProp: (prop) => (
		prop !== 'sx' && prop !== 'ownerState'
	),
	name: 'AppSelectListbox'
})<IAppMenuListboxProps>(({ theme, sx = [] }) => {
	return extend(
		{
			margin: 0,
			padding: `${theme.spacing(2)} 0`,
			background: theme.color.bg_01,
			border: 'none',
			borderRadius: theme.spacing(2),
			outline: 'none',
			boxShadow: theme.depth.tertiary,

			// overflowY: 'auto',
			// maxHeight: '100px',
		},
		...Array.isArray(sx) ? sx : [sx]
	);
});
AppSelectListbox.displayName = 'AppSelectListbox';


export { AppSelectListbox };
