// Types
import * as I from './epos_cabinet_api/models';


//! ========== Common types ==========
/**
 * Конвертированный response, возвращаемый методами apiCalls. Два типа:
 * - при fulfilled { `ok`: true, `data`: data, `error`: null }
 * - при rejected { `ok`: false, `data`: null, `error`: error }
 * @property `ok` статус запроса
 * @property `data` response.data запроса
 * @property `error` текст ошибки запроса 
 */
export interface IConvertedResponse<D = any> {
	/** `true` при fulfilled response, `false` при rejected response */
	ok:    boolean;
	data:  null | D;
	error: string;
};

/**
 * Список элементов при постраничной выдаче
 * @property `start` Индекс начального элемента
 * @property `count` Количество элементов в результате
 * @property `total` Общее количество элементов, удовлетворяющих запросу
 * @property `records` Запрошенный массив записей
 */
export interface IPaged<D = any> {
	/** Индекс начального элемента */
	start: number;
	/** Количество элементов в результате */
	count: number;
	/** Общее количество элементов, удовлетворяющих запросу */
	total: number;
	/** Запрошенные записи */
	records: Array<D>;
};

/**
 * @property `skip` количество пропускаемых элементов при пагинации
 * @property `count` запрашиваемое количество элементов при пагинации
 * @property `filter` фильтры поиска
 */
export interface IGetAllParams<I = {}> {
	/**	количество пропускаемых элементов при пагинации */
	skip: number;
	/**	запрашиваемое количество элементов при пагинации */
	count: number;
	/**	фильтры поиска */
	filter: I;
}


//! ========== Helpers Api types ==========
export interface IGetMCCsAllParams extends IGetAllParams<{
	/** Код MCC для поиска */
	code?: string;
}> {};

export interface IGetCurrenciesAllParams extends IGetAllParams<{
	/** Код валюты для поиска */
	code?: number;
}> {};

export interface IGetLocalesAllParams extends IGetAllParams<{
	// нет фильтров
}> {};

export interface IGetBanksAllParams extends IGetAllParams<{
	/** Шаблон для поиска банка по BIC */
	bic?:  string;
	/** Шаблон поиска банка по номеру счета */
	iban?: string;
}> {};

export interface IGetReportsAllParams extends IGetAllParams<{
	// нет фильтров
}> {};

export interface IGetCurrencyRatesAllParams extends IGetAllParams<{
	/** Начальная дата для выборки данных */
	beginDate?:  Date;
	/** Конечная дата для выборки данных */
	endDate?: Date;
	/** Строка для поиска */
	searchString?: string;
}> {};


//! ========== Invoice Api types ==========
export interface IGetInvoicesAllParams extends IGetAllParams<{
	/** Начальная дата для выборки данных */
	beginDate?:  Date;
	/** Конечная дата для выборки данных */
	endDate?: Date;
	/** Строка для поиска */
	searchString?: string;
	/** Необходимые состояния */
	states?: Array<I.InvoiceStateEnum>;
	/** Тип запроса (по дате создания, по дате оплаты) */
	requestType?: 1 | 2;
}> {};


//! ========== Report Api types ==========
export interface IGetReportServiceProviderParams {
	/** Идентификатор ПУ */
	id: number;
	/** Начальная дата для выборки данных */
	utcBeginDate?:  Date;
	/** Конечная дата для выборки данных */
	utcEndDate?: Date;
	/** Высылать ли отчет на email */
	sendByEmail?: boolean;
	/** Адреса, на которые выслать отчет */
	emails?: Array<string> | null;
	/** ??? */
	isAsync?: boolean;
};


//! ========== ServiceProvider Api types ==========
export interface IGetServiceProvidersAllParams extends IGetAllParams<{
	/** строка поиска */
	searchString?: string;
}> {};

export interface IGetBiometryQrCodeByIdParams {
	/** Идентификатор услуги ПУ */
	serviceId?: number;
	/** Идентификатор РТТ */
	rttCode?: number;
	/** Идентификатор кассы РТТ */
	cashBoxCode?: number;
	/** default value === 174 */
	imgWidth?: number;
	/** default value === 306 */
	imgHeight?: number;
	/** default value === false */
	getImage?: boolean;
};


//! ========== UserManage Api types ==========
export interface IGetUsersAllParams extends IGetAllParams<{
	/** строка поиска */
	searchString?: string;
}> {};

export enum EUserRoles {
	sp					= 'epos.sp',
	aggregator	= 'epos.aggregator'
};


//! Пока типы из сваггера, потом можно переписать на свои
// Enums
export {
	ReportAttachFileType,
	ServiceProviderStateEnum,
	ServiceProviderERIPStateEnum,
	OwnerShipTypeEnum,
	ConfigTypeEnum,
	PenaltyTypeEnum,
	InvoiceStateEnum,
	PeriodicityTypeEnum,
	CurrencyRateTypeEnum,
	TipOrConvenienceEnum,
} from './epos_cabinet_api/models';

// Interfaces
export interface IAccount extends I.Account {};
export interface IAddress extends I.Address {};
export interface IAggregatorPublicInfo extends I.AggregatorPublicInfo {};
export interface IAmount extends I.Amount {};
export interface IBank extends I.Bank {};
export interface IBillingInfo extends I.BillingInfo {};
export interface IBiometryQRCode extends I.BiometryQRCode {};
export interface IBusinessCard extends I.BusinessCard {};
export interface ICashBoxNotifiers extends I.CashBoxNotifiers {};
export interface ICashBoxNotify extends I.CashBoxNotify {};
export interface ICashBox extends I.CashBox {};
export interface IComission extends I.Comission {};
export interface IConfirmedTransactionReestr extends I.ConfirmedTransactionReestr {};
export interface IConfirmedTransactionService extends I.ConfirmedTransactionService {};
export interface IConfirmedTransaction extends I.ConfirmedTransaction {};
export interface IContact extends I.Contact {};
export interface ICurrency extends I.Currency {};
export interface ICurrencyRate extends I.CurrencyRate {};
export interface ICustomAmount extends I.CustomAmount {};
export interface IDiscount extends I.Discount {};
export interface IEripMCC extends I.EripMCC {};
export interface IErrorStatus extends I.ErrorStatus {};
export interface IGEOLocation extends I.GEOLocation {};
export interface IGuidStringProperty extends I.GuidStringProperty {};
export interface IInt64StringProperty extends I.Int64StringProperty {};
export interface IInvoiceItem extends I.InvoiceItem {};
export interface IInvoiceListItem extends I.InvoiceListItem {};
export interface IInvoiceQRCode extends I.InvoiceQRCode {};
export interface IInvoice extends I.Invoice { canPayAtOnce?: boolean };
export interface ILegalInfo extends I.LegalInfo {};
export interface ILocale extends I.Locale {};
export interface ILoginInfo extends I.LoginInfo {};
export interface IMCC extends I.MCC {};
export interface IMerchantInfo extends I.MerchantInfo {};
export interface IMonthlyActService extends I.MonthlyActService {};
export interface IMonthlyAct extends I.MonthlyAct {};
export interface INotifyParams extends I.NotifyParams {};
export interface IOneSignalAppNotify extends I.OneSignalAppNotify {};
export interface IOrigin extends I.Origin {};
export interface IPaymentData extends I.PaymentData {};
export interface IPaymentDueTerms extends I.PaymentDueTerms {};
export interface IPaymentRules extends I.PaymentRules {};
export interface IPerson extends I.Person {};
export interface IPhone extends I.Phone {};
export interface IReport extends I.Report {};
export interface IRetailOutletMerchantInfo extends I.RetailOutletMerchantInfo {};
export interface IRetailOutlet extends I.RetailOutlet {};
export interface IServiceComission extends I.ServiceComission {};
export interface IServiceProviderContract extends I.ServiceProviderContract {};
export interface IServiceProviderIII extends I.ServiceProviderIII {};
export interface IServiceProviderInfo extends I.ServiceProviderInfo {};
export interface IServiceProviderPublicInfo extends I.ServiceProviderPublicInfo {};
export interface IServiceProviderReport extends I.ServiceProviderReport {};
export interface IServiceProvider extends I.ServiceProvider {};
export interface IServiceRestriction extends I.ServiceRestriction {};
export interface IService extends I.Service {};
export interface IShippingInfo extends I.ShippingInfo {};
export interface ISourceInfo extends I.SourceInfo {};
export interface ITipOrConvenience extends I.TipOrConvenience {};
export interface ITokenResponse extends I.TokenResponse {};
export interface IUserInfo extends I.UserInfo {};
export interface IWebPayParam extends I.WebPayParam {};

/*
Есть инит-схемы в initialModels
// Account
// Address
// AggregatorPublicInfo
// Amount
// Bank
// BillingInfo
// BiometryQRCode
// BusinessCard
// CashBoxNotifiers
// CashBoxNotify
// CashBox
// Comission
// ConfirmedTransactionReestr
// ConfirmedTransactionService
// ConfirmedTransaction
// Contact
// Currency
// CustomAmount
// Discount
// EripMCC
// ErrorStatus
// GEOLocation
// GuidStringProperty
// Int64StringProperty
// InvoiceItem
// InvoiceListItem
// InvoiceQRCode
// Invoice
// LegalInfo
// Locale
// LoginInfo
// MCC
// MerchantInfo
// MonthlyActService
// MonthlyAct
// NotifyParams
// OneSignalAppNotify
// Origin
// PaymentData
// PaymentDueTerms
// PaymentRules
// Person
// Phone
// Report
// RetailOutletMerchantInfo
// RetailOutlet
// ServiceComission
// ServiceProviderContract
// ServiceProviderIII
// ServiceProviderInfo
// ServiceProviderPublicInfo
// ServiceProviderReport
// ServiceProvider
// ServiceRestriction
// Service
// ShippingInfo
// SourceInfo
// TipOrConvenience
// TokenResponse
// UserInfo
// WebPayParam
*/