import React from 'react';
import { extend } from 'lodash';
import { muiEsasStyled } from 'app/theme';
import { AppIcon } from './AppIcon';
import ScrollToTop from 'react-scroll-to-top';
// Types


const AppScrollToTopBase: React.FC = (props) => {
	return (
		<ScrollToTop
			{...props}
			smooth
			component={<AppIcon iconType="up"/>}
		/>
	)
}


const AppScrollToTop: React.FC = muiEsasStyled(AppScrollToTopBase, {
	// Перечисляем пропсы, которые не будут отображаться атрибутами в DOM
	shouldForwardProp: (prop) => (
		prop!== 'sx'
	),
	name: 'AppIconButton'
})(({ theme }) => {

	return extend({ 
		position: 'fixed',
		right: theme.spacing(2),
		bottom: theme.spacing(2),
		zIndex: 1300,
		width: theme.spacing(10),
		height: theme.spacing(10),
		display: 'inline-flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexGrow: 0,
		flexShrink: 0,
		margin: 0,
		padding: 0,
		color: theme.color.tertiary,
		background: theme.color.bg_01,
		border: `1px solid ${theme.color.tertiary}`,
		borderRadius: '50%',
		cursor: 'pointer',
		opacity: 0.5,
		transition: 'color 0.2s, opacity 0.2s, transform 0.2s, box-shadow 0.2s',
		'&:hover': {
			color: theme.color.secondary,
			opacity: 1,
			transform: 'translateY(-4px)',
			boxShadow: theme.depth.primary
		}
	}, []);
});


export { AppScrollToTop };
// export type { TAppScrollToTop };
