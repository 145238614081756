/* tslint:disable */
/* eslint-disable */
/**
 * EPOS cabinet API
 * Cabinet API сервиса E-POS
 *
 * OpenAPI spec version: v1
 * Contact: support-api@epos.by
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ConfirmedTransactionReestr } from '../models';
import { ErrorStatus } from '../models';
import { MonthlyAct } from '../models';
/**
 * ReportApi - axios parameter creator
 * @export
 */
export const ReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Формирование ежемесячного акта по ПУ
         * @param {number} id 
         * @param {Date} [utcBeginDate] 
         * @param {Date} [utcEndDate] 
         * @param {boolean} [sendByEmail] 
         * @param {string} [emails] 
         * @param {boolean} [isAsync] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1reportserviceprovideridmonthlyactReportGET: async (id: number, utcBeginDate?: Date, utcEndDate?: Date, sendByEmail?: boolean, emails?: string, isAsync?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1reportserviceprovideridmonthlyactReportGET.');
            }
            const localVarPath = `/v1/report/serviceprovider/{id}/monthlyact`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (utcBeginDate !== undefined) {
                localVarQueryParameter['utcBeginDate'] = (utcBeginDate as any instanceof Date) ?
                    (utcBeginDate as any).toISOString() :
                    utcBeginDate;
            }

            if (utcEndDate !== undefined) {
                localVarQueryParameter['utcEndDate'] = (utcEndDate as any instanceof Date) ?
                    (utcEndDate as any).toISOString() :
                    utcEndDate;
            }

            if (sendByEmail !== undefined) {
                localVarQueryParameter['sendByEmail'] = sendByEmail;
            }

            if (emails !== undefined) {
                localVarQueryParameter['emails'] = emails;
            }

            if (isAsync !== undefined) {
                localVarQueryParameter['isAsync'] = isAsync;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Формирвоание реестра сверенных транзакций по ПУ
         * @param {number} id 
         * @param {Date} [utcBeginDate] 
         * @param {Date} [utcEndDate] 
         * @param {boolean} [sendByEmail] 
         * @param {string} [emails] 
         * @param {boolean} [isAsync] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1reportserviceprovideridreestrReportGET: async (id: number, utcBeginDate?: Date, utcEndDate?: Date, sendByEmail?: boolean, emails?: string, isAsync?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1reportserviceprovideridreestrReportGET.');
            }
            const localVarPath = `/v1/report/serviceprovider/{id}/reestr`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (utcBeginDate !== undefined) {
                localVarQueryParameter['utcBeginDate'] = (utcBeginDate as any instanceof Date) ?
                    (utcBeginDate as any).toISOString() :
                    utcBeginDate;
            }

            if (utcEndDate !== undefined) {
                localVarQueryParameter['utcEndDate'] = (utcEndDate as any instanceof Date) ?
                    (utcEndDate as any).toISOString() :
                    utcEndDate;
            }

            if (sendByEmail !== undefined) {
                localVarQueryParameter['sendByEmail'] = sendByEmail;
            }

            if (emails !== undefined) {
                localVarQueryParameter['emails'] = emails;
            }

            if (isAsync !== undefined) {
                localVarQueryParameter['isAsync'] = isAsync;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Формирвоание реестра сверенных транзакций по ПУ в формате csv
         * @param {number} id 
         * @param {Date} [utcBeginDate] 
         * @param {Date} [utcEndDate] 
         * @param {boolean} [sendByEmail] 
         * @param {string} [emails] 
         * @param {boolean} [isAsync] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1reportserviceprovideridreestrcsvReportGET: async (id: number, utcBeginDate?: Date, utcEndDate?: Date, sendByEmail?: boolean, emails?: string, isAsync?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1reportserviceprovideridreestrcsvReportGET.');
            }
            const localVarPath = `/v1/report/serviceprovider/{id}/reestr/csv`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (utcBeginDate !== undefined) {
                localVarQueryParameter['utcBeginDate'] = (utcBeginDate as any instanceof Date) ?
                    (utcBeginDate as any).toISOString() :
                    utcBeginDate;
            }

            if (utcEndDate !== undefined) {
                localVarQueryParameter['utcEndDate'] = (utcEndDate as any instanceof Date) ?
                    (utcEndDate as any).toISOString() :
                    utcEndDate;
            }

            if (sendByEmail !== undefined) {
                localVarQueryParameter['sendByEmail'] = sendByEmail;
            }

            if (emails !== undefined) {
                localVarQueryParameter['emails'] = emails;
            }

            if (isAsync !== undefined) {
                localVarQueryParameter['isAsync'] = isAsync;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Формирвоание реестра сверенных транзакций по ПУ
         * @param {number} id 
         * @param {Date} [utcBeginDate] 
         * @param {Date} [utcEndDate] 
         * @param {boolean} [sendByEmail] 
         * @param {string} [emails] 
         * @param {boolean} [isAsync] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1reportserviceprovideridreestrwithclientReportGET: async (id: number, utcBeginDate?: Date, utcEndDate?: Date, sendByEmail?: boolean, emails?: string, isAsync?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1reportserviceprovideridreestrwithclientReportGET.');
            }
            const localVarPath = `/v1/report/serviceprovider/{id}/reestrwithclient`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (utcBeginDate !== undefined) {
                localVarQueryParameter['utcBeginDate'] = (utcBeginDate as any instanceof Date) ?
                    (utcBeginDate as any).toISOString() :
                    utcBeginDate;
            }

            if (utcEndDate !== undefined) {
                localVarQueryParameter['utcEndDate'] = (utcEndDate as any instanceof Date) ?
                    (utcEndDate as any).toISOString() :
                    utcEndDate;
            }

            if (sendByEmail !== undefined) {
                localVarQueryParameter['sendByEmail'] = sendByEmail;
            }

            if (emails !== undefined) {
                localVarQueryParameter['emails'] = emails;
            }

            if (isAsync !== undefined) {
                localVarQueryParameter['isAsync'] = isAsync;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportApi - functional programming interface
 * @export
 */
export const ReportApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Формирование ежемесячного акта по ПУ
         * @param {number} id 
         * @param {Date} [utcBeginDate] 
         * @param {Date} [utcEndDate] 
         * @param {boolean} [sendByEmail] 
         * @param {string} [emails] 
         * @param {boolean} [isAsync] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1reportserviceprovideridmonthlyactReportGET(id: number, utcBeginDate?: Date, utcEndDate?: Date, sendByEmail?: boolean, emails?: string, isAsync?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<MonthlyAct>>> {
            const localVarAxiosArgs = await ReportApiAxiosParamCreator(configuration).v1reportserviceprovideridmonthlyactReportGET(id, utcBeginDate, utcEndDate, sendByEmail, emails, isAsync, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Формирвоание реестра сверенных транзакций по ПУ
         * @param {number} id 
         * @param {Date} [utcBeginDate] 
         * @param {Date} [utcEndDate] 
         * @param {boolean} [sendByEmail] 
         * @param {string} [emails] 
         * @param {boolean} [isAsync] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1reportserviceprovideridreestrReportGET(id: number, utcBeginDate?: Date, utcEndDate?: Date, sendByEmail?: boolean, emails?: string, isAsync?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ConfirmedTransactionReestr>>> {
            const localVarAxiosArgs = await ReportApiAxiosParamCreator(configuration).v1reportserviceprovideridreestrReportGET(id, utcBeginDate, utcEndDate, sendByEmail, emails, isAsync, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Формирвоание реестра сверенных транзакций по ПУ в формате csv
         * @param {number} id 
         * @param {Date} [utcBeginDate] 
         * @param {Date} [utcEndDate] 
         * @param {boolean} [sendByEmail] 
         * @param {string} [emails] 
         * @param {boolean} [isAsync] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1reportserviceprovideridreestrcsvReportGET(id: number, utcBeginDate?: Date, utcEndDate?: Date, sendByEmail?: boolean, emails?: string, isAsync?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ErrorStatus>>> {
            const localVarAxiosArgs = await ReportApiAxiosParamCreator(configuration).v1reportserviceprovideridreestrcsvReportGET(id, utcBeginDate, utcEndDate, sendByEmail, emails, isAsync, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Формирвоание реестра сверенных транзакций по ПУ
         * @param {number} id 
         * @param {Date} [utcBeginDate] 
         * @param {Date} [utcEndDate] 
         * @param {boolean} [sendByEmail] 
         * @param {string} [emails] 
         * @param {boolean} [isAsync] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1reportserviceprovideridreestrwithclientReportGET(id: number, utcBeginDate?: Date, utcEndDate?: Date, sendByEmail?: boolean, emails?: string, isAsync?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ConfirmedTransactionReestr>>> {
            const localVarAxiosArgs = await ReportApiAxiosParamCreator(configuration).v1reportserviceprovideridreestrwithclientReportGET(id, utcBeginDate, utcEndDate, sendByEmail, emails, isAsync, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ReportApi - factory interface
 * @export
 */
export const ReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Формирование ежемесячного акта по ПУ
         * @param {number} id 
         * @param {Date} [utcBeginDate] 
         * @param {Date} [utcEndDate] 
         * @param {boolean} [sendByEmail] 
         * @param {string} [emails] 
         * @param {boolean} [isAsync] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1reportserviceprovideridmonthlyactReportGET(id: number, utcBeginDate?: Date, utcEndDate?: Date, sendByEmail?: boolean, emails?: string, isAsync?: boolean, options?: AxiosRequestConfig): Promise<AxiosResponse<MonthlyAct>> {
            return ReportApiFp(configuration).v1reportserviceprovideridmonthlyactReportGET(id, utcBeginDate, utcEndDate, sendByEmail, emails, isAsync, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Формирвоание реестра сверенных транзакций по ПУ
         * @param {number} id 
         * @param {Date} [utcBeginDate] 
         * @param {Date} [utcEndDate] 
         * @param {boolean} [sendByEmail] 
         * @param {string} [emails] 
         * @param {boolean} [isAsync] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1reportserviceprovideridreestrReportGET(id: number, utcBeginDate?: Date, utcEndDate?: Date, sendByEmail?: boolean, emails?: string, isAsync?: boolean, options?: AxiosRequestConfig): Promise<AxiosResponse<ConfirmedTransactionReestr>> {
            return ReportApiFp(configuration).v1reportserviceprovideridreestrReportGET(id, utcBeginDate, utcEndDate, sendByEmail, emails, isAsync, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Формирвоание реестра сверенных транзакций по ПУ в формате csv
         * @param {number} id 
         * @param {Date} [utcBeginDate] 
         * @param {Date} [utcEndDate] 
         * @param {boolean} [sendByEmail] 
         * @param {string} [emails] 
         * @param {boolean} [isAsync] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1reportserviceprovideridreestrcsvReportGET(id: number, utcBeginDate?: Date, utcEndDate?: Date, sendByEmail?: boolean, emails?: string, isAsync?: boolean, options?: AxiosRequestConfig): Promise<AxiosResponse<ErrorStatus>> {
            return ReportApiFp(configuration).v1reportserviceprovideridreestrcsvReportGET(id, utcBeginDate, utcEndDate, sendByEmail, emails, isAsync, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Формирвоание реестра сверенных транзакций по ПУ
         * @param {number} id 
         * @param {Date} [utcBeginDate] 
         * @param {Date} [utcEndDate] 
         * @param {boolean} [sendByEmail] 
         * @param {string} [emails] 
         * @param {boolean} [isAsync] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1reportserviceprovideridreestrwithclientReportGET(id: number, utcBeginDate?: Date, utcEndDate?: Date, sendByEmail?: boolean, emails?: string, isAsync?: boolean, options?: AxiosRequestConfig): Promise<AxiosResponse<ConfirmedTransactionReestr>> {
            return ReportApiFp(configuration).v1reportserviceprovideridreestrwithclientReportGET(id, utcBeginDate, utcEndDate, sendByEmail, emails, isAsync, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportApi - object-oriented interface
 * @export
 * @class ReportApi
 * @extends {BaseAPI}
 */
export class ReportApi extends BaseAPI {
    /**
     * 
     * @summary Формирование ежемесячного акта по ПУ
     * @param {number} id 
     * @param {Date} [utcBeginDate] 
     * @param {Date} [utcEndDate] 
     * @param {boolean} [sendByEmail] 
     * @param {string} [emails] 
     * @param {boolean} [isAsync] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public async v1reportserviceprovideridmonthlyactReportGET(id: number, utcBeginDate?: Date, utcEndDate?: Date, sendByEmail?: boolean, emails?: string, isAsync?: boolean, options?: AxiosRequestConfig) : Promise<AxiosResponse<MonthlyAct>> {
        return ReportApiFp(this.configuration).v1reportserviceprovideridmonthlyactReportGET(id, utcBeginDate, utcEndDate, sendByEmail, emails, isAsync, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Формирвоание реестра сверенных транзакций по ПУ
     * @param {number} id 
     * @param {Date} [utcBeginDate] 
     * @param {Date} [utcEndDate] 
     * @param {boolean} [sendByEmail] 
     * @param {string} [emails] 
     * @param {boolean} [isAsync] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public async v1reportserviceprovideridreestrReportGET(id: number, utcBeginDate?: Date, utcEndDate?: Date, sendByEmail?: boolean, emails?: string, isAsync?: boolean, options?: AxiosRequestConfig) : Promise<AxiosResponse<ConfirmedTransactionReestr>> {
        return ReportApiFp(this.configuration).v1reportserviceprovideridreestrReportGET(id, utcBeginDate, utcEndDate, sendByEmail, emails, isAsync, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Формирвоание реестра сверенных транзакций по ПУ в формате csv
     * @param {number} id 
     * @param {Date} [utcBeginDate] 
     * @param {Date} [utcEndDate] 
     * @param {boolean} [sendByEmail] 
     * @param {string} [emails] 
     * @param {boolean} [isAsync] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public async v1reportserviceprovideridreestrcsvReportGET(id: number, utcBeginDate?: Date, utcEndDate?: Date, sendByEmail?: boolean, emails?: string, isAsync?: boolean, options?: AxiosRequestConfig) : Promise<AxiosResponse<ErrorStatus>> {
        return ReportApiFp(this.configuration).v1reportserviceprovideridreestrcsvReportGET(id, utcBeginDate, utcEndDate, sendByEmail, emails, isAsync, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Формирвоание реестра сверенных транзакций по ПУ
     * @param {number} id 
     * @param {Date} [utcBeginDate] 
     * @param {Date} [utcEndDate] 
     * @param {boolean} [sendByEmail] 
     * @param {string} [emails] 
     * @param {boolean} [isAsync] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public async v1reportserviceprovideridreestrwithclientReportGET(id: number, utcBeginDate?: Date, utcEndDate?: Date, sendByEmail?: boolean, emails?: string, isAsync?: boolean, options?: AxiosRequestConfig) : Promise<AxiosResponse<ConfirmedTransactionReestr>> {
        return ReportApiFp(this.configuration).v1reportserviceprovideridreestrwithclientReportGET(id, utcBeginDate, utcEndDate, sendByEmail, emails, isAsync, options).then((request) => request(this.axios, this.basePath));
    }
}
