import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';

import { globalAppConfig, globalAppConfigUrl } from './appConfig';

import { App } from './app/App';

import './i18n/i18n';
import './index.scss';
import { eposCabinetApiCalls } from 'apis/cabinetApi/cabinetApiCalls';


// PUBLIC_URL задается через cross-env в scripts
const { PUBLIC_URL } = process.env;


axios.get(`${PUBLIC_URL}/${globalAppConfigUrl}`)
	.then((response) => {
		globalAppConfig.appConfig = response.data;
	})
	.catch((error) => {
		console.error('Error: appConfig.json didn\'t read correctly. DefaultAppConfig will be applied.');
	})
	.then(() => {
		ReactDOM.render(
			<React.StrictMode>
				<App />
			</React.StrictMode>,
			document.getElementById('root')
		);
	});
