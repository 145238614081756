import React from 'react';
import { extend } from 'lodash';
import { muiEsasStyled } from 'app/theme';
import MenuUnstyled, {
	MenuUnstyledProps,
} from '@mui/base/MenuUnstyled';
import PopperUnstyled from '@mui/base/PopperUnstyled';
// Types
import { SxProps, Theme } from '@mui/material/styles';


interface IAppMenuListboxProps extends React.ComponentProps<'ul'> {
	sx?: SxProps<Theme>;
};
const AppMenuListbox: React.FC<IAppMenuListboxProps> = muiEsasStyled('ul', {
	// Перечисляем пропсы, которые не будут отображаться атрибутами в DOM
	shouldForwardProp: (prop) => (
		prop !== 'sx' && prop !== 'ownerState'
	),
	name: 'AppMenuListbox'
})<IAppMenuListboxProps>(({theme, sx = []}) => {
	return extend({
		margin: 0,
		padding: `${theme.spacing(2)} 0`,
		background: theme.color.bg_01,
		borderRadius: theme.spacing(2),
		boxShadow: theme.depth.tertiary,
	}, ...Array.isArray(sx) ? sx : [sx])
});


const Popper = muiEsasStyled(PopperUnstyled)`
	z-index: 1;
`;


const AppMenu: React.FC<MenuUnstyledProps> = (props) => {
	const { components, children, ...other } = props;
	
	return (
		<MenuUnstyled
			components={{
				Root: Popper,
				Listbox: AppMenuListbox,
				...components
			}}
			{...other}
		>
			{children}
		</MenuUnstyled>
	);
}


export { AppMenu, AppMenuListbox };



/*
export default function UnstyledMenuSimple() {
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
	const isOpen = Boolean(anchorEl);
	const buttonRef = React.useRef<HTMLButtonElement>(null);
	const menuActions = React.useRef<MenuUnstyledActions>(null);

	const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		if (isOpen) {
			setAnchorEl(null);
		} else {
			setAnchorEl(event.currentTarget);
		}
	};

	const handleButtonKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
		if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
			event.preventDefault();
			setAnchorEl(event.currentTarget);
			if (event.key === 'ArrowUp') {
				menuActions.current?.highlightLastItem();
			}
		}
	};

	const close = () => {
		setAnchorEl(null);
		buttonRef.current!.focus();
	};

	const createHandleMenuClick = (event: React.MouseEvent, menuItem: string) => {
		event.stopPropagation();
		return () => {
			console.log(`Clicked on ${menuItem}`);
			close();
		};
	};

	return (
		<div>
			<TriggerButton
				type="button"
				onClick={handleButtonClick}
				onKeyDown={handleButtonKeyDown}
				ref={buttonRef}
				aria-controls={isOpen ? 'simple-menu' : undefined}
				aria-expanded={isOpen || undefined}
				aria-haspopup="menu"
			>
				Language
			</TriggerButton>

			<MenuUnstyled
				actions={menuActions}
				open={isOpen}
				onClose={close}
				anchorEl={anchorEl}
				components={{ Root: Popper, Listbox: StyledListbox }}
				componentsProps={{ listbox: { id: 'simple-menu' } }}
			>
				<StyledMenuItem onClick={(event) => createHandleMenuClick(event, 'English')}>
					English
				</StyledMenuItem>
				<StyledMenuItem onClick={(event) => createHandleMenuClick(event, '中文')}>中文</StyledMenuItem>
				<StyledMenuItem onClick={(event) => createHandleMenuClick(event, 'Português')}>
					Português
				</StyledMenuItem>
			</MenuUnstyled>
		</div>
	);
}
*/

