import React from 'react';
import {
	Stack, StackProps
} from '@mui/material';


const AppTableFilters: React.FC<StackProps> = ({ children, sx = [], ...props }) => {
	return (
		<Stack
			spacing={6}
			sx={[
				(theme) => ({}),
				...(Array.isArray(sx) ? sx : [sx])
			]}
			{...props}
		>
			{children}
		</Stack>
	);
}

export { AppTableFilters };
