import React from 'react';
import { Divider, DividerProps } from '@mui/material';


const AppDivider: React.FC<DividerProps> = ({ sx = [] }) => {
	return (
		<Divider
			sx={[
				(theme) => ({
					borderColor: theme.color.tertiary
				}),
				...(Array.isArray(sx) ? sx : [sx])
			]}
		/>
	);
}

export { AppDivider };
