import { useCallback } from 'react';
import { useAppDispatch, useAppSelector, shallowEqual } from 'hooks';
import { decodeTokenClaims } from 'utils';
// Types
import { eposCabinetApiCalls } from 'apis/cabinetApi/cabinetApiCalls';
import { userInfoActions } from './userInfoSlice';
import { EUserRoles, IServiceProvider, IUserInfo } from 'apis/cabinetApi/extraTypes';


interface IUseUserInfoCRUD {
	/** Профиль авторизованного через iii пользователя, полученный с cabinet api */
	readonly userInfo: IUserInfo | null;
	readonly userProvider: IServiceProvider | null;
	readonly getUserInfo: () => Promise<void>;
	readonly isAggregator: boolean;
	readonly isProvider: boolean;
};

const useUserInfoCRUD = (): IUseUserInfoCRUD => {
	const { accessToken, userInfo, userProvider } = useAppSelector((store) => store.userInfo, shallowEqual);
	const appDispatch = useAppDispatch();

	const getUserInfo = useCallback(async () => {
		if (accessToken === null) return;
		const userId = decodeTokenClaims(accessToken).sub;
		const userResponse = await eposCabinetApiCalls.getUserById(userId);
		const userInfo = userResponse.data;
		if (userResponse.ok && userInfo) {
			appDispatch(userInfoActions.userInfoFetched(userInfo));
		} else {
			return;
		}

		if (userInfo.role === EUserRoles.aggregator || userInfo.serviceProviderId == null) {
			return;
		}
		const providerResponce = await eposCabinetApiCalls.getServiceProviderById(userInfo.serviceProviderId)
		const serviceProvider = providerResponce.data;
		if (providerResponce.ok && serviceProvider) {
			appDispatch(userInfoActions.userProviderFetched(serviceProvider));
		} else {
			return;
		}
	}, [accessToken]);

	const isAggregator = userInfo?.role === 'epos.aggregator';
	const isProvider = userInfo?.role === 'epos.sp';


	return {
		userInfo,
		userProvider,
		getUserInfo,
		isAggregator,
		isProvider
	};
}

export { useUserInfoCRUD };
