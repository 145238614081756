import { useState, useLayoutEffect } from 'react';


const queries = [
	'(max-width: 899px)',
	'(min-width: 900px) and (max-width: 1199px)',
	'(min-width: 1200px)'
];


interface IUseMatchMedia {
	/**	true при viewportWidth `[0 - 900)` px */
	isMobile:  boolean;
	/**	true при viewportWidth `[900 - 1200)` px */
	isTablet:  boolean;
	/**	true при viewportWidth `[1200 - ...)` px */
	isDesktop: boolean;
};
export const useMatchMedia = (): IUseMatchMedia => {
	// при SSR
	// if (typeof window === 'undefined') return {};

	const mediaQueryLists = queries.map((query) => matchMedia(query));

	const getValues = () => mediaQueryLists.map((mql) => mql.matches);

	const [values, setValues] = useState(getValues);

	useLayoutEffect(() => {
		const handler = () => setValues(getValues);

		mediaQueryLists.forEach((mql) => mql.addEventListener('change', handler));

		return () => mediaQueryLists.forEach((mql) => mql.removeEventListener('change', handler));
	}, []);

	return {
		isMobile:  values[0],
		isTablet:  values[1],
		isDesktop: values[2],
	};
	
	// ['isMobile', 'isTablet', 'isDesktop'].reduce((acc, screen, index) => ({
	// 	...acc,
	// 	[screen]: values[index]
	// }), {});
}
