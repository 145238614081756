import React from 'react';
import {
	Stack
} from '@mui/material';
import { AppChip } from 'app/components';
// Types
import { SxProps, Theme } from '@mui/system';
import { IAppChipProps } from 'app/components';
import {
	ServiceProviderStateEnum,
	ServiceProviderERIPStateEnum
} from 'apis/cabinetApi/extraTypes';


interface IEposStateChipsProps {
	state?: ServiceProviderERIPStateEnum | ServiceProviderStateEnum;
	eripState?: ServiceProviderERIPStateEnum | ServiceProviderStateEnum;
	spacing?: number;
	sx?: SxProps<Theme>;
}
const EposStateChips: React.FC<IEposStateChipsProps> = ({ state = '0', eripState = '0', spacing = 2, sx = [] }) => {
	const statuses: { [key: string]: IAppChipProps['status'] } = {
		'0': 'error',
		'1': 'success',
		'2': 'default',
	};

	return (
		<Stack
			direction="row"
			spacing={spacing}
			sx={[
				(theme) => ({

				}),
				...(Array.isArray(sx) ? sx : [sx])
			]}
		>
			<AppChip
				status={statuses[state]}
				size="md"
			>
				{'A'}
			</AppChip>

			<AppChip
				status={statuses[eripState]}
				size="md"
			>
				{'E'}
			</AppChip>
		</Stack>
	);
}

export { EposStateChips };
