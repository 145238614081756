import { combineReducers } from 'redux';

import { authSlice } from 'app/auth/redux/authSlice';
import { userInfoSlice } from 'app/modules/profile/redux/userInfoSlice';
import { directorySlice } from 'app/modules/directory/redux/directorySlice';
import { invoicesSlice } from 'app/modules/invoices/redux/invoicesSlice';
import { serviceProvidersSlice } from 'app/modules/serviceProviders/redux/providersSlice';
import { usersSlice } from 'app/modules/users/redux/usersSlice';
import { currencyRatesSlice } from 'app/modules/currencyRates/redux/currencyRatesSlice';

const rootReducer = combineReducers({
	//* persisted reducer, сохраняется в local storage
	auth: authSlice.reducer,

	//* стандартные редюсеры
	//	данные пользователя, в модуле auth
	userInfo: userInfoSlice.reducer,
	//  данные с helpers api
	directory: directorySlice.reducer,
	//	счета
	invoices: invoicesSlice.reducer,
	//  поставщики услуг
	serviceProviders: serviceProvidersSlice.reducer,
	//	пользователи
	users: usersSlice.reducer,
	//  курсы валют
	currencyRates: currencyRatesSlice.reducer,


});


export { rootReducer };
