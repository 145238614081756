import React, { useState } from 'react';
import { Collapse, Box, LinearProgress } from '@mui/material';
import { AppTableRow, AppTableCell } from './';
// Types
import { IAppTableRowProps } from './';


interface IAppTableExpandableRowProps extends IAppTableRowProps {
	rowId:          string;
	isExpanded:     boolean;
	isLoading:      boolean;
	expandColSpan:	number;
	expandChildren:	React.ReactNode;
	onRowClick:     any;
};
export const AppTableExpandableRow: React.FC<IAppTableExpandableRowProps> = (props) => {
	const {
		children, rowId, isExpanded, isLoading,
		expandChildren, expandColSpan, onRowClick
	} = props;


	return (
		<React.Fragment>
			<AppTableRow
				onClick={(event) => onRowClick(event, rowId)}
				selected={isExpanded}
				sx={(theme) => ({
					borderBottom: 'none !important',
					cursor: 'pointer',
				})}
			>
				{children}
			</AppTableRow>

			<AppTableRow selected={true} >
				<AppTableCell
					colSpan={expandColSpan}
					sx={{
						paddingTop: 0,
						paddingBottom: 0,
					}}
				>
					{(isLoading) && (
						<Box
							sx={(theme) => ({
								position: 'absolute',
								margin: 0,
								top: theme.spacing(-1),
								left: 0,
								right: 0,
							})}
						>
							<LinearProgress/>
						</Box>
					)}

					<Collapse in={isExpanded}>
						{expandChildren}
					</Collapse>
				</AppTableCell>
			</AppTableRow>
		</React.Fragment>
	)
}
AppTableExpandableRow.displayName = 'AppTableExpandableRow';
