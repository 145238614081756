import * as Yup from 'yup';
import { errMsg } from './_errorMessages';
import {
	nullableString,
	nullableNumber, 
	nullableDate,
	today,
	nullableArray,
	percentSchema,
	prepareBeginDate,
	prepareEndDate,
} from './_shortcuts';


export const invoiceSchema = Yup.object().shape({
	'id': nullableString(),
	merchantInfo: Yup.object().shape({
	//*	Организация
		'serviceProviderId': Yup.number()
			.required(errMsg.required),

	//*	Услуга
		'serviceId': Yup.number()
			.when('serviceProviderId', {
				is: (id: any) => id,
				then: (schema) => schema
					.required(errMsg.required)
			}),

		retailOutlet: Yup.object().shape({
	//*	Торговая точка
			'code': Yup.number()
				.test('is-required-parent', errMsg.required, (value, context) => {
					const { serviceProviderId, serviceId } = context?.from?.[1].value as any;
					// Если выбран ПУ, тогда optional, иначе required
					return (serviceProviderId || serviceId) ? Boolean(value) : true;
				}),
				// .required(errMsg.required),
		}).nullable().default(null),
	//*	Кассовое место
		cashBox: Yup.object().shape({
			'code': Yup.number()
		}).nullable().default(null)
	}),

	//*	Номер счета
	'number': nullableString()
		.required(errMsg.required),
	
	//*	Валюта
	'currency': nullableString()
		.required(errMsg.required),

	items: nullableArray().of(Yup.object().shape({
	//*	Артикул
		'code': nullableString(),
	//*	Назначение платежа
		'name': nullableString()
			.required(errMsg.required),
		unitPrice: Yup.object({
	//*	Сумма
			'value': Yup.number()
				// .positive(errMsg.min.than0)
				.required(errMsg.required)
		}),
		discount: Yup.object({
	//*	Скидка (процент)
			'percent': percentSchema(),
	//*	Скидка (сумма)
			'amount': nullableNumber()
		}).nullable().default(null),
		'quantity': Yup.number()
			.default(1)
			.required(errMsg.required)
	})),

	billingInfo: Yup.object({
	//*	ФИО
		contact: Yup.object({
			'lastName': nullableString(),
			'firstName': nullableString(),
			'middleName': nullableString()
		})
	}).nullable().default(null),

	paymentRules: Yup.object({
	//*	Разрешить редактирование суммы при оплате
		'requestAmount': Yup.boolean(),
	//*	Тариф
		'isTariff': Yup.boolean(),
	//*	Тариф до даты
		'isTariffExpire': Yup.boolean(),
	//*	Разрешить редактирование ФИО при оплате
		'requestPersonName': Yup.boolean(),
	//*	Разрешить редактировать назначение платежа
		'requestPurpose': Yup.boolean(),
	//*	Запросить номер телефона
		'requestPhone': Yup.boolean(),
	//*	Запросить адрес эл. почти (email)
		'requestEMail': Yup.boolean(),
	//*	Запросить почтовый адрес/адрес доставки
		'requestAddress': Yup.boolean(),
	}),

	//*	Доступен на оплату с даты
	'dateInAirUTC': nullableDate()
		.transform(prepareBeginDate)
		.required(errMsg.required),
	
	paymentDueTerms: Yup.object({
	//*	Срок оплаты (дата)
		'dueUTC': nullableDate()
			.min(prepareEndDate(today), errMsg.laterNow)
			.transform(prepareEndDate)
			.test('is-required-parent', errMsg.required, (value, context) => {
				const { isTariff, isTariffExpire } = (context as any).from[1].value.paymentRules;
				return (isTariff && !isTariffExpire) ? true : Boolean(value);
			}),
	//* Срок оплаты (дней)
		'termsDay': Yup.number(),
	}).nullable().default(null),

	//*	Примечание
	'note': nullableString()

});
