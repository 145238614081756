import { useMatchMedia } from 'hooks';
import React, { createContext, useContext, useState, useCallback, useMemo, useEffect, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';


interface IUseLayoutUIContextValue {
	isAsideMenuVisible: boolean;
	showAsideMenu: () => void;
	hideAsideMenu: () => void;

	comfirmModalParams: IComfirmModalParams | null;
	showConfirmModal:   (params: IComfirmModalParams) => void;
	hideConfirmModal:   () => void;

	isGetMobileAppModalOpen: boolean;
	hideGetMobileAppModal:   () => void;
};
interface IComfirmModalParams {
	title:     string;
	text?:     string;
	callback?: () => void;
	action?:   string;
};
// Трюк с undefined в качестве init value контекста
// https://stackoverflow.com/questions/58193424/passing-state-with-usecontext-in-typescript
const LayoutContext = createContext<IUseLayoutUIContextValue | undefined>(undefined);
const useLayoutUIContext = () => {
	const contextValue = useContext(LayoutContext);
	if (contextValue === undefined) throw new Error('Expected context value to be set');
	return contextValue;
};


const LayoutUIProvider: React.FC = ({ children }) => {
	const { pathname } = useLocation();

	// При переходе на новую страницу - скрол в начало
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	//* ===== Aside меню =====
	const [isAsideMenuVisible, setAsideMenuVisible] = useState<boolean>(true);

	const showAsideMenu = () => {
		setAsideMenuVisible(true);
	};
	const hideAsideMenu = () => {
		setAsideMenuVisible(false);
	}


	//* ===== Модальное окно подтверждения действия =====
	const [comfirmModalParams, setComfirmModalParams] = useState<IUseLayoutUIContextValue['comfirmModalParams']>(null);
	const showConfirmModal = useCallback<IUseLayoutUIContextValue['showConfirmModal']>((params) => {
		setComfirmModalParams({
			...params,
			callback: () => {
				params.callback?.();
				hideConfirmModal();
			}
		});
	}, []);
	const hideConfirmModal = useCallback<IUseLayoutUIContextValue['hideConfirmModal']>(() => {
		setComfirmModalParams(null);
	}, []);


	//* ===== Модальное окно загрузки мобильного приложения =====
	const { isMobile } = useMatchMedia();
	const [isGetMobileAppModalOpen, setGetMobileAppModalOpen] = useState(false);
	const hideGetMobileAppModal = () => setGetMobileAppModalOpen(false);
	useLayoutEffect(() => {
		setGetMobileAppModalOpen(isMobile);
	}, [isMobile]);


	return (
		<LayoutContext.Provider
			value={{
				// Aside
				isAsideMenuVisible,
				showAsideMenu,
				hideAsideMenu,
				// Confirm modal
				comfirmModalParams,
				showConfirmModal,
				hideConfirmModal,
				// GetMobileAppModal
				isGetMobileAppModalOpen,
				hideGetMobileAppModal
			}}
		>
			{children}
		</LayoutContext.Provider>
	);
}


export { LayoutUIProvider, useLayoutUIContext };
export type { IComfirmModalParams };
