import React from 'react';
import { useFormikContext, useField } from 'formik';
import { AppFormSwitch } from 'app/components';


const StateFormSwitch: React.FC<{ name: string }> = ({ name }) => {
	const { setFieldValue } = useFormikContext();
	const [ field ] = useField(name);

	return (
		<AppFormSwitch
			name={field.name}
			value={field.value}
			checked={field.value == 1}
			onChange={(event) => {
				const { checked } = event.target;
				setFieldValue(field.name, (checked) ? 1 : 0);
			}}
		/>
	);
}


StateFormSwitch.displayName = 'StateFormSwitch';
export { StateFormSwitch };
