import React from 'react';
import classNames from 'classnames';
import { extend } from 'lodash';
import { muiEsasStyled } from 'app/theme';
import MenuItemUnstyled, {
	menuItemUnstyledClasses,
	MenuItemUnstyledProps,
} from '@mui/base/MenuItemUnstyled';
// Types
import { SxProps, Theme } from '@mui/material/styles';


interface IAppMenuItemProps extends MenuItemUnstyledProps {
	sx?: SxProps<Theme>;
};
const AppMenuItem: React.FC<IAppMenuItemProps> = muiEsasStyled(MenuItemUnstyled, {
	// Перечисляем пропсы, которые не будут отображаться атрибутами в DOM
	shouldForwardProp: (prop) => (
		prop!== 'sx'
	),
	name: 'AppMenuItem'
})<IAppMenuItemProps>(({theme}) => {
	return extend({
		listStyle: 'none',
	}, []);
});

// const AppMenuItem = styled(MenuItemUnstyled)(
// 	({ theme }) => `
// 	list-style: none;
// 	padding: 8px;
// 	border-radius: 0.45em;
// 	cursor: default;

// 	&:last-of-type {
// 		border-bottom: none;
// 	}

// 	&.${menuItemUnstyledClasses.focusVisible} {
// 		outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
// 		background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
// 		color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
// 	}

// 	&.${menuItemUnstyledClasses.disabled} {
// 		color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
// 	}

// 	&:hover:not(.${menuItemUnstyledClasses.disabled}) {
// 		background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
// 		color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
// 	}
// 	`,
// );


export { AppMenuItem };
