import React from 'react';
import { extend } from 'lodash';

import { AppIcon } from 'app/components';
// Types
import { SelectUnstyledOwnerState } from '@mui/base/SelectUnstyled';
import { IAppIconProps } from 'app/components';


interface IAppSelectIconProps extends Omit<IAppIconProps, 'iconType'> {
	ownerState: SelectUnstyledOwnerState<any>;
};
const AppSelectIcon: React.FC<IAppSelectIconProps> = ({ ownerState }) => {
	const { open, disabled } = ownerState;
	return (
		<AppIcon 
			iconType="down"
			size="sm"
			sx={(theme) => extend(
				{
					marginLeft: theme.spacing(4),
					color: theme.color.secondary,
					transition: 'color 0.3s, transform 0.3s',
				},
				(open) && {
					transform: `rotate(180deg)`,
				},
				(disabled) && {
					color: theme.color.tertiary,
				}
			)}
		/>
	);
}
AppSelectIcon.displayName = 'AppSelectIcon';


export { AppSelectIcon };