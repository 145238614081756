import React, { useMemo } from 'react';
import { useField } from 'formik';
import { useDirectoryCRUD } from 'hooks';
import {
	MenuItem,
	Checkbox,
	ListItemText,
} from '@mui/material';
import { AppFormSelect } from 'app/components';
// Types
import { ICurrency } from 'apis/cabinetApi/extraTypes';


interface ICurrenciesFormSelectProps {
	name:        string;
	multiple?:   boolean;
	withoutBYN?: boolean;
}
const CurrenciesFormSelect: React.FC<ICurrenciesFormSelectProps> = ({ name, multiple, withoutBYN }) => {
	const { currencies } = useDirectoryCRUD();

	const options = useMemo(() => {
		return (withoutBYN)
			? currencies.filter((c) => c.code !== 933)
			: currencies;
	}, [currencies, withoutBYN])

	const [formik, meta, helpers] = useField(name);


	return (multiple) ? (
		<AppFormSelect
			name={formik.name}
			multiple
			// На входе и выходе значение в formik.values { code: number }: ICurrency,
			// для Select input.value: number, берем currency.code[]
			value={formik.value.map((currency: ICurrency) => currency.code)}
			onChange={(event) => {
				const codesArr = (event.target.value as Array<number>).filter(code => code);
				const currenciesArr: Array<ICurrency> = codesArr.map((code) => ({ code }));
				helpers.setValue(currenciesArr);
			}}
			renderValue={(selected: any) => selected
				.map((code: number) => currencies.find((currency) => currency.code === code))
				.map((currency: ICurrency) => currency?.abbr)
				.join(', ')
			}
		>
			{options.map((currency, index) => (
				<MenuItem key={index} value={currency.code}>
					<Checkbox checked={Boolean(formik.value.find((value: ICurrency) => value.code === currency.code))} />
					<ListItemText primary={currency.abbr}/>
				</MenuItem>
			))}
		</AppFormSelect>
	) : (
		<AppFormSelect name={formik.name}>
			{options.map((currency) => (
				<MenuItem key={currency.code} value={currency.code}>
					{currency.abbr}
				</MenuItem>
			))}
		</AppFormSelect>
	);
}


CurrenciesFormSelect.displayName = 'CurrenciesFormSelect';
export { CurrenciesFormSelect };
