import React, { createContext, useContext, useState, useEffect } from 'react';
import { SplashScreen } from 'app/components';
// Types


interface ISplashScreenContext {
	setCount?: React.Dispatch<React.SetStateAction<number>>;
};
const SplashScreenContext = createContext<ISplashScreenContext>({});

const SplashScreenProvider: React.FC = ({ children }) => {
	const [count, setCount] = useState(0);
	let visible = count > 0;

	useEffect(() => {
		const splashScreen = document.getElementById('splash-screen');

		// Show SplashScreen
		if (splashScreen && visible) {
			splashScreen.classList.remove('hidden');

			return () => {
				splashScreen.classList.add('hidden');
			};
		}

		// Hide SplashScreen
		let timeout: ReturnType<typeof setTimeout>;
		if (splashScreen && !visible) {
			timeout = setTimeout(() => {
				splashScreen.classList.add('hidden');
			}, 500);
		}

		return () => {
			clearTimeout(timeout);
		};
	}, [visible]);

	return (
		<SplashScreenContext.Provider value={{ setCount }}>
			{children}

			<SplashScreen/>
		</SplashScreenContext.Provider>
	)
}

const SplashScreenFallback: React.FC<{ visible?: boolean }> = ({ visible = true }) => {
	const { setCount } = useContext(SplashScreenContext);


	useEffect(() => {
		if (!visible || !setCount) {
			return;
		}

		setCount((prev: number) => {
			return prev + 1;
		});

		return () => {
			setCount((prev: number) => {
				return prev - 1;
			});
		};
	}, [setCount, visible]);
	
	return null;
}

export { SplashScreenProvider, SplashScreenFallback };
