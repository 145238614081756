import { eposCabinetApiCalls } from 'apis/cabinetApi/cabinetApiCalls';
import { directoryActions } from './directorySlice';
// Types
import { TThunkAction } from 'reduxStore/reduxStore';


export const fetchDirectory = (): TThunkAction<Promise<void>> => async (dispatch) => {
	const defaultParams = { skip: 0, count: 1000, filter: {} };

	const MCCsResponse = await eposCabinetApiCalls.getMCCsAll(defaultParams);
	if (MCCsResponse.ok && MCCsResponse.data) {
		dispatch(directoryActions.getMCCsAllFulfilled(MCCsResponse.data.records));
	}
	const currenciesResponse = await eposCabinetApiCalls.getCurrenciesAll(defaultParams);
	if (currenciesResponse.ok && currenciesResponse.data) {
		dispatch(directoryActions.getCurrenciesAllFulfilled(currenciesResponse.data.records));
	}
	const localesResponse = await eposCabinetApiCalls.getLocalesAll(defaultParams);
	if (localesResponse.ok && localesResponse.data) {
		dispatch(directoryActions.getLocalesAllFulfilled(localesResponse.data.records));
	}
	const reportsResponse = await eposCabinetApiCalls.getReportsAll(defaultParams);
	if (reportsResponse.ok && reportsResponse.data) {
		dispatch(directoryActions.getReportsAllFulfilled(reportsResponse.data.records));
	}

	return;
}

