import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import { useField } from 'formik';
// Material-ui
import {
	CircularProgress,
	TextField,
	Autocomplete,
	AutocompleteChangeReason,
} from '@mui/material';
import { autocompleteSxProp } from './epos/_sxProps';
import { useTranslation } from 'react-i18next';
import { AppIcon } from './AppIcon';


const IconComponent: React.FC = (props) => {
	return (
		<AppIcon
			iconType="down"
			size="sm"
			sx={(theme) => ({
				top: 'auto',
				right: theme.spacing(2),
				color: theme.color.secondary,
				transition: 'color 0.3s, transform 0.3s'
			})}
			{...props}
		/>
	);
}


interface IAppAsyncAutocompleteProps<T = any> {
	name: string;
	autocompleteValue?: any;
	onAutocompleteChange: (value: T | null, reason?: AutocompleteChangeReason) => void;
	getOptionsAsync: (searchString: string, count: number) => Promise<any>;
	getOptionLabel?: (option: any) => string;
	getOptionDisabled?: (option: any) => boolean;
	renderOption?: (props: React.HTMLAttributes<HTMLLIElement>, option: any) => React.ReactNode;
	disabled?: boolean;
	freeSolo?: boolean;
	maxOptionsLength?: number;
};
const AppAsyncAutocomplete: React.FC<IAppAsyncAutocompleteProps> = (props) => {
	const {
		name,
		onAutocompleteChange,
		getOptionsAsync,
		getOptionLabel,
		getOptionDisabled,
		renderOption,
		autocompleteValue,
		disabled = false,
		freeSolo = false,
		maxOptionsLength = 20,
	} = props;
	const { t } = useTranslation(undefined, { keyPrefix: 'validation' });
	const [ field, { error, touched }, helpers ] = useField(name);

	// Обработчик автокомплита. Value берем извне
	const autocompleteChangeHandler = (event: React.SyntheticEvent<Element, Event>, value: any, reason: AutocompleteChangeReason) => {
		onAutocompleteChange(value, reason);
	}

	// Value и обработчик инпута
	const [inputValue, setInputValue] = useState<string>('');
	
	const prevInputValue = useRef<string>('');
	const inputChangeHandler = (event: React.SyntheticEvent<Element, Event>, value: string, reason: any) => {
		const nextValue = value.replace(/^\s+/, '');
		if (freeSolo && autocompleteValue == null) {
			helpers.setValue(nextValue);
		}
		setInputValue((prevValue) => {
			prevInputValue.current = prevValue;
			return nextValue;
		});
	}

	// При сбросе initialValue очистить input, сам автокомплит не очищает
	useEffect(() => {
		if (autocompleteValue === null && inputValue) {
			setInputValue('');
		}
	}, [autocompleteValue]);

	const requestDelayMs = 500;
	const [open, setOpen] = useState<boolean>(false);
	const [options, setOptions] = useState<Array<any>>([]);
	const [isOptionsLoading, setOptionsLoading] = useState<boolean>(false);

	useLayoutEffect(() => {
		// При фокусе и непустом значении не делать запрос
		if (open && autocompleteValue) return;
		// При фокусе и неполных options сделать запрос
		if (open && options.length < maxOptionsLength) {
			updateOptions();
		} else {
			setOptionsLoading(false);
		}
	}, [open]);

	useLayoutEffect(() => {
		// При потере инпутом фокуса не делаем запрос
		if (!open) return;

		// Если пользовалель очистил инпут:
		if (inputValue.length === 0) {
			// делаем запрос без задержки
			updateOptions();
			return;
		}

		// Если пользовалель удаляет или меняет символы в инпуте:
		if (inputValue.length <= prevInputValue.current.length) {
			// делаем запрос с задержкой
			updateOptions(requestDelayMs);
			return;
		}

		// Если пользователь добавляет символы в инпут:
		// делаем запрос, если options заполнен (total больше лимита options)
		if (inputValue.length > 0 && options.length >= maxOptionsLength) {
			updateOptions(requestDelayMs);
			return;
		}
	}, [inputValue]);

	// Метод для вызова запроса с задержкой
	const delayTimer = useRef<NodeJS.Timeout | null>(null);
	function updateOptions(delayMs = 0) {
		if (delayTimer.current) {
			clearTimeout(delayTimer.current);
		}
		delayTimer.current = setTimeout(async () => {
			setOptionsLoading(true);
			try {
				const prepatedValue = inputValue.toLowerCase().trim();
				const response = await getOptionsAsync(prepatedValue, maxOptionsLength);
				setOptions(response.data.records);
			} catch(error) {
				setOpen(false);
			} finally {
				setOptionsLoading(false);
			}
		}, delayMs);
	}


	return (
		<Autocomplete
			options={options}
			getOptionLabel={getOptionLabel}
			getOptionDisabled={getOptionDisabled}
			renderOption={renderOption}
			// Предотвращение сортировки "на месте" при ожидании запроса
			filterOptions={(options.length < maxOptionsLength) ? undefined : (_) => _}

			value={autocompleteValue}
			onChange={autocompleteChangeHandler}

			inputValue={inputValue}
			onInputChange={inputChangeHandler}

			open={open}
			onOpen={() => setOpen(true)}
			onClose={() => setOpen(false)}

			loading={isOptionsLoading}
			loadingText={t('LOADING')}
			noOptionsText={t('NO_RESULTS')}

			renderInput={(params) => (
				<TextField
					{...params}
					// {...field}
					name={field.name}
					value={field.value}
					onBlur={field.onBlur}

					error={Boolean(touched && error)}
					helperText={touched && error && t(error)}
					sx={autocompleteSxProp}
					InputProps={{
						...params.InputProps,
						endAdornment: (<>
							{isOptionsLoading ? <CircularProgress color="primary" size={16} /> : null}
							{/* <IconComponent/> */}
							{params.InputProps.endAdornment}
							{/* {<IconComponent/>} */}
						</>),
					}}
				/>
			)}

			// forcePopupIcon={false}
			disabled={disabled}
			freeSolo={freeSolo}
		/>
	);
}


AppAsyncAutocomplete.displayName = 'AppAsyncAutocomplete';
export type { IAppAsyncAutocompleteProps };
export { AppAsyncAutocomplete };
