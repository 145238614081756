// Types
import { IModuleState } from 'reduxStore/reduxTypes';
import {
	IServiceProvider,
	IGetServiceProvidersAllParams,
	IService,
	IRetailOutlet,
	IContact,
	IServiceProviderReport,
	IAccount,
	IAddress,
	IBusinessCard,
	ICashBox,
	ICurrency,
	IEripMCC,
	ILegalInfo,
	IMCC,
	IMerchantInfo,
	INotifyParams,
	IOrigin,
	IRetailOutletMerchantInfo,
	IServiceComission,
	IServiceProviderContract,
	IServiceProviderIII,
	IServiceRestriction,
	ServiceProviderStateEnum,
} from 'apis/cabinetApi/extraTypes';
import { FormikHelpers } from 'formik';


//! ========== Common types ==========
export interface IServiceProviderAsProp {
	serviceProvider: IServiceProvider;
};
export interface IServiceProviderDTOAsProp {
	serviceProvider: ServiceProviderDTO;
};
export interface IServiceAsProp {
	service:      ServiceDTO;
};
export interface IRetailOutletAsProp {
	retailOutlet: RetailOutletDTO;
};
export interface ICashBoxAsProp {
	cashBox:			ICashBox;
};
export interface IContactAsProp {
	contact:      IContact;
};
export interface INoticeAsProp {
	notice:       Notice;
};
export interface IServiceProviderReportAsProp {
	report:       IServiceProviderReport;
};


//! ========== Models converters ==========
//* Преобразование после получения ПУ из redux для таблицы и редактрования
//* преобразование NotifyParams в Array<>
export class ServiceProviderDTO {
	readonly code: IServiceProvider['code'];
	readonly legalInfo?: ILegalInfo;
	readonly businessCard?: IBusinessCard;
	readonly merchant?: IMerchantInfo;
	readonly contract?: IServiceProviderContract;
	readonly services?: Array<ServiceDTO> | null;
	readonly contacts?: Array<IContact> | null;
	readonly currencies?: Array<ICurrency> | null;
	readonly retailOutlets?: Array<RetailOutletDTO> | null;
	readonly state: IServiceProvider['state'];
	readonly eripState?: IServiceProvider['eripState'];
	readonly reports?: Array<IServiceProviderReport> | null;
	readonly loyalityCode: IServiceProvider['loyalityCode'];
	readonly notifyParams: NotifyParamsDTO;
	readonly canBiometryPay: IServiceProvider['canBiometryPay'];
	readonly iiiInfo?: IServiceProviderIII;
	readonly origin?: IOrigin;

	constructor(sp: IServiceProvider | ServiceProviderDTO) {
		this.code = sp.code;
		this.legalInfo = sp.legalInfo;
		this.businessCard = sp.businessCard;
		this.merchant = sp.merchant;
		this.contract = sp.contract;
		this.services = sp.services?.map(s => new ServiceDTO(s)) || [];
		this.contacts = sp.contacts || [];
		this.currencies = sp.currencies || [];
		this.retailOutlets = sp.retailOutlets?.map(ro => new RetailOutletDTO(ro)) || [];
		this.state = sp.state;
		this.eripState = sp.eripState;
		this.reports = sp.reports || [];
		this.loyalityCode = sp.loyalityCode;
		this.notifyParams = new NotifyParamsDTO(sp.notifyParams);
		this.canBiometryPay = sp.canBiometryPay;
		this.iiiInfo = sp.iiiInfo;
		this.origin = sp.origin;
	}
}
export class ServiceDTO {
	readonly id?: IService['id'];
	readonly name?: IService['name'];
	readonly eripServiceCode?: IService['eripServiceCode'];
	readonly restrictions?: IServiceRestriction;
	readonly comissions?: IServiceComission;
	readonly account?: IAccount;
	readonly notifyParams: NotifyParamsDTO;
	readonly state: IService['state'];
	readonly eripState?: IService['eripState'];
	readonly requestServiceProvider: IService['requestServiceProvider'];
	readonly requestUrl: IService['requestUrl'];

	constructor(s: IService) {
		this.id = s.id;
		this.name = s.name;
		this.eripServiceCode = s.eripServiceCode;
		this.restrictions = s.restrictions;
		this.comissions = s.comissions;
		this.account = s.account;
		this.notifyParams = new NotifyParamsDTO(s.notifyParams);
		this.state = s.state;
		this.eripState = s.eripState;
		this.requestServiceProvider = s.requestServiceProvider;
		this.requestUrl = s.requestUrl;
	}
}
export class RetailOutletDTO {
	readonly code?: IRetailOutlet['code'];
	readonly address?: IAddress;
	readonly businessCard?: IBusinessCard;
	readonly retailOutletMerchantInfo?: IRetailOutletMerchantInfo;
	readonly eripMcc?: IEripMCC;
	readonly mcc?: IMCC;
	readonly cashBoxes?: Array<ICashBox> | null;
	readonly descr: IRetailOutlet['descr'];
	readonly notifyParams: NotifyParamsDTO;
	readonly state?: IRetailOutlet['state'];

	constructor(ro: IRetailOutlet) {
		this.code = ro.code;
		// this.address = ro.address;
		// Предыдущее приложение сохраняло город адреса в retailOutletMerchantInfo.storeLocalityCity
		// при редактировании ПУ перезаписать address.city, если он пустой и есть storeLocalityCity
		this.address = {
			...ro.address,
			city: ro.address?.city ?? ro.retailOutletMerchantInfo?.storeLocalityCity
		};
		this.businessCard = ro.businessCard;
		this.retailOutletMerchantInfo = ro.retailOutletMerchantInfo;
		this.eripMcc = ro.eripMcc;
		this.mcc = ro.mcc;
		this.cashBoxes = ro.cashBoxes;
		this.descr = ro.descr;
		this.notifyParams = new NotifyParamsDTO(ro.notifyParams);
		this.state = ro.state;
	}
}

export enum ENoticeTypes {
	emails = 'emails',
	smses =  'smses',
	urls =   'urls',
	users =  'users'
};

export class Notice {
	readonly type: ENoticeTypes;
	readonly notice: string | null;

	constructor(type: ENoticeTypes = ENoticeTypes.emails, notice: string | null = '') {
		this.type = type;
		this.notice = notice;
	}
}
export class NotifyParamsDTO {
	notices?: Array<Notice>;
	readonly emails?: INotifyParams['emails'];
	readonly smses?: INotifyParams['smses'];
	readonly urls?: INotifyParams['urls'];
	readonly users?: INotifyParams['users'];
	readonly cashBoxNotifyUrl?: INotifyParams['cashBoxNotifyUrl'];

	constructor(np?: INotifyParams | NotifyParamsDTO) {
		if (!np) return;
		if (np.hasOwnProperty('notices')) {
			this.emails = [];
			this.smses = [];
			this.urls = [];
			this.users = [];
			this.cashBoxNotifyUrl = np.cashBoxNotifyUrl;
			(np as any)?.notices?.forEach((notice: Notice) => {
				if (notice.type === ENoticeTypes.emails && notice.notice) this.emails?.push(notice.notice);
				if (notice.type === ENoticeTypes.smses && notice.notice) this.smses?.push(notice.notice);
				if (notice.type === ENoticeTypes.urls && notice.notice) this.urls?.push(notice.notice);
				if (notice.type === ENoticeTypes.users && notice.notice) this.users?.push(notice.notice);			
			});
			delete this.notices;
		} else {
			const notices: Array<Notice> = [];
			np.emails?.filter(n => n).forEach(n => notices.push(new Notice(ENoticeTypes.emails, n)));
			np.smses?.filter(n => n).forEach(n => notices.push(new Notice(ENoticeTypes.smses, n)));
			np.urls?.filter(n => n).forEach(n => notices.push(new Notice(ENoticeTypes.urls, n)));
			np.users?.filter(n => n).forEach(n => notices.push(new Notice(ENoticeTypes.users, n)));
			this.notices = notices;
		}
	}
}


export const reportAttachOptions = [
	{ value: 10, label: 'CSV'   },
	{ value: 20, label: 'PDF'   },
	{ value: 30, label: 'HTML'  },
	{ value: 40, label: 'EXCEL' }
];
export enum EReportAttachFileTypes {
	CSV   = 10,
	PDF   = 20,
	HTML  = 30,
	EXCEL = 40
};
export const contactOptions = [
	{ value: 'contact_person', label: 'CONTACT_PERSON' },
	{ value: 'chief',          label: 'CHIEF'          },
	{ value: 'accountant',     label: 'ACCOUNTANT'     }
];


//! ========== Form types ==========
export interface IServiceProviderTabPanelProps {
	value: string;
};
export enum ETabPanels {
	main					= 'main',
	services			= 'services',
	retailOutlets	= 'retailOutlets',
	contacts			= 'contacts',
	notifyParams	= 'notifyParams',
	reports				= 'reports',
	iiiInfo				= 'iiiInfo'
};
export type TTabStateTypes = 'list' | 'new' | 'edit';
export interface IListDataCardProps {
	onClickAction?: () => void;
	editAction?:    () => void;
	deleteAction?:  () => void;
};
export interface IServiceCardProps extends IListDataCardProps, IServiceAsProp {};
export interface IRetailOutletCardProps extends IListDataCardProps, IRetailOutletAsProp {};
export interface ICashBoxCardProps extends IListDataCardProps, ICashBoxAsProp {};
export interface IContactCardProps extends IListDataCardProps, IContactAsProp {};
export interface IReportCardProps extends IListDataCardProps, IServiceProviderReportAsProp {};
export interface INoticeCardProps extends IListDataCardProps, INoticeAsProp {};


export interface IProviderLocationState {
	// activeTabPanel?: ETabPanels;
	// servicesTabPanel?: {
	// 	view: 'list' | 'new' | number;
	// 	notifyParamsView: 'list' | 'new' | number;
	// };
	// retailOutletsTabPanel?: {
	// 	view: 'list' | number;
	// 	notifyParamsView: 'list' | number;
	// 	contactsView: 'list' | number;
	// 	cashBoxesView: 'list' | number;
	// };
	// contactsTabPanel?: {
	// 	view: 'list' | number;
	// };
	// notifyParamsTabPanel?: {
	// 	view: 'list' | number;
	// };
	// reportsTabPanel?: {
	// 	view: 'list' | number;
	// };


	/** Отображаемая таб-панель формы */ 
	tabPanel: ETabPanels;

	/** Состояние формы редактирования `услуг` ('list' | 'new' | 'edit') */
	servicesTabState: TTabStateTypes;
	/** Индекс редактируемой `услуги` */
	serviceIndex?: number;
	/** Состояние подформы редактирования нотификаций услуг */
	serviceNoticesState?: TTabStateTypes;
	/** Индекс редактируемой нотификации услуги */
	serviceNoticeIndex?: number;

	/** Состояние формы редактирования `РТТ` ('list' | 'new' | 'edit') */
	retailOutletsTabState: TTabStateTypes;
	/** Индекс редактируемой `РТТ` */
	retailOutletIndex?: number;
	/** Состояние подформы редактирования нотификаций РТТ */
	retailOutletNoticesState: TTabStateTypes;
	/** Индекс редактируемой нотификации РТТ */
	retailOutletNoticeIndex?: number;
	/** Состояние подформы редактирования контактов РТТ */
	retailOutletContactsState: TTabStateTypes;
	/** Индекс редактируемого контакта РТТ */
	retailOutletContactIndex?: number;
	/** Состояние подформы редактирования касс РТТ */
	retailOutletCashBoxesState: TTabStateTypes;
	/** Индекс редактируемой кассы РТТ */
	retailOutletCashBoxIndex?: number;

	/** Состояние формы редактирования `контактов` ('list' | 'new' | 'edit') */
	contactsTabState: TTabStateTypes;
	/** Индекс редактируемого `контакта` */
	contactIndex?: number;

	notifyParamsTabState: TTabStateTypes;
	noticeIndex?: number;
	notifyType?: ENoticeTypes;

	/** Состояние формы редактирования `отчетов` ('list' | 'new' | 'edit') */
	reportsTabState: TTabStateTypes;
	/** Индекс редактируемого `отчета` */
	reportIndex?: number;
};


//! ========== Provider types ==========
export interface IProvidersNavigate {
	navigateToListPage: () => void;
	navigateToNewPage:  () => void;
	navigateToEditPage: (code?: string | number) => void;
};

interface IServiceHelpers {
	chosenService: ServiceDTO | null;
	setChosenService: React.Dispatch<React.SetStateAction<ServiceDTO | null>>;
	setServicesView: (view: TTabStateTypes, serviceIndex?: number | undefined) => void;
	setServiceNoticesView: (view: TTabStateTypes, noticeIndex?: number | undefined) => void;
};
interface IRetailOutletHelpers {
	chosenRetailOutlet: RetailOutletDTO | null;
	setChosenRetailOutlet: React.Dispatch<React.SetStateAction<RetailOutletDTO | null>>;
	setRetailOutletsView: (view: TTabStateTypes, retailOutletIndex?: number | undefined) => void;
	setRetailOutletNoticesView: (view: TTabStateTypes, retailOutletNoticeIndex?: number | undefined) => void;
	setRetailOutletContactsView: (view: TTabStateTypes, retailOutletContactIndex?: number | undefined) => void;
	setRetailOutletCashBoxesView: (view: TTabStateTypes, retailOutletCashBoxIndex?: number | undefined) => void;
};
interface IContactHelpers {
	chosenContact: IContact | null;
	setChosenContact: React.Dispatch<React.SetStateAction<IContact | null>>;
	setContactsView: (view: TTabStateTypes, contactIndex?: number | undefined) => void;
};
interface INoticeHelpers {
	chosenNotice: Notice | null;
	setChosenNotice: React.Dispatch<React.SetStateAction<Notice | null>>;
	setNotifyParamsView: (view: TTabStateTypes, noticeIndex?: number | undefined) => void;
};
interface IReportHelpers {
	chosenReport: IServiceProviderReport | null;
	setChosenReport: React.Dispatch<React.SetStateAction<IServiceProviderReport | null>>;
	setReportsView: (view: TTabStateTypes, reportIndex?: number | undefined) => void;
};

export interface IUseProvidersUIContext extends IProvidersNavigate {
	queryParams:                 IGetServiceProvidersAllParams,
	setQueryParams:              (nextQueryParams?: IGetServiceProvidersAllParams) => void;
	onTableFilterChange:         (values: IGetServiceProvidersAllParams['filter']) => void;
	onTableRowExpand:            (id: string) => void;
	onServiceProviderFormSubmit: (values: ServiceProviderDTO) => void;

	providersFormState:        IProviderLocationState;
	initProvidersFormState:    IProviderLocationState;
	setProvidersFormState:     React.Dispatch<React.SetStateAction<IProviderLocationState>>;
	changeFormTab: (tabPanel?: ETabPanels) => void;

	serviceHelpers:      IServiceHelpers;
	retailOutletHelpers: IRetailOutletHelpers;
	contactHelpers:      IContactHelpers;
	noticeHelpers:       INoticeHelpers;
	reportHelpers:       IReportHelpers;

	updateServiceProviderIIIWithModal: (code: number, iiiData?: IServiceProviderIII | null) => void;
	isProviderHasToken: boolean;
	clientSecret: string;
};


//! ========== useCRUD types ==========
export interface IUseProvidersCRUDReturn extends IModuleState<IServiceProvider> {
	getServiceProvidersForTable: (params: IGetServiceProvidersAllParams) => Promise<void>;
	getServiceProviderFullData:  (code: number) => Promise<void>;
	setInitServiceProvider:      () => void;
	saveServiceProvider:         (values: IServiceProvider) => Promise<boolean>;
	changeServiceProviderState:  (code: number, state: ServiceProviderStateEnum | undefined) => void;
	updateServiceProviderIII:    (code: number, iiiData?: IServiceProviderIII | null) => Promise<boolean>;
};
