import { add, format, isToday, isValid, set } from 'date-fns';
import { ru, enGB } from 'date-fns/locale';
// Types
import { Locale } from 'date-fns';


export function formatDate(date: any, resultFormat: string = 'dd.MM.yyyy', lang: Locale = ru): string | null {
	if (date == null) return null;

	const dateObj = new Date(date);
	// Если date не instanseof Date или не строка, преобразующаяся к Date
	if (isNaN(Number(dateObj))) return null;

	// if (typeof resultFormat === 'string' && resultFormat.toLowerCase() === 'date') return dateObj;

	return format(dateObj, resultFormat, {locale: lang});

/**
 * *========= Standard Date and Time Format Specifiers ==============
 * ?Format|                Description                   |    Example
 * s      | The seconds of the minute between 0-59.      | 0 to 59
 * ss     | The seconds of the minute with leading zero. | 00 to 59
 * m      | The minute of the hour between 0-59.         | 0 to 59
 * mm     | The minute of the hour with leading zero.    | 00 to 59
 * h      | The hour of the day between 1-12.            | 1 to 12
 * hh     | The hour of the day with leading zero.       | 01 to 12
 * H      | The hour of the day between 0-23.            | 0 to 23
 * HH     | The hour of the day with leading zero.       | 00 to 23
 * d      | The day of the month between 1 and 31.       | 1 to 31
 * dd     | The day of the month with leading zero.      | 01 to 31
 * E      | Abbreviated day name.                        | Mon to Sun
 * EEEE   | The full day name.                           | Monday to Sunday
 * M      | The month of the year between 1-12.          | 1 to 12
 * MM     | The month of the year with leading zero.     | 01 to 12
 * MMM    | Abbreviated month name.                      | Jan to Dec
 * MMMM   | The full month name.                         | January to December
 * y      | Displays the year as a two-digit number.     | 99 or 07
 * yyyy   | Displays the full four digit year.           | 1999 or 2007
 * aaaa   | Displays the first character of the A.M./P.M.| A or P
 * o      | The ordinal suffix of the current day.       | st, nd, rd, or th
 * all the features https://date-fns.org/v2.8.1/docs/format
 */
}


/**
 * Метод преобразовывает входящую локальную дату в UTC, не сдвигая время
 * @param date локальная дата instanseof Date
 * @returns UTC дата instanseof Date временем, идентичным входящей дате
 */
export function removeDateTimezoneOffset(date: Date): Date {
	// Вычислить разницу локального времени с UTC в ms
	const userTimezoneOffset = date.getTimezoneOffset() * 60000;
	// Создать локальный Date без смещения часовых поясов на основе timestamp 
	const dateWithoutTimezoneOffset = new Date(date.getTime() + userTimezoneOffset);
	return dateWithoutTimezoneOffset;
}


/**
 * Метод возвращает входящую дату с T00:00:00
 * @param date локальная дата instanseof Date
 * @returns дата instanseof Date со временем T00:00:00
 */
export function setDateMidnight(date: Date) {
	return set(date, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
}


interface IPrepareDateOpts {
	midnight?: boolean;
	utc?: boolean;
	add?: {
		years?:   number | undefined;
		months?:  number | undefined;
		weeks?:   number | undefined;
		days?:    number | undefined;
		hours?:   number | undefined;
		minutes?: number | undefined;
		seconds?: number | undefined;
	};
	set?: {
		year?:    number | undefined;
		month?:   number | undefined;
		date?:    number | undefined;
		hours?:   number | undefined;
		minutes?: number | undefined;
		seconds?: number | undefined;
	};
};
export function prepareDate(dateObj?: Date | null | undefined, opts?: IPrepareDateOpts): Date {
	let d: Date = dateObj ? new Date(dateObj) : new Date();
	if (opts === undefined) return d;

	if (opts.midnight) {
		d = setDateMidnight(d);
	}
	d = set(d, { ...opts.set, milliseconds: 0 });
	d = add(d, { ...opts.add });
	if (opts.utc) {
		d = removeDateTimezoneOffset(d);
	}

	return d;
}


export function isDateToday(date: Date | string | null | undefined): boolean {
	if (date === undefined) return false;
	if (date === null) return false;

	const dateObj = new Date(date);
	if (!isValid(dateObj)) return false;

	return isToday(dateObj);
}


export function prepareEndDate(date: Date | string | null | undefined): Date | null | undefined {
	if (date === undefined) return date;
	if (date === null) return date;
	if (date === '') return null;

	const dateObj = new Date(date);
	if (!isValid(dateObj)) return null;

	return prepareDate(dateObj, { add: { days: 1, seconds: -1 } });
}
