import React from 'react';
import { Link } from 'react-router-dom';

import {
	Box,
} from '@mui/material';

import { HeaderBar } from 'app/components/headerComponents/HeaderBar';
import { EposLogotype } from 'app/components/headerComponents/EposLogotype';
import { ProfileToggler } from 'app/components/headerComponents/ProfileToggler';


const AppHeader: React.FC = () => {
	return (
		<HeaderBar>
			<Box flexShrink={0}>
				<Link to="/">
					<EposLogotype />
				</Link>
			</Box>

			<ProfileToggler/>
		</HeaderBar>
	);
}

export { AppHeader };
