import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDirectoryCRUD } from 'hooks';
import { useFormikContext, useField } from 'formik';
import {
	Autocomplete,
	TextField,
} from '@mui/material';
import { autocompleteSxProp } from './_sxProps';
// Types
import { IMCC } from 'apis/cabinetApi/extraTypes';
import { AppAsyncAutocomplete } from '../AppAsyncAutocomplete';
import { eposCabinetApiCalls } from 'apis/cabinetApi/cabinetApiCalls';


const MccAutocomplete: React.FC<{ name: string }> = ({ name }) => {
	const { t } = useTranslation();
	const { MCCs } = useDirectoryCRUD();

	const { setFieldValue } = useFormikContext();

	const [ field, meta ] = useField(name);
	const { touched, error } = meta;

	const [autocompleteValue, setAutocompleteValue] = useState<IMCC | null>(null);
	useEffect(() => {
		const ititAutocompleteValue = MCCs.find((mcc) => mcc.code === field.value) || null;
		setAutocompleteValue(ititAutocompleteValue);
	}, [MCCs]);


	return (
		<Autocomplete
			options={MCCs}
			getOptionLabel={(mcc) => `(${mcc.code}) ${mcc.name}`}
			value={autocompleteValue}
			onChange={(event, value) => {
				setAutocompleteValue(value);
				setFieldValue(name, value?.code);
			}}
			renderInput={(params) => (
				<TextField
					{...params}
					{...field}
					error={Boolean(touched && error)}
					helperText={touched && error && t(`validation.${error}`)}
					sx={autocompleteSxProp}
				/>
			)}
			forcePopupIcon={false}
		/>
	);
}


const MccAsyncAutocomplete: React.FC<{ mccName: string }> = ({ mccName }) => {
	const [mccField, mccMeta, { setValue }] = useField<IMCC>(mccName);
	const [codeField, codeMeta] = useField(`${mccName}.code`);

	const [autocompleteValue, setAutocompleteValue] = useState<IMCC | null>(null);

	const autocompleteChangeHandler = useCallback((value: IMCC | null) => {
		setAutocompleteValue(value);
		if (value) {
			setValue(value);
		} else {
			setValue({
				code: null,
			});
		}
	}, [setValue]);

	// Установка init значения автокомплита
	useEffect(() => {
		if (mccMeta.initialValue && codeMeta.initialValue) {
			setAutocompleteValue(mccMeta.initialValue);
		}
	}, [codeMeta.initialValue]);


	return (
		<AppAsyncAutocomplete
			name={`${mccName}.code`}
			autocompleteValue={autocompleteValue}
			onAutocompleteChange={autocompleteChangeHandler}
			getOptionsAsync={(code, count) => {
				return eposCabinetApiCalls.getMCCsAll({
					skip: 0,
					count,
					filter: { code }
				});
			}}
			getOptionLabel={(option: IMCC) => `(${option.code}) ${option.name}`}
			maxOptionsLength={262}
		/>
	);
}


MccAutocomplete.displayName = 'MccAutocomplete';
export { MccAutocomplete };
