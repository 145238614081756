import React, { createContext, useContext, useState, useCallback, useMemo, useEffect, useLayoutEffect } from 'react';
import { isEqual } from 'lodash';
import { applyTableFilter, initQueryParams } from 'utils';
import { useServiceProvidersCRUD } from 'hooks';
import { serviceProviderSchema } from 'app/yup';
// Types
import {
	IGetServiceProvidersAllParams,
	IContact,
	IServiceProviderReport,
} from 'apis/cabinetApi/extraTypes'
import {
	IProviderLocationState,
	IProvidersNavigate,
	IUseProvidersUIContext,
	Notice,
	ETabPanels,
	TTabStateTypes,
	ServiceProviderDTO,
	ServiceDTO,
	RetailOutletDTO,
} from 'app/modules/serviceProviders/types';
import { useTranslation } from 'react-i18next';
import { useLayoutUIContext } from 'app/providers';
import { useLocation } from 'react-router-dom';


// Трюк с undefined в качестве init value контекста
// https://stackoverflow.com/questions/58193424/passing-state-with-usecontext-in-typescript
const ProvidersContext = createContext<IUseProvidersUIContext | undefined>(undefined);
const useProvidersUIContext = () => {
	const contextValue = useContext(ProvidersContext);
	if (contextValue === undefined) throw new Error('Expected context value to be set');
	return contextValue;
};


const ProvidersUIProvider: React.FC<{ navigateActions: IProvidersNavigate }> = ({ navigateActions, children }) => {
	const { t } = useTranslation(undefined, { keyPrefix: 'schemas.ServiceProvider' });

	const {
		getServiceProvidersForTable,
		getServiceProviderFullData,
		saveServiceProvider,
		updateServiceProviderIII,
		edittingEntity
	} = useServiceProvidersCRUD();

	const { showConfirmModal } = useLayoutUIContext();


	//* ========== Логика таблицы ==========
	// При переходе пользователя - поиск по УНП ПУ пользователя
	const unpFromRouterState = useLocation().state as string | undefined;
	const [queryParams, setQueryParamsBase] = useState<IGetServiceProvidersAllParams>({
		...initQueryParams,
		filter: {
			...initQueryParams.filter,
			searchString: unpFromRouterState ?? ''
		}
	});
	const setQueryParams = useCallback((nextQueryParams?: IGetServiceProvidersAllParams | null) => {
		if (nextQueryParams) {
			setQueryParamsBase((prevQueryParams) => {
				if (isEqual(prevQueryParams, nextQueryParams)) {
					return prevQueryParams;
				} else {
					return nextQueryParams;
				}
			});
		} 
		if (nextQueryParams === null) {
			setQueryParamsBase({ ...initQueryParams });
			return;
		}
		if (nextQueryParams === undefined) {
			setQueryParamsBase((prevQueryParams) => ({ ...prevQueryParams }));
		}
		
	}, []);

	// Запрос на обновление records при изменении queryParams через пагинацию или фильтры
	useEffect(() => {
		getServiceProvidersForTable(queryParams);
	}, [getServiceProvidersForTable, queryParams]);

	const onTableFilterChange = useCallback((values: typeof queryParams.filter) => {
		applyTableFilter(queryParams, setQueryParams, values);
	}, [queryParams, setQueryParams]);

	const onTableRowExpand = useCallback((rowId: string) => {
		const code = Number(rowId);
		if (edittingEntity.code !== code) {
			return getServiceProviderFullData(code);
		}
	}, [getServiceProviderFullData, edittingEntity]);


	//* ========== Логика формы ==========
	const onServiceProviderFormSubmit = useCallback(async (values: ServiceProviderDTO) => {
		const castedValues = serviceProviderSchema.cast(values);
		// Ошибка Тип "null" не может быть назначен для типа "number | undefined".
		// const convertedServiceProvider = new ServiceProviderDTO(castedValues);
		const convertedServiceProvider = new ServiceProviderDTO(castedValues as ServiceProviderDTO);
		const result = await saveServiceProvider(convertedServiceProvider);
		if (result) {
			setQueryParams();
			setTimeout(() => {
				navigateActions.navigateToListPage();
			}, 0);
		}
	}, []);


	//* ========== Табы ==========
	const initProvidersFormState: IProviderLocationState = {
		/**/ tabPanel: ETabPanels.main,
		/**/ servicesTabState: 'list',
		/**/ serviceNoticesState: 'list',
		/**/ retailOutletsTabState: 'list',
		/**/ retailOutletNoticesState: 'list',
		/**/ retailOutletContactsState: 'list',
		/**/ retailOutletCashBoxesState: 'list',
		/**/ contactsTabState: 'list',
		/**/ notifyParamsTabState: 'list',
		/**/ reportsTabState: 'list',
	};
	const [providersFormState, setProvidersFormState] = useState<IProviderLocationState>(initProvidersFormState);
	const changeFormTab = useCallback((tabPanel: ETabPanels = ETabPanels.main) => {
		setProvidersFormState(prev => ({ ...prev, tabPanel }));
	}, []);

	const [chosenService, setChosenService] = useState<ServiceDTO | null>(null);
	const serviceHelpers = useMemo(() => ({
		chosenService,
		setChosenService,
		setServicesView: (view: TTabStateTypes, serviceIndex?: number) => {
			if (view !== 'list' && serviceIndex === undefined) {
				throw new Error('Не передал в setServicesView serviceIndex!');
			}
			setProvidersFormState((prev) => ({
				...prev,
				tabPanel: ETabPanels.services,
				servicesTabState: view,
				serviceIndex: (view === 'list') ? undefined : serviceIndex
			}));
		},
		setServiceNoticesView: (view: TTabStateTypes, noticeIndex?: number) => {
			if (view !== 'list' && noticeIndex === undefined) {
				throw new Error('Не передал в setServiceNoticesView noticeIndex!');
			}
			setProvidersFormState((prev) => ({
				...prev,
				tabPanel: ETabPanels.services,
				serviceNoticesState: view,
				serviceNoticeIndex: (view === 'list') ? undefined : noticeIndex
			}));
		}
	}), [chosenService]);

	const [chosenRetailOutlet, setChosenRetailOutlet] = useState<RetailOutletDTO | null>(null);
	const retailOutletHelpers = useMemo(() => ({
		chosenRetailOutlet,
		setChosenRetailOutlet,
		setRetailOutletsView: (view: TTabStateTypes, retailOutletIndex?: number) => {
			if (view !== 'list' && retailOutletIndex === undefined) {
				throw new Error('Не передал в setServicesView serviceIndex!');
			}
			setProvidersFormState((prev) => ({
				...prev,
				tabPanel: ETabPanels.retailOutlets,
				retailOutletsTabState: view,
				retailOutletIndex: (view === 'list') ? undefined : retailOutletIndex
			}));
		},
		setRetailOutletNoticesView: (view: TTabStateTypes, retailOutletNoticeIndex?: number) => {
			if (view !== 'list' && retailOutletNoticeIndex === undefined) {
				throw new Error('Не передал в setServicesView serviceIndex!');
			}
			setProvidersFormState((prev) => ({
				...prev,
				tabPanel: ETabPanels.retailOutlets,
				retailOutletNoticesState: view,
				retailOutletNoticeIndex: (view === 'list') ? undefined : retailOutletNoticeIndex
			}));
		},
		setRetailOutletContactsView: (view: TTabStateTypes, retailOutletContactIndex?: number) => {
			if (view !== 'list' && retailOutletContactIndex === undefined) {
				throw new Error('Не передал в setServicesView serviceIndex!');
			}
			setProvidersFormState((prev) => ({
				...prev,
				tabPanel: ETabPanels.retailOutlets,
				retailOutletContactsState: view,
				retailOutletContactIndex: (view === 'list') ? undefined : retailOutletContactIndex
			}));
		},
		setRetailOutletCashBoxesView: (view: TTabStateTypes, retailOutletCashBoxIndex?: number) => {
			if (view !== 'list' && retailOutletCashBoxIndex === undefined) {
				throw new Error('Не передал в setServicesView serviceIndex!');
			}
			setProvidersFormState((prev) => ({
				...prev,
				tabPanel: ETabPanels.retailOutlets,
				retailOutletCashBoxesState: view,
				retailOutletCashBoxIndex: (view === 'list') ? undefined : retailOutletCashBoxIndex
			}));
		}
	}), [chosenRetailOutlet]);

	const [chosenContact, setChosenContact] = useState<IContact | null>(null);
	const contactHelpers = useMemo(() => ({
		chosenContact,
		setChosenContact,
		setContactsView: (view: TTabStateTypes, contactIndex?: number) => {
			if (view !== 'list' && contactIndex === undefined) {
				throw new Error('Не передал в setServicesView serviceIndex!');
			}
			setProvidersFormState((prev) => ({
				...prev,
				tabPanel: ETabPanels.contacts,
				contactsTabState: view,
				contactIndex: (view === 'list') ? undefined : contactIndex
			}));
		},
	}), [chosenContact]);

	const [chosenNotice, setChosenNotice] = useState<Notice | null>(null);
	const noticeHelpers = useMemo(() => ({
		chosenNotice,
		setChosenNotice,
		setNotifyParamsView: (view: TTabStateTypes, noticeIndex?: number) => {
			if (view !== 'list' && noticeIndex === undefined) {
				throw new Error('Не передал в setServicesView serviceIndex!');
			}
			setProvidersFormState((prev) => ({
				...prev,
				tabPanel: ETabPanels.notifyParams,
				notifyParamsTabState: view,
				noticeIndex: (view === 'list') ? undefined : noticeIndex
			}));
		}
	}), [chosenNotice]);

	const [chosenReport, setChosenReport] = useState<IServiceProviderReport | null>(null);
	const reportHelpers = useMemo(() => ({
		chosenReport,
		setChosenReport,
		setReportsView: (view: TTabStateTypes, reportIndex?: number) => {
			if (view !== 'list' && reportIndex === undefined) {
				throw new Error('Не передал в setReportsView reportIndex!');
			}
			setProvidersFormState((prev) => ({
				...prev,
				tabPanel: ETabPanels.reports,
				reportsTabState: view,
				reportIndex: (view === 'list') ? undefined : reportIndex
			}));
		},
	}), [chosenReport]);

	// 
	const [isProviderHasToken, setProviderHasToken] = useState<boolean>(false);
	const [clientSecret, setClientSecret] = useState<string>('');
	useLayoutEffect(() => {
		setClientSecret(edittingEntity.iiiInfo?.clientSecret ?? '');
		if (edittingEntity.iiiInfo?.clientId) {
			setProviderHasToken(true);
		}
	}, [edittingEntity]);
	const updateServiceProviderIIIWithModal = useCallback<IUseProvidersUIContext['updateServiceProviderIIIWithModal']>((code, iiiData) => {
		showConfirmModal({
			title:    t('ACTIONS.III'),
			text:     t('CONFIRM_MODAL.III_?'),
			callback: async () => {
				const result = await updateServiceProviderIII(code, iiiData);
				if (result) {
					setProviderHasToken(true);
					setClientSecret(iiiData?.clientSecret ?? '');
				}
			},
			action:   t('CONFIRM_MODAL.III_!')
		});
	}, [edittingEntity]);


	return (
		<ProvidersContext.Provider
			value={{
				queryParams,
				setQueryParams,
				onTableFilterChange,
				onTableRowExpand,
				onServiceProviderFormSubmit,

				providersFormState,
				initProvidersFormState,
				setProvidersFormState,
				changeFormTab,

				serviceHelpers,
				retailOutletHelpers,
				contactHelpers,
				noticeHelpers,
				reportHelpers,
				// serviceProvider iii 
				updateServiceProviderIIIWithModal,
				isProviderHasToken,
				clientSecret,

				...navigateActions
			}}
		>
			{children}
		</ProvidersContext.Provider>
	);
}


export { ProvidersUIProvider, useProvidersUIContext };
